import {Icon, Pagination} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import FeedbackModal, {FeedbackModalProps} from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import {AuthRoutes} from 'routes';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import Table from 'components/Table';
import {
  useDeleteGenerativePrompt,
  useFetchGenerativePromptsLazy,
} from 'store/reducers/generativePrompts';
import {usePrompts} from 'features/usePrompts';
import PageWrapper from '../../components/PageWrapper';
import styles from './GenerativePromptList.module.scss';

type TableRow = [
  title: string,
  type: string,
  competence: string,
  buttons: JSX.Element,
];

function GenerativePromptList(): JSX.Element {
  const [currentPromptListPage, setCurrentPromptListPage] = useState(1);
  const [numberOfPromptsPerPage, setNumberOfPromptsPerPage] =
    useState<SelectOption>({label: '10', value: '10'});

  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const [isDeletePromptModalOpen, setIsDeletePromptModalOpen] = useState(false);
  const [generativePromptIdToDelete, setGenerativePromptIdToDelete] =
    useState(0);
  const {responseFeedbacks} = usePrompts();

  const [
    fetchGenerativePromptsLazy,
    {
      isFetching: isFetchingGenerativePrompts,
      isLoading: isLoadingGenerativePrompts,
      isUninitialized: isFetchGenerativePromptsUninitialized,
      data: generativePromptList,
    },
  ] = useFetchGenerativePromptsLazy();
  const [
    deleteGenerativePrompt,
    {
      isLoading: isDeletingGenerativePrompt,
      isSuccess: isDeleteGenerativePromptSuccess,
      isError: isDeleteGenerativePromptError,
      status: deleteGenerativePromptStatus,
      reset: resetDeleteGenerativePromptStatus,
    },
  ] = useDeleteGenerativePrompt();

  const totalNumberOfTablePages = useMemo(
    () =>
      generativePromptList?.total
        ? Math.ceil(
            generativePromptList.total / Number(numberOfPromptsPerPage?.value),
          )
        : 0,
    [generativePromptList],
  );

  const history = useHistory();

  // Opening feedback modal for API responses
  useEffect(() => {
    if (isDeleteGenerativePromptError || isDeleteGenerativePromptSuccess) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [deleteGenerativePromptStatus]);

  function fetchPrompts() {
    const offset =
      (currentPromptListPage - 1) * Number(numberOfPromptsPerPage?.value);
    const limit = numberOfPromptsPerPage?.value;
    fetchGenerativePromptsLazy(
      {
        limit,
        offset,
      },
      true,
    );
  }

  // Refetching prompts on current page or prompts per page change
  useEffect(() => {
    fetchPrompts();
  }, [currentPromptListPage, numberOfPromptsPerPage?.value]);

  // Refetching prompts after deletion
  useEffect(() => {
    if (isDeleteGenerativePromptSuccess) {
      fetchPrompts();
    }
  }, [isDeleteGenerativePromptSuccess]);

  const isLoading =
    isFetchingGenerativePrompts ||
    isLoadingGenerativePrompts ||
    isDeletingGenerativePrompt;
  const hasFirstPromptsFetchHappened = !isFetchGenerativePromptsUninitialized;

  function onClickDeletePrompt(promptId: number) {
    setGenerativePromptIdToDelete(promptId);
    setIsDeletePromptModalOpen(true);
  }

  function onDeletePrompt() {
    deleteGenerativePrompt({generativePromptId: generativePromptIdToDelete});
    setIsDeletePromptModalOpen(false);
  }

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isDeleteGenerativePromptError || isDeleteGenerativePromptSuccess) {
      return 'deletePrompt';
    }

    return 'default';
  }

  function getResponseMessage(): string {
    if (isDeleteGenerativePromptSuccess) {
      return responseFeedbacks.deletePrompt.success.message;
    }
    if (isDeleteGenerativePromptError) {
      return responseFeedbacks.deletePrompt.error.message;
    }

    return '';
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isDeleteGenerativePromptSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    function onCloseModalCallback() {
      setIsResponseFeedbackModalOpen(false);
      resetDeleteGenerativePromptStatus();
    }

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseModalCallback,
      onClose: onCloseModalCallback,
    };
  }

  function onSubmitSearch() {
    setCurrentPromptListPage(1);

    fetchPrompts();
  }

  function resetTableData() {
    setCurrentPromptListPage(1);

    fetchGenerativePromptsLazy(
      {
        limit: numberOfPromptsPerPage?.value,
        offset:
          (currentPromptListPage - 1) * Number(numberOfPromptsPerPage?.value),
      },
      true,
    );
  }

  const tableHeaders = ['Título', 'Tipo', 'Competência', 'Ações'];

  const tableRows: TableRow[] =
    generativePromptList?.results?.reduce(
      (accumulator: TableRow[], {id, title, type, competence}) => {
        return [
          ...accumulator,
          [
            title,
            type,
            competence,
            <div key={`prompt-${id}-actions`} className={styles.buttonWrapper}>
              <Link
                to={`/prompt-generativo/${id}`}
                title="Ir aos detalhes do prompt generativo"
              >
                <Icon icon={['fas', 'external-link']} color="#666" size="lg" />
              </Link>

              <button
                type="button"
                className={styles.deleteButton}
                title="Excluir prompt generativo"
                onClick={() => onClickDeletePrompt(id)}
              >
                <Icon icon={['fas', 'trash-alt']} color="#666" size="lg" />
              </button>
            </div>,
          ],
        ];
      },
      [],
    ) ?? [];

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader
          title="Prompts Generativos"
          buttonText="Criar prompt generativo"
          onClickButton={() =>
            history.push(AuthRoutes.generativePromptCreation)
          }
        />

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingTable numberOfRows={6} />
          </div>
        )}

        {!isLoading &&
          hasFirstPromptsFetchHappened &&
          !generativePromptList?.results?.length && (
            <NoResults resetData={resetTableData} />
          )}

        {!isLoading && !!generativePromptList?.results?.length && (
          <div className={styles.tableWrapper}>
            <TableHeader
              onSubmitSearch={onSubmitSearch}
              setNumberOfResultsPerPage={setNumberOfPromptsPerPage}
              numberOfResultsPerPage={numberOfPromptsPerPage}
            />

            <Table tableHeaders={tableHeaders} tableContents={tableRows} />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPromptListPage}
                hasNext={currentPromptListPage < totalNumberOfTablePages}
                hasPrevious={currentPromptListPage > 1}
                totalPages={totalNumberOfTablePages}
                onChange={(page) => setCurrentPromptListPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      {isDeletePromptModalOpen && (
        <DeletionModal
          isOpen
          message="Deseja mesmo excluir esse prompt generativo?"
          onClickDelete={onDeletePrompt}
          onClose={() => setIsDeletePromptModalOpen(false)}
          subtitle="Tem certeza?"
          title="Excluir prompt"
        />
      )}

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default GenerativePromptList;
