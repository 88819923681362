import styles from './PromptUnifiedPreview.module.scss';

export interface Prompt {
  id: number;
  title: string;
  description: string;
}

export interface PromptPreviewProps {
  prompts: Prompt[];
}

function PromptUnifiedPreview({prompts}: PromptPreviewProps): JSX.Element {
  return (
    <section className={styles.scroll}>
      <header>
        <h2>Preview do Prompt</h2>
      </header>
      <div className={styles.wrapper}>
        {prompts.map((prompt) => (
          <div key={prompt.id} className={styles.block}>
            <div className={styles.blockHeader}>{prompt.title}</div>

            <div className={styles.blockContent}>{prompt.description}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default PromptUnifiedPreview;
