import {Icon, InputText} from 'letrus-ui';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {useFetchPromptUnifiedById} from 'store/reducers/promptsUnified';
import {useFetchPromptsLazy} from 'store/reducers/prompts';
import {useFetchCorpusCompetences} from 'store/reducers/corpus';
import UnifiedPromptBlocksInfoModal from 'components/UnifiedPromptBlocksInfoModal';
import PageHeader from 'components/PageHeader';
import SelectOption from 'utils/types/SelectOption';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import PromptUnifiedPreview from 'components/PromptUnifiedPreview';
import {usePromptsUnified, PromptCompetences} from 'features/usePromptsUnified';
import PageWrapper from '../../components/PageWrapper';
import styles from './PromptUnifiedVisualization.module.scss';

interface RouteParams {
  unified_prompt_id: string;
}

interface FormValues {
  name: string;
  prompts: SelectOption[];
  prompt_competences: PromptCompetences[];
}

function PromptUnifiedVisualization(): JSX.Element {
  const {unified_prompt_id} = useParams<RouteParams>();
  const [
    isUnifiedPromptBlocksInfoModalOpen,
    setIsUnifiedPromptBlocksInfoModalOpen,
  ] = useState(false);

  const {getFormFieldError} = usePromptsUnified();

  const {data: promptUnified, isLoading: isLoadingPromptUnified} =
    useFetchPromptUnifiedById({
      id: Number(unified_prompt_id),
    });
  const [
    fetchPromptsLazy,
    {data: promptListResponse, isLoading: isLoadingPromptList},
  ] = useFetchPromptsLazy();
  const {data: corpusCompetences, isLoading: isLoadingCorpusCompetences} =
    useFetchCorpusCompetences({
      limit: 100,
    });

  const isLoading =
    isLoadingPromptList || isLoadingCorpusCompetences || isLoadingPromptUnified;

  const {
    control: formControl,
    setValue: setFormValue,
    watch: formWatch,
    formState: {errors: formErrors},
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {prompt_competences: []},
  });
  const {fields, append, remove} = useFieldArray({
    name: 'prompt_competences',
    control: formControl,
  });
  const [promptCompetences, prompts] = formWatch([
    'prompt_competences',
    'prompts',
  ]);
  const history = useHistory();

  useEffect(() => {
    if (promptUnified && promptListResponse?.results) {
      setFormValue('name', promptUnified.name);
      const newPrompts = promptUnified.prompts.reduce<SelectOption[]>(
        (accumulator, currentPromptUnified) => {
          const promptInList = promptListResponse.results.find(
            (prompt) => prompt.id === currentPromptUnified.prompt_template_id,
          );
          if (promptInList) {
            return [
              ...accumulator,
              {label: promptInList.title, value: String(promptInList.id)},
            ];
          }
          return accumulator;
        },
        [],
      );
      setFormValue('prompts', newPrompts);
    }
  }, [promptUnified, promptListResponse]);

  useEffect(() => {
    fetchPromptsLazy(
      {
        limit: 9999,
      },
      true,
    );
  }, []);

  useEffect(() => {
    const filteredPrompts = prompts
      ? prompts.filter(
          (prompt) =>
            !fields.length ||
            !fields.find((field) => field.prompt_id === Number(prompt.value)),
        )
      : [];

    if (filteredPrompts.length && promptUnified && corpusCompetences?.results) {
      const newPromptsWithCompetences = filteredPrompts.map((prompt) => {
        const promptCompetences = promptUnified.prompts.find(
          (promptOutput) =>
            promptOutput.prompt_template_id === Number(prompt.value),
        )?.expected_outputs;

        const competences: SelectOption[] = [];

        if (promptCompetences) {
          Object.keys(promptCompetences).forEach((key) => {
            const competence = corpusCompetences.results
              .concat([{id: 99999, name: 'general_comment'}])
              .find(
                (competence) =>
                  competence.name.toLowerCase() === key.toLowerCase(),
              );
            if (competence) {
              competences.push({
                label: competence.name,
                value: String(competence.id),
              });
            }
          });
        }

        return {
          prompt_id: Number(prompt.value),
          prompt_name: prompt.label,
          competences,
        };
      });
      append(newPromptsWithCompetences);
    }

    const promptsToRemove = fields
      .map((field, index) => ({...field, index}))
      .filter(
        (field) =>
          !prompts?.find((prompt) => field.prompt_id === Number(prompt.value)),
      );

    if (promptsToRemove.length) {
      const indexesToRemove = promptsToRemove.map((prompt) => prompt.index);
      remove(indexesToRemove);
    }
  }, [prompts, corpusCompetences, promptUnified]);

  return (
    <PageWrapper>
      <form className={styles.container}>
        <PageHeader
          title="Visualizar prompt"
          onGoBackButtonClick={history.goBack}
          rightElement={<div />}
        />

        {isLoading && <LoadingForm numberOfInputs={6} />}

        {!isLoading && promptListResponse?.results && promptUnified && (
          <fieldset>
            {prompts?.length ? (
              <div className={styles.previewWrapper}>
                <PromptUnifiedPreview
                  prompts={prompts.map((prompt) => {
                    const promptInList = promptListResponse.results.find(
                      (promptListItem) =>
                        promptListItem.id === Number(prompt.value),
                    );
                    return {
                      id: promptInList?.id || Number(prompt.value),
                      title: promptInList?.title || prompt.label,
                      description: promptInList?.description || prompt.label,
                    };
                  })}
                />
              </div>
            ) : (
              <div />
            )}

            <div className={styles.fieldsWrapper}>
              <Controller
                control={formControl}
                name="name"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <>
                    <InputText
                      id="title"
                      labelText="Nome do prompt"
                      placeholder="Ex: Prompt para validar competência 1"
                      name={name}
                      disabled
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value ?? ''}
                      errorMessage={getFormFieldError(formErrors?.name?.type)}
                    />
                  </>
                )}
              />

              <label htmlFor="prompts">
                Inserir prompts
                <button
                  type="button"
                  className={styles.helpIconButton}
                  onClick={() => setIsUnifiedPromptBlocksInfoModalOpen(true)}
                >
                  <Icon icon={['far', 'question-circle']} size="1x" />
                </button>
              </label>
              <Controller
                control={formControl}
                name="prompts"
                rules={{
                  required: true,
                }}
                render={({field: {name, onChange, value}}) => (
                  <>
                    <Select
                      options={promptListResponse.results.map((prompt) => ({
                        label: prompt.title,
                        value: String(prompt.id),
                      }))}
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          maxWidth: '300px',
                        }),
                      }}
                      isMulti
                      onChange={onChange}
                      value={value}
                      name={name}
                      isDisabled
                      inputId="prompts"
                      placeholder="Selecione os prompts"
                    />
                    {formErrors?.prompts && (
                      <label
                        htmlFor="prompts"
                        className={styles.error}
                        id="error"
                      >
                        {(formErrors?.prompts as any)?.message
                          ? (formErrors?.prompts as any)?.message
                          : getFormFieldError(
                              (formErrors?.prompts as any)?.type,
                            )}
                      </label>
                    )}
                  </>
                )}
              />

              {!!fields?.length &&
                fields.map((field, index) => (
                  <div key={field.id}>
                    <label htmlFor={`prompt_competences.${index}.competences`}>
                      {field.prompt_name}
                    </label>
                    <Controller
                      control={formControl}
                      name={`prompt_competences.${index}.competences` as const}
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <>
                          <Select
                            styles={{
                              ...reactSelectStyles,
                              container: (provided) => ({
                                ...provided,
                                minWidth: 300,
                              }),
                            }}
                            onChange={onChange}
                            options={corpusCompetences?.results
                              ?.concat([{id: 99999, name: 'general_comment'}])
                              .filter(
                                (competence) =>
                                  !promptCompetences.reduce(
                                    (accumulator, prompt_competence) => {
                                      if (
                                        prompt_competence.competences?.find(
                                          (promptCompetence) =>
                                            competence.id ===
                                            Number(promptCompetence.value),
                                        )
                                      ) {
                                        return true;
                                      }
                                      return accumulator;
                                    },
                                    false,
                                  ),
                              )
                              .map(({id, name}) => ({
                                label: name,
                                value: String(id),
                              }))}
                            isMulti
                            ref={ref}
                            isDisabled
                            onBlur={onBlur}
                            value={
                              promptCompetences.find(
                                (promptCompetence) =>
                                  promptCompetence.prompt_id ===
                                  field.prompt_id,
                              )?.competences || value
                            }
                            placeholder="Selecione as competências"
                            name={name}
                            inputId={name}
                          />
                          {formErrors?.prompt_competences && (
                            <label
                              htmlFor={`prompt_competences.${index}.competences`}
                              className={styles.error}
                              id="error"
                            >
                              {getFormFieldError(
                                (
                                  formErrors?.prompt_competences &&
                                  (formErrors?.prompt_competences[index]
                                    ?.competences as any)
                                )?.type,
                              )}
                            </label>
                          )}
                        </>
                      )}
                    />
                  </div>
                ))}
            </div>
          </fieldset>
        )}
      </form>

      {isUnifiedPromptBlocksInfoModalOpen && (
        <UnifiedPromptBlocksInfoModal
          onClose={() => setIsUnifiedPromptBlocksInfoModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
}

export default PromptUnifiedVisualization;
