import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const usersReducer = createApi({
  reducerPath: 'users',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchCurrentUser: builder.query<
        APIComponents['schemas']['UserGet'],
        void
      >({
        query: () => ({
          url: '/users/me',
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useQuery: useFetchCurrentUser} =
  usersReducer.endpoints.fetchCurrentUser;
