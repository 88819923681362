import {Icon, Pagination} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import FeedbackModal, {FeedbackModalProps} from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import {AuthRoutes} from 'routes';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import HorizontalScrollTable from 'components/HorizontalScrollTable';
import {useDeleteCorpus, useFetchCorpusLazy} from 'store/reducers/corpus';
import {useCorpus} from 'features/useCorpus';
import PageWrapper from '../../components/PageWrapper';
import styles from './CorpusList.module.scss';

type TableRow = [
  id: number | string,
  composition_raw: string,
  genre: string,
  general: string,
  c1: string,
  j1: string,
  c2: string,
  j2: string,
  c3: string,
  j3: string,
  c4: string,
  j4: string,
  c5: string,
  j5: string,
  c6: string,
  j6: string,
  buttons: JSX.Element,
];

function CorpusList(): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const [currentCorpusListPage, setCurrentCorpusListPage] = useState(1);
  const [numberOfCorpusPerPage, setNumberOfCorpusPerPage] =
    useState<SelectOption>({label: '10', value: '10'});
  const [currentFilter, setCurrentFilter] = useState<SelectOption>({
    label: 'Mais recentes',
    value: '-created',
  });

  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [isDeleteCorpusModalOpen, setIsDeleteCorpusModalOpen] = useState(false);
  const [corpusIdToDelete, setCorpusIdToDelete] = useState(0);

  const [
    fetchCorpusLazy,
    {
      isFetching: isFetchingCorpus,
      isLoading: isLoadingCorpus,
      isUninitialized: isFetchCorpusUninitialized,
      data: corpusList,
    },
  ] = useFetchCorpusLazy();

  const [
    deleteCorpus,
    {
      isLoading: isDeletingCorpus,
      isSuccess: isDeleteCorpusSuccess,
      isError: isDeleteCorpusError,
      status: deleteCorpusStatus,
      reset: resetDeleteCorpusStatus,
    },
  ] = useDeleteCorpus();

  const {responseFeedbacks} = useCorpus();

  const totalNumberOfTablePages = useMemo(
    () =>
      corpusList?.total
        ? Math.ceil(corpusList.total / Number(numberOfCorpusPerPage?.value))
        : 0,
    [corpusList],
  );

  const history = useHistory();

  // Opening feedback modal for API responses
  useEffect(() => {
    if (isDeleteCorpusError || isDeleteCorpusSuccess) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [deleteCorpusStatus]);

  function fetchCorpus() {
    const filter = currentFilter.value;
    const offset =
      (currentCorpusListPage - 1) * Number(numberOfCorpusPerPage?.value);
    const limit = numberOfCorpusPerPage?.value;
    fetchCorpusLazy(
      {
        genre__name: searchText,
        ordering: filter,
        limit,
        offset,
      },
      true,
    );
  }

  // Refetching prompts on current page or prompts per page change
  useEffect(() => {
    fetchCorpus();
  }, [currentCorpusListPage, numberOfCorpusPerPage?.value, currentFilter]);

  // Refetching prompts after deletion
  useEffect(() => {
    if (isDeleteCorpusSuccess) {
      fetchCorpus();
    }
  }, [isDeleteCorpusSuccess]);

  const isLoading = isFetchingCorpus || isLoadingCorpus || isDeletingCorpus;
  const hasFirstCorpusFetchHappened = !isFetchCorpusUninitialized;

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchCorpusLazy({limit: numberOfCorpusPerPage?.value, offset: 0}, true);

      setCurrentCorpusListPage(1);
    }

    const searchInputValue = event.target.value.toLowerCase();

    setSearchText(searchInputValue);
  }

  function onClickDeleteCorpus(corpusId: number) {
    setCorpusIdToDelete(corpusId);
    setIsDeleteCorpusModalOpen(true);
  }

  function onDeleteCorpus() {
    deleteCorpus({corpusId: corpusIdToDelete});
    setIsDeleteCorpusModalOpen(false);
  }

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isDeleteCorpusError || isDeleteCorpusSuccess) {
      return 'deleteCorpus';
    }

    return 'default';
  }

  function getResponseMessage(): string {
    if (isDeleteCorpusSuccess) {
      return responseFeedbacks.deleteCorpus.success.message;
    }
    if (isDeleteCorpusError) {
      return responseFeedbacks.deleteCorpus.error.message;
    }

    return '';
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isDeleteCorpusSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    function onCloseModalCallback() {
      setIsResponseFeedbackModalOpen(false);
      resetDeleteCorpusStatus();
    }

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseModalCallback,
      onClose: onCloseModalCallback,
    };
  }

  function onSubmitSearch() {
    setCurrentCorpusListPage(1);

    fetchCorpus();
  }

  function resetTableData() {
    setCurrentCorpusListPage(1);
    setSearchText('');

    fetchCorpusLazy(
      {
        limit: numberOfCorpusPerPage?.value,
        offset:
          (currentCorpusListPage - 1) * Number(numberOfCorpusPerPage?.value),
      },
      true,
    );
  }

  const tableHeaders =
    corpusList?.results && corpusList.results[0]?.competences?.length
      ? corpusList.results[0].competences.reduce<string[]>(
          (accumulator, _, index) => {
            const newRow = [
              `Nota da competência ${index + 1}`,
              `Justificativa da competência ${index + 1}`,
            ];

            if (index === corpusList.results[0].competences.length - 1) {
              return [...accumulator, ...newRow, 'Ações'];
            }

            return [...accumulator, ...newRow];
          },
          ['ID', 'Texto', 'Gênero', 'Comentário geral'],
        )
      : [];

  const tableRows: TableRow[] =
    corpusList?.results?.reduce(
      (
        accumulator: TableRow[],
        {id, composition_raw, genre_name, general_comment, competences},
      ) => {
        const newRow: Array<string | JSX.Element> = [
          id,
          composition_raw.substring(0, 40),
          genre_name,
          general_comment.substring(0, 40),
        ];

        competences.forEach((competence) => {
          newRow.push(String(competence.score));
          newRow.push(competence.comment.substring(0, 40));
        });

        newRow.push(
          <div key={`corpus-${id}-actions`} className={styles.buttonWrapper}>
            <Link to={`/corpus/${id}`} title="Ir aos detalhes do corpus">
              <Icon icon={['fas', 'external-link']} color="#666" size="lg" />
            </Link>

            <button
              type="button"
              className={styles.deleteButton}
              title="Excluir corpus"
              onClick={() => onClickDeleteCorpus(Number(id))}
            >
              <Icon icon={['fas', 'trash-alt']} color="#666" size="lg" />
            </button>
          </div>,
        );

        return [...accumulator, newRow as TableRow];
      },
      [],
    ) ?? [];

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader
          title="Corpus"
          buttonText="Criar corpus"
          onClickButton={() => history.push(AuthRoutes.corpusCreation)}
        />

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingTable numberOfRows={6} />
          </div>
        )}

        {!isLoading &&
          hasFirstCorpusFetchHappened &&
          !corpusList?.results?.length && (
            <NoResults resetData={resetTableData} />
          )}

        {!isLoading && !!corpusList?.results?.length && (
          <div className={styles.tableWrapper}>
            <TableHeader
              onSubmitSearch={onSubmitSearch}
              onChangeSearchText={onChangeSearchText}
              setNumberOfResultsPerPage={setNumberOfCorpusPerPage}
              searchText={searchText}
              numberOfResultsPerPage={numberOfCorpusPerPage}
              searchPlaceholder="Pesquisar por gênero"
              filters={[
                {
                  label: 'Mais recentes',
                  value: '-created',
                },
                {
                  label: 'Mais antigos',
                  value: 'created',
                },
              ]}
              onChangeFilter={(filter) => setCurrentFilter(filter)}
              selectedFilter={currentFilter}
            />

            <HorizontalScrollTable
              tableHeaders={tableHeaders}
              tableContents={tableRows}
              stickyPositions={[tableHeaders.length - 1]}
            />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentCorpusListPage}
                hasNext={currentCorpusListPage < totalNumberOfTablePages}
                hasPrevious={currentCorpusListPage > 1}
                totalPages={totalNumberOfTablePages}
                onChange={(page) => setCurrentCorpusListPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      {isDeleteCorpusModalOpen && (
        <DeletionModal
          isOpen
          message="Deseja mesmo excluir esse corpus?"
          onClickDelete={onDeleteCorpus}
          onClose={() => setIsDeleteCorpusModalOpen(false)}
          subtitle="Tem certeza?"
          title="Excluir corpus"
        />
      )}

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default CorpusList;
