import PageWrapper from 'components/PageWrapper';
import {Icon} from 'letrus-ui';
import {Link} from 'react-router-dom';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import {useFetchCurrentUser} from 'store/reducers/users';
import {AuthRoutes} from 'routes';
import GPT4Logo from 'images/GPT4_logo.png';
import styles from './Dashboard.module.scss';

const Dashboard: React.FC = () => {
  const {isFetching, isLoading, data} = useFetchCurrentUser();

  return (
    <PageWrapper>
      <div className={styles.container}>
        <h1>Dashboard</h1>

        {isFetching || isLoading ? (
          <LoadingRow height={20} width={200} />
        ) : (
          <p>Boas-vindas, {data?.name || 'Letrer'}</p>
        )}

        <ul className={styles.cardsGrid}>
          <Link to={AuthRoutes.grammarRuleList}>
            <li>
              <Icon icon={['fas', 'spell-check']} size="2x" />
              <span>Regras gramaticais</span>
            </li>
          </Link>
          <Link to={AuthRoutes.textMarkerList}>
            <li>
              <Icon icon={['fas', 'highlighter']} size="2x" />
              <span>Marcadores textuais</span>
            </li>
          </Link>
          <Link to={AuthRoutes.textMarkerCategoryList}>
            <li>
              <Icon icon={['fas', 'th-large']} size="2x" />
              <span>Categorias de marcadores textuais</span>
            </li>
          </Link>
          <Link to={AuthRoutes.entityEvalutionList}>
            <li>
              <Icon icon={['far', 'clipboard-list-check']} size="2x" />
              <span>Avaliações de entidade</span>
            </li>
          </Link>
          <Link to={AuthRoutes.plagiarismTextList}>
            <li>
              <Icon icon={['far', 'copy']} size="2x" />
              <span>Textos de plágio</span>
            </li>
          </Link>
          <Link to={AuthRoutes.plagiarismTextTest}>
            <li>
              <Icon icon={['fas', 'print-search']} size="2x" />
              <span>Testar plágio</span>
            </li>
          </Link>
          <Link to={AuthRoutes.blockList}>
            <li>
              <Icon icon={['fas', 'cube']} size="2x" />
              <span>Blocos</span>
            </li>
          </Link>
          <Link to={AuthRoutes.promptList}>
            <li>
              <Icon icon={['fas', 'terminal']} size="2x" />
              <span>Prompts</span>
            </li>
          </Link>
          <Link to={AuthRoutes.promptUnifiedList}>
            <li>
              <Icon icon={['fas', 'code-merge']} size="2x" />
              <span>Prompts Unificados</span>
            </li>
          </Link>
          <Link to={AuthRoutes.answerList}>
            <li>
              <Icon icon={['fas', 'reply']} size="2x" />
              <span>Respostas</span>
            </li>
          </Link>
          <Link to={AuthRoutes.answerPlayground}>
            <li>
              <img src={GPT4Logo} alt="chatgpt" />
              <span>Gerar respostas</span>
            </li>
          </Link>
          <Link to={AuthRoutes.corpusList}>
            <li>
              <Icon icon={['fas', 'book']} size="2x" />
              <span>Corpus</span>
            </li>
          </Link>
          <Link to={AuthRoutes.scheduling}>
            <li>
              <Icon icon={['fas', 'calendar-alt']} size="2x" />
              <span>Scheduling</span>
            </li>
          </Link>
          <Link to={AuthRoutes.generativePromptList}>
            <li>
              <Icon icon={['fas', 'flask']} size="2x" />
              <span>Prompts Generativos</span>
            </li>
          </Link>
          <Link to={AuthRoutes.generativePlayground}>
            <li>
              <Icon icon={['fas', 'cogs']} size="2x" />
              <span>Playground Generativo</span>
            </li>
          </Link>
        </ul>
      </div>
    </PageWrapper>
  );
};

export default Dashboard;
