import {Icon, NewButton} from 'letrus-ui';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import FeedbackModal from 'components/FeedbackModal';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import {useTestPlagiarismText} from 'store/reducers/plagiarismTexts';
import PageWrapper from '../../components/PageWrapper';
import styles from './PlagiarismTextTest.module.scss';

interface FormValues {
  text: string;
}

const PlagiarismTextTest: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormValues>();

  const history = useHistory();

  const [
    testPlagiarismText,
    {
      error: isTestPlagiarismTextError,
      isLoading: isTestingPlagiarismText,
      data,
    },
  ] = useTestPlagiarismText();

  function onSubmit() {
    const {text} = getValues();

    testPlagiarismText({text});
  }

  useEffect(() => {
    if (isTestPlagiarismTextError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isTestPlagiarismTextError]);

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Testar plágio</h1>
          </div>

          <NewButton
            text="Testar"
            kind="primary"
            userRole="teacher"
            type="submit"
            isLoading={isTestingPlagiarismText}
            disabled={isTestingPlagiarismText}
          />
        </header>

        <div className={styles.fieldset}>
          <label htmlFor="text">Texto</label>
          <textarea
            id="text"
            placeholder="Ex: Um plágio mal feito"
            className={errors?.text ? styles.error : ''}
            {...register('text', {required: true})}
          />
          {errors?.text?.type === 'required' && (
            <label htmlFor="text" className={styles.errorLabel}>
              Este campo não pode ficar em branco
            </label>
          )}

          {isTestingPlagiarismText && (
            <div className={styles.loadingWrapper}>
              <LoadingTable numberOfRows={5} />
            </div>
          )}
        </div>

        {data && !isTestingPlagiarismText && (
          <div className={styles.resultsWrapper}>
            <h3>Resultado</h3>

            <p className={styles.plagiarismPercentage}>
              Percentual do texto plagiado: <strong>{data.score * 100}%</strong>
            </p>

            <table>
              <thead className={styles.resultsHeaderRow}>
                <tr>
                  <th>Trecho</th>
                  <th>Similaridade</th>
                  <th>Fonte</th>
                  <th>Número de tokens</th>
                </tr>
              </thead>

              <tbody>
                {data.sentences.map((item) => (
                  <tr key={item.text} className={styles.resultsValueRow}>
                    <td>{item.text}</td>
                    <td>{item.score}</td>
                    <td>{item.original_url}</td>
                    <td>{item.n_tokens}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Testar plágio"
        subtitle="Erro!"
        feedbackMessage="Ocorreu um erro ao testar o texto inserido. Verifique os dados e tente novamente."
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText="Tentar novamente"
        iconName="arrow-left"
      />
    </PageWrapper>
  );
};

export default PlagiarismTextTest;
