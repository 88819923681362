import {StylesConfig} from 'react-select';

const reactSelectStyles: StylesConfig<any, any, any> = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    minWidth: 100,
    borderColor: '#95989a',
    '&:hover': {borderColor: '#5d3d85'},
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#95989A',
    paddingRight: 12,
  }),
  indicatorSeparator: () => ({}),
};

export {reactSelectStyles};
