import {Icon, Pagination} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import FeedbackModal, {FeedbackModalProps} from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import {AuthRoutes} from 'routes';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import Table from 'components/Table';
import {
  useDeletePromptUnified,
  useFetchPromptsUnifiedLazy,
} from 'store/reducers/promptsUnified';
import {usePromptsUnified} from 'features/usePromptsUnified';
import PageWrapper from '../../components/PageWrapper';
import styles from './PromptUnifiedList.module.scss';

type TableRow = [id: number, name: string, buttons: JSX.Element];

function PromptUnifiedList(): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const [currentPromptUnifiedListPage, setCurrentPromptUnifiedListPage] =
    useState(1);
  const [numberOfPromptsUnifiedPerPage, setNumberOfPromptsUnifiedPerPage] =
    useState<SelectOption>({label: '10', value: '10'});
  const [currentFilter, setCurrentFilter] = useState<SelectOption>({
    label: 'Mais recentes',
    value: '-created',
  });
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const [isDeletePromptUnifiedModalOpen, setIsDeletePromptUnifiedModalOpen] =
    useState(false);
  const [promptUnifiedIdToDelete, setPromptUnifiedIdToDelete] = useState(0);
  const {responseFeedbacks} = usePromptsUnified();

  const [
    fetchPromptsUnifiedLazy,
    {
      isFetching: isFetchingPromptsUnified,
      isLoading: isLoadingPromptsUnified,
      isUninitialized: isFetchPromptsUnifiedUninitialized,
      data: promptUnifiedList,
    },
  ] = useFetchPromptsUnifiedLazy();
  const [
    deletePrompt,
    {
      isLoading: isDeletingPromptUnified,
      isSuccess: isDeletePromptUnifiedSuccess,
      isError: isDeletePromptUnifiedError,
      status: deletePromptUnifiedStatus,
      reset: resetDeletePromptUnifiedStatus,
    },
  ] = useDeletePromptUnified();

  const totalNumberOfTablePages = useMemo(
    () =>
      promptUnifiedList?.total
        ? Math.ceil(
            promptUnifiedList.total /
              Number(numberOfPromptsUnifiedPerPage?.value),
          )
        : 0,
    [promptUnifiedList],
  );

  const history = useHistory();

  // Opening feedback modal for API responses
  useEffect(() => {
    if (isDeletePromptUnifiedError || isDeletePromptUnifiedSuccess) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [deletePromptUnifiedStatus]);

  // Refetching prompts on current page or prompts per page change
  useEffect(() => {
    fetchPrompts();
  }, [
    currentPromptUnifiedListPage,
    numberOfPromptsUnifiedPerPage?.value,
    currentFilter,
  ]);

  // Refetching prompts after deletion
  useEffect(() => {
    if (isDeletePromptUnifiedSuccess) {
      fetchPrompts();
    }
  }, [isDeletePromptUnifiedSuccess]);

  function fetchPrompts() {
    const filter = currentFilter.value;
    const offset =
      (currentPromptUnifiedListPage - 1) *
      Number(numberOfPromptsUnifiedPerPage?.value);
    const limit = numberOfPromptsUnifiedPerPage?.value;
    fetchPromptsUnifiedLazy(
      {
        name: searchText,
        ordering: filter !== 'recent' ? filter : undefined,
        limit,
        offset,
      },
      true,
    );
  }

  const isLoading =
    isFetchingPromptsUnified ||
    isLoadingPromptsUnified ||
    isDeletingPromptUnified;
  const hasFirstPromptsFetchHappened = !isFetchPromptsUnifiedUninitialized;

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchPromptsUnifiedLazy(
        {limit: numberOfPromptsUnifiedPerPage?.value, offset: 0},
        true,
      );

      setCurrentPromptUnifiedListPage(1);
    }

    const searchInputValue = event.target.value.toLowerCase();

    setSearchText(searchInputValue);
  }

  function onClickDeletePromptUnified(promptId: number) {
    setPromptUnifiedIdToDelete(promptId);
    setIsDeletePromptUnifiedModalOpen(true);
  }

  function onDeletePromptUnified() {
    deletePrompt({promptId: promptUnifiedIdToDelete});
    setIsDeletePromptUnifiedModalOpen(false);
  }

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isDeletePromptUnifiedError || isDeletePromptUnifiedSuccess) {
      return 'deletePromptUnified';
    }

    return 'default';
  }

  function getResponseMessage(): string {
    if (isDeletePromptUnifiedSuccess) {
      return responseFeedbacks.deletePromptUnified.success.message;
    }
    if (isDeletePromptUnifiedError) {
      return responseFeedbacks.deletePromptUnified.error.message;
    }

    return '';
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isDeletePromptUnifiedSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    function onCloseModalCallback() {
      setIsResponseFeedbackModalOpen(false);
      resetDeletePromptUnifiedStatus();
    }

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseModalCallback,
      onClose: onCloseModalCallback,
    };
  }

  function onSubmitSearch() {
    setCurrentPromptUnifiedListPage(1);

    fetchPrompts();
  }

  function resetTableData() {
    setCurrentPromptUnifiedListPage(1);
    setSearchText('');

    fetchPromptsUnifiedLazy(
      {
        limit: numberOfPromptsUnifiedPerPage?.value,
        offset:
          (currentPromptUnifiedListPage - 1) *
          Number(numberOfPromptsUnifiedPerPage?.value),
      },
      true,
    );
  }

  const tableHeaders: string[] = ['ID', 'Nome', 'Ações'];

  const tableRows: TableRow[] =
    promptUnifiedList?.results?.reduce(
      (accumulator: TableRow[], {id, name}) => [
        ...accumulator,
        [
          id,
          name,
          <div key={`prompt-${id}-actions`} className={styles.buttonWrapper}>
            <Link
              to={`/prompts-unificados/${id}`}
              title="Ir aos detalhes do prompt"
            >
              <Icon icon={['fas', 'external-link']} color="#666" size="lg" />
            </Link>

            <button
              type="button"
              className={styles.deleteButton}
              title="Excluir prompt unificado"
              onClick={() => onClickDeletePromptUnified(id)}
            >
              <Icon icon={['fas', 'trash-alt']} color="#666" size="lg" />
            </button>
          </div>,
        ],
      ],
      [],
    ) ?? [];

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader
          title="Prompts unificados"
          buttonText="Criar prompt unificado"
          onClickButton={() => history.push(AuthRoutes.promptUnifiedCreation)}
        />

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingTable numberOfRows={6} />
          </div>
        )}

        {!isLoading &&
          hasFirstPromptsFetchHappened &&
          !promptUnifiedList?.results?.length && (
            <NoResults resetData={resetTableData} />
          )}

        {!isLoading && !!promptUnifiedList?.results?.length && (
          <div className={styles.tableWrapper}>
            <TableHeader
              onSubmitSearch={onSubmitSearch}
              onChangeSearchText={onChangeSearchText}
              setNumberOfResultsPerPage={setNumberOfPromptsUnifiedPerPage}
              searchText={searchText}
              numberOfResultsPerPage={numberOfPromptsUnifiedPerPage}
              searchPlaceholder="Pesquisar prompts unificados"
              filters={[
                {
                  label: 'Mais recentes',
                  value: '-created',
                },
                {
                  label: 'Mais antigos',
                  value: 'created',
                },
              ]}
              onChangeFilter={(filter) => setCurrentFilter(filter)}
              selectedFilter={currentFilter}
            />

            <Table tableHeaders={tableHeaders} tableContents={tableRows} />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPromptUnifiedListPage}
                hasNext={currentPromptUnifiedListPage < totalNumberOfTablePages}
                hasPrevious={currentPromptUnifiedListPage > 1}
                totalPages={totalNumberOfTablePages}
                onChange={(page) => setCurrentPromptUnifiedListPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      {isDeletePromptUnifiedModalOpen && (
        <DeletionModal
          isOpen
          message="Deseja mesmo excluir esse prompt unificado?"
          onClickDelete={onDeletePromptUnified}
          onClose={() => setIsDeletePromptUnifiedModalOpen(false)}
          subtitle="Tem certeza?"
          title="Excluir prompt unificado"
        />
      )}

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default PromptUnifiedList;
