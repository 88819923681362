import {FieldError} from 'react-hook-form';

export function useBlocks() {
  const responseFeedbacks = {
    createBlock: {
      title: 'Criar bloco',
      error: {
        message: 'Ocorreu um erro ao criar o bloco.',
      },
      success: {
        message: 'O bloco foi criado.',
      },
    },
    createTag: {
      title: 'Criar tag',
      error: {
        message: 'Ocorreu um erro ao criar a tag.',
      },
      success: {
        message: 'A tag foi criada.',
      },
    },
    createBlockVersion: {
      title: 'Criar versão do bloco',
      error: {
        message:
          'Ocorreu um erro ao tentar criar a versão do bloco, verifique os dados e tente novamente.',
      },
      success: {
        message: 'A versão do bloco foi criada.',
      },
    },
    deleteBlockVersion: {
      title: 'Excluir versão do bloco',
      error: {
        message:
          'Ocorreu um erro ao tentar excluir a versão do bloco, verifique os dados e tente novamente.',
      },
      success: {
        message: 'A versão do bloco foi excluída.',
      },
    },
    default: {
      title: 'Requisição',
      error: {
        message: 'Erro',
      },
      success: {
        message: 'Sucesso',
      },
    },
  };

  function getFormFieldError(
    errorType: FieldError['type'] | undefined,
  ): string | undefined {
    if (errorType === 'required') {
      return 'Esse campo não pode ficar em branco';
    }

    return;
  }

  return {
    responseFeedbacks,
    getFormFieldError,
  };
}
