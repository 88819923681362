import {Redirect, Route, useLocation} from 'react-router-dom';
import {PublicRoutes} from 'routes';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {clearState} from 'store/reducers/authentication';
import {deleteCookie, getCookie} from './cookies';

export interface AuthRouteProps {
  path: string;
  exact?: boolean;
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  path,
  exact = false,
  children,
}) => {
  const dispatch = useAppDispatch();
  const cookie = getCookie('nlptToken');
  const isAuthed = useAppSelector((state) => state.authentication.isAuthed);
  const {pathname} = useLocation();

  if (!cookie || !isAuthed) {
    deleteCookie('nlptToken');
    dispatch(clearState());

    return (
      <Redirect
        to={{pathname: PublicRoutes.login, search: `?next=${pathname}`}}
      />
    );
  }

  return <Route path={path} exact={exact} render={() => children} />;
};

export default AuthRoute;
