import {Checkbox, Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import FeedbackModal from 'components/FeedbackModal';
import {useCreateTextMarkerCategory} from 'store/reducers/textMarkerCategories';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './TextMarkerCategoryCreation.module.scss';

interface FormValues {
  name: string;
  database_rule: boolean;
}

const TextMarkerCategoryCreation: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: {errors},
  } = useForm<FormValues>();

  const [
    createTextMarkerCategory,
    {
      isError: isCreateTextMarkerCategoryError,
      isLoading: isCreatingTextMarkerCategory,
      isSuccess: isCreateTextMarkerCategorySuccess,
      error: createTextMarkerCategoryError,
    },
  ] = useCreateTextMarkerCategory();

  const history = useHistory();

  useEffect(() => {
    if (isCreateTextMarkerCategoryError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateTextMarkerCategoryError]);

  useEffect(() => {
    if (isCreateTextMarkerCategorySuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateTextMarkerCategorySuccess]);

  const createTextMarkerCategoryErrorData = (
    createTextMarkerCategoryError as ResponseError
  )?.data as SimpleErrorPayload;

  function onSubmit() {
    const textMarkerCategoryData = getValues();

    createTextMarkerCategory(textMarkerCategoryData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Criar categoria de marcador</h1>
          </div>

          <NewButton
            text="Criar"
            kind="primary"
            userRole="teacher"
            type="submit"
            isLoading={isCreatingTextMarkerCategory}
            disabled={isCreatingTextMarkerCategory}
          />
        </header>

        <div className={styles.fieldset}>
          <InputText
            id="name"
            labelText="Nome da categoria"
            placeholder="Ex: Propositivos"
            errorMessage={
              errors?.name?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            {...register('name', {required: true})}
          />

          <Controller
            control={control}
            name="database_rule"
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <Checkbox
                id="database_rule"
                labelText="É uma regra do banco de dados"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={String(value)}
              />
            )}
          />
        </div>
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Criar categoria de marcador"
        subtitle={isCreateTextMarkerCategoryError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreateTextMarkerCategoryError
            ? createTextMarkerCategoryErrorData.detail
            : 'A categoria foi criada.'
        }
        onClose={
          isCreateTextMarkerCategoryError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.textMarkerCategoryList)
        }
        onButtonClick={
          isCreateTextMarkerCategoryError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.textMarkerCategoryList)
        }
        buttonText={
          isCreateTextMarkerCategoryError
            ? 'Tentar novamente'
            : 'Voltar às categorias'
        }
        iconName={isCreateTextMarkerCategoryError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default TextMarkerCategoryCreation;
