import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const promptsReducer = createApi({
  reducerPath: 'prompts',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchPrompts: builder.query<
        APIComponents['schemas']['PaginatedPromptGet'],
        {
          search?: string;
          limit?: number | string;
          offset?: number;
          title?: string;
          description?: string;
          ordering?: string;
        }
      >({
        query: ({search, limit, offset, title, description, ordering}) => ({
          url: '/prompts',
          method: 'GET',
          params: {
            search: search || undefined,
            limit,
            offset,
            title,
            description,
            ordering,
          },
        }),
      }),
      fetchPromptVersionsById: builder.query<
        APIComponents['schemas']['PromptVersion'][],
        {promptId: number; limit?: number | string; offset?: number}
      >({
        query: ({promptId, limit, offset}) => ({
          url: `/prompts/${promptId}/versions`,
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
      }),
      fetchPromptVersionById: builder.query<
        APIComponents['schemas']['GetPrompt'],
        {promptId: number; version: number}
      >({
        query: ({promptId, version}) => ({
          url: `/prompts/${promptId}/versions/${version}`,
          method: 'GET',
        }),
      }),
      createPrompt: builder.mutation<
        APIComponents['schemas']['Prompt'],
        Omit<APIComponents['schemas']['CreatePrompt'], 'id'>
      >({
        query: (data) => ({
          method: 'POST',
          url: `/prompts/`,
          data,
        }),
      }),
      createPromptVersion: builder.mutation<
        APIComponents['schemas']['GetPrompt'],
        {promptId: number; block_version_ids: number[]}
      >({
        query: (data) => ({
          method: 'POST',
          url: `/prompts/${data.promptId}/versions`,
          data: {
            block_version_ids: data.block_version_ids,
          },
        }),
      }),
      updateProductionVersion: builder.mutation<any, any & {id: number}>({
        query: (data) => ({
          method: 'PATCH',
          url: `/prompts/${data.id}`,
          data: {
            version: data.version,
          },
        }),
      }),
      deletePrompt: builder.mutation<void, number>({
        query: (promptId) => ({
          method: 'DELETE',
          url: `/prompts/${promptId}`,
        }),
      }),
      deletePromptVersion: builder.mutation<
        void,
        {promptId: number; promptVersionNumber: number}
      >({
        query: ({promptId, promptVersionNumber}) => ({
          method: 'DELETE',
          url: `/prompts/${promptId}/versions/${promptVersionNumber}`,
        }),
      }),
    };
  },
});

export const {
  fetchPrompts: {useQuery: useFetchPrompts, useLazyQuery: useFetchPromptsLazy},
  fetchPromptVersionsById: {useLazyQuery: useFetchPromptVersionsByIdLazy},
  fetchPromptVersionById: {useLazyQuery: useFetchPromptVersionByIdLazy},
  createPrompt: {useMutation: useCreatePrompt},
  createPromptVersion: {useMutation: useCreatePromptVersion},
  deletePrompt: {useMutation: useDeletePrompt},
  deletePromptVersion: {useMutation: useDeletePromptVersion},
  updateProductionVersion: {useMutation: useUpdatePromptProductionVersion},
} = promptsReducer.endpoints;
