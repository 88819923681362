import {ReactComponent as Illustration} from 'images/nlp.svg';
import {ReactComponent as Logo} from 'images/logoLetrus.svg';
import {InputPassword, InputText, NewButton} from 'letrus-ui';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {login, selectAuth} from 'store/reducers/authentication';
import {getCookie} from 'utils/cookies';
import {useQueryParams} from 'utils/hooks/useQueryParams';
import styles from './Login.module.scss';

interface FormValues {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const [showRestorePassword, setShowRestorePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const queryParams = useQueryParams();
  const dispatch = useAppDispatch();
  const {status} = useAppSelector(selectAuth);

  const {
    handleSubmit,
    formState: {errors, isSubmitted: isFormSubmitted},
    getValues,
    control,
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const {
    handleSubmit: restorePasswordHandleSubmit,
    formState: {errors: restorePasswordErrors},
    control: restorePasswordControl,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const authData = useAppSelector((state) => state.authentication);
  const isAuthed = getCookie('nlptToken') && authData?.isAuthed;

  useEffect(() => {
    if (isAuthed) {
      const nextRoute = queryParams.get('next') || '/';
      history.push(nextRoute);
    }
  }, [isAuthed]);

  function toggleView() {
    setShowRestorePassword((prevState) => !prevState);
  }

  function onSubmit() {
    const {username, password} = getValues();
    dispatch(login({username, password}));
  }

  function onRestorePassword() {}

  return (
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <div className={styles.firstContent}>
          <div className={styles.card}>
            <h1 className={styles.title}>
              Boas-vindas à plataforma de ferramentas de NLP da Letrus!
            </h1>
            <figure className={styles.illustration}>
              <Illustration />
            </figure>
          </div>
        </div>

        <div className={styles.secondContent}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>NLP TOOLS</div>
            {!showRestorePassword ? (
              <>
                <div className={styles.formContainer}>
                  <Logo className={styles.logo} />

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.form}
                  >
                    <Controller
                      control={control}
                      name="username"
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <InputText
                          id="username"
                          labelText="Usuário"
                          placeholder="Ex: kleber@letrus.com.br"
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          ref={ref}
                          value={value ?? ''}
                          errorMessage={
                            errors?.username?.type === 'required'
                              ? 'Este campo não pode ficar em branco'
                              : undefined
                          }
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="password"
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <InputPassword
                          showPassword={showPassword}
                          onClickPasswordIcon={() =>
                            setShowPassword(!showPassword)
                          }
                          id="password"
                          labelText="Senha"
                          placeholder="Ex: 12391275"
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          ref={ref}
                          value={value ?? ''}
                          errorMessage={
                            errors?.password?.type === 'required'
                              ? 'Este campo não pode ficar em branco'
                              : undefined
                          }
                        />
                      )}
                    />

                    {status === 'failed' && isFormSubmitted ? (
                      <span className={styles.errorText}>
                        Usuário ou senha inválidos.
                        <br />
                        Por favor, verifique e tente novamente.
                      </span>
                    ) : null}

                    <div className={styles.loginButtonWrapper}>
                      <NewButton
                        userRole="teacher"
                        type="submit"
                        disabled={status === 'loading'}
                        isLoading={status === 'loading'}
                      >
                        Entrar
                      </NewButton>
                    </div>
                  </form>
                </div>

                <div className={styles.formFooter}>
                  <button
                    type="button"
                    className={styles.linkButton}
                    onClick={toggleView}
                  >
                    Esqueci minha senha
                  </button>
                </div>
              </>
            ) : (
              <div
                className={`${styles.formContainer} ${styles.passwordRedefinition}`}
              >
                <form
                  onSubmit={restorePasswordHandleSubmit(onRestorePassword)}
                  className={styles.form}
                >
                  <Controller
                    control={restorePasswordControl}
                    name="username"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, ref, value}}) => (
                      <InputText
                        id="username"
                        labelText="Recuperar senha"
                        placeholder="Ex: kleber@letrus.com.br"
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value ?? ''}
                        errorMessage={
                          restorePasswordErrors?.username?.type === 'required'
                            ? 'Campo obrigatório'
                            : undefined
                        }
                      />
                    )}
                  />

                  <div className={styles.buttonsRow}>
                    <NewButton userRole="anonymous" onClick={toggleView}>
                      Voltar
                    </NewButton>
                    <NewButton userRole="teacher" type="submit">
                      Recuperar
                    </NewButton>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
