function isValidJSON(json: string) {
  try {
    JSON.parse(json);
  } catch (error) {
    return false;
  }

  return true;
}

export default isValidJSON;
