import {Icon, Pagination} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import {useFetchBlocksLazy, useDeleteBlock} from 'store/reducers/blocks';
import FeedbackModal from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import {AuthRoutes} from 'routes';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import Table from 'components/Table';
import PageWrapper from '../../components/PageWrapper';
import styles from './BlockList.module.scss';

type TableContents = [
  block_id: number,
  header: string,
  content: string,
  tag_name: string,
  buttons: JSX.Element,
][];

function BlockList(): JSX.Element {
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfBlocksPerPage, setNumberOfBlocksPerPage] =
    useState<SelectOption | null>({label: '10', value: '10'});
  const [isDeleteBlockModalOpen, setIsDeleteBlockModalOpen] =
    useState<boolean>(false);
  const [blockToDeleteId, setBlockToDeleteId] = useState<number>(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<SelectOption>({
    label: 'Mais recentes',
    value: 'recent',
  });
  const [searchBy, setSearchBy] = useState<SelectOption>({
    label: 'Header',
    value: 'header',
  });

  const [
    fetchBlocksLazy,
    {
      isFetching: isFetchingBlocks,
      isLoading: isLoadingBlocks,
      isUninitialized: isFetchBlocksUninitialized,
      data,
    },
  ] = useFetchBlocksLazy();
  const [
    deleteBlock,
    {
      isLoading: isDeletingBlock,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
    },
  ] = useDeleteBlock();

  const history = useHistory();

  useEffect(() => {
    fetchBlocksLazy(
      {
        search: searchText,
        limit: numberOfBlocksPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfBlocksPerPage?.value),
      },
      true,
    );
  }, [currentPage, numberOfBlocksPerPage?.value]);

  useEffect(() => {
    onSubmitSearch();
  }, [searchBy]);

  useEffect(() => {
    const filter = currentFilter.value;
    fetchBlocksLazy(
      {
        limit: numberOfBlocksPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfBlocksPerPage?.value),
        ordering: filter !== 'recent' ? filter : undefined,
      },
      false,
    );
  }, [currentFilter]);

  useEffect(() => {
    if (isDeleteError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteSuccess && !isDeletingBlock) {
      fetchBlocksLazy({}, false);
    }
  }, [isDeleteSuccess, isDeletingBlock]);

  const totalNumberOfPages = useMemo(
    () =>
      data?.total
        ? Math.ceil(data.total / Number(numberOfBlocksPerPage?.value))
        : 0,
    [data],
  );
  const isLoading = isLoadingBlocks || isFetchingBlocks;
  const hasFirstBlocksFetchHappened = !isFetchBlocksUninitialized;

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchBlocksLazy({limit: numberOfBlocksPerPage?.value, offset: 0}, true);

      setCurrentPage(1);
    }

    const inputValue = event.target.value.toLowerCase();

    setSearchText(inputValue);
  }

  function onClickDeleteBlock(blockId: number) {
    setBlockToDeleteId(blockId);
    setIsDeleteBlockModalOpen(true);
  }

  function onDeleteBlock() {
    deleteBlock({blockId: blockToDeleteId});
    setIsDeleteBlockModalOpen(false);
  }

  function onSubmitSearch() {
    setCurrentPage(1);

    fetchBlocksLazy(
      {
        limit: numberOfBlocksPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfBlocksPerPage?.value),
        tag_name: searchBy.value === 'tag' ? searchText : undefined,
        header: searchBy.value === 'header' ? searchText : undefined,
      },
      true,
    );
  }

  function resetData() {
    setCurrentPage(1);
    setSearchText('');

    fetchBlocksLazy(
      {
        limit: numberOfBlocksPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfBlocksPerPage?.value),
      },
      true,
    );
  }

  const tableHeaders = ['ID', 'Header', 'Conteúdo', 'Nome das tags', 'Ações'];

  const tableContents: TableContents | undefined = data?.results?.reduce(
    (accumulator: TableContents, {block_id, header, latest_version, tags}) => [
      ...accumulator,
      [
        block_id,
        header,
        latest_version.content,
        tags?.reduce((acc, tag) => `${acc}, ${tag.name}`, '').substring(0, 30),
        <div key={block_id} className={styles.buttonWrapper}>
          <Link
            to={`/blocos/${block_id}/${latest_version.version}`}
            title="Ir aos detalhes do bloco"
          >
            <Icon icon={['fas', 'external-link']} color="#666" size="lg" />
          </Link>

          <button
            type="button"
            className={styles.deleteButton}
            title="Excluir bloco"
            onClick={() => onClickDeleteBlock(block_id)}
          >
            <Icon icon={['fas', 'trash-alt']} color="#666" size="lg" />
          </button>
        </div>,
      ],
    ],
    [],
  );

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader
          title="Blocos"
          buttonText="Criar bloco"
          onClickButton={() => history.push(AuthRoutes.blockCreation)}
        />

        {(isLoading || isDeletingBlock) && (
          <div className={styles.loadingWrapper}>
            <LoadingTable />
          </div>
        )}

        {!isLoading &&
          hasFirstBlocksFetchHappened &&
          !data?.results?.length && <NoResults resetData={resetData} />}

        {!isLoading && data?.results?.length && !isDeletingBlock && (
          <div className={styles.tableWrapper}>
            <TableHeader
              onSubmitSearch={onSubmitSearch}
              onChangeSearchText={onChangeSearchText}
              setNumberOfResultsPerPage={setNumberOfBlocksPerPage}
              searchText={searchText}
              filters={[
                {
                  label: 'Mais recentes',
                  value: 'recent',
                },
                {
                  label: 'Título ↓',
                  value: 'header',
                },
              ]}
              onChangeFilter={(filter) => setCurrentFilter(filter)}
              selectedFilter={currentFilter}
              numberOfResultsPerPage={numberOfBlocksPerPage}
              searchPlaceholder="Pesquisar blocos"
              searchOptions={[
                {
                  label: 'Header',
                  value: 'header',
                },
                {
                  label: 'Tag',
                  value: 'tag',
                },
              ]}
              searchBy={searchBy}
              onChangeSearchBy={(searchByOption) => setSearchBy(searchByOption)}
            />

            <Table tableHeaders={tableHeaders} tableContents={tableContents} />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                hasNext={currentPage < totalNumberOfPages}
                hasPrevious={currentPage > 1}
                totalPages={totalNumberOfPages}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      <DeletionModal
        isOpen={isDeleteBlockModalOpen}
        message="Deseja mesmo excluir esse bloco?"
        onClickDelete={onDeleteBlock}
        onClose={() => setIsDeleteBlockModalOpen(false)}
        subtitle="Tem certeza?"
        title="Excluir bloco"
      />

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Excluir bloco"
        subtitle={isDeleteError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isDeleteError
            ? 'Ocorreu um erro ao excluir o bloco, tente novamente.'
            : 'O bloco foi excluído.'
        }
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText={isDeleteError ? 'Tentar novamente' : 'Fechar'}
        iconName={isDeleteError ? 'undo' : undefined}
      />
    </PageWrapper>
  );
}

export default BlockList;
