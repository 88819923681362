import {Modal} from 'letrus-ui';
import styles from './PromptVariablesModal.module.scss';
import FeedbackIllustration from '../../images/illustration_01.svg';

export interface PromptVariablesModalProps {
  onClose(): void;
}

function PromptVariablesModal({
  onClose,
}: PromptVariablesModalProps): JSX.Element {
  return (
    <Modal
      isOpen
      clickOutsideToClose
      onClose={onClose}
      buttonClose
      title="Variáveis para os prompts"
      minWidth={400}
      minHeight={510}
    >
      <div className={styles.modalWrapper}>
        <img src={FeedbackIllustration} alt="Ilustração de feedback" />

        <h2>
          Caso esteja criando prompts, as seguintes variáveis podem ser
          utilizadas:
        </h2>

        <h3>ENEM:</h3>

        <ul>
          <li>
            <b>{`{instruction_enem}`}: Opcional</b>

            <ul>
              <li>Preenchido com modelo generativo da resposta</li>
            </ul>
          </li>

          <li>
            <b>{`{score}`}: Opcional</b>

            <ul>
              <li>Preenchido com pontuação gerada</li>
            </ul>
          </li>

          <li>
            <b>{`{c1} {c2} ... {c5}`}: Opcional</b>

            <ul>
              <li>Preenchido com nota da competência</li>
            </ul>
          </li>
        </ul>

        <h3>GM:</h3>

        <ul>
          <li>
            <b>{`{instruction_gm}`}: Opcional</b>

            <ul>
              <li>Preenchido com modelo generativo da resposta</li>
            </ul>
          </li>

          <li>
            <b>{`{score}`}: Opcional</b>

            <ul>
              <li>Preenchido com pontuação gerada</li>
            </ul>
          </li>

          <li>
            <b>{`{instruction_description}`}: Opcional</b>

            <ul>
              <li>Preenchido com descritivo do tema nas tabelas</li>
            </ul>
          </li>

          <li>
            <b>{`{collection}`}: Opcional</b>

            <ul>
              <li>Preenchido com a coletânea no momento do processamento</li>
            </ul>
          </li>

          <li>
            <b>{`{genre}`}: Opcional</b>

            <ul>
              <li>Preenchido com o gênero</li>
            </ul>
          </li>

          <li>
            <b>{`{genre_description}`}: Opcional</b>

            <ul>
              <li>Preenchido com uma descrição do gênero</li>
            </ul>
          </li>

          <li>
            <b>{`{c1} {c2} ... {c6}`}: Opcional</b>

            <ul>
              <li>Preenchido com nota da competência</li>
            </ul>
          </li>
        </ul>
      </div>
    </Modal>
  );
}

export default PromptVariablesModal;
