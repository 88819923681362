import {Icon, Pagination} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {AuthRoutes} from 'routes';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import SelectOption from 'utils/types/SelectOption';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import Table from 'components/Table';
import {useFetchAnswersLazy} from 'store/reducers/answers';
import PageWrapper from '../../components/PageWrapper';
import styles from './AnswerList.module.scss';

type TableRow = [
  id: number,
  prompt_title: string,
  prompt_version: number,
  text: string,
  created: string,
  status: string,
  bookmark: string,
  buttons: JSX.Element,
];

function AnswerList(): JSX.Element {
  const [currentAnswerListPage, setCurrentAnswerListPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState<SelectOption>({
    label: 'Mais recentes',
    value: 'recent',
  });
  const [numberOfAnswersPerPage, setNumberOfAnswersPerPage] =
    useState<SelectOption>({label: '10', value: '10'});

  const history = useHistory();

  const [
    fetchAnswersLazy,
    {
      isFetching: isFetchingAnswers,
      isLoading: isLoadingAnswers,
      isUninitialized: isFetchAnswersUninitialized,
      data: answerList,
    },
  ] = useFetchAnswersLazy();

  // Refetching prompts on current page or prompts per page change
  useEffect(() => {
    fetchAnswersLazy(
      {
        limit: numberOfAnswersPerPage?.value,
        offset:
          (currentAnswerListPage - 1) * Number(numberOfAnswersPerPage?.value),
      },
      true,
    );
  }, [currentAnswerListPage, numberOfAnswersPerPage?.value]);

  useEffect(() => {
    const filter = currentFilter.value;
    fetchAnswersLazy(
      {
        limit: numberOfAnswersPerPage?.value,
        offset:
          (currentAnswerListPage - 1) * Number(numberOfAnswersPerPage?.value),
        ordering: filter === 'old' ? 'created' : undefined,
        bookmark: filter === 'bookmark' || undefined,
      },
      false,
    );
  }, [currentFilter]);

  const totalNumberOfTablePages = useMemo(
    () =>
      answerList?.total
        ? Math.ceil(answerList.total / Number(numberOfAnswersPerPage?.value))
        : 0,
    [answerList],
  );

  const isLoading = isFetchingAnswers || isLoadingAnswers;
  const hasFirstPromptsFetchHappened = !isFetchAnswersUninitialized;

  function resetTableData() {
    setCurrentAnswerListPage(1);

    fetchAnswersLazy(
      {
        limit: numberOfAnswersPerPage?.value,
        offset:
          (currentAnswerListPage - 1) * Number(numberOfAnswersPerPage?.value),
      },
      true,
    );
  }

  const tableHeaders = [
    'ID',
    'Título do Prompt',
    'Versão do Prompt',
    'Texto usado',
    'Data de criação',
    'Status',
    'Favorito',
    'Ações',
  ];

  const tableRows: TableRow[] =
    answerList?.results?.reduce(
      (
        accumulator: TableRow[],
        {
          id,
          prompt: {title: prompt_title},
          prompt_version: {version: prompt_version},
          composition_string,
          created,
          has_response,
          bookmark,
        },
      ) => [
        ...accumulator,
        [
          id,
          prompt_title,
          prompt_version,
          composition_string?.substring(0, 20),
          dayjs(created).format('DD/MM/YYYY'),
          has_response ? 'Respondida' : 'Esperando resposta',
          bookmark ? 'Sim' : 'Não',
          <div key={`answer-${id}-actions`} className={styles.buttonWrapper}>
            <Link to={`/respostas/${id}`} title="Ir aos detalhes da resposta">
              <Icon icon={['fas', 'external-link']} color="#666" size="lg" />
            </Link>
          </div>,
        ],
      ],
      [],
    ) ?? [];

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader
          title="Respostas"
          buttonText="Gerar resposta"
          onClickButton={() => history.push(AuthRoutes.answerPlayground)}
        />

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingTable numberOfRows={6} />
          </div>
        )}

        {!isLoading &&
          hasFirstPromptsFetchHappened &&
          !answerList?.results?.length && (
            <NoResults resetData={resetTableData} />
          )}

        {!isLoading && !!answerList?.results?.length && (
          <div className={styles.tableWrapper}>
            <TableHeader
              setNumberOfResultsPerPage={setNumberOfAnswersPerPage}
              numberOfResultsPerPage={numberOfAnswersPerPage}
              filters={[
                {
                  label: 'Mais recentes',
                  value: 'recent',
                },
                {
                  label: 'Mais antigas',
                  value: 'old',
                },
                {
                  label: 'Favoritos',
                  value: 'bookmark',
                },
              ]}
              onChangeFilter={(filter) => setCurrentFilter(filter)}
              selectedFilter={currentFilter}
            />

            <Table tableHeaders={tableHeaders} tableContents={tableRows} />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentAnswerListPage}
                hasNext={currentAnswerListPage < totalNumberOfTablePages}
                hasPrevious={currentAnswerListPage > 1}
                totalPages={totalNumberOfTablePages}
                onChange={(page) => setCurrentAnswerListPage(page)}
              />
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
}

export default AnswerList;
