import Qs from 'qs';
import {deleteCookie, getCookie} from 'utils/cookies';
import {BaseQueryFn} from '@reduxjs/toolkit/query';
import axios, {AxiosRequestConfig, AxiosError} from 'axios';
import {FormErrorPayload, SimpleErrorPayload} from 'utils/types/ResponseError';

const axiosBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: Record<
        string,
        string | number | string[] | number[] | undefined
      >;
    },
    unknown,
    unknown
  > =>
  async ({url, method, data, params} = {method: 'GET', url: ''}) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        timeout: 360 * 1000,
        paramsSerializer: (params: Record<string, string>) =>
          Qs.stringify(params, {arrayFormat: 'repeat'}),
        params,
        headers: {
          Authorization: `Bearer ${getCookie('nlptToken')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      return {data: result.data};
    } catch (axiosError) {
      const errorPayload = axiosError as AxiosError<
        FormErrorPayload | SimpleErrorPayload
      >;

      // If response came from backend without a status code, the server is probably out of service
      if (!errorPayload.response?.status) {
        alert(
          'Ocorreu um erro ao se comunicar com a API, é provável que ela esteja fora do ar. Contate um desenvolvedor',
        );
      }

      // Status code 401 means that current user is trying to communicate with API without being authenticated
      if (errorPayload.code === '401') {
        deleteCookie('nlptToken');
        window.location.reload();
      }

      return {
        error: {
          status: errorPayload.response?.status,
          data: errorPayload.response?.data,
        },
      };
    }
  };

export default axiosBaseQuery;
