import {Icon} from 'letrus-ui';
import {ReactNode} from 'react';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  title: string;
  onGoBackButtonClick?(): void;
  rightElement: ReactNode;
}

function PageHeader({
  title,
  onGoBackButtonClick,
  rightElement,
}: PageHeaderProps): JSX.Element {
  return (
    <header className={styles.pageHeader}>
      <div className={styles.buttonContainer}>
        {!!onGoBackButtonClick && (
          <button type="button" onClick={onGoBackButtonClick}>
            <Icon icon="chevron-left" color="#414449" size="lg" />
          </button>
        )}

        <h1>{title}</h1>
      </div>

      <div className={styles.rightElementContainer}>{rightElement}</div>
    </header>
  );
}

export default PageHeader;
