import {NewButton, InputNumber} from 'letrus-ui';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {
  useUpdateCorpus,
  useFetchCorpusById,
  useFetchCorpusGenres,
  useFetchCorpusCompetences,
  useFetchCorpusCollections,
  useFetchCorpusInstructions,
} from 'store/reducers/corpus';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import SelectOption from 'utils/types/SelectOption';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import {useCorpus} from 'features/useCorpus';
import PageWrapper from 'components/PageWrapper';
import PageHeader from 'components/PageHeader';
import TextArea from 'components/TextArea';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import styles from './CorpusEdition.module.scss';

interface RouteParams {
  id: string;
}

type CorpusPayload = APIComponents['schemas']['CorpusPayload'] & {
  id: number;
};

interface CorpusCompetences {
  competence_id: number;
  title: string;
  score?: number;
  comment: string;
}

interface FormValues {
  composition_raw: string;
  genre: SelectOption;
  competences: CorpusCompetences[];
  general_comment: string;
  collection: SelectOption;
  instruction: SelectOption;
}

function CorpusEdition(): JSX.Element {
  const {id: corpusId} = useParams<RouteParams>();
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const {responseFeedbacks, getFormFieldError} = useCorpus();

  const {data: corpusGenres, isLoading: isLoadingCorpusGenres} =
    useFetchCorpusGenres({
      limit: 100,
    });
  const {data: corpusCompetences, isLoading: isLoadingCorpusCompetences} =
    useFetchCorpusCompetences({
      limit: 100,
    });
  const {data: corpusData, isLoading: isLoadingCorpus} = useFetchCorpusById({
    id: parseInt(corpusId, 10),
  });
  const {data: corpusCollections, isLoading: isLoadingCorpusCollections} =
    useFetchCorpusCollections({
      limit: 100,
    });
  const {data: corpusInstructions, isLoading: isLoadingCorpusInstructions} =
    useFetchCorpusInstructions({
      limit: 100,
    });

  const [
    updateCorpus,
    {
      isError: isUpdateCorpusError,
      isLoading: isUpdatingCorpus,
      isSuccess: isUpdateCorpusSuccess,
      error: updateCorpusError,
      status: updateCorpusStatus,
    },
  ] = useUpdateCorpus();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    formState: {errors: formErrors},
    reset: formReset,
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {competences: []},
  });
  const {fields, append} = useFieldArray({
    name: 'competences',
    control: formControl,
  });

  const history = useHistory();

  useEffect(() => {
    const competences = corpusCompetences?.results;
    if (competences?.length && !fields.length) {
      append(
        competences.map((competence) => ({
          competence_id: competence.id,
          title: competence.name,
          score: undefined,
          comment: '',
        })),
      );
    }
  }, [corpusCompetences]);

  useEffect(() => {
    const competences = corpusCompetences?.results;
    const collections = corpusCollections?.results;
    const instructions = corpusInstructions?.results;
    if (
      corpusData &&
      competences?.length &&
      collections?.length &&
      instructions?.length &&
      fields.length &&
      !fields[0]?.score
    ) {
      const collection = collections.find(
        (collection) => collection.id === corpusData.collection_id,
      );
      const instruction = instructions.find(
        (instruction) => instruction.id === corpusData.instruction_id,
      );
      formReset({
        competences: corpusData.competences.map((competence) => {
          const competenceInList = competences.find(
            (listCompetence) => listCompetence.id === competence.id_competence,
          );

          return {
            competence_id: competence.id_competence,
            title: competenceInList?.name || `c${competence.id}`,
            score: competence.score,
            comment: competence.comment,
          };
        }),
        genre: {
          label: corpusData.genre_name,
          value: String(corpusData.genre_id),
        },
        collection: collection
          ? {
              label: collection.name,
              value: String(collection.id),
            }
          : undefined,
        instruction: instruction
          ? {
              label: instruction.name,
              value: String(instruction.id),
            }
          : undefined,
        composition_raw: corpusData.composition_raw,
        general_comment: corpusData.general_comment,
      });
    }
  }, [
    corpusCompetences,
    corpusData,
    corpusGenres,
    corpusCollections,
    corpusInstructions,
    fields,
  ]);

  // Opening feedback modal for API responses
  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isUpdateCorpusSuccess || isUpdateCorpusError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [updateCorpusStatus]);

  const updateCorpusErrorData = (updateCorpusError as ResponseError)
    ?.data as FormErrorPayload;

  const isLoading =
    isLoadingCorpusGenres ||
    isLoadingCorpusCompetences ||
    isLoadingCorpusCollections ||
    isLoadingCorpusInstructions ||
    isLoadingCorpus;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isUpdateCorpusError || isUpdateCorpusSuccess) {
      return 'updateCorpus';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isUpdateCorpusSuccess) {
      return responseFeedbacks.updateCorpus.success.message;
    }
    if (isUpdateCorpusError) {
      const errorMessages = Object.entries(updateCorpusErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }

    return '';
  }

  function onResponseFeedbackClose() {
    setIsResponseFeedbackModalOpen(false);
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isUpdateCorpusSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: () => onResponseFeedbackClose(),
      onClose: () => onResponseFeedbackClose(),
    };
  }

  function onFormSubmit() {
    const {
      composition_raw,
      general_comment,
      collection,
      instruction,
      genre,
      competences,
    } = getFormValues();

    const serializedFormValues: CorpusPayload = {
      id: parseInt(corpusId, 10),
      composition_raw,
      general_comment,
      genre_id: Number(genre.value),
      collection_id: Number(collection.value),
      instruction_id: Number(instruction.value),
      competences: competences.map((competence) => ({
        id: competence.competence_id,
        score: competence.score || 0,
        comment: competence.comment,
      })),
    };

    updateCorpus(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Visualizar corpus"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <NewButton
              text="Editar Corpus"
              kind="primary"
              userRole="teacher"
              type="submit"
              isLoading={isUpdatingCorpus}
              disabled={isUpdatingCorpus}
            />
          }
        />

        {isLoading && <LoadingForm numberOfInputs={6} />}

        {!isLoading &&
          corpusGenres?.results.length &&
          corpusCompetences?.results.length && (
            <fieldset className={styles.fieldset}>
              <div className={styles.inputWrapper}>
                <Controller
                  control={formControl}
                  name="composition_raw"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, value}}) => (
                    <TextArea
                      id="content"
                      labelText="Texto"
                      placeholder="Texto a ser utilizado"
                      name={name}
                      rows={8}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ?? ''}
                      errorMessage={getFormFieldError(
                        formErrors?.composition_raw?.type,
                      )}
                      resizable
                    />
                  )}
                />
              </div>

              {!!corpusGenres?.results?.length && (
                <div className={styles.inputWrapper}>
                  <label htmlFor="genre">Gênero</label>
                  <Controller
                    control={formControl}
                    name="genre"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          onChange={onChange}
                          options={corpusGenres.results.map(({id, name}) => ({
                            label: name,
                            value: String(id),
                          }))}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Selecione o gênero"
                          name={name}
                          inputId="genre"
                        />
                      </>
                    )}
                  />
                </div>
              )}

              {!!corpusCollections?.results?.length && (
                <div className={styles.inputWrapper}>
                  <label htmlFor="collection">Coletânea</label>
                  <Controller
                    control={formControl}
                    name="collection"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          onChange={onChange}
                          options={corpusCollections.results.map(
                            ({id, name}) => ({
                              label: name,
                              value: String(id),
                            }),
                          )}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Selecione a coletânea"
                          name={name}
                          inputId="collection"
                        />
                      </>
                    )}
                  />
                </div>
              )}

              {!!corpusInstructions?.results?.length && (
                <div className={styles.inputWrapper}>
                  <label htmlFor="instruction">Proposta</label>
                  <Controller
                    control={formControl}
                    name="instruction"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          onChange={onChange}
                          options={corpusInstructions.results.map(
                            ({id, name}) => ({
                              label: name,
                              value: String(id),
                            }),
                          )}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Selecione a proposta"
                          name={name}
                          inputId="instruction"
                        />
                      </>
                    )}
                  />
                </div>
              )}

              {!!fields.length &&
                fields.map((field, index) => (
                  <div key={field.competence_id}>
                    <h3>Competência {field.title}</h3>

                    <div className={styles.inputWrapper}>
                      <Controller
                        control={formControl}
                        name={`competences.${index}.score` as const}
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: {name, onBlur, onChange, value, ref},
                        }) => {
                          return (
                            <InputNumber
                              id={`competences.${index}.score`}
                              labelText="Nota"
                              placeholder="1-5"
                              name={name}
                              ref={ref}
                              onBlur={onBlur}
                              onChange={(event) => {
                                const score = parseInt(event.target.value, 10);
                                if (score < 0) {
                                  onChange(0);
                                  return;
                                }
                                if (score > 5) {
                                  onChange(5);
                                  return;
                                }
                                onChange(score);
                              }}
                              value={value ?? ''}
                              min={0}
                              max={5}
                              errorMessage={
                                formErrors?.competences?.[index]?.score
                                  ?.type === 'required'
                                  ? 'Este campo não pode ficar em branco'
                                  : undefined
                              }
                              data-testid={`score-${field.competence_id}`}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className={styles.inputWrapper}>
                      <Controller
                        control={formControl}
                        name={`competences.${index}.comment` as const}
                        rules={{
                          required: true,
                        }}
                        render={({field: {name, onBlur, onChange, value}}) => {
                          return (
                            <TextArea
                              id={`competences.${index}.comment`}
                              labelText="Justificativa"
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value ?? ''}
                              errorMessage={
                                formErrors?.competences?.[index]?.comment
                                  ?.type === 'required'
                                  ? 'Este campo não pode ficar em branco'
                                  : undefined
                              }
                              resizable
                              data-testid={`comment-${field.competence_id}`}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}

              <div className={styles.inputWrapper}>
                <Controller
                  control={formControl}
                  name="general_comment"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, value}}) => (
                    <TextArea
                      id="general_comment"
                      labelText="Comentário geral"
                      placeholder="Ex: O narrador pode utilizar linguagem coloquial, porém de maneira monitorada, os desvios devem se manter num padrão sintático adequado. Evite gírias, use expressões que sejam de entendimento geral. Em hipótese nenhuma use linguagem das redes sociais como abreviações."
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ?? ''}
                      errorMessage={getFormFieldError(
                        formErrors?.general_comment?.type,
                      )}
                      resizable
                    />
                  )}
                />
              </div>
            </fieldset>
          )}
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default CorpusEdition;
