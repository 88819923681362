import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const tagsReducer = createApi({
  reducerPath: 'tags',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchTags: builder.query<
        APIComponents['schemas']['Tag'][],
        {search?: string; limit?: number | string; offset?: number}
      >({
        query: ({limit, offset, search}) => ({
          url: '/tags/',
          method: 'GET',
          params: {
            limit,
            offset,
            search,
          },
        }),
      }),
      createTag: builder.mutation<
        APIComponents['schemas']['Tag'],
        Omit<APIComponents['schemas']['Tag'], 'id'>
      >({
        query: (data) => ({
          method: 'POST',
          url: `/tags/`,
          data,
        }),
      }),
    };
  },
});

export const {
  fetchTags: {useQuery: useFetchTags, useLazyQuery: useFetchTagsLazy},
  createTag: {useMutation: useCreateTag},
} = tagsReducer.endpoints;
