import {FieldError} from 'react-hook-form';

export function useAnswers() {
  const responseFeedbacks = {
    createAnswer: {
      title: 'Criar resposta',
      error: {
        message: 'Ocorreu um erro ao criar a resposta.',
      },
      success: {
        message: 'A resposta foi criada com sucesso.',
      },
    },
    bookmarkAnswer: {
      title: 'Favoritar resposta',
      error: {
        message: 'Ocorreu um erro ao favoritar a resposta.',
      },
      success: {
        message: 'A resposta foi favoritada com sucesso.',
      },
    },
    unbookmarkAnswer: {
      title: 'Favoritar resposta',
      error: {
        message: 'Ocorreu um erro ao desfavoritar a resposta.',
      },
      success: {
        message: 'A resposta foi desfavoritada com sucesso.',
      },
    },
    updateAnswerAnnotations: {
      title: 'Editar anotações da resposta',
      error: {
        message: 'Ocorreu um erro ao editar as anotações da resposta.',
      },
      success: {
        message: 'As anotações foram salvas com sucesso.',
      },
    },
    annotateAnswerResults: {
      title: 'Anotar resultados',
      error: {
        message: 'Ocorreu um erro ao anotar os resultados.',
      },
      success: {
        message: 'Os resultados foram anotados com sucesso.',
      },
    },
    default: {
      title: 'Requisição',
      error: {
        message: 'Erro',
      },
      success: {
        message: 'Sucesso',
      },
    },
  };

  function getFormFieldError(
    errorType: FieldError['type'] | undefined,
  ): string | undefined {
    if (errorType === 'required') {
      return 'Esse campo não pode ficar em branco';
    }

    return;
  }

  return {
    responseFeedbacks,
    getFormFieldError,
  };
}
