import {Modal, NewButton} from 'letrus-ui';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import FeedbackIllustration from '../../images/illustration_01.svg';
import styles from './FeedbackModal.module.scss';

export interface FieldError {
  fieldName: string;
  errors: string[];
}

export interface FeedbackModalProps {
  isOpen: boolean;
  onClose(): void;
  onButtonClick(): void;
  title: string;
  subtitle: string;
  feedbackMessage?: string | FieldError[];
  buttonText: string;
  iconName?: IconName;
}

function FeedbackModal({
  isOpen,
  onButtonClick,
  onClose,
  title,
  subtitle,
  feedbackMessage,
  buttonText,
  iconName,
}: FeedbackModalProps): JSX.Element {
  const isSingleFeedback = typeof feedbackMessage === 'string';
  const isMultipleFeedback = Array.isArray(feedbackMessage);

  return (
    <Modal
      isOpen={isOpen}
      clickOutsideToClose
      onClose={onClose}
      buttonClose
      title={title}
      minWidth={400}
      minHeight={510}
      vh={55}
    >
      <div className={styles.modalWrapper}>
        <img src={FeedbackIllustration} alt="Ilustração de feedback" />

        <h2>{subtitle}</h2>

        {isSingleFeedback && <p>{feedbackMessage}</p>}

        {isMultipleFeedback && typeof feedbackMessage !== 'string' && (
          <ul>
            {feedbackMessage?.map(({fieldName, errors}) => (
              <li key={`${fieldName}-error`}>
                <b>Campo {fieldName}</b>

                <ul>
                  {errors?.map((error) => (
                    <li key={`${fieldName}-${error}`}>{error}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        )}

        <NewButton
          icon={iconName ? ['far', iconName] : undefined}
          text={buttonText}
          onClick={onButtonClick}
        />
      </div>
    </Modal>
  );
}

export default FeedbackModal;
