/* eslint-disable react/no-array-index-key */
import styles from './Table.module.scss';

export interface TableProps {
  tableHeaders: string[];
  tableContents: Array<Array<string | JSX.Element>>;
}

function Table({tableHeaders, tableContents}): JSX.Element {
  const rows = tableContents.map((row, rowIndex) => {
    const cells = row.map((cellData, cellIndex) => (
      <td key={`${rowIndex}-${cellIndex}`}>{cellData}</td>
    ));
    return <tr key={rowIndex}>{cells}</tr>;
  });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {tableHeaders.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>

      <tbody>{rows}</tbody>
    </table>
  );
}

export default Table;
