import {Icon, InputNumber, InputText, NewButton} from 'letrus-ui';
import Select from 'react-select';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCreateEntityEvalution} from 'store/reducers/entityEvalutions';
import FeedbackModal from 'components/FeedbackModal';
import {useFetchNLPEntitiesLazy} from 'store/reducers/nlpEntities';
import {NLPEntityEnum} from 'utils/types/NLPToolsAPI';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import SelectOption from 'utils/types/SelectOption';
import PageWrapper from '../../components/PageWrapper';
import styles from './EntityEvaluationCreation.module.scss';

interface FormValues {
  testName: string;
  exemplesQuantity: string;
  entityEvaluationType: SelectOption;
  entities: SelectOption[];
}

const EntityEvaluationCreation: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: {errors},
  } = useForm<FormValues>();

  const [
    createEntityEvaluation,
    {
      isError: isCreateEntityEvaluationError,
      isLoading: isCreatingEntityEvaluation,
      isSuccess: isCreateEntityEvaluationSuccess,
      error: createEntityEvaluationError,
    },
  ] = useCreateEntityEvalution();
  const [
    fetchNLPEntitiesLazy,
    {
      error: fetchNLPEntitiesError,
      isLoading: isLoadingNLPEntities,
      data: NLPEntitiesData,
    },
  ] = useFetchNLPEntitiesLazy();

  const history = useHistory();

  const {entityEvaluationType} = watch();

  useEffect(() => {
    if (isCreateEntityEvaluationError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateEntityEvaluationError]);

  useEffect(() => {
    if (isCreateEntityEvaluationSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateEntityEvaluationSuccess]);

  useEffect(() => {
    if (entityEvaluationType?.value) {
      fetchNLPEntitiesLazy(
        {entity_type: getValues().entityEvaluationType?.value as NLPEntityEnum},
        true,
      );
    }
  }, [entityEvaluationType?.value]);

  const createEntityEvaluationErrorData = (
    createEntityEvaluationError as ResponseError
  )?.data as SimpleErrorPayload;

  const entityEvaluationEntityOptions = [
    {
      label: 'Regras gramaticais',
      value: 'grammarrule',
    },
    {
      label: 'Marcadores textuais',
      value: 'textmarker',
    },
  ];

  function onSubmit() {
    const {exemplesQuantity, testName, entityEvaluationType, entities} =
      getValues();

    const entity_ids = entities.find((entity) => entity?.value === 'all')
      ? NLPEntitiesData?.map((NLPEntitiy) => NLPEntitiy?.id)
      : entities.map((entity) => parseInt(entity.value, 10));

    const serializedEntityEvaluationData = {
      title: testName,
      entity_type: entityEvaluationType.value as NLPEntityEnum,
      entity_ids,
      num_samples: parseInt(exemplesQuantity, 10),
    };

    createEntityEvaluation(serializedEntityEvaluationData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Criar avaliação de entidade</h1>
          </div>

          <NewButton
            text="Criar"
            kind="primary"
            userRole="teacher"
            type="submit"
            isLoading={isCreatingEntityEvaluation}
            disabled={isCreatingEntityEvaluation}
          />
        </header>

        <div className={styles.fieldset}>
          <InputText
            id="testName"
            labelText="Nome da avaliação"
            placeholder="Ex: Avaliação"
            errorMessage={
              errors?.testName?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            {...register('testName', {required: true})}
          />

          <InputNumber
            id="exemplesQuantity"
            labelText="Quantidade de exemplos por entidade"
            placeholder="Ex: 7"
            errorMessage={
              errors?.exemplesQuantity?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            {...register('exemplesQuantity', {required: true})}
          />

          <label htmlFor="entityEvaluationType">Tipo de avaliação</label>
          <div data-testid="entityEvaluationTypeWrapper">
            <Controller
              control={control}
              name="entityEvaluationType"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <Select
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '16px',
                      color: '#95989A',
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: 300,
                      borderColor: errors?.entityEvaluationType
                        ? '#f24e54'
                        : '#95989A',
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputId="entityEvaluationType"
                  options={entityEvaluationEntityOptions}
                  placeholder="Selecione a entidade"
                  name={name}
                  value={value}
                  ref={ref}
                />
              )}
            />
          </div>

          {errors?.entityEvaluationType ? (
            <label htmlFor="entityEvaluationType" className={styles.errorLabel}>
              Este campo não pode ficar em branco
            </label>
          ) : null}

          {isLoadingNLPEntities ? (
            <div className={styles.loadingSelectWrapper}>
              <LoadingRow height={50} width={300} />
            </div>
          ) : null}

          {entityEvaluationType?.value && !isLoadingNLPEntities && (
            <>
              <label htmlFor="entities">
                {entityEvaluationType.value === 'grammarrule'
                  ? 'Regras gramaticais'
                  : 'Marcadores textuais'}
              </label>
              <Controller
                control={control}
                name="entities"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <Select
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '16px',
                        color: '#95989A',
                      }),
                      control: (provided) => ({
                        ...provided,
                        minWidth: 300,
                        borderColor: errors?.entities ? '#f24e54' : '#95989A',
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    isMulti
                    onBlur={onBlur}
                    onChange={onChange}
                    options={[
                      ...(NLPEntitiesData?.length && !fetchNLPEntitiesError
                        ? [
                            {
                              label: `${
                                entityEvaluationType.value === 'grammarrule'
                                  ? 'Todas as regras'
                                  : 'Todos os marcadores'
                              }`,
                              value: 'all',
                            },
                          ]
                        : []),
                      ...(NLPEntitiesData?.length && !fetchNLPEntitiesError
                        ? NLPEntitiesData.map((NLPEntity) => ({
                            label: NLPEntity.name,
                            value: String(NLPEntity.id),
                          }))
                        : []),
                    ]}
                    placeholder={`Selecione ${
                      entityEvaluationType.value === 'grammarrule'
                        ? 'as regras'
                        : 'os marcadores '
                    }`}
                    inputId="entities"
                    name={name}
                    value={value}
                    ref={ref}
                  />
                )}
              />
              {errors?.entities ? (
                <label htmlFor="textmarker" className={styles.errorLabel}>
                  Este campo não pode ficar em branco
                </label>
              ) : null}
            </>
          )}
        </div>
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Criar avaliação de entidade"
        subtitle={isCreateEntityEvaluationError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreateEntityEvaluationError
            ? createEntityEvaluationErrorData.detail
            : 'A avaliação foi criada.'
        }
        onClose={
          isCreateEntityEvaluationError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.entityEvalutionList)
        }
        onButtonClick={
          isCreateEntityEvaluationError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.entityEvalutionList)
        }
        buttonText={
          isCreateEntityEvaluationError
            ? 'Tentar novamente'
            : 'Voltar às avaliações'
        }
        iconName={isCreateEntityEvaluationError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default EntityEvaluationCreation;
