import {DropdownMenu, PageWrapper as UIPageWrapper} from 'letrus-ui';
import {ItemProps} from 'letrus-ui/dist/components/DropdownMenu';
import {Menu} from 'letrus-ui/dist/components/SideBar';
import {useHistory} from 'react-router-dom';
import {AuthRoutes} from 'routes';
import {deleteCookie} from 'utils/cookies';
import styles from './PageWrapper.module.scss';

interface PageWrapperProps {
  children?: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({children}) => {
  const history = useHistory();

  const dropdownProps: Array<ItemProps> = [
    {
      icon: 'sign-out',
      id: 2,
      onClick: () => {
        deleteCookie('nlptToken');

        history.push(AuthRoutes.homepage);
      },
      text: 'Sair',
    },
  ];

  const dropdownMenu = (
    <DropdownMenu items={dropdownProps} title="Menu" titleColor="black" />
  );

  const menuOptions: Menu[] = [
    {
      label: 'Dashboard',
      icon: {icon: 'home'},
      onClick: () => history.push(AuthRoutes.homepage),
      active: false,
    },
    {
      label: 'Regras',
      icon: {icon: 'spell-check'},
      onClick: () => history.push(AuthRoutes.grammarRuleList),
      active: false,
    },
    {
      label: 'Marcadores',
      icon: {icon: 'highlighter'},
      onClick: () => history.push(AuthRoutes.textMarkerList),
      active: false,
    },
    {
      label: 'Categorias',
      icon: {icon: 'th-large'},
      onClick: () => history.push(AuthRoutes.textMarkerCategoryList),
      active: false,
    },
    {
      label: 'Plágios',
      icon: {icon: 'copy'},
      onClick: () => history.push(AuthRoutes.plagiarismTextList),
      active: false,
    },
    {
      label: 'Avaliações',
      icon: {icon: 'clipboard-list-check'},
      onClick: () => history.push(AuthRoutes.entityEvalutionList),
      active: false,
    },
    {
      label: 'Blocos',
      icon: {icon: 'cube'},
      onClick: () => history.push(AuthRoutes.blockList),
      active: false,
    },
    {
      label: 'Prompts',
      icon: {icon: 'terminal'},
      onClick: () => history.push(AuthRoutes.promptList),
      active: false,
    },
    {
      label: 'Prompts Unificados',
      icon: {icon: 'code-merge'},
      onClick: () => history.push(AuthRoutes.promptUnifiedList),
      active: false,
    },

    {
      label: 'Respostas',
      icon: {icon: 'reply'},
      onClick: () => history.push(AuthRoutes.answerList),
    },
    {
      label: 'Corpus',
      icon: {icon: 'book'},
      onClick: () => history.push(AuthRoutes.corpusList),
    },
    {
      label: 'Scheduling',
      icon: {icon: 'calendar-alt'},
      onClick: () => history.push(AuthRoutes.scheduling),
    },
    {
      label: 'Prompts Generativos',
      icon: {icon: 'flask'},
      onClick: () => history.push(AuthRoutes.generativePromptList),
    },
    {
      label: 'Playground Generativo',
      icon: {icon: 'cogs'},
      onClick: () => history.push(AuthRoutes.generativePlayground),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <UIPageWrapper
        menu={menuOptions}
        rightComponent={dropdownMenu}
        theme="dark"
      >
        <div className={styles.container}>{children}</div>
      </UIPageWrapper>
    </div>
  );
};

export default PageWrapper;
