import {FieldError} from 'react-hook-form';
import {Block, BlockVersion} from 'components/DragItem';

export function usePrompts() {
  const responseFeedbacks = {
    createPrompt: {
      title: 'Criar prompt',
      error: {
        message: 'Ocorreu um erro ao criar o prompt.',
      },
      success: {
        message: 'O prompt foi criado.',
      },
    },
    createPromptVersion: {
      title: 'Criar versão do prompt',
      error: {
        message: 'Ocorreu um erro ao criar a versão do prompt.',
      },
      success: {
        message: 'Uma nova versão do prompt foi criada.',
      },
    },
    deletePrompt: {
      title: 'Excluir prompt',
      error: {
        message: 'Ocorreu um erro ao excluir o prompt, tente novamente.',
      },
      success: {
        message: 'O prompt foi excluído.',
      },
    },
    default: {
      title: 'Requisição',
      error: {
        message: 'Erro',
      },
      success: {
        message: 'Sucesso',
      },
    },
  };

  function getFormFieldError(
    errorType: FieldError['type'] | undefined,
  ): string | undefined {
    if (errorType === 'required') {
      return 'Esse campo não pode ficar em branco';
    }

    return;
  }

  function onChangeVersion(
    block: Block,
    version: BlockVersion,
    blocks: Block[],
    setBlocks: (blocks: Block[]) => void,
  ) {
    const newBlocks = blocks.map((currentBlock) => {
      if (currentBlock.id === block.id) {
        return {...block, currentVersion: version, content: version.content};
      }
      return currentBlock;
    });

    setBlocks(newBlocks);
  }

  function setNewBlockFollowingRules(
    newBlock: Block,
    blocks: Block[],
    setBlocks: (blocks: Block[]) => void,
  ) {
    const lastBlock = blocks[blocks.length - 1];
    if (lastBlock && lastBlock.fixed) {
      setBlocks([
        ...blocks.filter((b) => b.id !== lastBlock.id),
        newBlock,
        lastBlock,
      ]);
      return;
    }
    setBlocks(blocks.concat([newBlock]));
  }

  return {
    responseFeedbacks,
    getFormFieldError,
    onChangeVersion,
    setNewBlockFollowingRules,
  };
}
