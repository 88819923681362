import {Modal} from 'letrus-ui';
import styles from './UnifiedPromptBlocksInfoModal.module.scss';
import FeedbackIllustration from '../../images/illustration_01.svg';

export interface UnifiedPromptBlocksInfoModalProps {
  onClose(): void;
}

function UnifiedPromptBlocksInfoModal({
  onClose,
}: UnifiedPromptBlocksInfoModalProps): JSX.Element {
  return (
    <Modal
      isOpen
      clickOutsideToClose
      onClose={onClose}
      buttonClose
      title="Blocos para o índice de letramento"
      minWidth={400}
      minHeight={510}
    >
      <div className={styles.modalWrapper}>
        <img src={FeedbackIllustration} alt="Ilustração de feedback" />

        <h2>
          Caso esteja criando blocos para o Índice de Letramento, atente-se a
          adição dos seguintes textos nos blocos:
        </h2>

        <ul>
          <li>
            <b>
              {`{redacao}`}: Obrigatório para o sistema. Incluso automaticamente
              na criação de Prompts.
            </b>

            <ul>
              <li>Neste bloco será inserido a redação do aluno.</li>
            </ul>
          </li>

          <li>
            <b>
              {`{format_instruction}`}: Obrigatório para o sistema. Deve ser
              incluido manualmente.
            </b>

            <ul>
              <li>
                O sistema preencherá este bloco com informações sobre como o
                modelo generativo deve estruturar a resposta esperada.
              </li>
            </ul>
          </li>

          <li>
            <b>{`{proposta}`}: Opcional</b>

            <ul>
              <li>
                Cada redação contém uma proposta. Caso exista um bloco com esta
                tag, ela sera substituída pelo texto proposta associado a
                redação no momento do processamento.
              </li>
            </ul>
          </li>

          <li>
            <b>{`{genero}`}: Opcional</b>

            <ul>
              <li>
                Cada redação contém um gênero. Caso exista um bloco com esta
                tag, ela sera substituída pelo texto gênero associado a redação
                no momento do processamento.
              </li>
            </ul>
          </li>

          <li>
            <b>{`{coletanea}`}: Opcional</b>

            <ul>
              <li>
                Cada redação contém uma coletânea. Caso exista um bloco com esta
                tag, ela sera substituída pelo texto coletânea associado a
                redação no momento do processamento.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Modal>
  );
}

export default UnifiedPromptBlocksInfoModal;
