import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const corpusReducer = createApi({
  reducerPath: 'corpus',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  tagTypes: ['GetCorpus'],
  endpoints(builder) {
    return {
      fetchCorpus: builder.query<
        APIComponents['schemas']['Paginated_CorpusGet'],
        {
          created?: string;
          genre__name?: string;
          ordering?: string;
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({created, genre__name, ordering, limit, offset}) => ({
          url: '/corpus_il',
          method: 'GET',
          params: {
            created,
            genre__name,
            ordering,
            limit,
            offset,
          },
        }),
        providesTags: ['GetCorpus'],
      }),
      fetchCorpusGenres: builder.query<
        APIComponents['schemas']['Paginated_CorpusGenreGet'],
        {
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({limit, offset}) => ({
          url: '/corpus_il/genre',
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
      }),
      fetchCorpusCompetences: builder.query<
        APIComponents['schemas']['Paginated_CorpusCompetenceGet'],
        {
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({limit, offset}) => ({
          url: '/corpus_il/competence',
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
      }),
      fetchCorpusById: builder.query<
        APIComponents['schemas']['Corpus'],
        {id: number}
      >({
        query: (data) => ({
          method: 'GET',
          url: `/corpus_il/${data.id}`,
          data,
        }),
        keepUnusedDataFor: 1,
      }),
      fetchCorpusCollections: builder.query<
        APIComponents['schemas']['Paginated_CorpusCollectionGet'],
        {
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({limit, offset}) => ({
          url: '/corpus_il/collection',
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
      }),
      fetchCorpusInstructions: builder.query<
        APIComponents['schemas']['Paginated_CorpusInstructionGet'],
        {
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({limit, offset}) => ({
          url: '/corpus_il/instruction',
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
      }),
      createCorpus: builder.mutation<
        APIComponents['schemas']['Corpus'],
        APIComponents['schemas']['CorpusPayload']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/corpus_il/`,
          data,
        }),
        invalidatesTags: ['GetCorpus'],
      }),
      updateCorpus: builder.mutation<
        APIComponents['schemas']['Corpus'],
        APIComponents['schemas']['CorpusPayload'] & {id: number}
      >({
        query: (data) => ({
          method: 'PATCH',
          url: `/corpus_il/${data.id}`,
          data,
        }),
        invalidatesTags: ['GetCorpus'],
      }),
      deleteCorpus: builder.mutation<void, {corpusId: number}>({
        query: ({corpusId}) => ({
          method: 'DELETE',
          url: `/corpus_il/${corpusId}`,
        }),
        invalidatesTags: ['GetCorpus'],
      }),
    };
  },
});

export const {
  fetchCorpus: {useQuery: useFetchCorpus, useLazyQuery: useFetchCorpusLazy},
  fetchCorpusCollections: {
    useQuery: useFetchCorpusCollections,
    useLazyQuery: useFetchCorpusCollectionsLazy,
  },
  fetchCorpusInstructions: {
    useQuery: useFetchCorpusInstructions,
    useLazyQuery: useFetchCorpusInstructionsLazy,
  },
  fetchCorpusCompetences: {
    useQuery: useFetchCorpusCompetences,
    useLazyQuery: useFetchCorpusCompetencesLazy,
  },
  fetchCorpusGenres: {
    useQuery: useFetchCorpusGenres,
    useLazyQuery: useFetchCorpusGenresLazy,
  },
  fetchCorpusById: {
    useQuery: useFetchCorpusById,
    useLazyQuery: useFetchCorpusByIdLazy,
  },
  createCorpus: {useMutation: useCreateCorpus},
  updateCorpus: {useMutation: useUpdateCorpus},
  deleteCorpus: {useMutation: useDeleteCorpus},
} = corpusReducer.endpoints;
