import {NewButton} from 'letrus-ui';
import NoDataIllustration from 'images/no-data.svg';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import styles from './NoResultsWarning.module.scss';

interface NoResultsWarningProps {
  description: string;
  onButtonClick(): void;
  buttonText: string;
  buttonIcon: IconName;
}

function NoResultsWarning({
  description,
  onButtonClick,
  buttonText,
  buttonIcon,
}: NoResultsWarningProps): JSX.Element {
  return (
    <div className={styles.noResults}>
      <img src={NoDataIllustration} alt="Ilustração de sem resultados" />
      <h2>{description}</h2>

      <div className={styles.resetFormDataButtonWrapper} title="Recarregar">
        <NewButton
          icon={['fas', buttonIcon]}
          onClick={onButtonClick}
          text={buttonText}
        />
      </div>
    </div>
  );
}

export default NoResultsWarning;
