import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const blocksReducer = createApi({
  reducerPath: 'blocks',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  tagTypes: ['GetBlocks'],
  endpoints(builder) {
    return {
      fetchBlocks: builder.query<
        APIComponents['schemas']['Paginated_BlockGet'],
        {
          search?: string;
          limit?: number | string;
          offset?: number;
          ordering?: string;
          header?: string;
          tag_name?: string;
        }
      >({
        query: ({search, limit, offset, header, tag_name, ordering}) => ({
          url: '/blocks',
          method: 'GET',
          params: {
            search: search || undefined,
            limit,
            offset,
            ordering,
            header,
            tag__name: tag_name,
          },
        }),
        providesTags: ['GetBlocks'],
      }),
      fetchBlockVersionById: builder.query<
        APIComponents['schemas']['BlockById'],
        {blockId: number; version: number}
      >({
        query: ({blockId, version}) => ({
          url: `/blocks/${blockId}/versions/${version}`,
          method: 'GET',
        }),
      }),
      createBlock: builder.mutation<
        APIComponents['schemas']['Block'],
        Omit<APIComponents['schemas']['Block'], 'id'>
      >({
        query: (data) => ({
          method: 'POST',
          url: `/blocks/`,
          data,
        }),
        invalidatesTags: ['GetBlocks'],
      }),
      createBlockVersion: builder.mutation<
        APIComponents['schemas']['CreateBlockVersionResponse'],
        {blockId: number; content: string}
      >({
        query: (data) => ({
          method: 'POST',
          url: `/blocks/${data.blockId}`,
          data: {
            content: data.content,
          },
        }),
      }),
      updateProductionVersion: builder.mutation<
        APIComponents['schemas']['Block'],
        any & {id: number}
      >({
        query: (data) => ({
          method: 'PATCH',
          url: `/blocks/${data.id}`,
          data: {
            version: data.version,
          },
        }),
      }),
      deleteBlock: builder.mutation<void, {blockId: number}>({
        query: ({blockId}) => ({
          method: 'DELETE',
          url: `/blocks/${blockId}`,
        }),
        invalidatesTags: ['GetBlocks'],
      }),
      deleteBlockVersion: builder.mutation<
        void,
        {blockId: number; blockVersionNumber: number}
      >({
        query: ({blockId, blockVersionNumber}) => ({
          method: 'DELETE',
          url: `/blocks/${blockId}/versions/${blockVersionNumber}`,
        }),
      }),
    };
  },
});

export const {
  fetchBlocks: {useQuery: useFetchBlocks, useLazyQuery: useFetchBlocksLazy},
  fetchBlockVersionById: {useLazyQuery: useFetchBlockVersionByIdLazy},
  createBlock: {useMutation: useCreateBlock},
  createBlockVersion: {useMutation: useCreateBlockVersion},
  deleteBlock: {useMutation: useDeleteBlock},
  deleteBlockVersion: {useMutation: useDeleteBlockVersion},
  updateProductionVersion: {useMutation: useUpdateBlockProductionVersion},
} = blocksReducer.endpoints;
