import {NewButton, Pagination} from 'letrus-ui';
import {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import Select from 'react-select';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import {useScheduling} from 'features/useScheduling';
import SelectOption from 'utils/types/SelectOption';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import Table from 'components/Table';
import {useFetchCurrentUser} from 'store/reducers/users';
import {useFetchFlagsLazy} from 'store/reducers/flags';
import {
  useCreateSchedule,
  useFetchScheduleResultsLazy,
  useScheduleTrigger,
} from 'store/reducers/scheduling';
import {useFetchPromptsUnified} from 'store/reducers/promptsUnified';
import PageWrapper from '../../components/PageWrapper';
import styles from './Scheduling.module.scss';

type TableRow = [
  id: number,
  status: JSX.Element,
  corpus: number,
  date: string,
  link: JSX.Element,
];

interface FormValues {
  prompts: SelectOption[];
}

function Scheduling(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [currentScheduledResultsListPage, setCurrentScheduledResultsListPage] =
    useState(1);
  const [numberOfScheduledResultsPerPage, setNumberOfScheduledResultsPerPage] =
    useState<SelectOption>({label: '10', value: '10'});
  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    formState: {errors: formErrors},
    watch: formWatch,
    reset: formReset,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });
  const [prompts] = formWatch(['prompts']);
  const {responseFeedbacks, getFormFieldError} = useScheduling();

  const [
    createSchedule,
    {
      isError: isCreateScheduleError,
      isLoading: isCreatingSchedule,
      isSuccess: isCreateScheduleSuccess,
      error: createScheduleError,
      status: createScheduleStatus,
      data: scheduleData,
    },
  ] = useCreateSchedule();
  const [
    scheduleTrigger,
    {
      isError: isScheduleTriggerError,
      isLoading: isLoadingScheduleTrigger,
      isSuccess: isScheduleTriggerSuccess,
      error: scheduleTriggerError,
      status: scheduleTriggerStatus,
    },
  ] = useScheduleTrigger();
  const isLoadingSchedule = isLoadingScheduleTrigger || isCreatingSchedule;

  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isScheduleTriggerSuccess ||
      isCreateScheduleError ||
      isScheduleTriggerError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createScheduleStatus, scheduleTriggerStatus]);

  const createScheduleErrorData = (createScheduleError as ResponseError)
    ?.data as FormErrorPayload;

  const scheduleTriggerErrorData = (scheduleTriggerError as ResponseError)
    ?.data as FormErrorPayload;

  const {data: currentUser, isLoading: isLoadingCurrentUser} =
    useFetchCurrentUser();
  const [fetchFlagsLazy, {data: flags}] = useFetchFlagsLazy();
  const {
    data: promptUnifiedListResponse,
    isLoading: isLoadingPromptUnifiedList,
  } = useFetchPromptsUnified({limit: 9999});

  const [
    fetchScheduleResultsLazy,
    {
      isFetching: isFetchingScheduleResults,
      isLoading: isLoadingScheduleResults,
      isUninitialized: isFetchScheduleResultsUninitialized,
      data: scheduleResultsList,
    },
  ] = useFetchScheduleResultsLazy();

  const totalNumberOfTablePages = useMemo(
    () =>
      scheduleResultsList?.total
        ? Math.ceil(
            scheduleResultsList.total /
              Number(numberOfScheduledResultsPerPage?.value),
          )
        : 0,
    [scheduleResultsList],
  );

  useEffect(() => {
    if (!isLoadingCurrentUser && currentUser?.id) {
      fetchFlagsLazy({
        flagKey: 'nlptools-scheduling',
        entityId: String(currentUser.id),
        entityContext: {userId: currentUser.id},
      });
    }
  }, [currentUser, isLoadingCurrentUser]);

  useEffect(() => {
    if (isCreateScheduleSuccess && scheduleData?.id) {
      scheduleTrigger({scheduleId: scheduleData.id});
    }
  }, [isCreateScheduleSuccess]);

  // Refetching results on current page or results per page change
  useEffect(() => {
    fetchScheduleResults();
  }, [currentScheduledResultsListPage, numberOfScheduledResultsPerPage?.value]);

  function getResponseType(): keyof typeof responseFeedbacks {
    if (
      isCreateScheduleError ||
      isScheduleTriggerError ||
      isScheduleTriggerSuccess
    ) {
      return 'createManualSchedule';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreateScheduleError) {
      const errorMessages = Object.entries(createScheduleErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isScheduleTriggerError) {
      const errorMessages = Object.entries(scheduleTriggerErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isScheduleTriggerSuccess) {
      return responseFeedbacks.createManualSchedule.success.message;
    }

    return '';
  }

  function onResposeFeedbackClose() {
    setIsResponseFeedbackModalOpen(false);
    formReset({prompts: []});
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isScheduleTriggerSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onResposeFeedbackClose,
      onClose: onResposeFeedbackClose,
    };
  }

  function fetchScheduleResults() {
    const offset =
      (currentScheduledResultsListPage - 1) *
      Number(numberOfScheduledResultsPerPage?.value);
    const limit = numberOfScheduledResultsPerPage?.value;
    fetchScheduleResultsLazy(
      {
        limit,
        offset,
      },
      true,
    );
  }

  const isLoading =
    isFetchingScheduleResults ||
    isLoadingScheduleResults ||
    isLoadingPromptUnifiedList;
  const hasFirstScheduleResultsFetchHappened =
    !isFetchScheduleResultsUninitialized;

  function onFormSubmit() {
    const {prompts} = getFormValues();

    const serializedFormValues: APIComponents['schemas']['SchedulePayload'] = {
      ids: prompts.map((prompt) => Number(prompt.value)),
      execute_date: dayjs().format(),
    };

    createSchedule(serializedFormValues);
  }

  function resetTableData() {
    setCurrentScheduledResultsListPage(1);

    fetchScheduleResultsLazy(
      {
        limit: numberOfScheduledResultsPerPage?.value,
        offset:
          (currentScheduledResultsListPage - 1) *
          Number(numberOfScheduledResultsPerPage?.value),
      },
      true,
    );
  }

  function getStatusTextStyle(status: string) {
    if (status === '1' || status === 'Sucesso') {
      return styles.successText;
    }
    if (status === 'Executando') {
      return styles.waitingText;
    }
    return styles.errorText;
  }

  const tableHeaders: string[] = [
    'ID',
    'Status',
    'Tamanho do corpus',
    'Data de execução',
    'Link para o S3',
  ];

  const tableRows: TableRow[] =
    scheduleResultsList?.results?.reduce(
      (
        accumulator: TableRow[],
        {id, status, corpus_size, executed_date, s3_link},
      ) => [
        ...accumulator,
        [
          id,
          <span
            key={`schedling-${id}-status`}
            className={getStatusTextStyle(status)}
            title={status}
          >
            {status === '1' ? 'Finalizado' : status}
          </span>,
          corpus_size,
          executed_date
            ? dayjs(executed_date).format('DD/MM/YYYY')
            : 'Data não encontrada',
          <div key={`schedling-${id}-actions`} className={styles.buttonWrapper}>
            <Link to={{pathname: s3_link}} target="_blank" title={s3_link}>
              {s3_link}
            </Link>
          </div>,
        ],
      ],
      [],
    ) ?? [];

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader title="Scheduling" />

        <form
          className={styles.manualScheduling}
          onSubmit={handleFormSubmit(onFormSubmit)}
        >
          <fieldset>
            <div className={styles.actionsWrapper}>
              <h3>
                Prompts adicionados para serem executados em{' '}
                {dayjs(new Date()).format('DD/MM/YYYY')}
              </h3>
              {flags && flags.variantKey === 'on' && (
                <NewButton
                  text="Executar processamento"
                  kind="primary"
                  userRole="teacher"
                  type="submit"
                  disabled={isLoadingSchedule || !prompts?.length}
                />
              )}
            </div>
            <div className={styles.selectWrapper}>
              {!isLoadingPromptUnifiedList &&
                promptUnifiedListResponse?.results?.length && (
                  <>
                    <label htmlFor="prompts">
                      Adicionar prompts unificados
                    </label>
                    <Controller
                      control={formControl}
                      name="prompts"
                      rules={{
                        required: true,
                      }}
                      render={({field: {name, onChange, onBlur, value}}) => (
                        <>
                          <Select
                            options={promptUnifiedListResponse.results.map(
                              (prompt) => ({
                                label: prompt.name,
                                value: String(prompt.id),
                              }),
                            )}
                            styles={{
                              ...reactSelectStyles,
                              container: (provided) => ({
                                ...provided,
                                maxWidth: '300px',
                              }),
                            }}
                            isMulti
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                            isDisabled={!flags || flags.variantKey !== 'on'}
                            inputId="prompts"
                            placeholder="Selecione os prompts unificados"
                          />
                          {formErrors?.prompts && (
                            <label
                              htmlFor="prompts"
                              className={styles.error}
                              id="error"
                            >
                              {(formErrors?.prompts as any)?.message
                                ? (formErrors?.prompts as any)?.message
                                : getFormFieldError(
                                    (formErrors?.prompts as any)?.type,
                                  )}
                            </label>
                          )}
                        </>
                      )}
                    />
                  </>
                )}
            </div>
          </fieldset>
        </form>

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingTable numberOfRows={6} />
          </div>
        )}

        {!isLoading &&
          hasFirstScheduleResultsFetchHappened &&
          !scheduleResultsList?.results?.length && (
            <NoResults resetData={resetTableData} />
          )}

        {!isLoading && !!scheduleResultsList?.results?.length && (
          <div className={styles.tableWrapper}>
            <TableHeader
              setNumberOfResultsPerPage={setNumberOfScheduledResultsPerPage}
              numberOfResultsPerPage={numberOfScheduledResultsPerPage}
            />

            <Table tableHeaders={tableHeaders} tableContents={tableRows} />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentScheduledResultsListPage}
                hasNext={
                  currentScheduledResultsListPage < totalNumberOfTablePages
                }
                hasPrevious={currentScheduledResultsListPage > 1}
                totalPages={totalNumberOfTablePages}
                onChange={(page) => setCurrentScheduledResultsListPage(page)}
              />
            </div>
          </div>
        )}
      </div>
      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default Scheduling;
