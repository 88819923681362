import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import NoDataIllustration from 'images/no-data.svg';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import FeedbackModal from 'components/FeedbackModal';
import {
  useFetchPlagiarismTextByIdLazy,
  useUpdatePlagiarismText,
} from 'store/reducers/plagiarismTexts';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './PlagiarismTextEdition.module.scss';

interface FormValues {
  title: string;
  text: string;
  url: string;
  tags: string;
}

const PlagiarismTextEdition: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const {id: plagiarismTextId} = useParams<{id: string}>();

  const [
    updatePlagiarismText,
    {
      isError: isUpdatePlagiarismTextError,
      isSuccess: isUpdatePlagiarismTextSuccess,
      isLoading: isUpdatingPlagiarismText,
      error: updatePlagiarismTextError,
    },
  ] = useUpdatePlagiarismText();
  const [
    fetchPlagiarismTextByIdLazy,
    {
      isError: isFetchPlagiarismTextByIdError,
      isLoading: isLoadingPlagiarismTextById,
      data: fetchPlagiarismTextByIdData,
      isFetching: isFetcingPlagiarismTextById,
    },
  ] = useFetchPlagiarismTextByIdLazy();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      tags: '',
      text: '',
      title: '',
      url: '',
    },
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    fetchPlagiarismTextByIdLazy(parseInt(plagiarismTextId, 10), true);
  }, [plagiarismTextId]);

  useEffect(() => {
    if (fetchPlagiarismTextByIdData) {
      reset({
        tags: fetchPlagiarismTextByIdData.tags.reduce(
          (previous, current) => `${previous},${current}`,
        ),
        text: fetchPlagiarismTextByIdData.text,
        title: fetchPlagiarismTextByIdData.title,
        url: fetchPlagiarismTextByIdData.url,
      });
    }
  }, [fetchPlagiarismTextByIdData]);

  useEffect(() => {
    if (isUpdatePlagiarismTextError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isUpdatePlagiarismTextError]);

  useEffect(() => {
    if (isUpdatePlagiarismTextSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isUpdatePlagiarismTextSuccess]);

  const updatePlagiarismTextErrorData = (
    updatePlagiarismTextError as ResponseError
  )?.data as SimpleErrorPayload;

  function onSubmit() {
    const {tags, ...otherValues} = getValues();

    const plagiarismTextData = {
      tags: tags.split(',').map((tag) => tag),
      ...otherValues,
    };

    updatePlagiarismText({
      ...plagiarismTextData,
      id: parseInt(plagiarismTextId, 10),
    });
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {isFetchPlagiarismTextByIdError && !isLoadingPlagiarismTextById && (
          <div className={styles.noResults}>
            <img src={NoDataIllustration} alt="Ilustração de sem resultados" />
            <h2>Parece que esse texto de plágio não está disponível</h2>

            <div className={styles.resetDataButtonWrapper} title="Recarregar">
              <NewButton
                icon={['fas', 'undo']}
                onClick={() => history.push(AuthRoutes.plagiarismTextList)}
                text="Voltar aos textos"
              />
            </div>
          </div>
        )}

        {(isLoadingPlagiarismTextById || isFetcingPlagiarismTextById) && (
          <LoadingForm numberOfInputs={2} />
        )}

        {fetchPlagiarismTextByIdData && !isFetchPlagiarismTextByIdError && (
          <>
            <header>
              <div className={styles.buttonContainer}>
                <button type="button" onClick={history.goBack}>
                  <Icon icon="chevron-left" color="#414449" />
                </button>

                <h1>Editar categoria de marcador</h1>
              </div>

              <NewButton
                text="Editar"
                kind="primary"
                userRole="teacher"
                type="submit"
                isLoading={isUpdatingPlagiarismText}
                disabled={isUpdatingPlagiarismText}
              />
            </header>

            <div className={styles.fieldset}>
              <Controller
                control={control}
                name="title"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="title"
                    labelText="Título do texto"
                    placeholder="Ex: Harry Potter"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.title?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="text"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="text"
                    labelText="Texto"
                    placeholder="Ex: Um grande texto"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.text?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="url"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="url"
                    labelText="URL (fonte)"
                    placeholder="Ex: www.url.com.br"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.url?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="tags"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="tags"
                    labelText="Tags (separadas por vírgula)"
                    placeholder="Ex: tag1,tag2,tag3"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.tags?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                  />
                )}
              />
            </div>
          </>
        )}
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Atualizar texto de plágio"
        subtitle={isUpdatePlagiarismTextError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isUpdatePlagiarismTextError
            ? updatePlagiarismTextErrorData.detail
            : 'O texto de plágio foi atualizado.'
        }
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText={isUpdatePlagiarismTextError ? 'Tentar novamente' : 'Fechar'}
        iconName={isUpdatePlagiarismTextError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default PlagiarismTextEdition;
