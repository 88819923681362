import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const answersReducer = createApi({
  reducerPath: 'answers',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchAnswers: builder.query<
        APIComponents['schemas']['Paginated_Answers'],
        {
          limit?: number | string;
          offset?: number;
          ordering?: string;
          bookmark?: boolean;
        }
      >({
        query: ({limit, offset, ordering, bookmark}) => ({
          url: '/answer/',
          method: 'GET',
          params: {
            limit,
            offset,
            ordering,
            bookmark: bookmark !== undefined ? String(bookmark) : bookmark,
          },
        }),
      }),
      fetchAnswerById: builder.query<
        APIComponents['schemas']['Answer'],
        number
      >({
        query: (answerId) => ({
          method: 'GET',
          url: `/answer/${answerId}`,
        }),
      }),
      createAnswer: builder.mutation<
        APIComponents['schemas']['AnswerPlaygroundResponse'],
        APIComponents['schemas']['AnswerPlaygroundPost']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/playground/`,
          data,
        }),
      }),
      updateAnswer: builder.mutation<
        APIComponents['schemas']['AnswerPlaygroundResponse'],
        APIComponents['schemas']['AnswerUpdate'] & {answerId: number}
      >({
        query: ({answerId, annotation, score}) => ({
          method: 'PATCH',
          url: `/answer/${answerId}`,
          data: {
            annotation,
            score,
          },
        }),
      }),
      bookmarkAnswer: builder.mutation<
        APIComponents['schemas']['AnswerPlaygroundPost'],
        number
      >({
        query: (answerId: number) => ({
          method: 'PATCH',
          url: `/playground/${answerId}/bookmark/`,
        }),
      }),
      annotateAnswerResults: builder.mutation<
        APIComponents['schemas']['Annotation'],
        {
          answerId: number;
          score: number;
          annotation: string;
        }
      >({
        query: (data) => ({
          method: 'POST',
          url: `/playground/${data.answerId}/annotate/`,
          data,
        }),
      }),
    };
  },
});

export const {
  createAnswer: {useMutation: useCreateAnswer},
  updateAnswer: {useMutation: useUpdateAnswer},
  bookmarkAnswer: {useMutation: useBookmarkAnswer},
  annotateAnswerResults: {useMutation: useAnnotateAnswerResults},
  fetchAnswers: {useQuery: useFetchAnswers, useLazyQuery: useFetchAnswersLazy},
  fetchAnswerById: {
    useQuery: useFetchAnswerById,
    useLazyQuery: useFetchAnswerByIdLazy,
  },
} = answersReducer.endpoints;
