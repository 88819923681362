export type GenerativePromptGenre = 'ENEM' | 'GM';

export const generativePromptGenres: GenerativePromptGenre[] = ['ENEM', 'GM'];

interface Competences {
  ENEM: string[];
  GM: string[];
}

export const competences: Competences = {
  ENEM: ['c1', 'c2', 'c3', 'c4', 'c5', 'general_comment'],
  GM: ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'general_comment'],
};
