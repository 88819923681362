import skeleton from '../Skeleton.module.scss';
import styles from './LoadingTable.module.scss';

export interface LoadingTableProps {
  numberOfRows?: number;
}

const LoadingTable: React.FC<LoadingTableProps> = ({numberOfRows = 10}) => {
  return (
    <div data-testid="loading-table" className={`${styles.wrapper}`}>
      <table>
        <tbody>
          {Array.from(Array(numberOfRows).keys()).map((item) => (
            <tr
              key={`row-${item}`}
              data-testid={`row-${item}`}
              className={skeleton.shimmerEffect}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoadingTable;
