import {NewButton, InputNumber} from 'letrus-ui';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {
  useCreateCorpus,
  useFetchCorpusGenres,
  useFetchCorpusCompetences,
  useFetchCorpusCollections,
  useFetchCorpusInstructions,
} from 'store/reducers/corpus';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import {AuthRoutes} from 'routes';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import SelectOption from 'utils/types/SelectOption';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import {useCorpus} from 'features/useCorpus';
import PageWrapper from 'components/PageWrapper';
import PageHeader from 'components/PageHeader';
import TextArea from 'components/TextArea';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import styles from './CorpusCreation.module.scss';

interface CorpusCompetences {
  competence_id: number;
  title: string;
  score?: number;
  comment: string;
}

interface FormValues {
  composition_raw: string;
  genre: SelectOption;
  competences: CorpusCompetences[];
  general_comment: string;
  collection: SelectOption;
  instruction: SelectOption;
}

function CorpusCreation(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const {responseFeedbacks, getFormFieldError} = useCorpus();

  const {data: corpusGenres, isLoading: isLoadingCorpusGenres} =
    useFetchCorpusGenres({
      limit: 100,
    });
  const {data: corpusCompetences, isLoading: isLoadingCorpusCompetences} =
    useFetchCorpusCompetences({
      limit: 100,
    });
  const {data: corpusCollections, isLoading: isLoadingCorpusCollections} =
    useFetchCorpusCollections({
      limit: 100,
    });
  const {data: corpusInstructions, isLoading: isLoadingCorpusInstructions} =
    useFetchCorpusInstructions({
      limit: 100,
    });
  const [
    createCorpus,
    {
      isError: isCreateCorpusError,
      isLoading: isCreatingCorpus,
      isSuccess: isCreateCorpusSuccess,
      error: createCorpusError,
      status: createCorpusStatus,
    },
  ] = useCreateCorpus();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    formState: {errors: formErrors},
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {competences: []},
  });
  const {fields, append} = useFieldArray({
    name: 'competences',
    control: formControl,
  });

  const history = useHistory();

  useEffect(() => {
    const competences = corpusCompetences?.results;
    if (competences?.length && !fields.length) {
      append(
        competences.map((competence) => ({
          competence_id: competence.id,
          title: competence.name,
          score: undefined,
          comment: '',
        })),
      );
    }
  }, [corpusCompetences]);

  // Opening feedback modal for API responses
  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isCreateCorpusSuccess || isCreateCorpusError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createCorpusStatus]);

  const createCorpusErrorData = (createCorpusError as ResponseError)
    ?.data as FormErrorPayload;

  const isLoading =
    isLoadingCorpusGenres ||
    isLoadingCorpusCompetences ||
    isLoadingCorpusCollections ||
    isLoadingCorpusInstructions;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreateCorpusError || isCreateCorpusSuccess) {
      return 'createCorpus';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreateCorpusSuccess) {
      return responseFeedbacks.createCorpus.success.message;
    }
    if (isCreateCorpusError) {
      const errorMessages = Object.entries(createCorpusErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }

    return '';
  }

  function onResponseFeedbackClose(isCreateCorpusSuccess: boolean) {
    setIsResponseFeedbackModalOpen(false);
    if (isCreateCorpusSuccess) {
      history.push(AuthRoutes.corpusList);
    }
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isCreateCorpusSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: () => onResponseFeedbackClose(isCreateCorpusSuccess),
      onClose: () => onResponseFeedbackClose(isCreateCorpusSuccess),
    };
  }

  function onFormSubmit() {
    const {
      composition_raw,
      general_comment,
      genre,
      collection,
      instruction,
      competences,
    } = getFormValues();

    const serializedFormValues: APIComponents['schemas']['CorpusPayload'] = {
      composition_raw,
      general_comment,
      genre_id: Number(genre.value),
      collection_id: Number(collection.value),
      instruction_id: Number(instruction.value),
      competences: competences.map((competence) => ({
        id: competence.competence_id,
        score: competence.score || 0,
        comment: competence.comment,
      })),
    };

    createCorpus(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Criar corpus"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <NewButton
              text="Criar Corpus"
              kind="primary"
              userRole="teacher"
              type="submit"
              isLoading={isCreatingCorpus}
              disabled={isCreatingCorpus}
            />
          }
        />

        {isLoading && <LoadingForm numberOfInputs={6} />}

        {!isLoading &&
          corpusGenres?.results.length &&
          corpusCompetences?.results.length && (
            <fieldset className={styles.fieldset}>
              <div className={styles.inputWrapper}>
                <Controller
                  control={formControl}
                  name="composition_raw"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, value}}) => (
                    <TextArea
                      id="content"
                      labelText="Texto"
                      placeholder="Texto a ser utilizado"
                      name={name}
                      rows={8}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ?? ''}
                      errorMessage={getFormFieldError(
                        formErrors?.composition_raw?.type,
                      )}
                      resizable
                    />
                  )}
                />
              </div>

              {!!corpusGenres?.results?.length && (
                <div className={styles.inputWrapper}>
                  <label htmlFor="genre">Gênero</label>
                  <Controller
                    control={formControl}
                    name="genre"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          onChange={onChange}
                          options={corpusGenres.results.map(({id, name}) => ({
                            label: name,
                            value: String(id),
                          }))}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Selecione o gênero"
                          name={name}
                          inputId="genre"
                        />
                      </>
                    )}
                  />
                </div>
              )}

              {!!corpusCollections?.results?.length && (
                <div className={styles.inputWrapper}>
                  <label htmlFor="collection">Coletânea</label>
                  <Controller
                    control={formControl}
                    name="collection"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          onChange={onChange}
                          options={corpusCollections.results.map(
                            ({id, name}) => ({
                              label: name,
                              value: String(id),
                            }),
                          )}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Selecione a coletânea"
                          name={name}
                          inputId="collection"
                        />
                      </>
                    )}
                  />
                </div>
              )}

              {!!corpusInstructions?.results?.length && (
                <div className={styles.inputWrapper}>
                  <label htmlFor="instruction">Proposta</label>
                  <Controller
                    control={formControl}
                    name="instruction"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          onChange={onChange}
                          options={corpusInstructions.results.map(
                            ({id, name}) => ({
                              label: name,
                              value: String(id),
                            }),
                          )}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Selecione a proposta"
                          name={name}
                          inputId="instruction"
                        />
                      </>
                    )}
                  />
                </div>
              )}

              {!!fields.length &&
                fields.map((field, index) => (
                  <div key={field.competence_id}>
                    <h3>Competência {field.title}</h3>

                    <div className={styles.inputWrapper}>
                      <Controller
                        control={formControl}
                        name={`competences.${index}.score` as const}
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: {name, onBlur, onChange, value, ref},
                        }) => {
                          return (
                            <InputNumber
                              id={`competences.${index}.score`}
                              labelText="Nota"
                              placeholder="1-5"
                              name={name}
                              ref={ref}
                              onBlur={onBlur}
                              onChange={(event) => {
                                const score = parseInt(event.target.value, 10);
                                if (score < 0) {
                                  onChange(0);
                                  return;
                                }
                                if (score > 5) {
                                  onChange(5);
                                  return;
                                }
                                onChange(score);
                              }}
                              value={value ?? ''}
                              min={0}
                              max={5}
                              errorMessage={
                                formErrors?.competences?.[index]?.score
                                  ?.type === 'required'
                                  ? 'Este campo não pode ficar em branco'
                                  : undefined
                              }
                              data-testid={`score-${field.competence_id}`}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className={styles.inputWrapper}>
                      <Controller
                        control={formControl}
                        name={`competences.${index}.comment` as const}
                        rules={{
                          required: true,
                        }}
                        render={({field: {name, onBlur, onChange, value}}) => {
                          return (
                            <TextArea
                              id={`competences.${index}.comment`}
                              labelText="Justificativa"
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value ?? ''}
                              errorMessage={
                                formErrors?.competences?.[index]?.comment
                                  ?.type === 'required'
                                  ? 'Este campo não pode ficar em branco'
                                  : undefined
                              }
                              resizable
                              data-testid={`comment-${field.competence_id}`}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}

              <div className={styles.inputWrapper}>
                <Controller
                  control={formControl}
                  name="general_comment"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, value}}) => (
                    <TextArea
                      id="general_comment"
                      labelText="Comentário geral"
                      placeholder="Ex: O narrador pode utilizar linguagem coloquial, porém de maneira monitorada, os desvios devem se manter num padrão sintático adequado. Evite gírias, use expressões que sejam de entendimento geral. Em hipótese nenhuma use linguagem das redes sociais como abreviações."
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ?? ''}
                      errorMessage={getFormFieldError(
                        formErrors?.general_comment?.type,
                      )}
                      resizable
                    />
                  )}
                />
              </div>
            </fieldset>
          )}
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default CorpusCreation;
