import {RootState} from 'store';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('nlpToolsState');

    if (!serializedState) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: RootState) => {
  const selectedSlices = ['authentication'];

  try {
    const selectedState = Object.entries(state).filter(([key]) =>
      selectedSlices.includes(key),
    );
    const serializedState = JSON.stringify(Object.fromEntries(selectedState));

    localStorage.setItem('nlpToolsState', serializedState);
  } catch (err) {
    //
  }
};

export const clearState = () => {
  localStorage.removeItem('nlpToolsState');
};
