import {useEffect, useState} from 'react';
import {Modal} from 'letrus-ui';
import PromptPreview from 'components/PromptPreview';
import {useFetchPromptVersionByIdLazy} from 'store/reducers/prompts';
import {Block} from 'components/DragItem';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import styles from './PromptPreviewModal.module.scss';

export interface FieldError {
  fieldName: string;
  errors: string[];
}

export interface FeedbackModalProps {
  isOpen: boolean;
  onClose(): void;
  promptId: number;
  promptVersionNumber: number;
  answer?: string;
}

function PromptPreviewModal({
  isOpen,
  onClose,
  promptId,
  promptVersionNumber,
  answer,
}: FeedbackModalProps): JSX.Element {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [fetchPromptVersionByIdLazy, {data: fetchPromptVersionByIdData}] =
    useFetchPromptVersionByIdLazy();

  // Fetching prompt data when modal open
  useEffect(() => {
    if (isOpen) {
      fetchPromptVersionByIdLazy(
        {
          promptId,
          version: promptVersionNumber,
        },
        false,
      );
    }
  }, [isOpen]);

  // Filling with prompt blocks and its rules
  useEffect(() => {
    if (fetchPromptVersionByIdData && isOpen) {
      const {blocks: blocksData} = fetchPromptVersionByIdData;
      setBlocks(
        blocksData.map((block) => {
          return {
            id: block.id,
            content:
              block.versions.find((v) => v.id === block.block_version_id)
                ?.content || block.latest_version.content,
            header: block.header,
            currentVersion:
              block.versions.find((v) => v.id === block.block_version_id) ||
              block.latest_version,
            availableVersions: block.versions,
            disableRemove: block.rules.includes('required'),
            fixed: block.rules.includes('do_not_move'),
          };
        }),
      );
    }
  }, [fetchPromptVersionByIdData, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      clickOutsideToClose
      onClose={onClose}
      buttonClose
      minWidth={400}
      minHeight={600}
      vh={55}
    >
      {!blocks.length ? (
        <LoadingForm numberOfInputs={3} />
      ) : (
        <div className={styles.previewWrapper}>
          <PromptPreview blocks={blocks} answer={answer} />
        </div>
      )}
    </Modal>
  );
}

export default PromptPreviewModal;
