import skeleton from '../Skeleton.module.scss';
import styles from './LoadingForm.module.scss';

export interface LoadingFormProps {
  numberOfInputs?: number;
}

const LoadingForm: React.FC<LoadingFormProps> = ({numberOfInputs = 5}) => {
  return (
    <div className={styles.loadingForm} data-testid="loading-form">
      {Array.from(Array(numberOfInputs).keys()).map((item) => (
        <span key={`skeleton-${item}`}>
          <label
            htmlFor={`skeleton-${item}`}
            className={skeleton.shimmerEffect}
          >
            Skeleton
          </label>
          <input id={`skeleton-${item}`} className={skeleton.shimmerEffect} />
        </span>
      ))}
    </div>
  );
};

export default LoadingForm;
