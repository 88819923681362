import {Icon, NewButton, Pagination, Search} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import NoDataIllustration from 'images/no-data.svg';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import Tooltip from 'react-tooltip';
import {
  useDeleteTextMarker,
  useFetchTextMarkersLazy,
} from 'store/reducers/textMarkers';
import FeedbackModal from 'components/FeedbackModal';
import dayjs from 'dayjs';
import Select from 'react-select';
import SelectOption from 'utils/types/SelectOption';
import entitiesPerPageOptions from 'utils/entitiesPerPageOptions';
import {AuthRoutes} from 'routes';
import PageWrapper from '../../components/PageWrapper';
import styles from './TextMarkerList.module.scss';

const TextMarkerList: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfTextMarkersPerPage, setNumberOfTextMarkersPerPage] =
    useState<SelectOption | null>({label: '10', value: '10'});
  const [isDeleteTextMarkerModalOpen, setIsDeleteTextMarkerModalOpen] =
    useState<boolean>(false);
  const [textMarkerToDeleteId, setTextMarkerToDeleteId] = useState<number>(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const [
    fetchTextMarkersLazy,
    {
      isFetching: isFetchingTextMarkers,
      isLoading: isLoadingTextMarkers,
      isUninitialized: isFetchTextMarkersUninitialized,
      data,
    },
  ] = useFetchTextMarkersLazy();
  const [
    deleteTextMarker,
    {
      isLoading: isDeletingTextMarker,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
    },
  ] = useDeleteTextMarker();

  const history = useHistory();

  useEffect(() => {
    fetchTextMarkersLazy(
      {
        search: searchText,
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }, [currentPage, numberOfTextMarkersPerPage?.value]);

  useEffect(() => {
    if (isDeleteError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteSuccess && !isDeletingTextMarker) {
      fetchTextMarkersLazy({}, true);
    }
  }, [isDeleteSuccess, isDeletingTextMarker]);

  const totalNumberOfPages = useMemo(
    () =>
      data?.total
        ? Math.ceil(data.total / Number(numberOfTextMarkersPerPage?.value))
        : 0,
    [data],
  );
  const isLoading = isLoadingTextMarkers || isFetchingTextMarkers;
  const hasFirstTextMarkersFetchHappened = !isFetchTextMarkersUninitialized;

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchTextMarkersLazy(
        {limit: numberOfTextMarkersPerPage?.value, offset: 0},
        true,
      );

      setCurrentPage(1);
    }

    const inputValue = event.target.value.toLowerCase();

    setSearchText(inputValue);
  }

  function onClickDeleteTextMarker(textMarkerId: number) {
    setTextMarkerToDeleteId(textMarkerId);
    setIsDeleteTextMarkerModalOpen(true);
  }

  function onDeleteTextMarker() {
    deleteTextMarker({textMarkerId: textMarkerToDeleteId});
    setIsDeleteTextMarkerModalOpen(false);
  }

  function onSubmitSearch() {
    setCurrentPage(1);

    fetchTextMarkersLazy(
      {
        search: searchText,
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }

  function resetData() {
    setCurrentPage(1);
    setSearchText('');

    fetchTextMarkersLazy(
      {
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }

  return (
    <PageWrapper>
      <div className={styles.container}>
        <header>
          <h1>Marcadores textuais</h1>

          <NewButton
            text="Criar marcador"
            kind="primary"
            userRole="teacher"
            type="button"
            onClick={() => history.push(AuthRoutes.textMarkerCreation)}
          />
        </header>

        {(isLoading || isDeletingTextMarker) && (
          <div className={styles.loadingWrapper}>
            <LoadingTable />
          </div>
        )}

        {!isLoading &&
          hasFirstTextMarkersFetchHappened &&
          !data?.results?.length && (
            <div className={styles.noResults}>
              <img
                src={NoDataIllustration}
                alt="Ilustração de sem resultados"
              />
              <h2>Sem resultados</h2>

              <div className={styles.resetDataButtonWrapper} title="Recarregar">
                <NewButton icon={['fas', 'undo']} onClick={resetData} />
              </div>
            </div>
          )}

        {!isLoading && data?.results?.length && !isDeletingTextMarker && (
          <div className={styles.tableWrapper}>
            <div className={styles.searchContainer}>
              <Search
                onClick={onSubmitSearch}
                id="name"
                search={{
                  name: 'search',
                  value: searchText,
                  onChange: onChangeSearchText as (
                    eventOrValue: string | React.SyntheticEvent<Element, Event>,
                  ) => void,
                  // TODO: Type onChange correctly on UI
                }}
                searchProps={{
                  placeholder: 'Pesquisar marcador',
                  searchTag: 'input',
                  required: false,
                }}
              />

              <div
                data-tip=""
                data-for="numberOfEntitiesPerPageSelect"
                data-testid="numberOfEntitiesPerPageSelectWrapper"
                title="numberOfEntitiesPerPageSelectWrapper"
              >
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: 100,
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onChange={(event) => setNumberOfTextMarkersPerPage(event)}
                  options={entitiesPerPageOptions}
                  value={numberOfTextMarkersPerPage}
                  inputId="entityEvaluationVersion"
                  placeholder="Selecione a versão da regra"
                  name="entityEvaluationVersion"
                />
              </div>

              <Tooltip
                className={styles.numberOfEntitiesPerPageSelectTooltip}
                id="numberOfEntitiesPerPageSelect"
                type="dark"
                effect="solid"
                place="top"
              >
                <div className={styles.wrapper}>
                  <p>Número de categorias por página</p>
                </div>
              </Tooltip>
            </div>

            <table>
              <thead>
                <tr>
                  <th title="ID">ID</th>
                  <th title="Etiqueta">Etiqueta</th>
                  <th title="Categoria">Categoria</th>
                  <th title="Subcategoria">Subcategoria</th>
                  <th title="Data de criação">Data de criação</th>
                  <th title="Ações">Ações</th>
                </tr>
              </thead>

              <tbody>
                {data?.results?.map((item) => (
                  <tr key={item.id}>
                    <td title={String(item.id)}>{item.id}</td>
                    <td title={item.label}>{item.label}</td>
                    <td title={item.category.name}>{item.category.name}</td>
                    <td title={item.sub_category}>{item.sub_category}</td>
                    <td title={item.created}>
                      {dayjs(item.created).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      <div className={styles.buttonWrapper}>
                        <Link
                          to={`/marcadores-textuais/${item.id}/${item.latest_version}`}
                          title="Ir aos detalhes do marcador"
                        >
                          <Icon
                            icon={['fas', 'external-link']}
                            color="#666"
                            size="lg"
                          />
                        </Link>

                        <button
                          type="button"
                          className={styles.deleteButton}
                          title="Excluir marcador"
                          onClick={() => onClickDeleteTextMarker(item.id)}
                        >
                          <Icon
                            icon={['fas', 'trash-alt']}
                            color="#666"
                            size="lg"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                hasNext={currentPage < totalNumberOfPages}
                hasPrevious={currentPage > 1}
                totalPages={totalNumberOfPages}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      <DeletionModal
        isOpen={isDeleteTextMarkerModalOpen}
        message="Deseja mesmo excluir esse marcador?"
        onClickDelete={onDeleteTextMarker}
        onClose={() => setIsDeleteTextMarkerModalOpen(false)}
        subtitle="Tem certeza?"
        title="Excluir marcador"
      />

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Excluir marcador textual"
        subtitle={isDeleteError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isDeleteError
            ? 'Ocorreu um erro ao excluir o marcador, tente novamente.'
            : 'O marcador foi excluído.'
        }
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText={isDeleteError ? 'Tentar novamente' : 'Voltar'}
        iconName={isDeleteError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default TextMarkerList;
