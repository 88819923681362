import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const promptsUnifiedReducer = createApi({
  reducerPath: 'promptsUnified',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  tagTypes: ['GetPromptsUnified'],
  endpoints(builder) {
    return {
      fetchPromptsUnified: builder.query<
        APIComponents['schemas']['Paginated_PromptUnified'],
        {
          limit?: number | string;
          offset?: number;
          created?: string;
          name?: string;
          ordering?: string;
        }
      >({
        query: ({limit, offset, created, name, ordering}) => ({
          url: '/prompt_set',
          method: 'GET',
          params: {
            name,
            limit,
            offset,
            created,
            ordering,
          },
        }),
        providesTags: ['GetPromptsUnified'],
      }),
      fetchPromptUnifiedById: builder.query<
        APIComponents['schemas']['PromptUnified'],
        {id: number}
      >({
        query: (data) => ({
          method: 'GET',
          url: `/prompt_set/${data.id}`,
          data,
        }),
      }),
      createPromptUnified: builder.mutation<
        APIComponents['schemas']['PromptUnified'],
        APIComponents['schemas']['PromptUnifiedPayload']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/prompt_set`,
          data,
        }),
      }),
      deletePromptUnified: builder.mutation<void, {promptId: number}>({
        query: ({promptId}) => ({
          method: 'DELETE',
          url: `/prompt_set/${promptId}`,
        }),
        invalidatesTags: ['GetPromptsUnified'],
      }),
    };
  },
});

export const {
  fetchPromptsUnified: {
    useQuery: useFetchPromptsUnified,
    useLazyQuery: useFetchPromptsUnifiedLazy,
  },
  fetchPromptUnifiedById: {
    useQuery: useFetchPromptUnifiedById,
    useLazyQuery: useFetchPromptUnifiedByIdLazy,
  },
  createPromptUnified: {useMutation: useCreatePromptUnified},
  deletePromptUnified: {useMutation: useDeletePromptUnified},
} = promptsUnifiedReducer.endpoints;
