/* eslint-disable no-restricted-globals */
import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export interface ValidateGrammarRulePayload {
  suggestion?: string;
  rule_ids: string[];
  tokens: string[];
}

export const grammarRulesReducer = createApi({
  reducerPath: 'grammarRules',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchGrammarRules: builder.query<
        APIComponents['schemas']['Paginated_GrammarRuleGet_'],
        {search?: string; limit?: number | string; offset?: number}
      >({
        query: ({search, limit, offset}) => ({
          url: '/grammar_rules',
          method: 'GET',
          params: {
            search: search || undefined,
            limit,
            offset,
          },
        }),
      }),
      fetchGrammarRuleVersionById: builder.query<
        APIComponents['schemas']['GrammarRuleGetDetail'],
        {rule_id?: number; version?: number}
      >({
        query: ({rule_id, version}) => ({
          url: `/grammar_rules/${rule_id}/versions/${version}`,
          method: 'GET',
        }),
      }),
      createGrammarRule: builder.mutation<
        APIComponents['schemas']['GrammarRuleCreate'],
        APIComponents['schemas']['GrammarRuleCreate']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/grammar_rules`,
          data,
        }),
      }),
      createGrammarRuleVersion: builder.mutation<
        APIComponents['schemas']['GrammarRuleGetDetail'],
        APIComponents['schemas']['GrammarRuleUpdate'] & {id: number}
      >({
        query: (data) => ({
          method: 'POST',
          url: `/grammar_rules/${data.id}`,
          data,
        }),
      }),
      updateProductionVersion: builder.mutation<
        APIComponents['schemas']['GrammarRuleProdVersion'],
        APIComponents['schemas']['GrammarRuleProdVersion'] & {id: number}
      >({
        query: (data) => ({
          method: 'PATCH',
          url: `/grammar_rules/${data.id}`,
          data: {
            version: data.version,
          },
        }),
      }),
      deleteGrammarRule: builder.mutation<void, {grammarRuleId: number}>({
        query: ({grammarRuleId}) => ({
          method: 'DELETE',
          url: `/grammar_rules/${grammarRuleId}`,
        }),
      }),
      validateGrammarRule: builder.mutation<
        ValidateGrammarRulePayload,
        APIComponents['schemas']['GrammarRule'] & {text: string}
      >({
        query: (data) => ({
          method: 'POST',
          url: `/grammar_rules/validate`,
          data,
        }),
      }),
      deleteGrammarRuleVersion: builder.mutation<
        void,
        {grammarRuleId: number; grammarRuleVersionNumber: number}
      >({
        query: ({grammarRuleId, grammarRuleVersionNumber}) => ({
          method: 'DELETE',
          url: `/grammar_rules/${grammarRuleId}/versions/${grammarRuleVersionNumber}`,
        }),
      }),
    };
  },
});

export const {
  fetchGrammarRules: {
    useQuery: useFetchGrammarRules,
    useLazyQuery: useFetchGrammarRulesLazy,
  },
  fetchGrammarRuleVersionById: {
    useLazyQuery: useFetchGrammarRuleVersionByIdLazy,
  },
  createGrammarRule: {useMutation: useCreateGrammarRule},
  createGrammarRuleVersion: {useMutation: useCreateGrammarRuleVersion},
  deleteGrammarRule: {useMutation: useDeleteGrammaRule},
  deleteGrammarRuleVersion: {useMutation: useDeleteGrammarRuleVersion},
  validateGrammarRule: {useMutation: useValidateGrammarRule},
  updateProductionVersion: {useMutation: useUpdateGrammarRuleProductionVersion},
} = grammarRulesReducer.endpoints;
