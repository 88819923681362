import {Icon, Pagination} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import FeedbackModal, {FeedbackModalProps} from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import {AuthRoutes} from 'routes';
import TableHeader from 'components/TableHeader';
import ListHeader from 'components/ListHeader';
import NoResults from 'components/NoResults';
import Table from 'components/Table';
import {useDeletePrompt, useFetchPromptsLazy} from 'store/reducers/prompts';
import {usePrompts} from 'features/usePrompts';
import PageWrapper from '../../components/PageWrapper';
import styles from './PromptList.module.scss';

type TableRow = [
  id: number,
  title: string,
  description: string,
  buttons: JSX.Element,
];

function PromptList(): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const [currentPromptListPage, setCurrentPromptListPage] = useState(1);
  const [numberOfPromptsPerPage, setNumberOfPromptsPerPage] =
    useState<SelectOption>({label: '10', value: '10'});

  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const [isDeletePromptModalOpen, setIsDeletePromptModalOpen] = useState(false);
  const [promptIdToDelete, setPromptIdToDelete] = useState(0);
  const {responseFeedbacks} = usePrompts();
  const [currentFilter, setCurrentFilter] = useState<SelectOption>({
    label: 'Mais recentes',
    value: 'recent',
  });
  const [searchBy, setSearchBy] = useState<SelectOption>({
    label: 'Título',
    value: 'title',
  });

  const [
    fetchPromptsLazy,
    {
      isFetching: isFetchingPrompts,
      isLoading: isLoadingPrompts,
      isUninitialized: isFetchPromptsUninitialized,
      data: promptList,
    },
  ] = useFetchPromptsLazy();
  const [
    deletePrompt,
    {
      isLoading: isDeletingPrompt,
      isSuccess: isDeletePromptSuccess,
      isError: isDeletePromptError,
      status: deletePromptStatus,
      reset: resetDeletePromptStatus,
    },
  ] = useDeletePrompt();

  const totalNumberOfTablePages = useMemo(
    () =>
      promptList?.total
        ? Math.ceil(promptList.total / Number(numberOfPromptsPerPage?.value))
        : 0,
    [promptList],
  );

  const history = useHistory();

  // Opening feedback modal for API responses
  useEffect(() => {
    if (isDeletePromptError || isDeletePromptSuccess) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [deletePromptStatus]);

  function fetchPrompts() {
    const filter = currentFilter.value;
    const offset =
      (currentPromptListPage - 1) * Number(numberOfPromptsPerPage?.value);
    const limit = numberOfPromptsPerPage?.value;
    fetchPromptsLazy(
      {
        title: searchBy.value === 'title' ? searchText : undefined,
        description: searchBy.value === 'description' ? searchText : undefined,
        ordering: filter !== 'recent' ? filter : undefined,
        limit,
        offset,
      },
      true,
    );
  }

  // Refetching prompts on current page or prompts per page change
  useEffect(() => {
    fetchPrompts();
  }, [
    currentPromptListPage,
    numberOfPromptsPerPage?.value,
    searchBy,
    currentFilter,
  ]);

  // Refetching prompts after deletion
  useEffect(() => {
    if (isDeletePromptSuccess) {
      fetchPrompts();
    }
  }, [isDeletePromptSuccess]);

  const isLoading = isFetchingPrompts || isLoadingPrompts || isDeletingPrompt;
  const hasFirstPromptsFetchHappened = !isFetchPromptsUninitialized;

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchPromptsLazy({limit: numberOfPromptsPerPage?.value, offset: 0}, true);

      setCurrentPromptListPage(1);
    }

    const searchInputValue = event.target.value.toLowerCase();

    setSearchText(searchInputValue);
  }

  function onClickDeletePrompt(promptId: number) {
    setPromptIdToDelete(promptId);
    setIsDeletePromptModalOpen(true);
  }

  function onDeletePrompt() {
    deletePrompt(promptIdToDelete);
    setIsDeletePromptModalOpen(false);
  }

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isDeletePromptError || isDeletePromptSuccess) {
      return 'deletePrompt';
    }

    return 'default';
  }

  function getResponseMessage(): string {
    if (isDeletePromptSuccess) {
      return responseFeedbacks.deletePrompt.success.message;
    }
    if (isDeletePromptError) {
      return responseFeedbacks.deletePrompt.error.message;
    }

    return '';
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isDeletePromptSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    function onCloseModalCallback() {
      setIsResponseFeedbackModalOpen(false);
      resetDeletePromptStatus();
    }

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseModalCallback,
      onClose: onCloseModalCallback,
    };
  }

  function onSubmitSearch() {
    setCurrentPromptListPage(1);

    fetchPrompts();
  }

  function resetTableData() {
    setCurrentPromptListPage(1);
    setSearchText('');

    fetchPromptsLazy(
      {
        limit: numberOfPromptsPerPage?.value,
        offset:
          (currentPromptListPage - 1) * Number(numberOfPromptsPerPage?.value),
      },
      true,
    );
  }

  const tableHeaders = ['ID', 'Título', 'Descrição', 'Ações'];

  const tableRows: TableRow[] =
    promptList?.results?.reduce(
      (accumulator: TableRow[], {id, title, description, lastest_version}) => [
        ...accumulator,
        [
          id,
          title,
          description,
          <div key={`prompt-${id}-actions`} className={styles.buttonWrapper}>
            <Link
              to={`/prompts/${id}/${lastest_version?.version}`}
              title="Ir aos detalhes do prompt"
            >
              <Icon icon={['fas', 'external-link']} color="#666" size="lg" />
            </Link>

            <button
              type="button"
              className={styles.deleteButton}
              title="Excluir prompt"
              onClick={() => onClickDeletePrompt(id)}
            >
              <Icon icon={['fas', 'trash-alt']} color="#666" size="lg" />
            </button>
          </div>,
        ],
      ],
      [],
    ) ?? [];

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ListHeader
          title="Prompts"
          buttonText="Criar prompt"
          onClickButton={() => history.push(AuthRoutes.promptCreation)}
        />

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingTable numberOfRows={6} />
          </div>
        )}

        {!isLoading &&
          hasFirstPromptsFetchHappened &&
          !promptList?.results?.length && (
            <NoResults resetData={resetTableData} />
          )}

        {!isLoading && !!promptList?.results?.length && (
          <div className={styles.tableWrapper}>
            <TableHeader
              onSubmitSearch={onSubmitSearch}
              onChangeSearchText={onChangeSearchText}
              setNumberOfResultsPerPage={setNumberOfPromptsPerPage}
              searchText={searchText}
              numberOfResultsPerPage={numberOfPromptsPerPage}
              searchPlaceholder="Pesquisar prompts"
              filters={[
                {
                  label: 'Mais recentes',
                  value: 'recent',
                },
                {
                  label: 'Título ↓',
                  value: 'title',
                },
                {
                  label: 'Descrição ↓',
                  value: 'description',
                },
              ]}
              onChangeFilter={(filter) => setCurrentFilter(filter)}
              selectedFilter={currentFilter}
              searchOptions={[
                {
                  label: 'Titulo',
                  value: 'title',
                },
                {
                  label: 'Descrição',
                  value: 'description',
                },
              ]}
              searchBy={searchBy}
              onChangeSearchBy={(searchByOption) => setSearchBy(searchByOption)}
            />

            <Table tableHeaders={tableHeaders} tableContents={tableRows} />

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPromptListPage}
                hasNext={currentPromptListPage < totalNumberOfTablePages}
                hasPrevious={currentPromptListPage > 1}
                totalPages={totalNumberOfTablePages}
                onChange={(page) => setCurrentPromptListPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      {isDeletePromptModalOpen && (
        <DeletionModal
          isOpen
          message="Deseja mesmo excluir esse prompt?"
          onClickDelete={onDeletePrompt}
          onClose={() => setIsDeletePromptModalOpen(false)}
          subtitle="Tem certeza?"
          title="Excluir prompt"
        />
      )}

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default PromptList;
