/* eslint-disable @typescript-eslint/no-unused-vars */
import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {AuthRoutes} from 'routes';
import {useCreateGenerativePrompt} from 'store/reducers/generativePrompts';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import TextArea from 'components/TextArea';
import {competences, generativePromptGenres} from 'utils/constants/genres';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import SelectOption from 'utils/types/SelectOption';
import {isEmpty} from 'lodash';
import PromptVariablesModal from 'components/PromptVariablesModal';
import PageHeader from 'components/PageHeader';
import {usePrompts as useGenerativePrompts} from 'features/useGenerativePrompts';
import PageWrapper from '../../components/PageWrapper';
import styles from './GenerativePromptCreation.module.scss';

interface FormValues {
  title: string;
  type: SelectOption;
  competence: SelectOption;
  text: string;
}

function GenerativePromptCreation(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [isPromptVariablesModalOpen, setIsPromptVariablesModalOpen] =
    useState(false);

  const {responseFeedbacks, getFormFieldError} = useGenerativePrompts();

  const [
    createGenerativePrompt,
    {
      isError: isCreateGenerativePromptError,
      isLoading: isCreatingGenerativePrompt,
      isSuccess: isCreateGenerativePromptSuccess,
      error: createGenerativePromptError,
      status: createGenerativePromptStatus,
    },
  ] = useCreateGenerativePrompt();

  const {
    getValues: getFormValues,
    handleSubmit: handleFormSubmit,
    watch: formWatch,
    control: formControl,
    formState: {errors: formErrors},
  } = useForm<FormValues>({
    mode: 'onBlur',
  });
  const [type] = formWatch(['type']);

  const history = useHistory();

  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isCreateGenerativePromptSuccess || isCreateGenerativePromptError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createGenerativePromptStatus]);

  const createPromptErrorData = (createGenerativePromptError as ResponseError)
    ?.data as FormErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreateGenerativePromptError || isCreateGenerativePromptSuccess) {
      return 'createGenerativePrompt';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreateGenerativePromptError) {
      const errorMessages = Object.entries(createPromptErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isCreateGenerativePromptSuccess) {
      return responseFeedbacks.createGenerativePrompt.success.message;
    }

    return '';
  }

  function onResposeFeedbackClose() {
    setIsResponseFeedbackModalOpen(false);
    if (isCreateGenerativePromptSuccess) {
      history.push(AuthRoutes.promptList);
    }
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isCreateGenerativePromptSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onResposeFeedbackClose,
      onClose: onResposeFeedbackClose,
    };
  }

  function onFormSubmit() {
    const {title, type, text, competence} = getFormValues();
    const serializedFormValues: APIComponents['schemas']['CreateGenerativePrompt'] =
      {
        title,
        type: type.value.toLowerCase() as 'enem' | 'gm',
        text,
        competence: competence.value,
      };
    createGenerativePrompt(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Criar prompt generativo"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <div className={styles.rightContent}>
              <button
                type="button"
                className={styles.helpIconButton}
                onClick={() => setIsPromptVariablesModalOpen(true)}
              >
                <Icon icon={['far', 'question-circle']} size="2x" />
              </button>
              <NewButton
                text="Criar prompt generativo"
                kind="primary"
                userRole="teacher"
                type="submit"
                isLoading={isCreatingGenerativePrompt}
                disabled={isCreatingGenerativePrompt || !isEmpty(formErrors)}
              />
            </div>
          }
        />

        <fieldset className={styles.fieldset}>
          <div className={styles.fieldsWrapper}>
            <Controller
              control={formControl}
              name="title"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="title"
                  labelText="Título"
                  placeholder="Ex: Prompt para validar competência 1"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={getFormFieldError(formErrors?.title?.type)}
                />
              )}
            />

            <label htmlFor="type">Tipo</label>
            <Controller
              control={formControl}
              name="type"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <>
                  <Select
                    styles={{
                      ...reactSelectStyles,
                      container: (provided) => ({
                        ...provided,
                        minWidth: 300,
                      }),
                    }}
                    onChange={onChange}
                    options={generativePromptGenres.map((genre) => ({
                      label: genre,
                      value: genre,
                    }))}
                    ref={ref}
                    onBlur={onBlur}
                    value={value}
                    placeholder="ENEM/GM"
                    name={name}
                    inputId="type"
                  />
                </>
              )}
            />

            {formErrors?.type && (
              <label htmlFor="type" className={styles.error} id="type-error">
                Esse campo não pode ficar em branco
              </label>
            )}

            <label htmlFor="competence">Competência</label>
            <Controller
              control={formControl}
              name="competence"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <>
                  <Select
                    styles={{
                      ...reactSelectStyles,
                      container: (provided) => ({
                        ...provided,
                        minWidth: 300,
                      }),
                    }}
                    onChange={onChange}
                    options={competences[type?.value]?.map((competence) => ({
                      label: competence,
                      value: competence,
                    }))}
                    ref={ref}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Competência"
                    name={name}
                    inputId="competence"
                  />
                </>
              )}
            />

            {formErrors?.competence && (
              <label
                htmlFor="competence"
                className={styles.error}
                id="competence-error"
              >
                Esse campo não pode ficar em branco
              </label>
            )}

            <Controller
              control={formControl}
              name="text"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <TextArea
                  id="text"
                  labelText="Texto"
                  placeholder="Conteúdo do prompt que vai para o gpt, deve conter -> {essay}"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={getFormFieldError(formErrors?.text?.type)}
                  rows={8}
                  resizable
                />
              )}
            />
          </div>
        </fieldset>
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
      {isPromptVariablesModalOpen && (
        <PromptVariablesModal
          onClose={() => setIsPromptVariablesModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
}

export default GenerativePromptCreation;
