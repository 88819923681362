/* eslint-disable @typescript-eslint/no-unused-vars */
import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {useFetchGenerativePromptById} from 'store/reducers/generativePrompts';
import TextArea from 'components/TextArea';
import {competences, generativePromptGenres} from 'utils/constants/genres';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import SelectOption from 'utils/types/SelectOption';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import PromptVariablesModal from 'components/PromptVariablesModal';
import PageHeader from 'components/PageHeader';
import {usePrompts as useGenerativePrompts} from 'features/useGenerativePrompts';
import PageWrapper from '../../components/PageWrapper';
import styles from './GenerativePromptVisualization.module.scss';

interface RouteParams {
  generative_prompt_id: string;
}

interface FormValues {
  title: string;
  type: SelectOption;
  competence: SelectOption;
  text: string;
}

function GenerativePromptVisualization(): JSX.Element {
  const {generative_prompt_id: generativePromptId} = useParams<RouteParams>();
  const [isPromptVariablesModalOpen, setIsPromptVariablesModalOpen] =
    useState(false);
  const {data: generativePromptData, isLoading: isLoadingGenerativePrompt} =
    useFetchGenerativePromptById({
      id: parseInt(generativePromptId, 10),
    });

  const {getFormFieldError} = useGenerativePrompts();

  const {
    watch: formWatch,
    control: formControl,
    formState: {errors: formErrors},
    reset: formReset,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });
  const [type] = formWatch(['type']);

  const history = useHistory();

  useEffect(() => {
    if (generativePromptData && !isLoadingGenerativePrompt) {
      formReset({
        title: generativePromptData?.title,
        type: {
          label: generativePromptData?.type,
          value: generativePromptData?.type,
        },
        competence: {
          label: generativePromptData?.competence,
          value: generativePromptData?.competence,
        },
        text: generativePromptData?.text,
      });
    }
  }, [generativePromptData, isLoadingGenerativePrompt]);

  return (
    <PageWrapper>
      <form className={styles.container}>
        <PageHeader
          title="Visualizar prompt generativo"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <div className={styles.rightContent}>
              <button
                type="button"
                className={styles.helpIconButton}
                onClick={() => setIsPromptVariablesModalOpen(true)}
              >
                <Icon icon={['far', 'question-circle']} size="2x" />
              </button>
            </div>
          }
        />

        {isLoadingGenerativePrompt && (
          <div className={styles.loadingForm}>
            <LoadingForm numberOfInputs={4} />
          </div>
        )}

        {!isLoadingGenerativePrompt && (
          <fieldset className={styles.fieldset}>
            <div className={styles.fieldsWrapper}>
              <Controller
                control={formControl}
                name="title"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="title"
                    labelText="Título"
                    placeholder="Ex: Prompt para validar competência 1"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={getFormFieldError(formErrors?.title?.type)}
                    disabled
                  />
                )}
              />

              <label htmlFor="type">Tipo</label>
              <Controller
                control={formControl}
                name="type"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <>
                    <Select
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          minWidth: 300,
                        }),
                      }}
                      onChange={onChange}
                      options={generativePromptGenres.map((genre) => ({
                        label: genre,
                        value: genre,
                      }))}
                      ref={ref}
                      onBlur={onBlur}
                      value={value}
                      placeholder="ENEM/GM"
                      name={name}
                      inputId="type"
                      isDisabled
                    />
                  </>
                )}
              />

              <label htmlFor="competence">Competência</label>
              <Controller
                control={formControl}
                name="competence"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <>
                    <Select
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          minWidth: 300,
                        }),
                      }}
                      onChange={onChange}
                      options={competences[type?.value]?.map((competence) => ({
                        label: competence,
                        value: competence,
                      }))}
                      ref={ref}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Competência"
                      name={name}
                      inputId="competence"
                      isDisabled
                    />
                  </>
                )}
              />

              <Controller
                control={formControl}
                name="text"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <TextArea
                    id="text"
                    labelText="Texto"
                    placeholder="Conteúdo do prompt que vai para o gpt, deve conter -> {essay}"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={getFormFieldError(formErrors?.text?.type)}
                    rows={8}
                    resizable
                    disabled
                  />
                )}
              />
            </div>
          </fieldset>
        )}
      </form>
      {isPromptVariablesModalOpen && (
        <PromptVariablesModal
          onClose={() => setIsPromptVariablesModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
}

export default GenerativePromptVisualization;
