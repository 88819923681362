import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import {RootState} from 'store';
import qs from 'qs';
import letrusApi from 'store/services/api';
import {setCookie} from 'utils/cookies';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export interface AuthenticationState {
  status: 'idle' | 'loading' | 'failed';
  error: any;
  isAuthed: boolean;
}

export interface ResponseError {
  response: {
    data: any;
  };
}

export const initialState: AuthenticationState = {
  status: 'idle',
  error: {},
  isAuthed: false,
};

export const login = createAsyncThunk(
  '@authentication/login',
  async (data: APIComponents['schemas']['Login'], thunkAPI) => {
    const parsedData = qs.stringify(data);

    try {
      const response: AxiosResponse<APIComponents['schemas']['Token']> =
        await letrusApi('/auth', {
          method: 'post',
          data: parsedData,
        });

      setCookie({
        name: 'nlptToken',
        value: response.data.access_token,
        expires: 8,
      });

      return response.data;
    } catch (error) {
      const responseError = (error as ResponseError).response.data;

      return thunkAPI.rejectWithValue(responseError);
    }
  },
);

export const authenticationSlice = createSlice({
  name: '@authentication',
  initialState,
  reducers: {
    clearState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state) => {
        state.status = 'idle';
        state.isAuthed = true;
      })
      .addCase(login.rejected, (state, {payload}) => {
        state.status = 'failed';
        state.error = payload;
        state.isAuthed = false;
      });
  },
});

export const selectAuth = (state: RootState) => state.authentication;

export default authenticationSlice.reducer;
export const {clearState} = authenticationSlice.actions;
