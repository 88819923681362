import {FieldError} from 'react-hook-form';

export function useCorpus() {
  const responseFeedbacks = {
    createCorpus: {
      title: 'Criar corpus',
      error: {
        message: 'Ocorreu um erro ao criar o corpus',
      },
      success: {
        message: 'O corpus foi criado.',
      },
    },
    deleteCorpus: {
      title: 'Remover corpus',
      error: {
        message: 'Ocorreu um erro ao remover o corpus',
      },
      success: {
        message: 'O corpus foi removido.',
      },
    },
    updateCorpus: {
      title: 'Editar corpus',
      error: {
        message: 'Ocorreu um erro ao editar o corpus',
      },
      success: {
        message: 'O corpus foi editado.',
      },
    },
    default: {
      title: 'Requisição',
      error: {
        message: 'Erro',
      },
      success: {
        message: 'Sucesso',
      },
    },
  };

  function getFormFieldError(
    errorType: FieldError['type'] | undefined,
  ): string | undefined {
    if (errorType === 'required') {
      return 'Este campo não pode ficar em branco';
    }

    return;
  }

  return {
    responseFeedbacks,
    getFormFieldError,
  };
}
