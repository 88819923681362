import {NewButton} from 'letrus-ui';
import styles from './ListHeader.module.scss';

export interface ListHeaderProps {
  onClickButton?(): void;
  buttonText?: string;
  title: string;
}

function ListHeader({
  title,
  buttonText,
  onClickButton,
}: ListHeaderProps): JSX.Element {
  return (
    <header className={styles.header}>
      <h1>{title}</h1>

      {!!onClickButton && !!buttonText && (
        <NewButton
          text={buttonText}
          kind="primary"
          userRole="teacher"
          type="button"
          onClick={onClickButton}
        />
      )}
    </header>
  );
}

export default ListHeader;
