import {Checkbox, Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import NoDataIllustration from 'images/no-data.svg';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import {
  useFetchTextMarkerCategoryByIdLazy,
  useUpdateTextMarkerCategory,
} from 'store/reducers/textMarkerCategories';
import FeedbackModal from 'components/FeedbackModal';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './TextMarkerCategoryEdition.module.scss';

interface FormValues {
  name: string;
  database_rule: boolean;
}

const TextMarkerCategoryEdition: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const {id: textMarkerCategoryId} = useParams<{id: string; version: string}>();

  const [
    updateTextMarkerCategory,
    {
      isError: isUpdateTextMarkerCategoryError,
      isSuccess: isUpdateTextMarkerCategorySuccess,
      isLoading: isUpdatingTextMarkerCategory,
      error: updateTextMarkerCategoryError,
    },
  ] = useUpdateTextMarkerCategory();
  const [
    fetchTextMarkerCategoryByIdLazy,
    {
      isError: isFetchTextMarkerCategoryByIdError,
      isLoading: isLoadingTextMarkerCategoryById,
      data: fetchTextMarkerCategoryByIdData,
      isFetching: isFetcingTextMarkerCategoryById,
    },
  ] = useFetchTextMarkerCategoryByIdLazy();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      database_rule: false,
    },
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    fetchTextMarkerCategoryByIdLazy(parseInt(textMarkerCategoryId, 10), true);
  }, [textMarkerCategoryId]);

  useEffect(() => {
    if (fetchTextMarkerCategoryByIdData) {
      reset({
        name: fetchTextMarkerCategoryByIdData?.name,
        database_rule: fetchTextMarkerCategoryByIdData?.database_rule,
      });
    }
  }, [fetchTextMarkerCategoryByIdData]);

  useEffect(() => {
    if (isUpdateTextMarkerCategoryError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isUpdateTextMarkerCategoryError]);

  useEffect(() => {
    if (isUpdateTextMarkerCategorySuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isUpdateTextMarkerCategorySuccess]);

  const updateTextMarkerCategoryErrorData = (
    updateTextMarkerCategoryError as ResponseError
  )?.data as SimpleErrorPayload;

  function onSubmit() {
    const textMarkerCategoryData = getValues();

    updateTextMarkerCategory({
      ...textMarkerCategoryData,
      textMarkerCategoryId: parseInt(textMarkerCategoryId, 10),
    });
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {isFetchTextMarkerCategoryByIdError &&
          !isLoadingTextMarkerCategoryById && (
            <div className={styles.noResults}>
              <img
                src={NoDataIllustration}
                alt="Ilustração de sem resultados"
              />
              <h2>Parece que essa categoria não está disponível</h2>

              <div className={styles.resetDataButtonWrapper} title="Recarregar">
                <NewButton
                  icon={['fas', 'undo']}
                  onClick={() =>
                    history.push(AuthRoutes.textMarkerCategoryList)
                  }
                  text="Voltar às categorias"
                />
              </div>
            </div>
          )}

        {(isLoadingTextMarkerCategoryById ||
          isFetcingTextMarkerCategoryById) && (
          <LoadingForm numberOfInputs={2} />
        )}

        {fetchTextMarkerCategoryByIdData &&
          !isFetchTextMarkerCategoryByIdError && (
            <>
              <header>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={history.goBack}>
                    <Icon icon="chevron-left" color="#414449" />
                  </button>

                  <h1>Editar categoria de marcador</h1>
                </div>

                <NewButton
                  text="Editar"
                  kind="primary"
                  userRole="teacher"
                  type="submit"
                  isLoading={isUpdatingTextMarkerCategory}
                  disabled={isUpdatingTextMarkerCategory}
                />
              </header>

              <div className={styles.fieldset}>
                <Controller
                  control={control}
                  name="name"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="name"
                      labelText="Nome da categoria"
                      placeholder="Ex: Categoria X"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        errors?.name?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : undefined
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="database_rule"
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <Checkbox
                      id="database_rule"
                      labelText="É uma regra do banco de dados"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={String(value)}
                      checked={value}
                    />
                  )}
                />
              </div>
            </>
          )}
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Atualizar categoria de marcador"
        subtitle={isUpdateTextMarkerCategoryError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isUpdateTextMarkerCategoryError
            ? updateTextMarkerCategoryErrorData.detail
            : 'A categoria foi atualizada.'
        }
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText={
          isUpdateTextMarkerCategoryError ? 'Tentar novamente' : 'Fechar'
        }
        iconName={isUpdateTextMarkerCategoryError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default TextMarkerCategoryEdition;
