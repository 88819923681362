import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';
import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import App from './App';
import {store} from './store';
import * as serviceWorker from './serviceWorker';

dayjs.locale('pt-br');
dayjs.extend(localizedFormat);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
