/* eslint-disable react/no-array-index-key */
import styles from './HorizontalScrollTable.module.scss';

export interface HorizontalScrollTableProps {
  tableHeaders: string[];
  tableContents: Array<Array<string | number | JSX.Element>>;
  stickyPositions?: number[];
}

function HorizontalScrollTable({
  tableHeaders,
  tableContents,
  stickyPositions,
}: HorizontalScrollTableProps): JSX.Element {
  const rows = tableContents.map(
    (row: Array<string | number | JSX.Element>, rowIndex: number) => {
      const cells = row.map(
        (cellData: string | number | JSX.Element, cellIndex: number) => (
          <td
            className={`${
              stickyPositions?.includes(cellIndex) && styles.sticky
            }`}
            key={`${rowIndex}-${cellIndex}`}
          >
            {cellData}
          </td>
        ),
      );
      return <tr key={rowIndex}>{cells}</tr>;
    },
  );

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            {tableHeaders.map((header: string, headerIndex: number) => (
              <th
                className={`${
                  stickyPositions?.includes(headerIndex) && styles.sticky
                }`}
                key={header}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default HorizontalScrollTable;
