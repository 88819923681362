import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const generativePromptsReducer = createApi({
  reducerPath: 'generativePrompts',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  tagTypes: ['GetGenerativePrompts'],
  endpoints(builder) {
    return {
      fetchGenerativePrompts: builder.query<
        APIComponents['schemas']['Paginated_GenerativePrompts'],
        {
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({limit, offset}) => ({
          url: '/prompt_generative',
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
        providesTags: ['GetGenerativePrompts'],
      }),
      fetchGenerativePromptById: builder.query<
        APIComponents['schemas']['GenerativePrompt'],
        {
          id: number;
        }
      >({
        query: (data) => ({
          url: `/prompt_generative/${data.id}`,
          method: 'GET',
          data,
        }),
        providesTags: ['GetGenerativePrompts'],
      }),
      createGenerativePrompt: builder.mutation<
        APIComponents['schemas']['GenerativePrompt'],
        APIComponents['schemas']['CreateGenerativePrompt']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/prompt_generative`,
          data,
        }),
        invalidatesTags: ['GetGenerativePrompts'],
      }),
      predictPrompt: builder.mutation<
        string,
        APIComponents['schemas']['GenerativePredict']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/prompt_generative/predict`,
          data,
        }),
      }),
      deleteGenerativePrompt: builder.mutation<
        void,
        {generativePromptId: number}
      >({
        query: ({generativePromptId}) => ({
          method: 'DELETE',
          url: `/prompt_generative/${generativePromptId}`,
        }),
        invalidatesTags: ['GetGenerativePrompts'],
      }),
    };
  },
});

export const {
  fetchGenerativePrompts: {
    useQuery: useFetchGenerativePrompts,
    useLazyQuery: useFetchGenerativePromptsLazy,
  },
  fetchGenerativePromptById: {
    useQuery: useFetchGenerativePromptById,
    useLazyQuery: useFetchGenerativePromptByIdLazy,
  },
  createGenerativePrompt: {useMutation: useCreateGenerativePrompt},
  predictPrompt: {useMutation: usePredictPrompt},
  deleteGenerativePrompt: {useMutation: useDeleteGenerativePrompt},
} = generativePromptsReducer.endpoints;
