import {Icon, Modal, NewButton} from 'letrus-ui';
import styles from './DeletionModal.module.scss';

export interface DeletionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClickDelete: () => void;
  title: string;
  subtitle: string;
  message: string;
}

const DeletionModal: React.FC<DeletionModalProps> = ({
  isOpen,
  onClickDelete,
  onClose,
  subtitle,
  title,
  message,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      clickOutsideToClose
      onClose={onClose}
      buttonClose
      title={title}
      minHeight={400}
      minWidth={400}
    >
      <div className={styles.deleteModalWrapper}>
        <Icon icon={['far', 'trash-alt']} size="4x" color="#414449" />

        <h2>{subtitle}</h2>

        <p>{message}</p>

        <div className={styles.buttonWrapper}>
          <NewButton text="Cancelar" type="button" onClick={onClose} />
          <NewButton
            text="Excluir"
            kind="primary"
            type="button"
            userRole="teacher"
            onClick={onClickDelete}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeletionModal;
