import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents, NLPEntityEnum} from 'utils/types/NLPToolsAPI';

export const entityEvalutionsReducer = createApi({
  reducerPath: 'entityEvalutions',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchEntityEvalutions: builder.query<
        APIComponents['schemas']['Paginated_EntityEvaluationGet_'],
        {
          search?: string;
          limit?: number | string;
          offset?: number;
          entity_type?: NLPEntityEnum;
        }
      >({
        query: ({search, limit, offset, entity_type}) => ({
          url: '/evaluations',
          method: 'GET',
          params: {
            search: search || undefined,
            limit,
            offset,
            entity_type: entity_type || undefined,
          },
        }),
      }),
      fetchEntityEvaluationVersionById: builder.query<
        APIComponents['schemas']['EntityEvaluationGetDetail'],
        {
          test_id: number;
          version: number;
        }
      >({
        query: ({test_id, version}) => ({
          url: `/evaluations/${test_id}/versions/${version}`,
          method: 'GET',
        }),
      }),
      fetchEntityEvaluationVersionSamplesById: builder.query<
        APIComponents['schemas']['Paginated_EntityEvaluationSampleGet_'],
        {
          test_id: number;
          version: number;
          limit?: number;
          offset?: number;
          entity_ids?: string | string[] | number | number[];
          annotation?: string;
        }
      >({
        query: ({test_id, version, annotation, entity_ids, limit, offset}) => ({
          url: `/evaluations/${test_id}/versions/${version}/samples`,
          method: 'GET',
          params: {
            limit,
            offset,
            annotation: annotation || undefined,
            entity_ids: entity_ids || undefined,
          },
        }),
      }),
      createEntityEvalution: builder.mutation<
        APIComponents['schemas']['EntityEvaluationCreate'],
        APIComponents['schemas']['EntityEvaluationCreate']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/evaluations`,
          data,
        }),
      }),
      annotateEvaluation: builder.query<
        APIComponents['schemas']['Paginated_EntityEvaluationSampleGet_'],
        {
          test_id: number;
          version: number;
          data: APIComponents['schemas']['EntityEvaluationSamplePatch'][];
        }
      >({
        query: ({test_id, version, data}) => ({
          url: `/evaluations/${test_id}/versions/${version}/samples`,
          method: 'PATCH',
          data,
        }),
      }),
      deleteEntityEvalution: builder.mutation<
        void,
        {entityEvalutionId: number}
      >({
        query: ({entityEvalutionId}) => ({
          method: 'DELETE',
          url: `/evaluations/${entityEvalutionId}`,
        }),
      }),
      reexecuteEntityEvaluation: builder.mutation<
        void,
        {entityEvalutionId: number}
      >({
        query: ({entityEvalutionId}) => ({
          method: 'POST',
          url: `/evaluations/${entityEvalutionId}/rerun`,
        }),
      }),
    };
  },
});

export const {
  fetchEntityEvalutions: {
    useQuery: useFetchEntityEvalutions,
    useLazyQuery: useFetchEntityEvalutionsLazy,
  },
  fetchEntityEvaluationVersionById: {
    useQuery: useFetchEntityEvaluationVersionById,
    useLazyQuery: useFetchEntityEvaluationVersionByIdLazy,
  },
  fetchEntityEvaluationVersionSamplesById: {
    useQuery: useFetchEntityEvaluationVersionSamplesById,
    useLazyQuery: useFetchEntityEvaluationVersionSamplesByIdLazy,
  },
  annotateEvaluation: {useLazyQuery: useAnnotateEvaluationLazy},
  createEntityEvalution: {useMutation: useCreateEntityEvalution},
  deleteEntityEvalution: {useMutation: useDeleteEntityEvalution},
  reexecuteEntityEvaluation: {useMutation: useReexecuteEntityEvaluation},
} = entityEvalutionsReducer.endpoints;
