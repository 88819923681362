/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Select from 'react-select';
import {useDrop, useDrag} from 'react-dnd';
import {Icon} from 'letrus-ui';
import {dragItemStyleSelect} from './dragItemStyleSelect';
import styles from './DragItem.module.scss';

export interface BlockVersion {
  id: number;
  version: number;
  content: string;
}

export interface Block {
  id: number;
  header: string;
  content: string;
  currentVersion: BlockVersion;
  availableVersions?: BlockVersion[];
  disableRemove?: boolean;
  fixed?: boolean;
}

interface DragItemProps {
  block: Block;
  blocks: Block[];
  setBlocks(blocks: Block[]): void;
  onChangeVersion?(block: Block, version: BlockVersion): void;
  key?: string | number;
  blockDraggingId: number | null;
  setBlockDraggingId?(id: number): void;
}

function DragItem({
  block,
  blocks,
  setBlocks,
  key,
  blockDraggingId,
  setBlockDraggingId,
  onChangeVersion,
}: DragItemProps): JSX.Element {
  const [{isDragging}, drag, dragPreview] = useDrag(() => ({
    type: 'block',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const [{isOver}, drop] = useDrop(
    () => ({
      accept: 'block',
      drop: () => swapBlock(),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [isDragging, blockDraggingId, blocks],
  );
  const shouldShowVersionsSelect =
    !!block.availableVersions?.length &&
    !!onChangeVersion &&
    !block.disableRemove &&
    !block.fixed;

  function swapBlock() {
    const newBlocks = blocks.map((currentBlock) => ({...currentBlock}));
    const dropIndex = newBlocks.findIndex(
      (currentBlock) => currentBlock.id === block.id,
    );
    const dragIndex = newBlocks.findIndex(
      (currentBlock) => currentBlock.id === blockDraggingId,
    );
    if (dropIndex < 0 || dragIndex < 0 || dropIndex === dragIndex) {
      return;
    }
    const tmp = newBlocks[dropIndex];
    newBlocks[dropIndex] = newBlocks[dragIndex];
    newBlocks[dragIndex] = tmp;
    setBlocks(newBlocks);
  }

  return (
    <div
      ref={!block.fixed ? drop : undefined}
      key={key}
      onMouseDown={() =>
        setBlockDraggingId ? setBlockDraggingId(block.id) : null
      }
    >
      <div ref={!block.fixed ? drag : undefined}>
        <div
          ref={!block.fixed ? dragPreview : undefined}
          title={block.content}
          className={`
            ${styles.block} 
            ${shouldShowVersionsSelect ? styles.withVersionSelect : ''} 
            ${block.disableRemove ? styles.unremovable : ''} 
            ${isOver && blockDraggingId !== block.id ? styles.blockOver : ''} 
            ${block.fixed ? styles.fixed : ''}
          `}
        >
          {block.header.length > 20
            ? `${block.header.substring(0, 20)}...`
            : block.header}

          {shouldShowVersionsSelect && (
            <Select
              styles={dragItemStyleSelect}
              isSearchable={false}
              onChange={(event) => {
                const version: BlockVersion = {
                  version: Number(event.label),
                  id: Number(event.value),
                  content:
                    block.availableVersions?.find(
                      (version) => version.id === Number(event.value),
                    )?.content || '',
                };
                onChangeVersion?.(block, version);
              }}
              options={block.availableVersions?.map((version) => ({
                label: version.version,
                value: version.id,
              }))}
              value={{
                label: block.currentVersion.version,
                value: block.currentVersion.id,
              }}
            />
          )}

          {!block.disableRemove && (
            <div
              data-testid="trash"
              className={styles.excludeIcon}
              onClick={() => setBlocks(blocks.filter((b) => b.id !== block.id))}
            >
              <Icon icon={['far', 'trash-alt']} size="1x" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DragItem;
