import {useMemo, useState} from 'react';
import {NewButton} from 'letrus-ui';
import {Block} from 'components/DragItem';
import useCopyToClipboard from 'utils/hooks/useCopyToClipboard';
import styles from './PromptPreview.module.scss';

export interface PromptPreviewProps {
  blocks: Block[];
  answer?: string;
}

function PromptPreview({blocks, answer}: PromptPreviewProps): JSX.Element {
  const [previewMode, setPreviewMode] = useState<'text' | 'blocks'>('blocks');
  const {clipboardText, copyTextToClipboard} = useCopyToClipboard();

  const textToCopy = useMemo(
    () =>
      blocks.reduce((accumulator, block) => {
        return `${accumulator}\n${block.content}`;
      }, ''),
    [blocks],
  );

  return (
    <section className={styles.scroll}>
      <header>
        <h2>Preview do Prompt</h2>
        <div className={styles.actions}>
          <NewButton
            icon={['fas', 'eye']}
            kind="primary"
            userRole="teacher"
            type="button"
            text={previewMode === 'text' ? 'Texto' : 'Blocos'}
            onClick={() =>
              setPreviewMode(previewMode === 'text' ? 'blocks' : 'text')
            }
          />
          <NewButton
            icon={['fas', clipboardText === textToCopy ? 'check' : 'clipboard']}
            kind="primary"
            userRole="teacher"
            type="button"
            text={clipboardText === textToCopy ? 'Copiado' : 'Copiar'}
            onClick={() => copyTextToClipboard(textToCopy)}
          />
        </div>
      </header>
      <div className={styles.wrapper}>
        {previewMode === 'blocks' && (
          <>
            {blocks.map((block) => (
              <div key={block.id} className={styles.block}>
                <div className={styles.blockHeader}>{block.header}</div>

                <div className={styles.blockContent}>{block.content}</div>
              </div>
            ))}
            {!!answer && (
              <div className={styles.block}>
                <div className={styles.blockHeader}>Resposta do GPT-4</div>

                <div className={styles.blockContent}>{answer}</div>
              </div>
            )}
          </>
        )}

        {previewMode === 'text' && (
          <>
            <div className={styles.block}>
              <div className={styles.blockHeader}>Texto Puro do Prompt</div>

              <div className={styles.blockRaw}>
                {blocks.map((b) => (
                  <span key={b.id}>{b.content}</span>
                ))}
              </div>
            </div>

            {!!answer && (
              <div className={`${styles.block} ${styles.fullBlock}`}>
                <div className={styles.blockHeader}>Resposta do GPT-4</div>

                <textarea className={styles.blockTextarea} disabled>
                  {answer}
                </textarea>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default PromptPreview;
