/* eslint-disable react/no-array-index-key */
import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useState} from 'react';
import isValidJSON from 'utils/isValidJSON';
import {
  useCreateGrammarRule,
  useValidateGrammarRule,
} from 'store/reducers/grammarRules';
import {flattenDepth} from 'lodash';
import FeedbackModal from 'components/FeedbackModal';
import {Example, Suggestion} from 'containers/GrammarRuleEdition';
import TextArea from 'components/TextArea';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './GrammarRuleCreation.module.scss';

interface FormValues {
  category: string;
  subcategory: string;
  pattern: string;
  antipatterns: string;
  sentences: string;
  identificationStart: string;
  identificationEnd: string;
  comment: string;
}

interface NewEntityFormValues {
  examples: Example[];
  suggestions: Suggestion[];
}

interface FormErrors {
  [formIndex: string]: boolean;
}

const GrammarRuleCreation: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const [newEntityFormValues, setNewEntityFormValues] =
    useState<NewEntityFormValues>({
      examples: [],
      suggestions: [],
    });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [
    createGrammarRule,
    {
      isError: isCreateGrammarRuleError,
      isLoading: isCreateGrammarRuleLoading,
      isSuccess: isCreateGrammarRuleSuccess,
      error: createGrammarRuleError,
    },
  ] = useCreateGrammarRule();
  const [
    validateGrammarRule,
    {
      isError: isValidateGrammarRuleError,
      isLoading: isValidatingGrammarRule,
      isSuccess: isValidateGrammarRuleSuccess,
      data: validateGrammarRuleData,
    },
  ] = useValidateGrammarRule();

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    trigger,
    formState: {errors},
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    if (isCreateGrammarRuleError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateGrammarRuleError]);

  useEffect(() => {
    if (isCreateGrammarRuleSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateGrammarRuleSuccess]);

  const createGrammarRuleErrorData = (createGrammarRuleError as ResponseError)
    ?.data as SimpleErrorPayload;

  const {pattern, antipatterns, sentences} = watch();

  const isValidateButtonDisabled =
    !pattern ||
    !antipatterns ||
    !sentences ||
    Boolean(errors.antipatterns) ||
    Boolean(errors.pattern) ||
    Boolean(
      Object.values(formErrors).find((newSuggestion) => newSuggestion == true),
    ) ||
    !Object.values(newEntityFormValues?.suggestions).length;

  function onChangeNewFormField(
    {
      target: {name, value},
    }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    fieldIndex: number,
    fieldName: string,
  ) {
    const newForms = [...newEntityFormValues[fieldName]];
    newForms[fieldIndex][name] = value;
    setNewEntityFormValues({
      ...newEntityFormValues,
      [fieldName]: newForms,
    });

    setFormErrors({...formErrors, [fieldIndex]: false});
  }

  function removeNewForm(fieldIndex: number, fieldName: string) {
    const filteredNewEntities = [...newEntityFormValues[fieldName]];

    filteredNewEntities.splice(fieldIndex, 1);

    setNewEntityFormValues({
      ...newEntityFormValues,
      [fieldName]: filteredNewEntities,
    });
  }

  function validateSuggestions() {
    let newFormErrors: FormErrors = {};

    newEntityFormValues.suggestions.forEach((suggestion, index) => {
      if (!isValidJSON(suggestion.json)) {
        newFormErrors = {
          ...newFormErrors,
          [index]: true,
        };
      }
    });

    setFormErrors(newFormErrors);
  }

  function triggerGrammarRuleValidation() {
    const {
      sentences,
      pattern,
      antipatterns,
      identificationStart,
      identificationEnd,
    } = getValues();

    trigger(['pattern', 'antipatterns'], {shouldFocus: true});
    validateSuggestions();
    validateGrammarRule({
      text: sentences,
      pattern: JSON.parse(pattern),
      antipatterns: JSON.parse(antipatterns),
      suggestions: flattenDepth(
        newEntityFormValues.suggestions.map((suggestion) =>
          JSON.parse(suggestion.json),
        ),
        1,
      ),
      on_match_start: parseInt(identificationStart, 10),
      on_match_end: parseInt(identificationEnd, 10),
    });
  }

  function onSubmit() {
    validateSuggestions();

    const {
      category,
      subcategory,
      comment,
      identificationStart,
      pattern,
      antipatterns,
      identificationEnd,
    } = getValues();

    try {
      flattenDepth(
        newEntityFormValues.suggestions.map((suggestion) =>
          JSON.parse(suggestion.json),
        ),
        1,
      );
    } catch (error) {
      return;
    }

    const serializedGrammarRuleData = {
      category,
      sub_category: subcategory,
      comment,
      version: {
        pattern: JSON.parse(pattern),
        antipatterns: JSON.parse(antipatterns),
        suggestions: flattenDepth(
          newEntityFormValues.suggestions.map((suggestion) =>
            JSON.parse(suggestion.json),
          ),
          1,
        ),
        on_match_start: parseInt(identificationStart, 10),
        on_match_end: parseInt(identificationEnd, 10),
        examples: newEntityFormValues.examples,
      },
    };

    createGrammarRule(serializedGrammarRuleData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Criar regra gramatical</h1>
          </div>

          <NewButton
            text="Criar"
            kind="primary"
            userRole="teacher"
            type="submit"
            isLoading={isCreateGrammarRuleLoading}
            disabled={isCreateGrammarRuleLoading}
          />
        </header>

        <div className={styles.fieldset}>
          <Controller
            control={control}
            name="category"
            rules={{
              required: true,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <InputText
                id="category"
                labelText="Nome da categoria"
                placeholder="Ex: Categoria X"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={
                  errors?.category?.type === 'required'
                    ? 'Este campo não pode ficar em branco'
                    : undefined
                }
              />
            )}
          />

          <Controller
            control={control}
            name="subcategory"
            rules={{
              required: true,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <InputText
                id="subcategory"
                labelText="Nome da subcategoria"
                placeholder="Ex: Subcategoria X"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={
                  errors?.subcategory?.type === 'required'
                    ? 'Este campo não pode ficar em branco'
                    : undefined
                }
              />
            )}
          />

          <Controller
            control={control}
            name="comment"
            rules={{
              required: true,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <TextArea
                id="comment"
                labelText="Comentário"
                placeholder="Ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={
                  errors?.comment?.type === 'required'
                    ? 'Este campo não pode ficar em branco'
                    : undefined
                }
                resizable
              />
            )}
          />

          <Controller
            control={control}
            name="pattern"
            rules={{
              required: true,
              validate: isValidJSON,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <TextArea
                id="pattern"
                labelText="Padrão"
                placeholder={`Ex: [{"LOWER": "mais"},{"LOWER": {"REGEX": "alt[ao]"}}]`}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={
                  errors?.pattern?.type === 'required'
                    ? 'Este campo não pode ficar em branco'
                    : errors?.pattern?.type === 'validate'
                    ? 'Padrão inválido, verifique o formato do JSON'
                    : undefined
                }
                resizable
              />
            )}
          />

          <Controller
            control={control}
            name="antipatterns"
            rules={{
              required: true,
              validate: isValidJSON,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <TextArea
                id="antipatterns"
                labelText="Antipadrões"
                placeholder={`Ex: [{"LOWER": {"REGEX": "alt[ao]"}},{"LOWER": "astral"}]`}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={
                  errors?.antipatterns?.type === 'required'
                    ? 'Este campo não pode ficar em branco'
                    : errors?.antipatterns?.type === 'validate'
                    ? 'Antipadrões inválidos, verifique o formato do JSON'
                    : undefined
                }
                resizable
              />
            )}
          />

          <Controller
            control={control}
            name="identificationStart"
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <InputText
                id="identificationStart"
                labelText="Início da identificação"
                placeholder="Ex: 1"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
              />
            )}
          />

          <Controller
            control={control}
            name="identificationEnd"
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <InputText
                id="identificationEnd"
                labelText="Fim da identificação"
                placeholder="Ex: 2"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
              />
            )}
          />

          <legend>Sugestões</legend>
          <div className={styles.suggestionsWrapper}>
            <div className={styles.newSuggestionForm}>
              {newEntityFormValues.suggestions.map((newSuggestion, index) => (
                <div key={`newSuggestion-${index}`}>
                  <div className={styles.title}>
                    <label htmlFor={`json-${index}`}>Nova sugestão</label>

                    <button
                      type="button"
                      onClick={() => removeNewForm(index, 'suggestions')}
                      title="Remover formulário"
                      className={styles.removeNewSuggestionButton}
                    >
                      <Icon icon={['fas', 'times']} color="#414449" />
                    </button>
                  </div>

                  <div className={styles.textAreaWrapper}>
                    <TextArea
                      errorMessage={
                        formErrors[index]
                          ? 'Sugestão inválida, verifique o formato do JSON'
                          : undefined
                      }
                      onBlur={validateSuggestions}
                      id={`json-${index}`}
                      key={`json-${index}`}
                      name="json"
                      placeholder={`Ex: [[{"text": "maior"}]]`}
                      value={newSuggestion.json}
                      onChange={(event) =>
                        onChangeNewFormField(event, index, 'suggestions')
                      }
                      resizable
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.addSuggestionButton}>
              <NewButton
                type="button"
                icon={['fas', 'plus']}
                text="Adicionar sugestão"
                onClick={() =>
                  setNewEntityFormValues({
                    ...newEntityFormValues,
                    suggestions: [
                      ...newEntityFormValues.suggestions,
                      {
                        json: '',
                      },
                    ],
                  })
                }
              />
            </div>
          </div>

          <legend>Exemplos</legend>
          <div className={styles.examplesWrapper}>
            <div className={styles.newExampleForm}>
              {newEntityFormValues.examples.map((newExample, index) => (
                <div key={`newExample-${index}`}>
                  <div className={styles.title}>
                    <h4>Novo exemplo</h4>

                    <button
                      type="button"
                      onClick={() => removeNewForm(index, 'examples')}
                      title="Remover formulário"
                      className={styles.removeNewExampleButton}
                    >
                      <Icon icon={['fas', 'times']} />
                    </button>
                  </div>

                  <div className={styles.inputWrapper}>
                    <div className={styles.exampleInputWrapper}>
                      <InputText
                        id={`text-${index}`}
                        key={`text-${index}`}
                        name="text"
                        labelText="Exemplo"
                        placeholder="Ex: exemplo tal"
                        value={newExample.text}
                        onChange={(event) =>
                          onChangeNewFormField(event, index, 'examples')
                        }
                      />
                    </div>

                    <div className={styles.exampleInputWrapper}>
                      <InputText
                        id={`error_text-${index}`}
                        key={`error_text-${index}`}
                        name="error_text"
                        labelText="Trecho com erro"
                        placeholder="Ex: erro"
                        value={newExample.error_text}
                        onChange={(event) =>
                          onChangeNewFormField(event, index, 'examples')
                        }
                      />
                    </div>

                    <div className={styles.exampleInputWrapper}>
                      <InputText
                        id={`corrections-${index}`}
                        key={`corrections-${index}`}
                        name="corrections"
                        labelText="Correções"
                        placeholder="Ex: correção1;correção2;correção3;"
                        value={newExample.corrections}
                        onChange={(event) =>
                          onChangeNewFormField(event, index, 'examples')
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.addExampleButton}>
              <NewButton
                type="button"
                icon={['fas', 'plus']}
                text="Adicionar exemplo"
                onClick={() =>
                  setNewEntityFormValues({
                    ...newEntityFormValues,
                    examples: [
                      ...newEntityFormValues.examples,
                      {text: '', error_text: '', corrections: ''},
                    ],
                  })
                }
              />
            </div>
          </div>

          <div className={styles.sentenceValidationWrapper}>
            <div>
              <Controller
                control={control}
                name="sentences"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="sentences"
                    labelText="Sentença"
                    placeholder="Ex: beba agua"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                  />
                )}
              />
            </div>

            <NewButton
              text="Validar"
              icon={['fas', 'search']}
              isLoading={isValidatingGrammarRule}
              disabled={isValidatingGrammarRule || isValidateButtonDisabled}
              onClick={triggerGrammarRuleValidation}
            />
          </div>

          {validateGrammarRuleData && isValidateGrammarRuleSuccess && (
            <div className={styles.testRulerValidationWrapper}>
              <div className={styles.testValidateTable}>
                {validateGrammarRuleData?.tokens?.length > 0 ? (
                  <>
                    <div className={styles.affectedRules}>
                      <b>
                        {`Sentença inflige ${
                          validateGrammarRuleData.rule_ids.length > 1
                            ? 'nas regras: '
                            : 'na regra: '
                        }
                        `}
                      </b>
                      <ul>
                        {validateGrammarRuleData?.rule_ids?.map(
                          (rule_id, index) => (
                            <li key={rule_id}>
                              {rule_id}
                              {index !==
                                validateGrammarRuleData?.rule_ids.length -
                                  1 && <span>, </span>}
                            </li>
                          ),
                        )}
                      </ul>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th title="Token">Token</th>
                          <th title="Pos">Pos</th>
                          <th title="Tag">Tag</th>
                          <th title="Morph">Morph</th>
                        </tr>
                      </thead>
                      <tbody>
                        {validateGrammarRuleData.tokens.map((token) => (
                          <tr key={token[0]}>
                            <td>{token[0]}</td>
                            <td>{token[1]}</td>
                            <td>{token[2]}</td>
                            <td>{token[3]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <Icon icon={['fas', 'times']} color="#f24e54" size="lg" />
                    Não identificado
                  </>
                )}
              </div>
            </div>
          )}

          {isValidatingGrammarRule && (
            <div className={styles.loadingRowWrapper}>
              <LoadingRow height={25} />
            </div>
          )}

          {isValidateGrammarRuleError && (
            <label className={styles.errorLabel} htmlFor="sentences">
              Ocorreu um erro ao validar a regra.
              <br /> Por favor, verifique os campos e tente novamente
            </label>
          )}
        </div>
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Criar regra gramatical"
        subtitle={isCreateGrammarRuleError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreateGrammarRuleError
            ? createGrammarRuleErrorData.detail
            : 'A regra foi criada.'
        }
        onClose={
          isCreateGrammarRuleError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.grammarRuleList)
        }
        onButtonClick={
          isCreateGrammarRuleError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.grammarRuleList)
        }
        buttonText={
          isCreateGrammarRuleError ? 'Tentar novamente' : 'Voltar às regras'
        }
        iconName={isCreateGrammarRuleError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default GrammarRuleCreation;
