import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';
import {loadState, saveState} from 'utils/localStorage';
import {entityEvalutionsReducer} from './reducers/entityEvalutions';
import authenticationReducer from './reducers/authentication';
import {grammarRulesReducer} from './reducers/grammarRules';
import {nlpEntitiesReducer} from './reducers/nlpEntities';
import {usersReducer} from './reducers/users';
import {textMarkersReducer} from './reducers/textMarkers';
import {textMarkerCategoriesReducer} from './reducers/textMarkerCategories';
import {plagiarismTextsReducer} from './reducers/plagiarismTexts';
import {blocksReducer} from './reducers/blocks';
import {corpusReducer} from './reducers/corpus';
import {tagsReducer} from './reducers/tags';
import {generativePromptsReducer} from './reducers/generativePrompts';
import {promptsReducer} from './reducers/prompts';
import {promptsUnifiedReducer} from './reducers/promptsUnified';
import {answersReducer} from './reducers/answers';
import {schedulingReducer} from './reducers/scheduling';
import {flagsReducer} from './reducers/flags';

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    [grammarRulesReducer.reducerPath]: grammarRulesReducer.reducer,
    [usersReducer.reducerPath]: usersReducer.reducer,
    [entityEvalutionsReducer.reducerPath]: entityEvalutionsReducer.reducer,
    [nlpEntitiesReducer.reducerPath]: nlpEntitiesReducer.reducer,
    [textMarkersReducer.reducerPath]: textMarkersReducer.reducer,
    [textMarkerCategoriesReducer.reducerPath]:
      textMarkerCategoriesReducer.reducer,
    [plagiarismTextsReducer.reducerPath]: plagiarismTextsReducer.reducer,
    [tagsReducer.reducerPath]: tagsReducer.reducer,
    [blocksReducer.reducerPath]: blocksReducer.reducer,
    [corpusReducer.reducerPath]: corpusReducer.reducer,
    [generativePromptsReducer.reducerPath]: generativePromptsReducer.reducer,
    [promptsReducer.reducerPath]: promptsReducer.reducer,
    [promptsUnifiedReducer.reducerPath]: promptsUnifiedReducer.reducer,
    [answersReducer.reducerPath]: answersReducer.reducer,
    [corpusReducer.reducerPath]: corpusReducer.reducer,
    [schedulingReducer.reducerPath]: schedulingReducer.reducer,
    [flagsReducer.reducerPath]: flagsReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(grammarRulesReducer.middleware)
      .concat(usersReducer.middleware)
      .concat(entityEvalutionsReducer.middleware)
      .concat(nlpEntitiesReducer.middleware)
      .concat(textMarkersReducer.middleware)
      .concat(textMarkerCategoriesReducer.middleware)
      .concat(blocksReducer.middleware)
      .concat(corpusReducer.middleware)
      .concat(tagsReducer.middleware)
      .concat(generativePromptsReducer.middleware)
      .concat(promptsReducer.middleware)
      .concat(promptsUnifiedReducer.middleware)
      .concat(answersReducer.middleware)
      .concat(corpusReducer.middleware)
      .concat(plagiarismTextsReducer.middleware)
      .concat(schedulingReducer.middleware)
      .concat(flagsReducer.middleware);
  },
  preloadedState,
});

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
