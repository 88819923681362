import {StylesConfig} from 'react-select';
import {reactSelectStyles} from 'utils/styles/reactSelect';

const dragItemStyleSelect: StylesConfig<any, any, any> = {
  ...reactSelectStyles,
  container: (provided) => ({
    ...provided,
    width: '58px',
    marginLeft: '10px',
    height: '30px',
    boxShadow: 'none',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    border: 'none',
    borderWidth: '0px',
    boxShadow: 'none',
    '&:hover': {borderColor: 'transparent'},
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
    border: 'none',
    '&:focus': {outline: 'none'},
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
    '&:focus': {outline: 'none'},
  }),
  indicatorSeparator: () => ({
    display: 'none',
    '&:focus': {outline: 'none'},
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '25px',
  }),
};

export {dragItemStyleSelect};
