import {useState} from 'react';

type CopiedText = string | null;
type CopyTextToClipboard = (text: string) => Promise<boolean>; // Return success

interface UseCopyToClipboard {
  clipboardText: CopiedText;
  copyTextToClipboard: CopyTextToClipboard;
}

function useCopyToClipboard(): UseCopyToClipboard {
  const [clipboardText, setClipboardText] = useState<CopiedText>(null);

  async function copyTextToClipboard(text: string): Promise<boolean> {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setClipboardText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setClipboardText(null);
      return false;
    }
  }

  return {clipboardText, copyTextToClipboard};
}

export default useCopyToClipboard;
