import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCreateBlock} from 'store/reducers/blocks';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import Select from 'react-select';
import {AuthRoutes} from 'routes';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {useCreateTag, useFetchTags} from 'store/reducers/tags';
import SelectOption from 'utils/types/SelectOption';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {useBlocks} from 'features/useBlocks';
import UnifiedPromptBlocksInfoModal from 'components/UnifiedPromptBlocksInfoModal';
import PageHeader from 'components/PageHeader';
import TextArea from 'components/TextArea';
import PageWrapper from '../../components/PageWrapper';
import styles from './BlockCreation.module.scss';

interface FormValues {
  header: string;
  content: string;
  tags: SelectOption[];
  newTag: string;
}

function BlockCreation(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [showNewTagInput, setShowNewTagInput] = useState(false);
  const [
    isUnifiedPromptBlocksInfoModalOpen,
    setIsUnifiedPromptBlocksInfoModalOpen,
  ] = useState(false);

  const {responseFeedbacks, getFormFieldError} = useBlocks();

  const [
    createBlock,
    {
      isError: isCreateBlockError,
      isLoading: isCreatingBlock,
      isSuccess: isCreateBlockSuccess,
      error: createBlockError,
      status: createBlockStatus,
    },
  ] = useCreateBlock();
  const {data: tagList, refetch: refetchTags} = useFetchTags({
    limit: 100,
  });
  const [
    createTag,
    {
      isError: isCreateTagError,
      isSuccess: isCreateTagSuccess,
      status: createTagStatus,
      isLoading: isCreatingTag,
      error: createTagError,
    },
  ] = useCreateTag();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    formState: {errors: formErrors},
    watch: formWatch,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const history = useHistory();

  // Opening feedback modal for API responses
  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isCreateBlockSuccess ||
      isCreateBlockError ||
      isCreateTagSuccess ||
      isCreateTagError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createBlockStatus, createTagStatus]);

  // Await tag creation succeed to refetch and update the select options
  useEffect(() => {
    if (isCreateTagSuccess) {
      refetchTags();
    }
  }, [createTagStatus]);

  const createBlockErrorData = (createBlockError as ResponseError)
    ?.data as FormErrorPayload;
  const createTagErrorData = (createTagError as ResponseError)
    ?.data as FormErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreateBlockError || isCreateBlockSuccess) {
      return 'createBlock';
    }

    if (isCreateTagError || isCreateTagSuccess) {
      return 'createTag';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreateBlockSuccess) {
      return responseFeedbacks.createBlock.success.message;
    }
    if (isCreateBlockError) {
      const errorMessages = Object.entries(createBlockErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isCreateTagSuccess) {
      return responseFeedbacks.createTag.success.message;
    }
    if (isCreateTagError) {
      const errorMessages = Object.entries(createTagErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }

    return '';
  }

  function onResponseFeedbackClose(isCreateBlockSuccess: boolean) {
    setIsResponseFeedbackModalOpen(false);
    if (isCreateBlockSuccess) {
      history.push(AuthRoutes.blockList);
    }
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isCreateBlockSuccess || isCreateTagSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: () => onResponseFeedbackClose(isCreateBlockSuccess),
      onClose: () => onResponseFeedbackClose(isCreateBlockSuccess),
    };
  }

  function onFormSubmit() {
    const {header, content, tags} = getFormValues();

    const serializedFormValues: Omit<APIComponents['schemas']['Block'], 'id'> =
      {
        header,
        content,
        tags:
          tags?.reduce<number[]>(
            (acc, tag) => [...acc, Number(tag.value)],
            [],
          ) || [],
      };

    createBlock(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Criar bloco"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <div className={styles.rightContent}>
              <button
                type="button"
                className={styles.helpIconButton}
                onClick={() => setIsUnifiedPromptBlocksInfoModalOpen(true)}
              >
                <Icon icon={['far', 'question-circle']} size="2x" />
              </button>
              <NewButton
                text="Criar bloco"
                kind="primary"
                userRole="teacher"
                type="submit"
                isLoading={isCreatingBlock}
                disabled={isCreatingBlock}
              />
            </div>
          }
        />

        <fieldset className={styles.fieldset}>
          <Controller
            control={formControl}
            name="header"
            rules={{
              required: true,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <InputText
                id="header"
                labelText="Header"
                placeholder="Ex: O nível de formalidade"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={getFormFieldError(formErrors?.header?.type)}
              />
            )}
          />

          <Controller
            control={formControl}
            name="content"
            rules={{
              required: true,
            }}
            render={({field: {name, onBlur, onChange, ref, value}}) => (
              <TextArea
                id="content"
                labelText="Content"
                placeholder="Ex: O narrador pode utilizar linguagem coloquial, porém de maneira monitorada, os desvios devem se manter num padrão sintático adequado. Evite gírias, use expressões que sejam de entendimento geral. Em hipótese nenhuma use linguagem das redes sociais como abreviações."
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value ?? ''}
                errorMessage={getFormFieldError(formErrors?.content?.type)}
                resizable
              />
            )}
          />

          <div className={styles.tagSelectWrapper}>
            <label htmlFor="tags">Tags</label>
            <div>
              <Controller
                control={formControl}
                name="tags"
                rules={{
                  required: false,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <>
                    <Select
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          minWidth: 300,
                        }),
                      }}
                      onChange={onChange}
                      options={tagList?.map(({id, name}) => ({
                        label: name,
                        value: String(id),
                      }))}
                      isMulti
                      ref={ref}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Selecione a tag"
                      name={name}
                      inputId="tags"
                    />
                  </>
                )}
              />

              <NewButton
                size="small"
                type="button"
                icon="plus"
                onClick={() => {
                  if (showNewTagInput) {
                    document.getElementById('newTag')?.focus();
                  } else {
                    setShowNewTagInput(true);
                  }
                }}
              />
            </div>
            {formErrors?.tags && (
              <label htmlFor="tags" id="tag-error">
                Esse campo não pode ficar em branco
              </label>
            )}
          </div>

          {showNewTagInput && (
            <>
              <Controller
                control={formControl}
                name="newTag"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="newTag"
                    labelText="Adicionar nova tag"
                    placeholder="Ex: formalidade"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                  />
                )}
              />

              <div className={styles.newTagInputButtonsWrapper}>
                <NewButton
                  text="Adicionar"
                  size="small"
                  type="button"
                  disabled={!formWatch().newTag}
                  onClick={() => {
                    createTag({name: getFormValues().newTag});
                  }}
                  isLoading={isCreatingTag}
                />
                <NewButton
                  size="small"
                  type="button"
                  text="Fechar"
                  onClick={() => setShowNewTagInput(false)}
                />
              </div>
            </>
          )}
        </fieldset>
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
      {isUnifiedPromptBlocksInfoModalOpen && (
        <UnifiedPromptBlocksInfoModal
          onClose={() => setIsUnifiedPromptBlocksInfoModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
}

export default BlockCreation;
