/* eslint-disable @typescript-eslint/no-unused-vars */
import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {AuthRoutes} from 'routes';
import {useCreatePrompt} from 'store/reducers/prompts';
import {useFetchBlocksLazy} from 'store/reducers/blocks';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import {isEmpty} from 'lodash';
import UnifiedPromptBlocksInfoModal from 'components/UnifiedPromptBlocksInfoModal';
import PageHeader from 'components/PageHeader';
import TextArea from 'components/TextArea';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import DragItem, {Block, BlockVersion} from 'components/DragItem';
import PromptPreview from 'components/PromptPreview';
import {usePrompts} from 'features/usePrompts';
import PageWrapper from '../../components/PageWrapper';
import styles from './PromptCreation.module.scss';

interface BlockListItem {
  id: number;
  content: string;
  header: string;
  currentVersion: BlockVersion;
  availableVersions: BlockVersion[];
  rules: string[];
}

interface FormValues {
  title: string;
  description: string;
  blocks?: Block[];
}

function PromptCreation(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [blockDraggingId, setBlockDraggingId] = useState<null | number>(null);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [
    isUnifiedPromptBlocksInfoModalOpen,
    setIsUnifiedPromptBlocksInfoModalOpen,
  ] = useState(false);

  const {
    responseFeedbacks,
    getFormFieldError,
    onChangeVersion,
    setNewBlockFollowingRules,
  } = usePrompts();

  const [
    fetchBlocksLazy,
    {data: blockListResponse, isLoading: isLoadingBlockList},
  ] = useFetchBlocksLazy();
  const currentPaginationBlocks: BlockListItem[] =
    blockListResponse?.results?.map((block) => ({
      content: block.latest_version.content,
      header: block.header,
      id: block.block_id,
      rules: block.rules,
      currentVersion: block.latest_version,
      availableVersions: block.versions,
    })) || [];

  const [
    createPrompt,
    {
      isError: isCreatePromptError,
      isLoading: isCreatingPrompt,
      isSuccess: isCreatePromptSuccess,
      error: createPromptError,
      status: createPromptStatus,
    },
  ] = useCreatePrompt();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    formState: {errors: formErrors},
    setError: setFormError,
    clearErrors: clearFormError,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    fetchBlocksLazy(
      {
        ordering: 'id',
        limit: 3000,
      },
      true,
    );
  }, []);

  useEffect(() => {
    if (currentPaginationBlocks.length) {
      const requiredBlocks = currentPaginationBlocks.filter((block) => {
        const alreadyExist = blocks.find(
          (blockToFilter) => blockToFilter.id === block.id,
        );

        return block.rules.includes('required') && !alreadyExist;
      });
      if (requiredBlocks.length) {
        const mustBeTheLastBlock = requiredBlocks.find((block) =>
          block.rules.includes('must_be_the_last'),
        );
        setBlocks([
          ...requiredBlocks
            .filter((block) => block.id !== mustBeTheLastBlock?.id)
            .map((block) => ({
              id: block.id,
              content: block.content,
              header: block.header,
              currentVersion: block.currentVersion,
              availableVersions: block.availableVersions,
              disableRemove: block.rules.includes('required'),
              fixed: block.rules.includes('do_not_move'),
            }))
            .concat(
              mustBeTheLastBlock
                ? [
                    {
                      id: mustBeTheLastBlock.id,
                      content: mustBeTheLastBlock.content,
                      header: mustBeTheLastBlock.header,
                      currentVersion: mustBeTheLastBlock.currentVersion,
                      disableRemove:
                        mustBeTheLastBlock.rules.includes('required'),
                      fixed: mustBeTheLastBlock.rules.includes('do_not_move'),
                      availableVersions: mustBeTheLastBlock.availableVersions,
                    },
                  ]
                : [],
            ),
        ]);
      }
    }
  }, [currentPaginationBlocks]);

  useEffect(() => {
    if (formErrors?.blocks && blocks.length) {
      clearFormError('blocks');
    }
  }, [blocks]);

  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isCreatePromptSuccess || isCreatePromptError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createPromptStatus]);

  const createPromptErrorData = (createPromptError as ResponseError)
    ?.data as FormErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreatePromptError || isCreatePromptSuccess) {
      return 'createPrompt';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreatePromptError) {
      const errorMessages = Object.entries(createPromptErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isCreatePromptSuccess) {
      return responseFeedbacks.createPrompt.success.message;
    }

    return '';
  }

  function onResposeFeedbackClose() {
    setIsResponseFeedbackModalOpen(false);
    history.push(AuthRoutes.generativePromptList);
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isCreatePromptSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onResposeFeedbackClose,
      onClose: onResposeFeedbackClose,
    };
  }

  function onFormSubmit() {
    if (!blocks?.length) {
      setFormError('blocks', {
        type: 'error',
        message: 'Esse campo não pode ficar em branco',
      });
      return;
    }

    const {title, description} = getFormValues();

    const serializedFormValues: Omit<
      APIComponents['schemas']['CreatePrompt'],
      'id'
    > = {
      title,
      description,
      block_version_ids: blocks.map((block) => block.currentVersion.id),
    };

    createPrompt(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Criar prompt"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <div className={styles.rightContent}>
              <button
                type="button"
                className={styles.helpIconButton}
                onClick={() => setIsUnifiedPromptBlocksInfoModalOpen(true)}
              >
                <Icon icon={['far', 'question-circle']} size="2x" />
              </button>
              <NewButton
                text="Criar prompt"
                kind="primary"
                userRole="teacher"
                type="submit"
                isLoading={isCreatingPrompt}
                disabled={isCreatingPrompt || !isEmpty(formErrors)}
              />
            </div>
          }
        />

        {isLoadingBlockList && <LoadingForm numberOfInputs={6} />}

        {!isLoadingBlockList && (
          <fieldset className={styles.fieldset}>
            {blocks.length ? (
              <div className={styles.previewWrapper}>
                <PromptPreview blocks={blocks} />
              </div>
            ) : (
              <div />
            )}

            <div className={styles.fieldsWrapper}>
              <Controller
                control={formControl}
                name="title"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="title"
                    labelText="Título"
                    placeholder="Ex: Prompt para validar competência 1"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={getFormFieldError(formErrors?.title?.type)}
                  />
                )}
              />

              <Controller
                control={formControl}
                name="description"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <TextArea
                    id="description"
                    labelText="Descrição"
                    placeholder="Ex: Esse prompt faz isso, isso, e isso aqui..."
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={getFormFieldError(
                      formErrors?.description?.type,
                    )}
                    resizable
                  />
                )}
              />

              <label htmlFor="blocks">Inserir blocos</label>
              <Select
                styles={{
                  ...reactSelectStyles,
                  container: (provided) => ({
                    ...provided,
                    maxWidth: '300px',
                  }),
                }}
                onChange={(option) => {
                  const newBlock = {
                    header: option?.label || '',
                    id: Number(option?.value),
                  };

                  const blockOnList = currentPaginationBlocks.find(
                    (block) => block.id === Number(option?.value),
                  );

                  if (blockOnList) {
                    setNewBlockFollowingRules(
                      {
                        ...newBlock,
                        currentVersion: blockOnList.currentVersion,
                        availableVersions: blockOnList.availableVersions,
                        content: blockOnList.content,
                      },
                      blocks,
                      setBlocks,
                    );
                  }
                }}
                options={currentPaginationBlocks
                  .filter((b) => !blocks.find((block) => block.id === b.id))
                  .map((b) => ({label: b.header, value: String(b.id)}))}
                isMulti={false}
                name="blocks"
                inputId="blocks"
                placeholder="Selecione os blocos"
              />

              {formErrors?.blocks && (
                <label
                  htmlFor="blocks"
                  className={styles.blocksError}
                  id="blocks-error"
                >
                  Esse campo não pode ficar em branco
                </label>
              )}

              {!!blocks.length && (
                <>
                  <div className={styles.blocksWrapper}>
                    {blocks.map((block) => (
                      <DragItem
                        block={block}
                        blocks={blocks}
                        setBlocks={setBlocks}
                        setBlockDraggingId={setBlockDraggingId}
                        onChangeVersion={(block, version) =>
                          onChangeVersion(block, version, blocks, setBlocks)
                        }
                        blockDraggingId={blockDraggingId}
                        key={block.id}
                      />
                    ))}
                  </div>

                  <figure>
                    <Icon icon={['far', 'exclamation-circle']} />

                    <p>
                      A ordem dos blocos influencia no comportamento do prompt
                      (da esquerda para direta, de cima para baixo)
                    </p>
                  </figure>
                </>
              )}
            </div>
          </fieldset>
        )}
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
      {isUnifiedPromptBlocksInfoModalOpen && (
        <UnifiedPromptBlocksInfoModal
          onClose={() => setIsUnifiedPromptBlocksInfoModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
}

export default PromptCreation;
