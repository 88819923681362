import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {FLAGS_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const flagsReducer = createApi({
  reducerPath: 'flags',
  baseQuery: axiosBaseQuery({
    baseUrl: FLAGS_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchFlags: builder.query<
        APIComponents['schemas']['FlagInfo'],
        APIComponents['schemas']['FlagPayload']
      >({
        query: (data) => ({
          url: '/evaluation',
          method: 'POST',
          data,
        }),
      }),
    };
  },
});

export const {
  fetchFlags: {useQuery: useFetchFlags, useLazyQuery: useFetchFlagsLazy},
} = flagsReducer.endpoints;
