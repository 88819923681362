import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents, NLPEntityEnum} from 'utils/types/NLPToolsAPI';

export const nlpEntitiesReducer = createApi({
  reducerPath: 'nlpEntities',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchNLPEntities: builder.query<
        APIComponents['schemas']['NLPEntity'][],
        {
          entity_type: NLPEntityEnum;
        }
      >({
        query: ({entity_type}) => ({
          url: '/entities',
          method: 'GET',
          params: {
            entity_type,
          },
        }),
      }),
    };
  },
});

export const {
  fetchNLPEntities: {
    useQuery: useFetchNLPEntities,
    useLazyQuery: useFetchNLPEntitiesLazy,
  },
} = nlpEntitiesReducer.endpoints;
