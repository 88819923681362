import {FieldError} from 'react-hook-form';
import SelectOption from 'utils/types/SelectOption';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export interface PromptCompetences {
  prompt_id: number;
  competences: SelectOption[];
  prompt_name: string;
}

export function usePromptsUnified() {
  const responseFeedbacks = {
    createPromptUnified: {
      title: 'Criar prompt unificado',
      error: {
        message: 'Ocorreu um erro ao criar o prompt unificado.',
      },
      success: {
        message: 'O prompt unificado foi criado.',
      },
    },
    deletePromptUnified: {
      title: 'Excluir prompt unificado',
      error: {
        message: 'Ocorreu um erro ao excluir o prompt unificado.',
      },
      success: {
        message: 'O prompt unificado foi excluído.',
      },
    },
    default: {
      title: 'Requisição',
      error: {
        message: 'Erro',
      },
      success: {
        message: 'Sucesso',
      },
    },
  };

  function getFormFieldError(
    errorType: FieldError['type'] | undefined,
  ): string | undefined {
    if (errorType === 'required') {
      return 'Este campo não pode ficar em branco';
    }

    return;
  }

  function validatePrompts(
    prompts: SelectOption[],
    promptCompetences: PromptCompetences[],
    corpusCompetences?: APIComponents['schemas']['Paginated_CorpusCompetenceGet'],
  ) {
    if (!prompts?.length) {
      return 'Este campo não pode ficar em branco';
    }

    if (prompts.length > 7) {
      return 'Máximo de 7 prompts';
    }

    const competencesWithoutPrompt = corpusCompetences?.results
      ?.concat([{id: 99999, name: 'general_comment'}])
      .filter(
        (competence) =>
          !promptCompetences.reduce((accumulator, prompt_competence) => {
            if (
              prompt_competence.competences.find(
                (promptCompetence) =>
                  competence.id === Number(promptCompetence.value),
              )
            ) {
              return true;
            }
            return accumulator;
          }, false),
      );

    if (competencesWithoutPrompt?.length) {
      return 'Todas as competências devem ter um prompt associado';
    }
    return false;
  }

  function serializeFormValues(
    name: string,
    prompt_competences: PromptCompetences[],
    corpusCompetences?: APIComponents['schemas']['Paginated_CorpusCompetenceGet'],
  ): APIComponents['schemas']['PromptUnifiedPayload'] {
    const serializedFormValues: APIComponents['schemas']['PromptUnifiedPayload'] =
      {
        name,
        prompts: prompt_competences.map((prompt) => ({
          id: prompt.prompt_id,
          expected_outputs: prompt.competences.reduce(
            (accumulator, promptCompetence) => {
              const competence = corpusCompetences?.results
                ?.concat([{id: 99999, name: 'general_comment'}])
                .find(
                  (corpusCompetence) =>
                    corpusCompetence.id === Number(promptCompetence.value),
                );

              if (competence) {
                return {
                  ...accumulator,
                  [competence.name]:
                    competence.name !== 'general_comment'
                      ? `justificativa da ${competence.name}`
                      : 'comentário geral',
                };
              }
              return accumulator;
            },
            {},
          ),
        })),
      };
    return serializedFormValues;
  }

  return {
    responseFeedbacks,
    getFormFieldError,
    validatePrompts,
    serializeFormValues,
  };
}
