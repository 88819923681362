import {Icon, NewButton, Pagination, Search} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import NoDataIllustration from 'images/no-data.svg';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import Select from 'react-select';
import Tooltip from 'react-tooltip';
import {
  useDeletePlagiarismText,
  useFetchPlagiarismTextsLazy,
} from 'store/reducers/plagiarismTexts';
import FeedbackModal from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import entitiesPerPageOptions from 'utils/entitiesPerPageOptions';
import dayjs from 'dayjs';
import {AuthRoutes} from 'routes';
import PageWrapper from '../../components/PageWrapper';
import styles from './PlagiarismTextList.module.scss';

const PlagiarismTextList: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isDeletePlagiarismTextModalOpen, setIsDeletePlagiarismTextModalOpen] =
    useState<boolean>(false);
  const [plagiarismTextToDeleteId, setPlagiarismTextToDeleteId] =
    useState<number>(0);
  const [numberOfTextMarkersPerPage, setNumberOfTextMarkersPerPage] =
    useState<SelectOption | null>({label: '10', value: '10'});
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const [
    fetchPlagiarismTextsLazy,
    {
      isFetching: isFetchingPlagiarismTexts,
      isLoading: isLoadingPlagiarismTexts,
      isUninitialized: isFetchPlagiarismTextsUninitialized,
      data,
    },
  ] = useFetchPlagiarismTextsLazy();
  const [
    deletePlagiarismText,
    {
      isLoading: isDeletingPlagiarismText,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
    },
  ] = useDeletePlagiarismText();

  const history = useHistory();

  useEffect(() => {
    fetchPlagiarismTextsLazy(
      {
        search: searchText,
        limit: Number(numberOfTextMarkersPerPage?.value),
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }, [currentPage, numberOfTextMarkersPerPage?.value]);

  useEffect(() => {
    if (isDeleteError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteSuccess && !isDeletingPlagiarismText) {
      fetchPlagiarismTextsLazy({}, true);
    }
  }, [isDeleteSuccess, isDeletingPlagiarismText]);

  const totalNumberOfPages = useMemo(
    () =>
      data?.total
        ? Math.ceil(data.total / Number(numberOfTextMarkersPerPage?.value))
        : 0,
    [data],
  );
  const isLoading = isLoadingPlagiarismTexts || isFetchingPlagiarismTexts;
  const hasFirstPlagiarismTextsFetchHappened =
    !isFetchPlagiarismTextsUninitialized;

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchPlagiarismTextsLazy(
        {limit: Number(numberOfTextMarkersPerPage?.value), offset: 0},
        true,
      );

      setCurrentPage(1);
    }

    const inputValue = event.target.value.toLowerCase();

    setSearchText(inputValue);
  }

  function onClickDeletePlagiarismText(plagiarismTextId: number) {
    setPlagiarismTextToDeleteId(plagiarismTextId);
    setIsDeletePlagiarismTextModalOpen(true);
  }

  function onDeletePlagiarismText() {
    deletePlagiarismText(plagiarismTextToDeleteId);
    setIsDeletePlagiarismTextModalOpen(false);
  }

  function onSubmitSearch() {
    setCurrentPage(1);

    fetchPlagiarismTextsLazy(
      {
        search: searchText,
        limit: Number(numberOfTextMarkersPerPage?.value),
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }

  function resetData() {
    setCurrentPage(1);
    setSearchText('');

    fetchPlagiarismTextsLazy(
      {
        limit: Number(numberOfTextMarkersPerPage?.value),
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }

  return (
    <PageWrapper>
      <div className={styles.container}>
        <header>
          <h1>Textos de plágio</h1>

          <NewButton
            text="Criar texto de plágio"
            kind="primary"
            userRole="teacher"
            type="button"
            onClick={() => history.push(AuthRoutes.plagiarismTextCreation)}
          />
        </header>

        {(isLoading || isDeletingPlagiarismText) && (
          <div className={styles.loadingWrapper}>
            <LoadingTable />
          </div>
        )}

        {!isLoading &&
          hasFirstPlagiarismTextsFetchHappened &&
          !data?.results?.length && (
            <div className={styles.noResults}>
              <img
                src={NoDataIllustration}
                alt="Ilustração de sem resultados"
              />
              <h2>Sem resultados</h2>

              <div className={styles.resetDataButtonWrapper} title="Recarregar">
                <NewButton icon={['fas', 'undo']} onClick={resetData} />
              </div>
            </div>
          )}

        {!isLoading && data?.results?.length && !isDeletingPlagiarismText && (
          <div className={styles.tableWrapper}>
            <div className={styles.searchContainer}>
              <Search
                onClick={onSubmitSearch}
                id="name"
                search={{
                  name: 'search',
                  value: searchText,
                  onChange: onChangeSearchText as (
                    eventOrValue: string | React.SyntheticEvent<Element, Event>,
                  ) => void,
                  // TODO: Type onChange correctly on UI
                }}
                searchProps={{
                  placeholder: 'Pesquisar texto de plágio',
                  searchTag: 'input',
                  required: false,
                }}
              />

              <div
                data-tip=""
                data-for="numberOfEntitiesPerPageSelect"
                data-testid="numberOfEntitiesPerPageSelectWrapper"
                title="numberOfEntitiesPerPageSelectWrapper"
              >
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: 100,
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onChange={(event) => setNumberOfTextMarkersPerPage(event)}
                  options={entitiesPerPageOptions}
                  value={numberOfTextMarkersPerPage}
                  inputId="entityEvaluationVersion"
                  placeholder="Selecione a versão da regra"
                  name="entityEvaluationVersion"
                />
              </div>

              <Tooltip
                className={styles.numberOfEntitiesPerPageSelectTooltip}
                id="numberOfEntitiesPerPageSelect"
                type="dark"
                effect="solid"
                place="top"
              >
                <div className={styles.wrapper}>
                  <p>Número de categorias por página</p>
                </div>
              </Tooltip>
            </div>

            <table>
              <thead>
                <tr>
                  <th title="ID">ID</th>
                  <th title="Título">Título</th>
                  <th title="URL">URL</th>
                  <th title="Data de criação">Data de criação</th>
                  <th title="Ações">Ações</th>
                </tr>
              </thead>

              <tbody>
                {data?.results?.map((item) => (
                  <tr key={item.id}>
                    <td title={String(item.id)}>{item.id}</td>
                    <td title={item.title}>{item.title}</td>
                    <td title={item.url}>{item.url}</td>
                    <td title={dayjs(item.created).format('DD/MM/YYYY')}>
                      {dayjs(item.created).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      <div className={styles.buttonWrapper}>
                        <Link
                          to={`/textos-plagio/${item.id}`}
                          title="Ir aos detalhes do texto de plágio"
                        >
                          <Icon
                            icon={['fas', 'external-link']}
                            color="#666"
                            size="lg"
                          />
                        </Link>

                        <button
                          type="button"
                          className={styles.deleteButton}
                          title="Excluir texto de plágio"
                          onClick={() => onClickDeletePlagiarismText(item.id)}
                        >
                          <Icon
                            icon={['fas', 'trash-alt']}
                            color="#666"
                            size="lg"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                hasNext={currentPage < totalNumberOfPages}
                hasPrevious={currentPage > 1}
                totalPages={totalNumberOfPages}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      <DeletionModal
        isOpen={isDeletePlagiarismTextModalOpen}
        message="Deseja mesmo excluir esse texto de plágio?"
        onClickDelete={onDeletePlagiarismText}
        onClose={() => setIsDeletePlagiarismTextModalOpen(false)}
        subtitle="Tem certeza?"
        title="Excluir texto de plágio"
      />

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Excluir texto de plágio"
        subtitle={isDeleteError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isDeleteError
            ? 'Ocorreu um erro ao excluir o texto de plágio, tente novamente.'
            : 'O texto de plágio foi excluído.'
        }
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText={isDeleteError ? 'Tentar novamente' : 'Voltar'}
        iconName={isDeleteError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default PlagiarismTextList;
