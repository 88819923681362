import {Icon, InputText, NewButton, TextArea} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select, {SingleValue} from 'react-select';
import SelectOption from 'utils/types/SelectOption';
import {AuthRoutes} from 'routes';
import {
  useCreatePromptVersion,
  useFetchPromptVersionByIdLazy,
  useFetchPromptVersionsByIdLazy,
} from 'store/reducers/prompts';
import PromptPreview from 'components/PromptPreview';
import {useFetchBlocksLazy} from 'store/reducers/blocks';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import NoResultsWarning from 'components/NoResultsWarning';
import DragItem, {Block, BlockVersion} from 'components/DragItem';
import {isEmpty} from 'lodash';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import PageHeader from 'components/PageHeader';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {usePrompts} from 'features/usePrompts';
import PageWrapper from '../../components/PageWrapper';
import styles from './PromptEdition.module.scss';

interface RouteParams {
  prompt_id: string;
  prompt_version: string;
}

interface BlockListItem {
  id: number;
  content: string;
  header: string;
  currentVersion: BlockVersion;
  availableVersions: BlockVersion[];
  rules: string[];
}

interface FormValues {
  title: string;
  description: string;
  blocks?: Block[];
}

function PromptEdition(): JSX.Element {
  const {prompt_id, prompt_version} = useParams<RouteParams>();
  const promptId = Number(prompt_id);
  const promptVersion = Number(prompt_version);

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [blockDraggingId, setBlockDraggingId] = useState<null | number>(null);

  const [currentPaginationBlocks, setCurrentPaginationBlocks] = useState<
    BlockListItem[]
  >([]);
  const [allBlocks, setAllBlocks] = useState<BlockListItem[]>([]);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [promptVersionNumber, setPromptVersionNumber] = useState(
    Number(prompt_version),
  );

  const [
    fetchBlocksLazy,
    {data: blockListResponse, isLoading: isLoadingBlockList},
  ] = useFetchBlocksLazy();

  const {
    responseFeedbacks,
    getFormFieldError,
    onChangeVersion,
    setNewBlockFollowingRules,
  } = usePrompts();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    formState: {errors: formErrors},
    setError,
    clearErrors,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const [
    fetchPromptVersionByIdLazy,
    {
      isError: isFetchPromptVersionByIdError,
      isLoading: isLoadingPromptVersionById,
      data: fetchPromptVersionByIdData,
      isFetching: isFetchingPromptVersionById,
    },
  ] = useFetchPromptVersionByIdLazy();

  const [
    fetchPromptVersionsByIdLazy,
    {
      isError: isFetchPromptVersionsByIdError,
      isLoading: isLoadingPromptVersionsById,
      data: fetchPromptVersionsByIdData,
      isFetching: isFetchingPromptVersionsById,
    },
  ] = useFetchPromptVersionsByIdLazy();

  const [
    createPrompt,
    {
      isError: isCreatePromptVersionError,
      isLoading: isCreatingPromptVersion,
      isSuccess: isCreatePromptVersionSuccess,
      error: createPromptVersionError,
      status: createPromptVersionStatus,
      data: createPromptVersionData,
    },
  ] = useCreatePromptVersion();

  const isLoading =
    isLoadingPromptVersionById ||
    isFetchingPromptVersionById ||
    isLoadingPromptVersionsById ||
    isLoadingBlockList ||
    isFetchingPromptVersionsById;

  const history = useHistory();

  // Opening feedack modal for API responses
  useEffect(() => {
    const shouldOpenFeedbackModal =
      isCreatePromptVersionSuccess || isCreatePromptVersionError;

    if (shouldOpenFeedbackModal) {
      setIsFeedbackModalOpen(true);
    }
  }, [createPromptVersionStatus]);

  // Refetching prompt data on version change
  useEffect(() => {
    fetchPromptVersionsByIdLazy(
      {
        promptId,
      },
      false,
    );
    fetchPromptVersionByIdLazy(
      {
        promptId: Number(prompt_id),
        version: promptVersionNumber,
      },
      false,
    );
  }, [promptVersionNumber, prompt_id]);

  useEffect(() => {
    fetchBlocksLazy(
      {
        limit: 3000,
        ordering: 'id',
      },
      true,
    );
  }, []);

  // Filling blocks list with available blocks
  useEffect(() => {
    if (blockListResponse?.results?.length) {
      setCurrentPaginationBlocks(
        blockListResponse.results.map((block) => ({
          content: block.latest_version.content,
          header: block.header,
          id: block.block_id,
          currentVersion: block.latest_version,
          availableVersions: block.versions,
          rules: block.rules,
        })),
      );
      setAllBlocks([
        ...allBlocks,
        ...blockListResponse.results.map((block) => ({
          content: block.latest_version.content,
          header: block.header,
          id: block.block_id,
          currentVersion: block.latest_version,
          availableVersions: block.versions,
          rules: block.rules,
        })),
      ]);
    }
  }, [blockListResponse]);

  // Filling the inputs with current prompt version data
  useEffect(() => {
    if (fetchPromptVersionByIdData) {
      const {
        title,
        description,
        blocks: blocksData,
      } = fetchPromptVersionByIdData;
      resetForm({
        title,
        description,
      });
      setBlocks(
        blocksData.map((block) => {
          return {
            id: block.id,
            content:
              block.versions.find((v) => v.id === block.block_version_id)
                ?.content || block.latest_version.content,
            header: block.header,
            currentVersion:
              block.versions.find((v) => v.id === block.block_version_id) ||
              block.latest_version,
            availableVersions: block.versions,
            disableRemove: block.rules.includes('required'),
            fixed: block.rules.includes('do_not_move'),
          };
        }),
      );
    }
  }, [fetchPromptVersionByIdData, currentPaginationBlocks]);

  // Cleaning block form errors after validated
  useEffect(() => {
    if (formErrors?.blocks && blocks.length) {
      clearErrors('blocks');
    }
  }, [blocks]);

  const createPromptErrorData = (createPromptVersionError as ResponseError)
    ?.data as FormErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreatePromptVersionError || isCreatePromptVersionSuccess) {
      return 'createPromptVersion';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreatePromptVersionError) {
      const errorMessages = Object.entries(createPromptErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isCreatePromptVersionSuccess) {
      return responseFeedbacks.createPromptVersion.success.message;
    }

    return '';
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isCreatePromptVersionSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    function onCloseModalCallback() {
      if (isCreatePromptVersionSuccess) {
        resetFormData(createPromptVersionData?.version ?? 1);
      }
      setIsFeedbackModalOpen(false);
    }

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseModalCallback,
      onClose: onCloseModalCallback,
    };
  }

  function onFormSubmit() {
    if (!blocks?.length) {
      setError('blocks', {
        type: 'error',
        message: 'Esse campo não pode ficar em branco',
      });
      return;
    }

    const block_version_ids = blocks.map((block) => block.currentVersion.id);

    createPrompt({promptId, block_version_ids});
  }

  function resetFormData(promptVersion: number) {
    history.push(`/prompts/${prompt_id}/${promptVersion}`);

    setIsFeedbackModalOpen(false);
    setPromptVersionNumber(promptVersion);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        {isLoading && <LoadingForm numberOfInputs={3} />}

        {isFetchPromptVersionByIdError && !isLoading && (
          <NoResultsWarning
            description="Parece que essa versão do prompt não está disponível"
            buttonText="Voltar aos prompts"
            buttonIcon="arrow-left"
            onButtonClick={() => history.push(AuthRoutes.promptList)}
          />
        )}

        {fetchPromptVersionByIdData &&
          !isFetchPromptVersionByIdError &&
          !isLoading &&
          fetchPromptVersionsByIdData &&
          !isFetchPromptVersionsByIdError &&
          !isLoading && (
            <>
              <PageHeader
                title="Editar prompt"
                onGoBackButtonClick={history.goBack}
                rightElement={
                  <div className={styles.rightButtonsContainer}>
                    <NewButton
                      text="Criar nova versão"
                      kind="primary"
                      userRole="teacher"
                      type="submit"
                      isLoading={isCreatingPromptVersion}
                      disabled={isCreatingPromptVersion || !isEmpty(formErrors)}
                    />
                  </div>
                }
              />

              <fieldset className={styles.fieldset}>
                <div className={styles.previewWrapper}>
                  <div
                    className={styles.promptVersionSelectWrapper}
                    data-testid="promptVersionSelect"
                  >
                    <label htmlFor="promptVersion">Versão a visualizar</label>
                    <Select
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          minWidth: 300,
                        }),
                      }}
                      isMulti={false}
                      onChange={(event: SingleValue<SelectOption>) => {
                        resetFormData(Number(event?.value));
                      }}
                      options={fetchPromptVersionsByIdData
                        .map(({version}) => ({
                          label: `Versão ${version}`,
                          value: version,
                        }))
                        .reverse()}
                      value={
                        {
                          label: `Versão ${prompt_version}`,
                          value: String(promptVersion),
                        } as SelectOption
                      }
                      placeholder="Selecione a versão do prompt"
                      name="promptVersion"
                      inputId="promptVersion"
                    />
                  </div>
                  {blocks.length ? <PromptPreview blocks={blocks} /> : <div />}
                </div>

                <div className={styles.fieldsWrapper}>
                  <Controller
                    control={formControl}
                    name="title"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, ref, value}}) => (
                      <InputText
                        id="title"
                        labelText="Título"
                        placeholder="Ex: asdasdasd"
                        name={name}
                        disabled
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value ?? ''}
                        errorMessage={getFormFieldError(
                          formErrors?.title?.type,
                        )}
                      />
                    )}
                  />

                  <Controller
                    control={formControl}
                    name="description"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, ref, value}}) => (
                      <TextArea
                        id="description"
                        labelText="Descrição"
                        placeholder="Ex: asdasdasd"
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled
                        ref={ref}
                        value={value ?? ''}
                        errorMessage={getFormFieldError(
                          formErrors?.description?.type,
                        )}
                      />
                    )}
                  />
                  <label htmlFor="blocks">Inserir blocos</label>
                  <Select
                    styles={{
                      ...reactSelectStyles,
                      container: (provided) => ({
                        ...provided,
                        maxWidth: '300px',
                      }),
                    }}
                    onChange={(option) => {
                      const newBlock = {
                        header: option?.label || '',
                        id: Number(option?.value),
                      };

                      const blockOnList = currentPaginationBlocks.find(
                        (block) => block.id === Number(option?.value),
                      );

                      if (blockOnList) {
                        setNewBlockFollowingRules(
                          {
                            ...newBlock,
                            currentVersion: blockOnList.currentVersion,
                            availableVersions: blockOnList.availableVersions,
                            content: blockOnList.content,
                          },
                          blocks,
                          setBlocks,
                        );
                      }
                    }}
                    options={currentPaginationBlocks
                      .filter(
                        (currentPaginationBlock) =>
                          !blocks.find(
                            (block) => block.id === currentPaginationBlock.id,
                          ),
                      )
                      .map((currentPaginationBlock) => ({
                        label: currentPaginationBlock.header,
                        value: String(currentPaginationBlock.id),
                      }))}
                    isMulti={false}
                    name="blocks"
                    inputId="blocks"
                    placeholder="Selecione os blocos"
                  />

                  {formErrors?.blocks && (
                    <label
                      htmlFor="blocks"
                      className={styles.blocksError}
                      id="blocks-error"
                    >
                      Esse campo não pode ficar em branco
                    </label>
                  )}

                  {!!blocks.length && (
                    <>
                      <div className={styles.blocksWrapper}>
                        {blocks.map((block) => (
                          <DragItem
                            block={block}
                            blocks={blocks}
                            setBlocks={setBlocks}
                            setBlockDraggingId={setBlockDraggingId}
                            blockDraggingId={blockDraggingId}
                            onChangeVersion={(block, version) =>
                              onChangeVersion(block, version, blocks, setBlocks)
                            }
                            key={block.id}
                          />
                        ))}
                      </div>

                      <figure>
                        <Icon icon={['far', 'exclamation-circle']} />

                        <p>
                          A ordem dos blocos influencia no comportamento do
                          prompt (da esquerda para direta, de cima para baixo)
                        </p>
                      </figure>
                    </>
                  )}
                </div>
              </fieldset>
            </>
          )}
      </form>

      {isFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default PromptEdition;
