import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const textMarkerCategoriesReducer = createApi({
  reducerPath: 'textMarkerCategories',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchTextMarkerCategories: builder.query<
        APIComponents['schemas']['Paginated_TextMarkerCategoryGet_'],
        {search?: string; limit?: number | string; offset?: number}
      >({
        query: ({limit, offset, search}) => ({
          url: '/text_marker_categories',
          method: 'GET',
          params: {
            limit,
            offset,
            search,
          },
        }),
      }),
      createTextMarkerCategory: builder.mutation<
        APIComponents['schemas']['TextMarkerCategoryCreate'],
        APIComponents['schemas']['TextMarkerCategoryCreate']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/text_marker_categories`,
          data,
        }),
      }),
      fetchTextMarkerCategoryById: builder.query<
        APIComponents['schemas']['TextMarkerCategoryGetDetail'],
        number
      >({
        query: (textMarkerCategoryId) => ({
          url: `/text_marker_categories/${textMarkerCategoryId}`,
          method: 'GET',
        }),
      }),
      updateTextMarkerCategory: builder.mutation<
        APIComponents['schemas']['TextMarkerCategoryCreate'],
        APIComponents['schemas']['TextMarkerCategoryCreate'] & {
          textMarkerCategoryId: number;
        }
      >({
        query: (textMarkerCategoryIdData) => ({
          method: 'PUT',
          url: `/text_marker_categories/${textMarkerCategoryIdData.textMarkerCategoryId}`,
          data: textMarkerCategoryIdData,
        }),
      }),
      deleteTextMarkerCategory: builder.mutation<
        void,
        {textMarkerCategoryId: number}
      >({
        query: ({textMarkerCategoryId}) => ({
          method: 'DELETE',
          url: `/text_marker_categories/${textMarkerCategoryId}`,
        }),
      }),
    };
  },
});

export const {
  fetchTextMarkerCategories: {
    useQuery: useFetchTextMarkerCategories,
    useLazyQuery: useFetchTextMarkerCategoriesLazy,
  },
  createTextMarkerCategory: {useMutation: useCreateTextMarkerCategory},
  fetchTextMarkerCategoryById: {
    useLazyQuery: useFetchTextMarkerCategoryByIdLazy,
  },
  updateTextMarkerCategory: {useMutation: useUpdateTextMarkerCategory},
  deleteTextMarkerCategory: {useMutation: useDeleteTextMarkerCategory},
} = textMarkerCategoriesReducer.endpoints;
