import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export const schedulingReducer = createApi({
  reducerPath: 'scheduling',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchScheduleResults: builder.query<
        APIComponents['schemas']['Paginated_ScheduleResults'],
        {
          limit?: number | string;
          offset?: number;
        }
      >({
        query: ({limit, offset}) => ({
          url: '/schedule_results',
          method: 'GET',
          params: {
            limit,
            offset,
          },
        }),
      }),
      createSchedule: builder.mutation<
        APIComponents['schemas']['Schedule'],
        APIComponents['schemas']['SchedulePayload']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/schedule`,
          data,
        }),
      }),
      scheduleTrigger: builder.mutation<void, {scheduleId: number}>({
        query: ({scheduleId}) => ({
          method: 'GET',
          url: `/schedule/trigger/${scheduleId}`,
        }),
      }),
    };
  },
});

export const {
  fetchScheduleResults: {
    useQuery: useFetchScheduleResults,
    useLazyQuery: useFetchScheduleResultsLazy,
  },
  createSchedule: {useMutation: useCreateSchedule},
  scheduleTrigger: {useMutation: useScheduleTrigger},
} = schedulingReducer.endpoints;
