/* eslint-disable no-restricted-globals */
import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import {ValidateGrammarRulePayload} from '../grammarRules';

export const textMarkersReducer = createApi({
  reducerPath: 'textMarkers',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchTextMarkers: builder.query<
        APIComponents['schemas']['Paginated_TextMarkerGet_'],
        {search?: string; limit?: number | string; offset?: number}
      >({
        query: ({search, limit, offset}) => ({
          url: '/text_markers',
          method: 'GET',
          params: {
            search: search || undefined,
            limit,
            offset,
          },
        }),
      }),
      fetchTextMarkerVersionById: builder.query<
        APIComponents['schemas']['TextMarkerGetDetail'],
        {rule_id?: number; version?: number}
      >({
        query: ({rule_id, version}) => ({
          url: `/text_markers/${rule_id}/versions/${version}`,
          method: 'GET',
        }),
      }),
      createTextMarker: builder.mutation<
        APIComponents['schemas']['TextMarkerCreate'],
        APIComponents['schemas']['TextMarkerCreate']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/text_markers`,
          data,
        }),
      }),
      createTextMarkerVersion: builder.mutation<
        APIComponents['schemas']['TextMarkerGetDetail'],
        APIComponents['schemas']['TextMarkerUpdate']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/text_markers/${data.id}`,
          data,
        }),
      }),
      updateProductionVersion: builder.mutation<
        APIComponents['schemas']['TextMarkerProdVersion'],
        APIComponents['schemas']['TextMarkerProdVersion'] & {id: number}
      >({
        query: (data) => ({
          method: 'PATCH',
          url: `/text_markers/${data.id}`,
          data: {
            version: data.version,
          },
        }),
      }),
      deleteTextMarker: builder.mutation<void, {textMarkerId: number}>({
        query: ({textMarkerId}) => ({
          method: 'DELETE',
          url: `/text_markers/${textMarkerId}`,
        }),
      }),
      validateTextMarker: builder.mutation<
        ValidateGrammarRulePayload,
        APIComponents['schemas']['TextMarkerValidationData']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/text_markers/validate`,
          data,
        }),
      }),
      deleteTextMarkerVersion: builder.mutation<
        void,
        {textMarkerId: number; textMarkerVersionNumber: number}
      >({
        query: ({textMarkerId, textMarkerVersionNumber}) => ({
          method: 'DELETE',
          url: `/text_markers/${textMarkerId}/versions/${textMarkerVersionNumber}`,
        }),
      }),
    };
  },
});

export const {
  fetchTextMarkers: {
    useQuery: useFetchTextMarkers,
    useLazyQuery: useFetchTextMarkersLazy,
  },
  fetchTextMarkerVersionById: {useLazyQuery: useFetchTextMarkerVersionByIdLazy},
  createTextMarker: {useMutation: useCreateTextMarker},
  createTextMarkerVersion: {useMutation: useCreateTextMarkerVersion},
  deleteTextMarker: {useMutation: useDeleteTextMarker},
  deleteTextMarkerVersion: {useMutation: useDeleteTextMarkerVersion},
  validateTextMarker: {useMutation: useValidateTextMarker},
  updateProductionVersion: {useMutation: useUpdateTextMarkerProductionVersion},
} = textMarkersReducer.endpoints;
