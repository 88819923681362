/* eslint-disable react/no-array-index-key */
import {Icon, InputText, NewButton, Button} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import NoDataIllustration from 'images/no-data.svg';
import {useHistory, useParams} from 'react-router-dom';
import {ChangeEvent, useEffect, useState} from 'react';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import DeletionModal from 'components/DeletionModal';
import Select from 'react-select';
import {
  useCreateGrammarRuleVersion,
  useDeleteGrammarRuleVersion,
  useFetchGrammarRuleVersionByIdLazy,
  useUpdateGrammarRuleProductionVersion,
  useValidateGrammarRule,
} from 'store/reducers/grammarRules';
import FeedbackModal from 'components/FeedbackModal';
import isValidJSON from 'utils/isValidJSON';
import TextArea from 'components/TextArea';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import {AuthRoutes} from 'routes';
import PageWrapper from '../../components/PageWrapper';
import styles from './GrammarRuleEdition.module.scss';

interface FormValues {
  category: string;
  subcategory: string;
  pattern: string;
  antipatterns: string;
  sentences: string;
  identificationStart: string;
  identificationEnd: string;
  comment: string;
}

interface AdditionalFormValues {
  examples: Record<
    number,
    {
      corrections: string;
      text: string;
      error_text: string;
    }
  >;
  suggestions: Record<
    number,
    {
      json: string;
    }
  >;
}

export interface Suggestion {
  json: string;
}

export interface Example {
  corrections: string;
  text: string;
  error_text: string;
}

interface FormErrors {
  newSuggestions: {
    [formIndex: number]: boolean;
  };
  existentSuggestions: {
    [formIndex: number]: boolean;
  };
}

const GrammarRuleEdition: React.FC = () => {
  const [
    isCreateGrammarVersionModalOpen,
    setIsCreateGrammarRuleVersionModalOpen,
  ] = useState<boolean>(false);
  const [
    isUpdateGrammarRuleProductionVersionOpen,
    setIsUpdateGrammarRuleProductionVersionOpen,
  ] = useState<boolean>(false);
  const [additionalFormValues, setAdditionalFormValues] =
    useState<AdditionalFormValues>({
      examples: {},
      suggestions: {},
    });
  const [formErrors, setFormErrors] = useState<FormErrors>({
    existentSuggestions: {},
    newSuggestions: {},
  });
  const [entitiesToBeDeleted, setEntitiesToBeDeleted] = useState<{
    examples: number[];
    suggestions: number[];
  }>({
    examples: [],
    suggestions: [],
  });
  const [newEntityFormValues, setNewEntityFormValues] = useState<{
    examples: Example[];
    suggestions: Suggestion[];
  }>({
    examples: [],
    suggestions: [],
  });
  const {id: ruleId, version: ruleVersion} =
    useParams<{id: string; version: string}>();
  const [versionNumber, setVersionNumber] = useState<number>(
    parseInt(ruleVersion, 10),
  );
  const [productionVersion, setProductionVersion] = useState<
    number | undefined
  >(1);

  const [
    isDeleteGrammarRuleVersionModalOpen,
    setIsDeleteGrammarRuleVersionModalOpen,
  ] = useState<boolean>(false);

  const [
    createGrammarRuleVersion,
    {
      isError: isCreateGrammarRuleVersionError,
      isSuccess: isCreateGrammarRuleVersionSuccess,
      isLoading: isCreatingGrammarRuleVersion,
      data: createGrammarRuleVersionData,
    },
  ] = useCreateGrammarRuleVersion();
  const [
    fetchGrammarRuleVersionByIdLazy,
    {
      isError: isFetchGrammarRuleVersionByIdError,
      isLoading: isLoadingGrammarRuleVersionById,
      data: fetchGrammarRuleVersionByIdData,
      isFetching: isFetchingGrammarRuleVersionById,
    },
  ] = useFetchGrammarRuleVersionByIdLazy();

  const [
    validateGrammarRule,
    {
      isError: isValidateGrammarRuleError,
      isLoading: isValidatingGrammarRule,
      isSuccess: isValidateGrammarRuleSuccess,
      data: validateGrammarRuleData,
    },
  ] = useValidateGrammarRule();
  const [
    updateGrammarRuleProductionVersion,
    {
      isError: isUpdateGrammarRuleProductionVersionError,
      isLoading: isUpdatingGrammarRuleProductionVersion,
      isSuccess: isUpdateGrammarRuleProductionVersionSuccess,
    },
  ] = useUpdateGrammarRuleProductionVersion();
  const [grammarRuleToDeleteId, setGrammarRuleToDeleteId] = useState<number>(0);
  const [grammarRuleVersionToDelete, setGrammarRuleVersionToDelete] =
    useState<number>(0);

  const [deleteGrammarRuleVersion] = useDeleteGrammarRuleVersion();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    trigger,
    watch,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      category: '',
      subcategory: '',
      pattern: '',
      antipatterns: '',
      sentences: '',
      identificationStart: '',
      identificationEnd: '',
      comment: '',
    },
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    fetchGrammarRuleVersionByIdLazy(
      {
        rule_id: parseInt(ruleId, 10),
        version: versionNumber,
      },
      true,
    );
  }, [versionNumber, ruleId]);

  useEffect(() => {
    if (fetchGrammarRuleVersionByIdData) {
      setProductionVersion(fetchGrammarRuleVersionByIdData.prod_version);

      reset({
        category: fetchGrammarRuleVersionByIdData?.category,
        subcategory: fetchGrammarRuleVersionByIdData?.sub_category,
        pattern: JSON.stringify(
          fetchGrammarRuleVersionByIdData?.current_version.pattern,
        ),
        antipatterns: JSON.stringify(
          fetchGrammarRuleVersionByIdData?.current_version.antipatterns,
        ),
        identificationStart: String(
          fetchGrammarRuleVersionByIdData?.current_version.on_match_start,
        ),
        identificationEnd: String(
          fetchGrammarRuleVersionByIdData?.current_version.on_match_end,
        ),
        comment: fetchGrammarRuleVersionByIdData?.comment,
      });

      const examplesMap =
        fetchGrammarRuleVersionByIdData?.current_version.examples.reduce(
          (accumulator, current, index) => {
            accumulator[index] = current;
            return accumulator;
          },
          {},
        );

      const suggestionsMap =
        fetchGrammarRuleVersionByIdData?.current_version.suggestions.reduce(
          (accumulator, current, index) => {
            accumulator[index] = {json: JSON.stringify(current)};
            return accumulator;
          },
          {},
        );

      setAdditionalFormValues({
        ...additionalFormValues,
        examples: examplesMap,
        suggestions: suggestionsMap,
      });
    }
  }, [fetchGrammarRuleVersionByIdData]);

  useEffect(() => {
    if (isCreateGrammarRuleVersionError) {
      setIsCreateGrammarRuleVersionModalOpen(true);
    }
  }, [isCreateGrammarRuleVersionError]);

  useEffect(() => {
    if (isCreateGrammarRuleVersionSuccess) {
      setIsCreateGrammarRuleVersionModalOpen(true);
    }
  }, [isCreateGrammarRuleVersionSuccess]);

  useEffect(() => {
    if (isUpdateGrammarRuleProductionVersionError) {
      setIsUpdateGrammarRuleProductionVersionOpen(true);
    }
  }, [isUpdateGrammarRuleProductionVersionError]);

  useEffect(() => {
    if (isUpdateGrammarRuleProductionVersionSuccess) {
      setIsUpdateGrammarRuleProductionVersionOpen(true);
    }
  }, [isUpdateGrammarRuleProductionVersionSuccess]);

  const {pattern, antipatterns, sentences} = watch();

  const isValidateButtonDisabled =
    !pattern ||
    !antipatterns ||
    !sentences ||
    Boolean(errors.antipatterns) ||
    Boolean(errors.pattern) ||
    Boolean(
      Object.values(formErrors.existentSuggestions).find(
        (existentSuggestion) => existentSuggestion == true,
      ),
    ) ||
    Boolean(
      Object.values(formErrors.newSuggestions).find(
        (newSuggestion) => newSuggestion == true,
      ),
    ) ||
    (!Object.values(additionalFormValues.suggestions).length &&
      !Object.values(newEntityFormValues.suggestions).length);

  function onChangeField(
    {
      target: {value, name},
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldIndex: number,
    fieldName: string,
  ) {
    setAdditionalFormValues({
      ...additionalFormValues,
      [fieldName]: {
        ...additionalFormValues[fieldName],
        [fieldIndex]: {
          ...additionalFormValues[fieldName][fieldIndex],
          [name]: value,
        },
      },
    });

    if (fieldName === 'suggestions') {
      setFormErrors({
        ...formErrors,
        existentSuggestions: {
          ...formErrors.existentSuggestions,
          [fieldIndex]: false,
        },
      });
    }
  }

  function onChangeNewFormField(
    {
      target: {name, value},
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldIndex: number,
    fieldName: string,
  ) {
    const newFormValues = [...newEntityFormValues[fieldName]];
    newFormValues[fieldIndex][name] = value;
    setNewEntityFormValues({
      ...newEntityFormValues,
      [fieldName]: newFormValues,
    });

    if (fieldName === 'suggestions') {
      setFormErrors({
        ...formErrors,
        newSuggestions: {
          ...formErrors.newSuggestions,
          [fieldIndex]: false,
        },
      });
    }
  }
  function onClickDeleteGrammarRuleVersion(
    ruleId: number,
    ruleVersionNumber: number,
  ) {
    setGrammarRuleToDeleteId(ruleId);
    setGrammarRuleVersionToDelete(ruleVersionNumber);
    setIsDeleteGrammarRuleVersionModalOpen(true);
  }

  function onClickDeleteGrammarRuleVersionModal() {
    deleteGrammarRuleVersion({
      grammarRuleId: grammarRuleToDeleteId,
      grammarRuleVersionNumber: grammarRuleVersionToDelete,
    });
    setIsDeleteGrammarRuleVersionModalOpen(false);
    history.push(AuthRoutes.grammarRuleList);
  }

  function removeNewForm(index: number, entity: string) {
    const filteredNewEntities = [...newEntityFormValues[entity]];

    filteredNewEntities.splice(index, 1);

    setNewEntityFormValues({
      ...newEntityFormValues,
      [entity]: filteredNewEntities,
    });

    if (entity === 'suggestions') {
      setFormErrors({
        ...formErrors,
        newSuggestions: {
          ...formErrors.newSuggestions,
          [index]: false,
        },
      });
    }
  }

  function selectEntityIndexToBeDeleted(entity: string, index: number) {
    if (entitiesToBeDeleted[entity].includes(index)) {
      const filteredIndexes = entitiesToBeDeleted[entity].filter(
        (entityIndex) => entityIndex !== index,
      );

      setEntitiesToBeDeleted({
        ...entitiesToBeDeleted,
        [entity]: filteredIndexes,
      });

      return;
    }

    setEntitiesToBeDeleted({
      ...entitiesToBeDeleted,
      [entity]: [...entitiesToBeDeleted[entity], index],
    });
  }

  function validateSuggestions() {
    let newFormErrors: FormErrors = {
      existentSuggestions: {},
      newSuggestions: {},
    };

    Object.values(additionalFormValues.suggestions).forEach(
      (suggestion, index) => {
        if (!isValidJSON(suggestion.json)) {
          newFormErrors = {
            existentSuggestions: {
              ...newFormErrors.existentSuggestions,
              [index]: true,
            },
            newSuggestions: {
              ...newFormErrors.newSuggestions,
            },
          };
        }
      },
    );
    newEntityFormValues.suggestions.forEach((suggestion, index) => {
      if (!isValidJSON(suggestion.json)) {
        newFormErrors = {
          existentSuggestions: {
            ...newFormErrors.existentSuggestions,
          },
          newSuggestions: {
            ...newFormErrors.newSuggestions,
            [index]: true,
          },
        };
      }
    });

    setFormErrors(newFormErrors);
  }

  function triggerGrammarRuleValidation() {
    const {
      sentences,
      pattern,
      antipatterns,
      identificationStart,
      identificationEnd,
    } = getValues();

    trigger(['pattern', 'antipatterns'], {shouldFocus: true});
    validateSuggestions();
    validateGrammarRule({
      text: sentences,
      pattern: JSON.parse(pattern),
      antipatterns: JSON.parse(antipatterns),
      suggestions: [
        ...Object.values(additionalFormValues.suggestions).map((suggestion) =>
          JSON.parse(suggestion.json),
        ),
        ...newEntityFormValues.suggestions.map((suggestion) =>
          JSON.parse(suggestion.json),
        ),
      ],
      on_match_start: parseInt(identificationStart, 10),
      on_match_end: parseInt(identificationEnd, 10),
    });
  }

  function resetData(ruleId: number, ruleVersion: number) {
    history.push(`/regras-gramaticais/${ruleId}/${ruleVersion}`);

    setIsCreateGrammarRuleVersionModalOpen(false);
    setVersionNumber(ruleVersion);
    setEntitiesToBeDeleted({
      examples: [],
      suggestions: [],
    });
    setNewEntityFormValues({examples: [], suggestions: []});
  }

  function onSubmit() {
    validateSuggestions();

    const {
      category,
      subcategory,
      comment,
      pattern,
      antipatterns,
      identificationStart,
      identificationEnd,
    } = getValues();

    if (entitiesToBeDeleted.examples.length) {
      entitiesToBeDeleted.examples.forEach((exampleIndex) => {
        delete additionalFormValues.examples[exampleIndex];
      });
    }

    if (entitiesToBeDeleted.suggestions.length) {
      entitiesToBeDeleted.suggestions.forEach((suggestionIndex) => {
        delete additionalFormValues.suggestions[suggestionIndex];
      });
    }

    try {
      Object.values(additionalFormValues.suggestions).map((suggestion) =>
        JSON.parse(suggestion.json),
      );
      newEntityFormValues.suggestions.map((suggestion) =>
        JSON.parse(suggestion.json),
      );
    } catch (error) {
      return;
    }

    const grammarRuleVersionData = {
      id: fetchGrammarRuleVersionByIdData?.id as number,
      category,
      sub_category: subcategory,
      comment,
      version: {
        pattern: JSON.parse(pattern),
        antipatterns: JSON.parse(antipatterns),
        on_match_start: parseInt(identificationStart, 10),
        on_match_end: parseInt(identificationEnd, 10),
        suggestions: [
          ...Object.values(additionalFormValues.suggestions).map((suggestion) =>
            JSON.parse(suggestion.json),
          ),
          ...newEntityFormValues.suggestions.map((suggestion) =>
            JSON.parse(suggestion.json),
          ),
        ],
        examples: [
          ...Object.values(additionalFormValues.examples),
          ...newEntityFormValues.examples,
        ],
      },
    };

    createGrammarRuleVersion(grammarRuleVersionData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {isFetchGrammarRuleVersionByIdError &&
          !isLoadingGrammarRuleVersionById && (
            <div className={styles.noResults}>
              <img
                src={NoDataIllustration}
                alt="Ilustração de sem resultados"
              />
              <h2>Parece que essa versão da regra não está disponível</h2>

              <div className={styles.resetDataButtonWrapper} title="Recarregar">
                <NewButton
                  icon={['fas', 'undo']}
                  onClick={() => history.push(AuthRoutes.grammarRuleList)}
                  text="Voltar às regras"
                />
              </div>
            </div>
          )}

        {(isLoadingGrammarRuleVersionById ||
          isFetchingGrammarRuleVersionById ||
          isUpdatingGrammarRuleProductionVersion) && (
          <LoadingForm numberOfInputs={10} />
        )}

        {fetchGrammarRuleVersionByIdData &&
          !isFetchGrammarRuleVersionByIdError && (
            <>
              <header>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={history.goBack}>
                    <Icon icon="chevron-left" color="#414449" />
                  </button>

                  <h1>Editar regra gramatical</h1>
                </div>
                <div className={styles.RightButtonsContainer}>
                  <Button
                    text="Remover versão"
                    kind="negativeSecondary"
                    userRole="teacher"
                    type="button"
                    onClick={() =>
                      onClickDeleteGrammarRuleVersion(
                        parseInt(ruleId, 10),
                        parseInt(ruleVersion, 10),
                      )
                    }
                  />
                  <NewButton
                    text="Criar nova versão"
                    kind="primary"
                    userRole="teacher"
                    type="submit"
                    isLoading={isCreatingGrammarRuleVersion}
                    disabled={isCreatingGrammarRuleVersion}
                  />
                </div>
              </header>

              <div className={styles.fieldset}>
                <div
                  className={styles.grammarRuleVersionSelect}
                  data-testid="grammarRuleVersionSelect"
                >
                  <label htmlFor="ruleVersion">Versão a visualizar</label>
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: 318,
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    onChange={(event) => {
                      resetData(
                        parseInt(ruleId, 10),
                        parseInt(String(event?.value), 10),
                      );
                    }}
                    options={fetchGrammarRuleVersionByIdData.versions
                      .map(({version}) => ({
                        label: `Versão ${version}`,
                        value: version,
                      }))
                      .slice()
                      .reverse()}
                    value={{
                      label: `Versão ${ruleVersion}`,
                      value: parseInt(ruleVersion, 10),
                    }}
                    placeholder="Selecione a versão da regra"
                    name="ruleVersion"
                    inputId="ruleVersion"
                  />

                  <label htmlFor="productionVersion">Versão de produção</label>
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: 318,
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    onChange={(event) => {
                      setProductionVersion(event?.value);
                      updateGrammarRuleProductionVersion({
                        id: parseInt(ruleId, 10),
                        version: event?.value || null,
                      });
                    }}
                    options={[
                      {label: 'Nenhuma', value: undefined},
                      ...fetchGrammarRuleVersionByIdData.versions
                        .map(({version}) => ({
                          label: `Versão ${version}`,
                          value: version,
                        }))
                        .slice()
                        .reverse(),
                    ]}
                    value={{
                      label: productionVersion
                        ? `Versão ${productionVersion}`
                        : 'Nenhuma',
                      value: productionVersion || undefined,
                    }}
                    placeholder="Selecione a versão de produção"
                    name="productionVersion"
                    inputId="productionVersion"
                  />
                </div>
                <Controller
                  control={control}
                  name="category"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="category"
                      labelText="Nome da categoria"
                      placeholder="Ex: Categoria X"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        errors?.category?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : undefined
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="subcategory"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="subcategory"
                      labelText="Nome da subcategoria"
                      placeholder="Ex: Subcategoria X"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        errors?.subcategory?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : undefined
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="comment"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <TextArea
                      id="comment"
                      labelText="Comentário"
                      placeholder="Ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        errors?.comment?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : undefined
                      }
                      resizable
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="pattern"
                  rules={{
                    required: true,
                    validate: isValidJSON,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <TextArea
                      id="pattern"
                      labelText="Padrão"
                      placeholder="Ex: /(texto).../g"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        errors?.pattern?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : errors?.pattern?.type === 'validate'
                          ? 'Padrão inválido, verifique o formato do JSON'
                          : undefined
                      }
                      resizable
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="antipatterns"
                  rules={{
                    required: true,
                    validate: isValidJSON,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <TextArea
                      id="antipatterns"
                      labelText="Antipadrões"
                      placeholder={`Ex: [{"LOWER": "mais"},{"TEXT":{"REGEX": ”ˆ(menor|menores)$”}}]`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        errors?.antipatterns?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : errors?.antipatterns?.type === 'validate'
                          ? 'Antipadrões inválidos, verifique o formato do JSON'
                          : undefined
                      }
                      resizable
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="identificationStart"
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="identificationStart"
                      labelText="Início da identificação"
                      placeholder="Ex: 1"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="identificationEnd"
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="identificationEnd"
                      labelText="Fim da identificação"
                      placeholder="Ex: 5"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                    />
                  )}
                />

                <legend>Sugestões</legend>

                <div className={styles.suggestionsWrapper}>
                  {Object.keys(additionalFormValues.suggestions).length
                    ? fetchGrammarRuleVersionByIdData?.current_version.suggestions.map(
                        (_, index) => (
                          <div key={`newSuggestion-${index}`}>
                            <div className={styles.title}>
                              <label htmlFor={`json-${index}`}>
                                Sugestão {index + 1}
                              </label>

                              <button
                                type="button"
                                onClick={() =>
                                  selectEntityIndexToBeDeleted(
                                    'suggestions',
                                    index,
                                  )
                                }
                                title="Excluir sugestão"
                                className={`${
                                  entitiesToBeDeleted.suggestions.includes(
                                    index,
                                  )
                                    ? styles.selected
                                    : ''
                                } `}
                                data-testid="deleteSuggestionButton"
                              >
                                <Icon
                                  icon={['fas', 'trash']}
                                  color={`${
                                    entitiesToBeDeleted.suggestions.includes(
                                      index,
                                    )
                                      ? '#f24e54'
                                      : '#414449'
                                  }`}
                                />
                              </button>
                            </div>

                            <div className={styles.textAreaWrapper}>
                              <TextArea
                                errorMessage={
                                  formErrors.existentSuggestions[index]
                                    ? 'Sugestão inválida, verifique o formato do JSON'
                                    : undefined
                                }
                                onBlur={validateSuggestions}
                                id={`json-${index}`}
                                key={`json-${index}`}
                                name="json"
                                placeholder="Ex: texto"
                                value={
                                  additionalFormValues.suggestions[index].json
                                }
                                onChange={(event) =>
                                  onChangeField(event, index, 'suggestions')
                                }
                                resizable
                              />
                            </div>
                          </div>
                        ),
                      )
                    : null}

                  <div className={styles.newSuggestionForm}>
                    {newEntityFormValues.suggestions.map(
                      (newSuggestion, index) => (
                        <div key={`newSuggestion-${index}`}>
                          <div className={styles.title}>
                            <label htmlFor={`newSuggestionJson-${index}`}>
                              Nova sugestão
                            </label>

                            <button
                              type="button"
                              onClick={() =>
                                removeNewForm(index, 'suggestions')
                              }
                              title="Remover formulário"
                              className={styles.removeNewSuggestionButton}
                            >
                              <Icon icon={['fas', 'times']} color="#414449" />
                            </button>
                          </div>

                          <div className={styles.textAreaWrapper}>
                            <TextArea
                              errorMessage={
                                formErrors.newSuggestions[index]
                                  ? 'Sugestão inválida, verifique o formato do JSON'
                                  : undefined
                              }
                              onBlur={validateSuggestions}
                              id={`newSuggestionJson-${index}`}
                              key={`newSuggestionJson-${index}`}
                              name="json"
                              placeholder="Ex: texto"
                              value={newSuggestion.json}
                              onChange={(event) =>
                                onChangeNewFormField(
                                  event,
                                  index,
                                  'suggestions',
                                )
                              }
                              resizable
                            />
                          </div>
                        </div>
                      ),
                    )}
                  </div>

                  <div className={styles.addSuggestionButton}>
                    <NewButton
                      type="button"
                      icon={['fas', 'plus']}
                      text="Adicionar sugestão"
                      onClick={() =>
                        setNewEntityFormValues({
                          ...newEntityFormValues,
                          suggestions: [
                            ...newEntityFormValues.suggestions,
                            {
                              json: '',
                            },
                          ],
                        })
                      }
                    />
                  </div>
                </div>

                <legend>Exemplos</legend>

                <div className={styles.examplesWrapper}>
                  {Object.keys(additionalFormValues.examples).length
                    ? fetchGrammarRuleVersionByIdData?.current_version.examples.map(
                        (_, index) => (
                          <div key={`example-${index}`}>
                            <div className={styles.title}>
                              <h4>Exemplo {index + 1}</h4>

                              <button
                                type="button"
                                onClick={() =>
                                  selectEntityIndexToBeDeleted(
                                    'examples',
                                    index,
                                  )
                                }
                                title="Excluir exemplo"
                                className={`${
                                  entitiesToBeDeleted.examples.includes(index)
                                    ? styles.selected
                                    : ''
                                } `}
                              >
                                <Icon
                                  icon={['fas', 'trash']}
                                  color={`${
                                    entitiesToBeDeleted.examples.includes(index)
                                      ? '#f24e54'
                                      : '#414449'
                                  }`}
                                />
                              </button>
                            </div>

                            <div className={styles.inputWrapper}>
                              <div className={styles.exampleInputWrapper}>
                                <InputText
                                  id={`text-${index}`}
                                  key={`text-${index}`}
                                  name="text"
                                  labelText="Exemplo"
                                  placeholder="Ex: exemplo tal"
                                  value={
                                    additionalFormValues?.examples[index]?.text
                                  }
                                  onChange={(event) =>
                                    onChangeField(event, index, 'examples')
                                  }
                                />
                              </div>

                              <div className={styles.exampleInputWrapper}>
                                <InputText
                                  id={`error_text-${index}`}
                                  key={`error_text-${index}`}
                                  name="error_text"
                                  labelText="Trecho com erro"
                                  placeholder="Ex: erro"
                                  value={
                                    additionalFormValues?.examples[index]
                                      ?.error_text
                                  }
                                  onChange={(event) =>
                                    onChangeField(event, index, 'examples')
                                  }
                                />
                              </div>

                              <div className={styles.exampleInputWrapper}>
                                <InputText
                                  id={`corrections-${index}`}
                                  key={`corrections-${index}`}
                                  name="corrections"
                                  labelText="Correções"
                                  placeholder="Ex: correção1;correção2;correção3;"
                                  value={
                                    additionalFormValues?.examples[index]
                                      ?.corrections
                                  }
                                  onChange={(event) =>
                                    onChangeField(event, index, 'examples')
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ),
                      )
                    : null}

                  <div className={styles.newExampleForm}>
                    {newEntityFormValues.examples.map((newExample, index) => (
                      <div key={`newExample-${index}`}>
                        <div className={styles.title}>
                          <h4>Novo exemplo</h4>

                          <button
                            type="button"
                            onClick={() => removeNewForm(index, 'examples')}
                            title="Remover formulário"
                            className={styles.removeNewExampleButton}
                          >
                            <Icon icon={['fas', 'times']} />
                          </button>
                        </div>

                        <div className={styles.inputWrapper}>
                          <div className={styles.exampleInputWrapper}>
                            <InputText
                              id={`text-${index}`}
                              key={`text-${index}`}
                              name="text"
                              labelText="Exemplo"
                              placeholder="Ex: exemplo"
                              value={newExample.text}
                              onChange={(event) =>
                                onChangeNewFormField(event, index, 'examples')
                              }
                            />
                          </div>

                          <div className={styles.exampleInputWrapper}>
                            <InputText
                              id={`error_text-${index}`}
                              key={`error_text-${index}`}
                              name="error_text"
                              labelText="Trecho com erro"
                              placeholder="Ex: erro"
                              value={newExample.error_text}
                              onChange={(event) =>
                                onChangeNewFormField(event, index, 'examples')
                              }
                            />
                          </div>

                          <div className={styles.exampleInputWrapper}>
                            <InputText
                              id={`corrections-${index}`}
                              key={`corrections-${index}`}
                              name="corrections"
                              labelText="Correções"
                              placeholder="Ex: correção1;correção2;correção3;"
                              value={newExample.corrections}
                              onChange={(event) =>
                                onChangeNewFormField(event, index, 'examples')
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className={styles.addExampleButton}>
                    <NewButton
                      type="button"
                      icon={['fas', 'plus']}
                      text="Adicionar exemplo"
                      onClick={() =>
                        setNewEntityFormValues({
                          ...newEntityFormValues,
                          examples: [
                            ...newEntityFormValues.examples,
                            {corrections: '', text: '', error_text: ''},
                          ],
                        })
                      }
                    />
                  </div>
                </div>

                <div className={styles.sentenceValidationWrapper}>
                  <div>
                    <Controller
                      control={control}
                      name="sentences"
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <InputText
                          id="sentences"
                          labelText="Sentença"
                          placeholder="Ex: beba agua"
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          ref={ref}
                          value={value ?? ''}
                        />
                      )}
                    />
                  </div>

                  <NewButton
                    text="Validar"
                    icon={['fas', 'search']}
                    isLoading={isValidatingGrammarRule}
                    disabled={
                      isValidatingGrammarRule || isValidateButtonDisabled
                    }
                    onClick={triggerGrammarRuleValidation}
                  />
                </div>

                {validateGrammarRuleData && isValidateGrammarRuleSuccess && (
                  <div className={styles.testRulerValidationWrapper}>
                    <div className={styles.testValidateTable}>
                      {validateGrammarRuleData?.tokens?.length > 0 ? (
                        <>
                          <div className={styles.affectedRules}>
                            <b>
                              {`Sentença inflige ${
                                validateGrammarRuleData.rule_ids.length > 1
                                  ? 'nas regras: '
                                  : 'na regra: '
                              }
                        `}
                            </b>
                            <ul>
                              {validateGrammarRuleData?.rule_ids?.map(
                                (rule_id, index) => (
                                  <li key={rule_id}>
                                    {rule_id}
                                    {index !==
                                      validateGrammarRuleData?.rule_ids.length -
                                        1 && <span>, </span>}
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>

                          <table>
                            <thead>
                              <tr>
                                <th title="Token">Token</th>
                                <th title="Pos">Pos</th>
                                <th title="Tag">Tag</th>
                                <th title="Morph">Morph</th>
                              </tr>
                            </thead>
                            <tbody>
                              {validateGrammarRuleData.tokens.map((token) => (
                                <tr key={token[0]}>
                                  <td>{token[0]}</td>
                                  <td>{token[1]}</td>
                                  <td>{token[2]}</td>
                                  <td>{token[3]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <Icon
                            icon={['fas', 'times']}
                            color="#f24e54"
                            size="lg"
                          />
                          Não identificado
                        </>
                      )}
                    </div>
                  </div>
                )}
                {isValidatingGrammarRule && (
                  <div className={styles.loadingRowWrapper}>
                    <LoadingRow height={25} />
                  </div>
                )}

                {isValidateGrammarRuleError ? (
                  <label className={styles.errorLabel} htmlFor="sentences">
                    Ocorreu um erro ao validar a regra.
                    <br /> Por favor, verifique os campos e tente novamente
                  </label>
                ) : null}
              </div>
            </>
          )}
      </form>

      <FeedbackModal
        isOpen={isCreateGrammarVersionModalOpen}
        title="Criar nova versão"
        subtitle={isCreateGrammarRuleVersionError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreateGrammarRuleVersionError
            ? 'Ocorreu um erro ao atualizar a regra. Verifique os dados e tente novamente.'
            : 'A regra foi atualizada.'
        }
        onClose={
          isCreateGrammarRuleVersionError
            ? () => setIsCreateGrammarRuleVersionModalOpen(false)
            : () =>
                resetData(
                  parseInt(ruleId, 10),
                  createGrammarRuleVersionData?.current_version.version || 1,
                )
        }
        onButtonClick={
          isCreateGrammarRuleVersionError
            ? () => setIsCreateGrammarRuleVersionModalOpen(false)
            : () =>
                resetData(
                  parseInt(ruleId, 10),
                  createGrammarRuleVersionData?.current_version.version || 1,
                )
        }
        buttonText={
          isCreateGrammarRuleVersionError ? 'Tentar novamente' : 'Fechar'
        }
        iconName={isCreateGrammarRuleVersionError ? 'undo' : 'arrow-left'}
      />
      <DeletionModal
        isOpen={isDeleteGrammarRuleVersionModalOpen}
        message="Deseja mesmo excluir essa versão?"
        onClickDelete={onClickDeleteGrammarRuleVersionModal}
        onClose={() => setIsDeleteGrammarRuleVersionModalOpen(false)}
        subtitle="Tem certeza?"
        title="Excluir versão"
      />

      <FeedbackModal
        isOpen={isUpdateGrammarRuleProductionVersionOpen}
        title="Atualizar versão de produção"
        subtitle={
          isUpdateGrammarRuleProductionVersionError ? 'Erro!' : 'Sucesso!'
        }
        feedbackMessage={
          isUpdateGrammarRuleProductionVersionError
            ? 'Ocorreu um erro ao atualizar a versão de produção. Verifique os dados e tente novamente.'
            : 'A versão de produção foi atualizada.'
        }
        onClose={() => setIsUpdateGrammarRuleProductionVersionOpen(false)}
        onButtonClick={() => setIsUpdateGrammarRuleProductionVersionOpen(false)}
        buttonText={
          isUpdateGrammarRuleProductionVersionError
            ? 'Tentar novamente'
            : 'Fechar'
        }
        iconName={
          isUpdateGrammarRuleProductionVersionError ? 'undo' : 'arrow-left'
        }
      />
    </PageWrapper>
  );
};

export default GrammarRuleEdition;
