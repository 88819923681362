import SelectOption from 'utils/types/SelectOption';
import {ChangeEvent} from 'react';
import Select, {SingleValue} from 'react-select';
import {Search} from 'letrus-ui';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import entitiesPerPageOptions from 'utils/entitiesPerPageOptions';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
  onSubmitSearch?(): void;
  onChangeSearchText?(event: ChangeEvent<HTMLInputElement>): void;
  setNumberOfResultsPerPage(event: any): void;
  searchText?: string;
  numberOfResultsPerPage: SelectOption | null;
  filters?: SelectOption[];
  selectedFilter?: SelectOption;
  onChangeFilter?(selectedFilter: SelectOption): void;
  searchOptions?: SelectOption[];
  searchBy?: SelectOption;
  onChangeSearchBy?(searchBy: SelectOption): void;
  selectWrapperProps?: any;
  searchPlaceholder?: string;
}

function TableHeader({
  onSubmitSearch,
  searchText,
  onChangeSearchText,
  setNumberOfResultsPerPage,
  numberOfResultsPerPage,
  selectWrapperProps,
  searchPlaceholder,
  filters,
  selectedFilter,
  onChangeFilter,
  searchOptions,
  searchBy,
  onChangeSearchBy,
}: TableHeaderProps): JSX.Element {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.searchContainer}>
        {(!!searchPlaceholder || !!searchText) && (
          <Search
            onClick={onSubmitSearch}
            id="name"
            search={{
              name: 'search',
              value: searchText || '',
              onChange: onChangeSearchText as (
                eventOrValue: string | React.SyntheticEvent<Element, Event>,
              ) => void,
              // TODO: Type onChange correctly on UI
            }}
            searchProps={{
              placeholder: searchPlaceholder || '',
              searchTag: 'input',
              required: false,
            }}
          />
        )}

        {!!searchOptions && (
          <div className={styles.searchBy}>
            <label htmlFor="searchBy">Pesquisar por:</label>
            <Select
              styles={{
                ...reactSelectStyles,
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                  height: '30px',
                }),
                control: (provided) => ({
                  ...provided,
                  minHeight: '39px',
                  height: '39px',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  height: '39px',
                  padding: '0 6px',
                }),
                input: (provided) => ({
                  ...provided,
                  margin: '0px',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: '39px',
                }),
              }}
              isMulti={false}
              onChange={(event: SingleValue<SelectOption>) =>
                onChangeSearchBy && event ? onChangeSearchBy(event) : undefined
              }
              name="searchBy"
              options={searchOptions}
              value={searchBy}
            />
          </div>
        )}
      </div>

      <div {...selectWrapperProps} className={styles.filtersWrapper}>
        <Select
          styles={{...reactSelectStyles}}
          onChange={(event) => setNumberOfResultsPerPage(event)}
          options={entitiesPerPageOptions}
          value={numberOfResultsPerPage}
        />

        {!!filters && (
          <Select
            styles={{
              ...reactSelectStyles,
              container: (provided) => ({
                ...provided,
                width: '100%',
                maxWidth: '300px',
                marginLeft: '10px;',
              }),
            }}
            isMulti={false}
            onChange={(event: SingleValue<SelectOption>) =>
              onChangeFilter && event ? onChangeFilter(event) : undefined
            }
            options={filters}
            value={selectedFilter}
          />
        )}
      </div>
    </div>
  );
}

export default TableHeader;
