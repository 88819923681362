import React, {InputHTMLAttributes, LegacyRef} from 'react';
import styles from './TextArea.module.scss';

export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  labelText?: string;
  errorMessage?: string;
  ref?: LegacyRef<HTMLTextAreaElement>;
  rows?: number;
  resizable?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  labelText = '',
  errorMessage = '',
  resizable = false,
  rows,
  ...otherProps
}) => {
  return (
    <>
      {labelText && <label htmlFor={id}>{labelText}</label>}

      <textarea
        id={id}
        rows={rows || 4}
        className={`
          ${errorMessage ? styles.error : ''} 
          ${resizable ? styles.resizable : ''} 
        `}
        {...otherProps}
      />

      {errorMessage && (
        <label htmlFor={id} className={styles.errorLabel}>
          {errorMessage}
        </label>
      )}
    </>
  );
};

export default TextArea;
