import {Icon, InputText, NewButton, TextArea} from 'letrus-ui';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useCreatePlagiarismText} from 'store/reducers/plagiarismTexts';
import {useEffect, useState} from 'react';
import FeedbackModal from 'components/FeedbackModal';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './PlagiarismTextCreation.module.scss';

interface FormValues {
  title: string;
  text: string;
  url: string;
  tags: string;
}

const PlagiarismTextCreation: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormValues>();

  const [
    createPlagiarismText,
    {
      isError: isCreatePlagiarismTextError,
      isLoading: isCreatingPlagiarismText,
      isSuccess: isCreatePlagiarismTextSuccess,
      error: createPlagiarismTextError,
    },
  ] = useCreatePlagiarismText();

  const history = useHistory();

  useEffect(() => {
    if (isCreatePlagiarismTextError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreatePlagiarismTextError]);

  useEffect(() => {
    if (isCreatePlagiarismTextSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreatePlagiarismTextSuccess]);

  const createPlagiarismTextErrorData = (
    createPlagiarismTextError as ResponseError
  )?.data as SimpleErrorPayload;

  function onSubmit() {
    const {tags, ...otherValues} = getValues();

    const plagiarismTextData = {
      tags: tags.split(',').map((tag) => tag),
      ...otherValues,
    };

    createPlagiarismText(plagiarismTextData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Criar texto de plágio</h1>
          </div>

          <NewButton
            text="Criar"
            kind="primary"
            userRole="teacher"
            type="submit"
            isLoading={isCreatingPlagiarismText}
            disabled={isCreatingPlagiarismText}
          />
        </header>

        <div className={styles.fieldset}>
          <InputText
            errorMessage={
              errors?.title?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            labelText="Título do texto"
            placeholder="Ex: Harry Potter"
            id="title"
            {...register('title', {required: true})}
          />

          <TextArea
            errorMessage={
              errors?.text?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            labelText="Texto"
            placeholder="Ex: Um grande texto"
            id="text"
            {...register('text', {required: true})}
          />

          <InputText
            id="url"
            labelText="URL (fonte)"
            placeholder="Ex: www.url.com.br"
            errorMessage={
              errors?.url?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            {...register('url', {required: true})}
          />

          <InputText
            id="tags"
            labelText="Tags (separadas por vírgula)"
            placeholder="Ex: tag1,tag2,tag3"
            errorMessage={
              errors?.tags?.type === 'required'
                ? 'Este campo não pode ficar em branco'
                : undefined
            }
            {...register('tags', {required: true})}
          />
        </div>
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Criar texto de plágio"
        subtitle={isCreatePlagiarismTextError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreatePlagiarismTextError
            ? createPlagiarismTextErrorData.detail
            : 'O texto de plágio foi criado.'
        }
        onClose={
          isCreatePlagiarismTextError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.plagiarismTextList)
        }
        onButtonClick={
          isCreatePlagiarismTextError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.plagiarismTextList)
        }
        buttonText={
          isCreatePlagiarismTextError
            ? 'Tentar novamente'
            : 'Voltar aos textos de plágio'
        }
        iconName={isCreatePlagiarismTextError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default PlagiarismTextCreation;
