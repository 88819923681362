import axios, {AxiosError, AxiosInstance} from 'axios';
import Qs from 'qs';
import {API_URL} from 'utils/apiUrl';
import {getCookie} from 'utils/cookies';

export type Methods =
  | 'get'
  | 'put'
  | 'post'
  | 'head'
  | 'delete'
  | 'patch'
  | 'options';

interface FetchOptions {
  method?: Methods;
  data?: any;
  params?: Record<string, string>;
}

const letrusAxiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 360 * 1000,
  paramsSerializer: (params: Record<string, string>) =>
    Qs.stringify(params, {arrayFormat: 'repeat'}),
  withCredentials: true,
});

const defaultOptions: FetchOptions = {method: 'get'};

async function letrusApi(url: string, {method, data, params} = defaultOptions) {
  return letrusAxiosInstance({
    method,
    url,
    params,
    data,
    headers: {
      Authorization: `Bearer ${getCookie('nlptToken')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).catch((err: AxiosError) => {
    if (!err.code && !err.response) {
      alert(
        'Ocorreu um erro ao se comunicar com a API, é provável que ela esteja fora do ar. Contate um desenvolvedor',
      );
    }

    throw err;
  });
}

export default letrusApi;
