import {InputText, NewButton, Button} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import DeletionModal from 'components/DeletionModal';
import PageHeader from 'components/PageHeader';
import Select, {SingleValue} from 'react-select';
import FeedbackModal, {FeedbackModalProps} from 'components/FeedbackModal';
import TextArea from 'components/TextArea';
import {AuthRoutes} from 'routes';
import SelectOption from 'utils/types/SelectOption';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {
  useCreateBlockVersion,
  useDeleteBlockVersion,
  useFetchBlockVersionByIdLazy,
} from 'store/reducers/blocks';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import NoResultsWarning from 'components/NoResultsWarning';
import {isEmpty} from 'lodash';
import {useBlocks} from 'features/useBlocks';
import PageWrapper from '../../components/PageWrapper';
import styles from './BlockEdition.module.scss';

interface RouteParams {
  block_id: string;
  block_version: string;
}

interface FormValues {
  header: string;
  content: string;
  tags: SelectOption[];
}

function BlockEdition(): JSX.Element {
  const {block_id, block_version} = useParams<RouteParams>();
  const blockId = Number(block_id);
  const blockVersion = Number(block_version);

  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [isDeleteBlockVersionModalOpen, setIsDeleteBlockVersionModalOpen] =
    useState(false);

  const [blockVersionNumber, setBlockVersionNumber] = useState(blockVersion);

  const {responseFeedbacks} = useBlocks();

  const [
    fetchBlockVersionByIdLazy,
    {
      isError: isFetchBlockVersionByIdError,
      isLoading: isLoadingBlockVersionById,
      data: fetchBlockVersionByIdData,
      isFetching: isFetchingBlockVersionById,
    },
  ] = useFetchBlockVersionByIdLazy();
  const [
    createBlockVersion,
    {
      isError: isCreateBlockVersionError,
      isSuccess: isCreateBlockVersionSuccess,
      isLoading: isCreatingBlockVersion,
      data: createBlockVersionData,
      status: createBlockVersionStatus,
      error: createBlockVersionError,
      reset: resetCreateBlockVersionStatus,
    },
  ] = useCreateBlockVersion();

  const [
    deleteBlockVersion,
    {
      status: deleteBlockVersionStatus,
      isError: isDeleteBlockVersionError,
      isSuccess: isDeleteBlockVersionSuccess,
      error: deleteBlockVersionError,
      reset: resetDeleteBlockVersionStatus,
    },
  ] = useDeleteBlockVersion();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    reset: resetForm,
    formState: {errors: formErrors},
  } = useForm<FormValues>({
    defaultValues: {
      header: '',
      content: '',
      tags: [],
    },
    mode: 'onBlur',
  });

  const history = useHistory();

  // Refetch block data on version change
  useEffect(() => {
    fetchBlockVersionByIdLazy(
      {
        blockId,
        version: blockVersionNumber,
      },
      false,
    );
  }, [blockVersionNumber, block_id]);

  // Filling the inputs with current block version data
  useEffect(() => {
    if (fetchBlockVersionByIdData) {
      const {
        block: {header, tags},
        block_content: {content},
      } = fetchBlockVersionByIdData;

      resetForm({
        header,
        content,
        tags: tags.map((tag) => ({label: tag.name, value: String(tag.id)})),
      });
    }
  }, [fetchBlockVersionByIdData]);

  // Opening feedback modal for API responses
  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isCreateBlockVersionSuccess ||
      isCreateBlockVersionError ||
      isDeleteBlockVersionSuccess ||
      isDeleteBlockVersionError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createBlockVersionStatus, deleteBlockVersionStatus]);

  const isLoading = isLoadingBlockVersionById || isFetchingBlockVersionById;
  const createBlockVersionErrorData = (createBlockVersionError as ResponseError)
    ?.data as SimpleErrorPayload;
  const deleteBlockVersionErrorData = (deleteBlockVersionError as ResponseError)
    ?.data as SimpleErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreateBlockVersionError || isCreateBlockVersionSuccess) {
      return 'createBlockVersion';
    }
    if (isDeleteBlockVersionError || isDeleteBlockVersionSuccess) {
      return 'deleteBlockVersion';
    }

    return 'default';
  }

  function getResponseMessage(): string {
    if (isCreateBlockVersionSuccess) {
      return responseFeedbacks.createBlockVersion.success.message;
    }
    if (isCreateBlockVersionError) {
      return createBlockVersionErrorData?.detail;
    }
    if (isDeleteBlockVersionSuccess) {
      return responseFeedbacks.deleteBlockVersion.success.message;
    }
    if (isDeleteBlockVersionError) {
      return deleteBlockVersionErrorData?.detail;
    }

    return '';
  }

  function getResponseCallback() {
    if (isCreateBlockVersionSuccess) {
      return () => resetFormData(createBlockVersionData?.version ?? 1);
    }
    if (isDeleteBlockVersionSuccess) {
      return () => history.push(AuthRoutes.blockList);
    }

    return () => setIsResponseFeedbackModalOpen(false);
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback =
      (isCreateBlockVersionSuccess || isDeleteBlockVersionSuccess) &&
      !isCreateBlockVersionError &&
      !isDeleteBlockVersionError;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();
    const responseCallback = getResponseCallback();

    function onCloseModalCallback() {
      responseCallback?.();
      resetDeleteBlockVersionStatus();
      resetCreateBlockVersionStatus();
    }

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseModalCallback,
      onClose: onCloseModalCallback,
    };
  }

  function onClickDeleteBlockVersionModalButton() {
    deleteBlockVersion({
      blockId,
      blockVersionNumber: blockVersion,
    });
    setIsDeleteBlockVersionModalOpen(false);
  }

  function onFormSubmit() {
    const {content} = getFormValues();

    createBlockVersion({blockId, content});
  }

  function resetFormData(blockVersion: number) {
    history.push(`/blocos/${block_id}/${blockVersion}`);

    setIsResponseFeedbackModalOpen(false);
    setBlockVersionNumber(blockVersion);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingForm numberOfInputs={3} />
          </div>
        )}

        {isFetchBlockVersionByIdError && !isLoading && (
          <NoResultsWarning
            description="Parece que essa versão do bloco não está disponível"
            buttonText="Voltar aos blocos"
            buttonIcon="arrow-left"
            onButtonClick={() => history.push(AuthRoutes.blockList)}
          />
        )}

        {fetchBlockVersionByIdData &&
          !isFetchBlockVersionByIdError &&
          !isLoading && (
            <>
              <PageHeader
                title="Editar bloco"
                onGoBackButtonClick={() => history.push(AuthRoutes.blockList)}
                rightElement={
                  <div className={styles.rightButtonsContainer}>
                    <Button
                      text="Remover versão"
                      kind="negativeSecondary"
                      userRole="teacher"
                      type="button"
                      onClick={() => setIsDeleteBlockVersionModalOpen(true)}
                    />
                    <NewButton
                      text="Criar nova versão"
                      kind="primary"
                      userRole="teacher"
                      type="submit"
                      isLoading={isCreatingBlockVersion}
                      disabled={isCreatingBlockVersion || !isEmpty(formErrors)}
                    />
                  </div>
                }
              />

              <fieldset>
                <div
                  className={styles.blockVersionSelectWrapper}
                  data-testid="blockVersionSelect"
                >
                  <label htmlFor="blockVersion">Versão a visualizar</label>
                  <Select
                    styles={{
                      ...reactSelectStyles,
                      container: (provided) => ({
                        ...provided,
                        minWidth: 300,
                      }),
                    }}
                    isMulti={false}
                    onChange={(event: SingleValue<SelectOption>) => {
                      resetFormData(Number(event?.value));
                    }}
                    options={fetchBlockVersionByIdData.block.versions
                      .map(({version}) => ({
                        label: `Versão ${version}`,
                        value: version,
                      }))
                      .reverse()}
                    value={
                      {
                        label: `Versão ${block_version}`,
                        value: String(blockVersion),
                      } as SelectOption
                    }
                    placeholder="Selecione a versão do bloco"
                    name="blockVersion"
                    inputId="blockVersion"
                  />
                </div>

                <Controller
                  control={formControl}
                  name="header"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="header"
                      labelText="Header"
                      placeholder="Ex: O nível de formalidade"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      disabled
                    />
                  )}
                />

                <Controller
                  control={formControl}
                  name="content"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <TextArea
                      id="content"
                      labelText="Conteúdo"
                      placeholder="Ex: O narrador pode utilizar linguagem coloquial, porém de maneira monitorada, os desvios devem se manter num padrão sintático adequado. Evite gírias, use expressões que sejam de entendimento geral. Em hipótese nenhuma use linguagem das redes sociais como abreviações."
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value}
                      errorMessage={
                        formErrors?.content?.type === 'required'
                          ? 'Este campo não pode ficar em branco'
                          : undefined
                      }
                      resizable
                    />
                  )}
                />

                <label htmlFor="tag">Tags</label>
                <Controller
                  control={formControl}
                  name="tags"
                  rules={{
                    required: false,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <>
                      <Select
                        styles={{
                          ...reactSelectStyles,
                          container: (provided) => ({
                            ...provided,
                            maxWidth: 300,
                          }),
                        }}
                        onChange={onChange}
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Selecione a tag"
                        name={name}
                        inputId="tags"
                        isDisabled
                      />
                    </>
                  )}
                />
              </fieldset>
            </>
          )}
      </form>

      {isDeleteBlockVersionModalOpen && (
        <DeletionModal
          isOpen
          message="Deseja mesmo excluir essa versão?"
          onClickDelete={onClickDeleteBlockVersionModalButton}
          onClose={() => setIsDeleteBlockVersionModalOpen(false)}
          subtitle="Tem certeza?"
          title="Excluir versão"
        />
      )}

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default BlockEdition;
