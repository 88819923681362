import {Checkbox, Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import isValidJSON from 'utils/isValidJSON';
import {
  useCreateTextMarker,
  useValidateTextMarker,
} from 'store/reducers/textMarkers';
import FeedbackModal from 'components/FeedbackModal';
import TextArea from 'components/TextArea';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import Select from 'react-select';
import SelectOption from 'utils/types/SelectOption';
import {useFetchTextMarkerCategories} from 'store/reducers/textMarkerCategories';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './TextMarkerCreation.module.scss';

interface FormValues {
  category: SelectOption;
  subcategory: string;
  pattern: string;
  label: string;
  pattern_spacy: string;
  antipatterns: string;
  suggestion: string;
  sentences: string;
  identificationStart: string;
  identificationEnd: string;
  comment: string;
  isCaseSensitive: boolean;
}

const TextMarkerCreation: React.FC = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const {isFetching, isLoading, data} = useFetchTextMarkerCategories({
    limit: 1000,
  });
  const [
    createTextMarker,
    {
      isError: isCreateTextMarkerError,
      isLoading: isCreateTextMarkerLoading,
      isSuccess: isCreateTextMarkerSuccess,
      error: createTextMarkerError,
    },
  ] = useCreateTextMarker();
  const [
    validateTextMarker,
    {
      isError: isValidateTextMarkerError,
      isLoading: isValidatingTextMarker,
      isSuccess: isValidateTextMarkerSuccess,
      data: validateTextMarkerData,
    },
  ] = useValidateTextMarker();

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    trigger,
    formState: {errors},
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    if (isCreateTextMarkerError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateTextMarkerError]);

  useEffect(() => {
    if (isCreateTextMarkerSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isCreateTextMarkerSuccess]);

  const createTextMarkerErrorData = (createTextMarkerError as ResponseError)
    ?.data as SimpleErrorPayload;

  const {pattern_spacy, antipatterns, sentences} = watch();

  const isValidateButtonDisabled =
    !pattern_spacy ||
    !sentences ||
    !antipatterns ||
    Boolean(errors.pattern_spacy) ||
    Boolean(errors.antipatterns);

  function triggerTextMarkerValidation() {
    const {
      sentences,
      pattern,
      suggestion,
      isCaseSensitive,
      pattern_spacy,
      antipatterns,
      identificationStart,
      identificationEnd,
    } = getValues();

    trigger(['pattern_spacy', 'antipatterns'], {shouldFocus: true});
    validateTextMarker({
      text: sentences,
      pattern,
      case_sensitive: isCaseSensitive,
      pattern_spacy: JSON.parse(pattern_spacy),
      antipatterns: JSON.parse(antipatterns),
      suggestion,
      on_match_start: parseInt(identificationStart, 10),
      on_match_end: parseInt(identificationEnd, 10),
    });
  }

  function onSubmit() {
    const {
      category,
      subcategory,
      comment,
      label,
      isCaseSensitive,
      suggestion,
      identificationStart,
      pattern,
      pattern_spacy,
      antipatterns,
      identificationEnd,
    } = getValues();

    const serializedTextMarkerData = {
      category_id: parseInt(category?.value, 10),
      sub_category: subcategory,
      label,
      comment,
      version: {
        pattern,
        pattern_spacy: JSON.parse(pattern_spacy),
        antipatterns: JSON.parse(antipatterns),
        case_sensitive: !!isCaseSensitive,
        suggestion,
        on_match_start: parseInt(identificationStart, 10),
        on_match_end: parseInt(identificationEnd, 10),
      },
    };

    createTextMarker(serializedTextMarkerData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Criar marcador textual</h1>
          </div>

          <NewButton
            text="Criar"
            kind="primary"
            userRole="teacher"
            type="submit"
            isLoading={isCreateTextMarkerLoading}
            disabled={isCreateTextMarkerLoading}
          />
        </header>

        {!isFetching && !isLoading && data ? (
          <div className={styles.fieldset}>
            <div className={styles.selectWrapper}>
              <label htmlFor="category">Categoria</label>
              <Controller
                control={control}
                name="category"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    onChange={onChange}
                    options={data.results.map(({id, name}) => ({
                      label: name,
                      value: String(id),
                    }))}
                    ref={ref}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Selecione a categoria"
                    name={name}
                    inputId="category"
                  />
                )}
              />
              {errors?.category ? (
                <label htmlFor="category" className={styles.errorLabel}>
                  Este campo não pode ficar em branco
                </label>
              ) : null}
            </div>

            <Controller
              control={control}
              name="subcategory"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="subcategory"
                  labelText="Subcategoria"
                  placeholder="Ex: Subcategoria X"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={
                    errors?.subcategory?.type === 'required'
                      ? 'Este campo não pode ficar em branco'
                      : undefined
                  }
                />
              )}
            />

            <Controller
              control={control}
              name="label"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="label"
                  labelText="Etiqueta"
                  placeholder="Ex: Etiqueta X"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={
                    errors?.label?.type === 'required'
                      ? 'Este campo não pode ficar em branco'
                      : undefined
                  }
                />
              )}
            />

            <Controller
              control={control}
              name="comment"
              rules={{
                required: true,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <TextArea
                  id="comment"
                  labelText="Comentário"
                  placeholder="Ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={
                    errors?.comment?.type === 'required'
                      ? 'Este campo não pode ficar em branco'
                      : undefined
                  }
                  resizable
                />
              )}
            />

            <Controller
              control={control}
              name="pattern"
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="pattern"
                  labelText="Padrão"
                  placeholder={`Ex: [{"LOWER": "mais"},{"LOWER": {"REGEX": "alt[ao]"}}]`}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                />
              )}
            />

            <Controller
              control={control}
              name="pattern_spacy"
              rules={{
                required: true,
                validate: isValidJSON,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <TextArea
                  id="pattern_spacy"
                  labelText="Padrão SpaCy"
                  placeholder={`Ex: [{"LOWER": {"REGEX": "alt[ao]"}},{"LOWER": "astral"}]`}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={
                    errors?.pattern_spacy?.type === 'required'
                      ? 'Este campo não pode ficar em branco'
                      : errors?.pattern_spacy?.type === 'validate'
                      ? 'Padrão SpaCy inválido, verifique o formato do JSON'
                      : undefined
                  }
                  resizable
                />
              )}
            />
            <Controller
              control={control}
              name="antipatterns"
              rules={{
                required: true,
                validate: isValidJSON,
              }}
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <TextArea
                  id="antipatterns"
                  labelText="Antipadrões"
                  placeholder={`Ex: [[{"LOWER": {"REGEX": "alt[ao]"}},{"LOWER": "astral"}]]`}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                  errorMessage={
                    errors?.antipatterns?.type === 'required'
                      ? 'Este campo não pode ficar em branco'
                      : errors?.antipatterns?.type === 'validate'
                      ? 'Antipadrões inválidos, verifique o formato do JSON'
                      : undefined
                  }
                  resizable
                />
              )}
            />
            <Controller
              control={control}
              name="identificationStart"
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="identificationStart"
                  labelText="Início da identificação"
                  placeholder="Ex: 1"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                />
              )}
            />

            <Controller
              control={control}
              name="identificationEnd"
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="identificationEnd"
                  labelText="Fim da identificação"
                  placeholder="Ex: 2"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                />
              )}
            />

            <Controller
              control={control}
              name="suggestion"
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <InputText
                  id="suggestion"
                  labelText="Sugestão"
                  placeholder="Ex: Procure termos mais específicos"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={value ?? ''}
                />
              )}
            />

            <Controller
              control={control}
              name="isCaseSensitive"
              render={({field: {name, onBlur, onChange, ref, value}}) => (
                <Checkbox
                  id="isCaseSensitive"
                  labelText="É case sensitive"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  value={String(value)}
                />
              )}
            />

            <div className={styles.sentenceValidationWrapper}>
              <div>
                <Controller
                  control={control}
                  name="sentences"
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <InputText
                      id="sentences"
                      labelText="Sentença"
                      placeholder="Ex: beba agua"
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value ?? ''}
                    />
                  )}
                />
              </div>

              <NewButton
                text="Validar"
                icon={['fas', 'search']}
                isLoading={isValidatingTextMarker}
                disabled={isValidatingTextMarker || isValidateButtonDisabled}
                onClick={triggerTextMarkerValidation}
              />
            </div>

            {validateTextMarkerData && isValidateTextMarkerSuccess && (
              <div className={styles.resultsWrapper}>
                <span className={styles.result}>
                  {validateTextMarkerData.suggestion ? (
                    <>
                      <Icon icon={['fas', 'check']} color="#00c341" size="lg" />
                      Identificado
                    </>
                  ) : (
                    <>
                      <Icon icon={['fas', 'times']} color="#f24e54" size="lg" />
                      Não identificado
                    </>
                  )}
                </span>
              </div>
            )}

            {isValidatingTextMarker && (
              <div className={styles.loadingRowWrapper}>
                <LoadingRow height={25} />
              </div>
            )}

            {isValidateTextMarkerError && (
              <label className={styles.errorLabel} htmlFor="sentences">
                Ocorreu um erro ao validar o marcador.
                <br /> Por favor, verifique os campos e tente novamente
              </label>
            )}
          </div>
        ) : (
          <LoadingForm numberOfInputs={10} />
        )}
      </form>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Criar marcador textual"
        subtitle={isCreateTextMarkerError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreateTextMarkerError
            ? createTextMarkerErrorData.detail
            : 'O marcador foi criado.'
        }
        onClose={
          isCreateTextMarkerError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.textMarkerList)
        }
        onButtonClick={
          isCreateTextMarkerError
            ? () => setIsFeedbackModalOpen(false)
            : () => history.push(AuthRoutes.textMarkerList)
        }
        buttonText={
          isCreateTextMarkerError ? 'Tentar novamente' : 'Voltar aos marcadores'
        }
        iconName={isCreateTextMarkerError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default TextMarkerCreation;
