import {Icon, InputNumber, NewButton, StarRating} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {useFetchPrompts} from 'store/reducers/prompts';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import TextArea from 'components/TextArea';
import PromptPreviewModal from 'components/PromptPreviewModal';
import Select from 'react-select';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import SelectOption from 'utils/types/SelectOption';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {isEmpty} from 'lodash';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import GPT4Logo from 'images/GPT4_logo.png';
import {
  useAnnotateAnswerResults,
  useBookmarkAnswer,
  useFetchAnswerByIdLazy,
  useCreateAnswer,
} from 'store/reducers/answers';
import {useAnswers} from 'features/useAnswers';
import PageWrapper from '../../components/PageWrapper';
import styles from './AnswerPlayground.module.scss';
import LoadingResponse from './LoadingResponse';

interface FormValues {
  promptId: SelectOption;
  promptVersion: SelectOption;
  testType: SelectOption;
  text: string;
  compositionId: number;
  comments: string;
}

const FETCH_ANSWER_INTERVAL_IN_MILLISECONDS = 10000;

function AnswerPlayground(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [answerRating, setAnswerRating] = useState(0);
  const [isOpenPromptPreview, setIsOpenPromptPreview] = useState(false);
  const [answerData, setAnswerData] = useState<
    undefined | APIComponents['schemas']['Answer']
  >(undefined);
  const [fetchAnswerDataIntervalId, setFetchAnswerDataIntervalId] = useState<
    undefined | NodeJS.Timeout
  >(undefined);

  const {responseFeedbacks, getFormFieldError} = useAnswers();

  const {data: promptList, isLoading: isLoadingPrompts} = useFetchPrompts({
    limit: 1000,
  });
  const [
    createAnswer,
    {
      isLoading: isCreatingAnswer,
      isError: isCreateAnswerError,
      isSuccess: isCreateAnswerSuccess,
      error: createAnswerError,
      status: createAnswerStatus,
      reset: resetCreateAnswer,
      data: answerCreateData,
    },
  ] = useCreateAnswer();
  const [
    bookmarkAnswer,
    {
      isLoading: isBookmarkingAnswer,
      isError: isBookmarkAnswerError,
      isSuccess: isBookmarkAnswerSuccess,
      error: bookmarkAnswerError,
      status: bookmarkAnswerStatus,
      reset: resetBookmarkAnswer,
    },
  ] = useBookmarkAnswer();
  const [
    annotateAnswerResults,
    {
      isLoading: isAnnotatingAnswerResults,
      isError: isAnnotateAnswerResultsError,
      isSuccess: isAnnotateAnswerResultsSuccess,
      error: annotateAnswerResultsError,
      status: annotateAnswerResultsStatus,
      reset: resetAnnotateAnswerResults,
    },
  ] = useAnnotateAnswerResults();

  const [
    fetchAnswerByIdLazy,
    {
      isLoading: isLoadingAnswerById,
      isFetching: isFetchingAnswerById,
      isError: isFetchAnswerByIdError,
      data: answerDataFromFetch,
    },
  ] = useFetchAnswerByIdLazy();

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    formState: {errors: formErrors, isSubmitted: isFormSubmitted},
    reset: resetForm,
    watch: formWatch,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const [
    promptIdOption,
    promptVersionNumberOption,
    testType,
    text,
    compositionId,
  ] = formWatch([
    'promptId',
    'promptVersion',
    'testType',
    'text',
    'compositionId',
  ]);
  const promptId = Number(promptIdOption?.value);
  const promptVersionNumber = Number(
    promptVersionNumberOption?.label.split(' ')[1],
  );

  const isLoadingAnswer =
    isFetchingAnswerById ||
    isLoadingAnswerById ||
    isCreatingAnswer ||
    (!!answerCreateData?.id && !answerData?.content);

  const history = useHistory();

  useEffect(() => {
    setAnswerData(answerDataFromFetch);
  }, [answerDataFromFetch]);

  useEffect(() => {
    if (isFormSubmitted) {
      setAnswerData(undefined);
      resetForm({
        testType,
        compositionId,
        text,
        promptId: getFormValues('promptId'),
        promptVersion: getFormValues('promptVersion'),
      });
    }
  }, [promptId, promptVersionNumber, testType, text, compositionId]);

  // Opening feedback modal for API responses
  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      (isCreateAnswerSuccess && !isLoadingAnswer) ||
      isCreateAnswerError ||
      isFetchAnswerByIdError ||
      isBookmarkAnswerError ||
      isBookmarkAnswerSuccess ||
      isAnnotateAnswerResultsError ||
      isAnnotateAnswerResultsSuccess;

    if (shouldOpenResponseFeedbackModal && !isLoadingAnswer) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [
    isLoadingAnswer,
    createAnswerStatus,
    bookmarkAnswerStatus,
    annotateAnswerResultsStatus,
  ]);

  const answerDataWithNewRef =
    !!answerData?.id && !answerData?.content ? {...answerData} : answerData;

  useEffect(() => {
    if (
      !isCreatingAnswer &&
      !isLoadingAnswerById &&
      !!answerCreateData?.id &&
      !answerData?.content &&
      !fetchAnswerDataIntervalId
    ) {
      const interval = setInterval(() => {
        fetchAnswerByIdLazy(answerCreateData.id, false);
      }, FETCH_ANSWER_INTERVAL_IN_MILLISECONDS);

      setFetchAnswerDataIntervalId(interval);
    }
    if (answerData?.content && fetchAnswerDataIntervalId) {
      clearInterval(fetchAnswerDataIntervalId);
      setFetchAnswerDataIntervalId(undefined);
    }
  }, [answerCreateData, answerDataWithNewRef, isLoadingAnswer]);

  const createAnswerErrorData = (createAnswerError as ResponseError)
    ?.data as SimpleErrorPayload;
  const annotateAnswerResultsErrorData = (
    annotateAnswerResultsError as ResponseError
  )?.data as SimpleErrorPayload;
  const bookmarkAnswerErrorData = (bookmarkAnswerError as ResponseError)
    ?.data as SimpleErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreateAnswerError || isCreateAnswerSuccess) {
      return 'createAnswer';
    }
    if (isBookmarkAnswerError || isBookmarkAnswerSuccess) {
      return 'bookmarkAnswer';
    }
    if (isAnnotateAnswerResultsError || isAnnotateAnswerResultsSuccess) {
      return 'annotateAnswerResults';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreateAnswerSuccess) {
      return responseFeedbacks.createAnswer.success.message;
    }
    if (isCreateAnswerError) {
      return (
        createAnswerErrorData?.detail ??
        responseFeedbacks.createAnswer.error.message
      );
    }
    if (isAnnotateAnswerResultsSuccess) {
      return responseFeedbacks.annotateAnswerResults.success.message;
    }
    if (isAnnotateAnswerResultsError) {
      return annotateAnswerResultsErrorData?.detail;
    }
    if (isBookmarkAnswerSuccess) {
      return responseFeedbacks.bookmarkAnswer.success.message;
    }
    if (isBookmarkAnswerError) {
      return bookmarkAnswerErrorData?.detail;
    }

    return '';
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback =
      isCreateAnswerSuccess ||
      isAnnotateAnswerResultsSuccess ||
      isBookmarkAnswerSuccess;
    const feedbackEntity = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[feedbackEntity]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage ?? '',
      buttonText: 'Fechar',
      onButtonClick: onCloseResponseFeedbackModal,
      onClose: onCloseResponseFeedbackModal,
    };
  }

  function onFormSubmit() {
    const {promptId, promptVersion, text, compositionId} = getFormValues();

    const serializedFormValues: APIComponents['schemas']['AnswerPlaygroundPost'] =
      {
        prompt_template_id: Number(promptId.value),
        prompt_template_version_id: Number(promptVersion.value),
        composition_string:
          getFormValues().testType.value === 'text'
            ? text
            : Number(compositionId),
      };

    createAnswer(serializedFormValues);
  }

  function onCloseResponseFeedbackModal() {
    resetCreateAnswer();
    resetBookmarkAnswer();
    resetAnnotateAnswerResults();
    setIsResponseFeedbackModalOpen(false);
  }

  const selectedPromptId = Number(formWatch().promptId?.value ?? 0);
  const promptVersionOptions = useMemo(
    () =>
      promptList?.results
        .find(({id}) => id === selectedPromptId)
        ?.versions.map(({id, version}) => ({
          label: `Versão ${version}`,
          value: id,
        })),
    [selectedPromptId],
  );

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <header>
          <div className={styles.buttonContainer}>
            <button type="button" onClick={history.goBack}>
              <Icon icon="chevron-left" color="#414449" />
            </button>

            <h1>Playground de respostas</h1>
          </div>

          <div className={styles.rightButtons}>
            <NewButton
              text="Gerar resposta"
              kind="primary"
              userRole="teacher"
              type="submit"
              icon="comment-alt-plus"
              isLoading={isLoadingAnswer}
              disabled={
                isFormSubmitted || isLoadingAnswer || !isEmpty(formErrors)
              }
            />

            {!!answerData?.content && (
              <NewButton
                text="Favoritar resposta"
                kind="primary"
                userRole="teacher"
                type="button"
                icon="bookmark"
                isLoading={isBookmarkingAnswer}
                onClick={() => bookmarkAnswer(answerData?.id)}
                disabled={isBookmarkingAnswer}
              />
            )}
          </div>
        </header>

        <section>
          <fieldset className={styles.fieldset}>
            {isLoadingPrompts && <LoadingForm numberOfInputs={2} />}

            {!isLoadingPrompts && (
              <>
                <div className={styles.promptSelectWrapper}>
                  <div className={styles.promptHeader}>
                    <label htmlFor="promptId">Prompt</label>
                    <button
                      type="button"
                      className={styles.promptVisualizeButton}
                      disabled={!promptId || !promptVersionNumber}
                      onClick={() => setIsOpenPromptPreview(true)}
                    >
                      <Icon icon={['fas', 'eye']} color="#5d3d85" />
                    </button>
                  </div>
                  <div>
                    <Controller
                      control={formControl}
                      name="promptId"
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <>
                          <Select
                            styles={{
                              ...reactSelectStyles,
                              container: (provided) => ({
                                ...provided,
                                minWidth: 300,
                              }),
                            }}
                            onChange={onChange}
                            options={promptList?.results.map(({id, title}) => ({
                              label: title,
                              value: id,
                            }))}
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            placeholder="Selecione o prompt"
                            name={name}
                            inputId="promptId"
                          />
                        </>
                      )}
                    />

                    <Controller
                      control={formControl}
                      name="promptVersion"
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <>
                          <Select
                            styles={{
                              ...reactSelectStyles,
                              container: (provided) => ({
                                ...provided,
                                minWidth: 120,
                              }),
                            }}
                            onChange={onChange}
                            options={promptVersionOptions?.reverse()}
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            placeholder="Versão"
                            name={name}
                            inputId="promptVersion"
                          />
                        </>
                      )}
                    />
                  </div>
                  {formErrors?.promptId && (
                    <label htmlFor="promptId" className={styles.error}>
                      O prompt não pode ficar em branco
                    </label>
                  )}
                  {formErrors?.promptVersion && (
                    <label htmlFor="promptVersion" className={styles.error}>
                      A versão do prompt não pode ficar em branco
                    </label>
                  )}
                </div>

                <label htmlFor="testType">Tipo de teste</label>
                <Controller
                  control={formControl}
                  name="testType"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <>
                      <Select
                        styles={{
                          ...reactSelectStyles,
                          container: (provided) => ({
                            ...provided,
                            maxWidth: 300,
                          }),
                        }}
                        onChange={onChange}
                        options={[
                          {
                            label: 'Texto',
                            value: 'text',
                          },
                          {
                            label: 'ID da redação',
                            value: 'compositionId',
                          },
                        ]}
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Selecione o tipo de teste"
                        name={name}
                        inputId="testType"
                      />
                    </>
                  )}
                />
                {formErrors?.testType && (
                  <label htmlFor="testType" className={styles.error}>
                    O tipo de teste não pode ficar em branco
                  </label>
                )}

                {formWatch().testType?.value === 'text' && (
                  <Controller
                    control={formControl}
                    name="text"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, ref, value}}) => (
                      <TextArea
                        id="text"
                        labelText="Texto"
                        placeholder="Ex: No filme estadunidense “Coringa”, o personagem principal, Arthur Fleck, sofre de um transtorno mental que o faz ter episódios de riso exagerado e descontrolado em público, motivo pelo qual é frequentemente atacado nas ruas. Em consonância com a realidade de Arthur, está a de muitos cidadãos, já que o estigma associado às doenças mentais na sociedade brasileira ainda configura um desafio a ser sanado. Isso ocorre, seja pela negligência governamental nesse âmbito, seja pela discriminação desta classe por parcela da população verde-amarela. Dessa maneira, é imperioso que essa chaga social seja resolvida, a fim de que o longa norte-americano não mais reflita o contexto atual da nação.
                        Nessa perspectiva, acerca da lógica referente aos transtornos da mente, é válido retomar o aspecto supracitado quanto à omissão estatal neste caso. Segundo a OMS (Organização Mundial da Saúde), o Brasil é o país que apresenta o maior número de casos de depressão da América Latina e, mesmo diante desse cenário alarmante, os tratamentos às doenças mentais, quando oferecidos, não são, na maioria das vezes, eficazes. Isso acontece pela falta de investimento público em..."
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value ?? ''}
                        errorMessage={getFormFieldError(formErrors?.text?.type)}
                        resizable
                      />
                    )}
                  />
                )}

                {formWatch().testType?.value === 'compositionId' && (
                  <Controller
                    control={formControl}
                    name="compositionId"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, onBlur, onChange, ref, value}}) => (
                      <InputNumber
                        id="compositionId"
                        labelText="ID da redação"
                        placeholder="Ex: 1585"
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value ?? ''}
                        errorMessage={getFormFieldError(
                          formErrors?.compositionId?.type,
                        )}
                      />
                    )}
                  />
                )}
              </>
            )}
          </fieldset>

          <aside>
            {isLoadingAnswer && (
              <>
                <div className={styles.loadingMessageWrapper}>
                  <img src={GPT4Logo} alt="chatgpt" />
                  <p>
                    Aguardando a resposta do GPT-4, isso pode demorar, mas não
                    se preocupe, já guardamos os dados e também é possível de
                    acompanhar pela listagem de respostas =)
                  </p>
                </div>

                <LoadingResponse />
              </>
            )}

            {!isLoadingAnswer && !!answerData?.content && (
              <>
                <h2>Resposta</h2>

                <TextArea
                  id="answer_content"
                  value={answerData.content}
                  disabled
                  resizable
                />

                <div className={styles.ratingWrapper}>
                  <label>Nota</label>
                  <StarRating
                    amountOfStars={5}
                    activeStars={answerRating}
                    onClick={(rating) => setAnswerRating(rating)}
                    smallerRatingLabel="1"
                    biggerRatingLabel="5"
                    size="2x"
                  />
                </div>

                <Controller
                  control={formControl}
                  name="comments"
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <TextArea
                      id="comments"
                      labelText="Anotações"
                      placeholder="Ex: O GPT não conseguiu identificar o..."
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      value={value ?? ''}
                      errorMessage={getFormFieldError(
                        formErrors?.comments?.type,
                      )}
                      resizable
                    />
                  )}
                />

                <NewButton
                  text="Anotar resultados"
                  kind="primary"
                  userRole="teacher"
                  type="button"
                  icon="marker"
                  isLoading={isAnnotatingAnswerResults}
                  onClick={() =>
                    annotateAnswerResults({
                      answerId: answerData.id,
                      score: answerRating,
                      annotation: getFormValues().comments,
                    })
                  }
                  disabled={!answerRating || isAnnotatingAnswerResults}
                />
              </>
            )}
          </aside>
        </section>
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}

      {isOpenPromptPreview && promptId && promptVersionNumber && (
        <PromptPreviewModal
          isOpen
          onClose={() => setIsOpenPromptPreview(false)}
          promptId={promptId}
          promptVersionNumber={promptVersionNumber}
        />
      )}
    </PageWrapper>
  );
}

export default AnswerPlayground;
