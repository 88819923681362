import {JsonView, allExpanded, defaultStyles} from 'react-json-view-lite';
import {InputText, NewButton} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {usePredictPrompt} from 'store/reducers/generativePrompts';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import {generativePromptGenres} from 'utils/constants/genres';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import SelectOption from 'utils/types/SelectOption';
import {isEmpty} from 'lodash';
import PageHeader from 'components/PageHeader';
import {useGenerativePlayground} from 'features/useGenerativePlayground';
import PageWrapper from '../../components/PageWrapper';
import styles from './GenerativePlayground.module.scss';
import 'react-json-view-lite/dist/index.css';

interface FormValues {
  type: SelectOption;
  essay: string;
}

function GenerativePromptPlayground(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const {responseFeedbacks, getFormFieldError} = useGenerativePlayground();

  const [
    predictPrompt,
    {
      isError: isPredictPromptError,
      isLoading: isLoadingPredictPrompt,
      isSuccess: isPredictPromptSuccess,
      error: predictPromptError,
      status: predictPromptStatus,
      data: predictPromptData,
    },
  ] = usePredictPrompt();

  const {
    getValues: getFormValues,
    handleSubmit: handleFormSubmit,
    control: formControl,
    formState: {errors: formErrors},
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    const shouldOpenResponseFeedbackModal = isPredictPromptError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [predictPromptStatus]);

  const predictPromptErrorData = (predictPromptError as ResponseError)
    ?.data as FormErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isPredictPromptError || isPredictPromptSuccess) {
      return 'sendToPlayground';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isPredictPromptError) {
      const errorMessages = Object.entries(predictPromptErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isPredictPromptSuccess) {
      return responseFeedbacks.sendToPlayground.success.message;
    }

    return '';
  }

  function onResposeFeedbackClose() {
    setIsResponseFeedbackModalOpen(false);
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isPredictPromptSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: !isSuccessFeedback,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onResposeFeedbackClose,
      onClose: onResposeFeedbackClose,
    };
  }

  function onFormSubmit() {
    const {type, essay} = getFormValues();

    const serializedFormValues: APIComponents['schemas']['GenerativePredict'] =
      {
        essay_ids: [essay],
        model_id: 'gpt3',
        type: type.value.toLowerCase() as 'enem' | 'gm',
      };

    predictPrompt(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Playground generativo"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <div className={styles.rightContent}>
              <NewButton
                text="Enviar"
                kind="primary"
                userRole="teacher"
                type="submit"
                isLoading={isLoadingPredictPrompt}
                disabled={isLoadingPredictPrompt || !isEmpty(formErrors)}
              />
            </div>
          }
        />
        <section>
          <fieldset className={styles.fieldset}>
            <div className={styles.fieldsWrapper}>
              <label htmlFor="type">Tipo</label>
              <Controller
                control={formControl}
                name="type"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <>
                    <Select
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          minWidth: 300,
                        }),
                      }}
                      onChange={onChange}
                      options={generativePromptGenres.map((genre) => ({
                        label: genre,
                        value: genre,
                      }))}
                      ref={ref}
                      onBlur={onBlur}
                      value={value}
                      placeholder="ENEM/GM"
                      name={name}
                      inputId="type"
                    />
                  </>
                )}
              />

              {formErrors?.type && (
                <label htmlFor="type" className={styles.error} id="type-error">
                  Esse campo não pode ficar em branco
                </label>
              )}

              <Controller
                control={formControl}
                name="essay"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="title"
                    labelText="Id da redação"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={getFormFieldError(formErrors?.essay?.type)}
                  />
                )}
              />
            </div>
          </fieldset>

          <aside>
            {isPredictPromptSuccess && predictPromptData && (
              <JsonView
                data={predictPromptData}
                shouldExpandNode={allExpanded}
                style={defaultStyles}
              />
            )}
          </aside>
        </section>
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
    </PageWrapper>
  );
}

export default GenerativePromptPlayground;
