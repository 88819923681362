import {FieldError} from 'react-hook-form';

export function useScheduling() {
  const responseFeedbacks = {
    createManualSchedule: {
      title: 'Criar execução manual',
      error: {
        message: 'Ocorreu um erro ao criar a execução',
      },
      success: {
        message:
          'Execução agendada. Em breve o resultado será exibido na listagem',
      },
    },
    default: {
      title: 'Requisição',
      error: {
        message: 'Erro',
      },
      success: {
        message: 'Sucesso',
      },
    },
  };

  function getFormFieldError(
    errorType: FieldError['type'] | undefined,
  ): string | undefined {
    if (errorType === 'required') {
      return 'Este campo não pode ficar em branco';
    }

    return;
  }

  return {
    responseFeedbacks,
    getFormFieldError,
  };
}
