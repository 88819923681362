import skeleton from 'components/LoadingComponents/Skeleton.module.scss';

export interface LoadingRowProps {
  width?: number | string;
  height?: number | string;
}

const LoadingRow: React.FC<LoadingRowProps> = ({height = 30, width = 300}) => {
  return (
    <div
      className={skeleton.shimmerEffect}
      data-testid="loading-row"
      style={{width: '100%', maxWidth: width, height}}
    />
  );
};

export default LoadingRow;
