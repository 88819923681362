import PageWrapper from 'components/PageWrapper';
import NotFoundIllustration from 'images/not-found.svg';
import {NewButton} from 'letrus-ui';
import {useHistory} from 'react-router-dom';
import {AuthRoutes} from 'routes';
import styles from './NotFound.module.scss';

const NotFound: React.FC = () => {
  const history = useHistory();

  return (
    <PageWrapper>
      <div className={styles.container}>
        <img src={NotFoundIllustration} alt="Ilustração de não encontrado" />

        <h1>Página não encontrada!</h1>

        <div>
          <NewButton text="Voltar" icon="arrow-left" onClick={history.goBack} />
          <NewButton
            text="Dashboard"
            kind="primary"
            userRole="teacher"
            icon="home"
            onClick={() => history.push(AuthRoutes.homepage)}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
