import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import EntityEvalutionList from 'containers/EntityEvaluationList';
import Login from 'containers/Login';
import Dashboard from 'containers/Dashboard';
import GrammarRuleCreation from 'containers/GrammarRuleCreation';
import NotFound from 'containers/NotFound';
import EntityEvaluationCreation from 'containers/EntityEvaluationCreation';
import AuthRoute from 'utils/AuthRoute';
import GrammarRuleEdition from 'containers/GrammarRuleEdition';
import GrammarRuleList from 'containers/GrammarRuleList';
import EntityEvaluationDetails from 'containers/EntityEvaluationDetails';
import TextMarkerList from 'containers/TextMarkerList';
import TextMarkerCreation from 'containers/TextMarkerCreation';
import TextMarkerEdition from 'containers/TextMarkerEdition';
import TextMarkerCategoryList from 'containers/TextMarkerCategoryList';
import PlagiarismTextCreation from 'containers/PlagiarismTextCreation';
import TextMarkerCategoryCreation from 'containers/TextMarkerCategoryCreation';
import TextMarkerCategoryEdition from 'containers/TextMarkerCategoryEdition';
import PlagiarismTextList from 'containers/PlagiarismTextList';
import PlagiarismTest from 'containers/PlagiarismTextTest';
import PlagiarismTextEdition from 'containers/PlagiarismTextEdition';
import BlockCreation from 'containers/BlockCreation';
import AnswerPlayground from 'containers/AnswerPlayground';
import GenerativePromptCreation from 'containers/GenerativePromptCreation';
import GenerativePromptList from 'containers/GenerativePromptList';
import GenerativePromptVisualization from 'containers/GenerativePromptVisualization';
import GenerativePlayground from 'containers/GenerativePlayground';
import PromptCreation from 'containers/PromptCreation';
import PromptList from 'containers/PromptList';
import PromptEdition from 'containers/PromptEdition';
import PromptUnifiedList from 'containers/PromptUnifiedList';
import PromptUnifiedVisualization from 'containers/PromptUnifiedVisualization';
import PromptUnifiedCreation from 'containers/PromptUnifiedCreation';
import BlockEdition from 'containers/BlockEdition';
import BlockList from 'containers/BlockList';
import AnswerList from 'containers/AnswerList';
import AnswerVisualization from 'containers/AnswerVisualization';
import CorpusList from 'containers/CorpusList';
import CorpusCreation from 'containers/CorpusCreation';
import CorpusEdition from 'containers/CorpusEdition';
import Scheduling from 'containers/Scheduling';

export enum AuthRoutes {
  homepage = '/',
  dashboard = '/dashboard',
  grammarRuleEdition = '/regras-gramaticais/:id/:version',
  grammarRuleList = '/regras-gramaticais',
  grammarRuleCreation = '/criar-regra-gramatical',
  entityEvalutionList = '/avaliacoes-entidade',
  entityEvalutionCreation = '/criar-avaliacao-entidade',
  entityEvaluationDetails = '/avaliacoes-entidade/:id/:version',
  textMarkerList = '/marcadores-textuais',
  textMarkerCreation = '/criar-marcador-textual',
  textMarkerEdition = '/marcadores-textuais/:id/:version',
  textMarkerCategoryList = '/categorias-marcador',
  plagiarismTextCreation = '/criar-texto-plagio',
  textMarkerCategoryCreation = '/criar-categoria-marcador',
  textMarkerCategoryEdition = '/categorias-marcador/:id',
  plagiarismTextList = '/textos-plagio',
  plagiarismTextTest = '/teste-plagio',
  plagiarismTextEdition = '/textos-plagio/:id',
  blockCreation = '/criar-bloco',
  blockEdition = '/blocos/:block_id/:block_version',
  blockList = '/blocos',
  answerPlayground = '/playground-respostas',
  generativePromptCreation = '/criar-prompt-generativo',
  generativePromptList = '/prompts-generativos',
  generativePromptVisualization = '/prompt-generativo/:generative_prompt_id',
  generativePlayground = '/playground-generativo',
  promptList = '/prompts',
  promptEdition = '/prompts/:prompt_id/:prompt_version',
  promptCreation = '/criar-prompt',
  promptUnifiedList = '/prompts-unificados',
  promptUnifiedVisualization = '/prompts-unificados/:unified_prompt_id',
  promptUnifiedCreation = '/criar-prompt-unificado',
  answerList = '/respostas',
  answerVisualization = '/respostas/:answer_id',
  corpusCreation = '/criar-corpus',
  corpusEdition = '/corpus/:id',
  corpusList = '/corpus',
  scheduling = '/scheduling',
}

export enum PublicRoutes {
  login = '/login',
}

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PublicRoutes.login} component={Login} />

        <AuthRoute exact path={AuthRoutes.scheduling}>
          <Scheduling />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.corpusList}>
          <CorpusList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.corpusCreation}>
          <CorpusCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.corpusEdition}>
          <CorpusEdition />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.answerPlayground}>
          <AnswerPlayground />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.promptUnifiedList}>
          <PromptUnifiedList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.promptUnifiedCreation}>
          <PromptUnifiedCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.promptUnifiedVisualization}>
          <PromptUnifiedVisualization />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.generativePromptCreation}>
          <GenerativePromptCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.generativePromptList}>
          <GenerativePromptList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.generativePromptVisualization}>
          <GenerativePromptVisualization />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.generativePlayground}>
          <GenerativePlayground />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.promptCreation}>
          <DndProvider backend={HTML5Backend}>
            <PromptCreation />
          </DndProvider>
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.promptEdition}>
          <DndProvider backend={HTML5Backend}>
            <PromptEdition />
          </DndProvider>
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.promptList}>
          <PromptList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.answerList}>
          <AnswerList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.blockCreation}>
          <BlockCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.blockEdition}>
          <BlockEdition />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.blockList}>
          <BlockList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.plagiarismTextEdition}>
          <PlagiarismTextEdition />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.plagiarismTextCreation}>
          <PlagiarismTextCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.plagiarismTextTest}>
          <PlagiarismTest />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.textMarkerCategoryCreation}>
          <TextMarkerCategoryCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.textMarkerCategoryEdition}>
          <TextMarkerCategoryEdition />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.plagiarismTextList}>
          <PlagiarismTextList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.textMarkerCategoryList}>
          <TextMarkerCategoryList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.textMarkerEdition}>
          <TextMarkerEdition />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.textMarkerCreation}>
          <TextMarkerCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.textMarkerList}>
          <TextMarkerList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.entityEvaluationDetails}>
          <EntityEvaluationDetails />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.grammarRuleEdition}>
          <GrammarRuleEdition />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.entityEvalutionCreation}>
          <EntityEvaluationCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.entityEvalutionList}>
          <EntityEvalutionList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.grammarRuleCreation}>
          <GrammarRuleCreation />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.grammarRuleList}>
          <GrammarRuleList />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.answerVisualization}>
          <AnswerVisualization />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.homepage}>
          <Dashboard />
        </AuthRoute>
        <AuthRoute exact path={AuthRoutes.dashboard}>
          <Dashboard />
        </AuthRoute>
        <AuthRoute exact path="*">
          <NotFound />
        </AuthRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
