import {NewButton, StarRating, Icon} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import PageHeader from 'components/PageHeader';
import Select from 'react-select';
import TextArea from 'components/TextArea';
import SelectOption from 'utils/types/SelectOption';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import NoResultsWarning from 'components/NoResultsWarning';
import {
  useBookmarkAnswer,
  useFetchAnswerById,
  useUpdateAnswer,
} from 'store/reducers/answers';
import FeedbackModal, {FeedbackModalProps} from 'components/FeedbackModal';
import PromptPreviewModal from 'components/PromptPreviewModal';
import {useAnswers} from 'features/useAnswers';
import {isEmpty} from 'lodash';
import PageWrapper from '../../components/PageWrapper';
import styles from './AnswerVisualization.module.scss';

interface RouteParams {
  answer_id: string;
}

interface FormValues {
  promptId: SelectOption;
  promptVersion: SelectOption;
  composition_string: string;
  content: string;
  annotation: string;
}

function AnswerVisualization(): JSX.Element {
  const {answer_id} = useParams<RouteParams>();
  const answerId = Number(answer_id);

  const [answerRating, setAnswerRating] = useState(0);
  const [isOpenPromptPreview, setIsOpenPromptPreview] = useState(false);
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);

  const {responseFeedbacks, getFormFieldError} = useAnswers();

  const {
    isLoading: isLoadingAnswerById,
    isFetching: isFetchingAnswerById,
    isError: isFetchAnswerByIdError,
    data: answerData,
    refetch: refetchAnswerById,
  } = useFetchAnswerById(answerId);
  const [
    bookmarkAnswer,
    {
      isLoading: isBookmarkingAnswer,
      isError: isBookmarkAnswerError,
      isSuccess: isBookmarkAnswerSuccess,
      status: bookmarkAnswerStatus,
      reset: resetBookmarkAnswerStatus,
    },
  ] = useBookmarkAnswer();
  const [
    updateAnswer,
    {
      isLoading: isUpdatingAnswer,
      isError: isUpdateAnswerError,
      isSuccess: isUpdateAnswerSuccess,
      status: updateAnswerStatus,
      reset: resetUpdateAnswerStatus,
    },
  ] = useUpdateAnswer();

  const {
    control: formControl,
    reset: resetFormValues,
    getValues: getFormValues,
    formState: {errors: formErrors},
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const history = useHistory();

  // Filling the inputs with answer data
  useEffect(() => {
    if (answerData) {
      const {
        prompt: {id: promptId, title: promptTitle},
        prompt_version: {id: promptVersionId, version: promptVersionNumber},
        composition_string,
        content,
        annotation,
        score,
      } = answerData;

      resetFormValues({
        promptId: {
          label: promptTitle,
          value: String(promptId),
        },
        promptVersion: {
          label: `Versão ${promptVersionNumber}`,
          value: String(promptVersionId),
        },
        composition_string,
        content,
        annotation: annotation ?? undefined,
      });
      setAnswerRating(score);
    }
  }, [answerData]);

  // Opening feedback modal for API responses
  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isBookmarkAnswerError ||
      isBookmarkAnswerSuccess ||
      isUpdateAnswerError ||
      isUpdateAnswerSuccess;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [bookmarkAnswerStatus, updateAnswerStatus]);

  const isLoading =
    isLoadingAnswerById ||
    isFetchingAnswerById ||
    isBookmarkingAnswer ||
    isUpdatingAnswer;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isBookmarkAnswerError || isBookmarkAnswerSuccess) {
      return answerData?.bookmark ? 'unbookmarkAnswer' : 'bookmarkAnswer';
    }
    if (isUpdateAnswerError || isUpdateAnswerSuccess) {
      return 'updateAnswerAnnotations';
    }

    return 'default';
  }

  function getResponseMessage(): string {
    if (isBookmarkAnswerSuccess) {
      return answerData?.bookmark
        ? responseFeedbacks.unbookmarkAnswer.success.message
        : responseFeedbacks.bookmarkAnswer.success.message;
    }
    if (isBookmarkAnswerError) {
      return answerData?.bookmark
        ? responseFeedbacks.unbookmarkAnswer.error.message
        : responseFeedbacks.bookmarkAnswer.error.message;
    }
    if (isUpdateAnswerSuccess) {
      return responseFeedbacks.updateAnswerAnnotations.success.message;
    }
    if (isUpdateAnswerError) {
      return responseFeedbacks.updateAnswerAnnotations.error.message;
    }

    return '';
  }

  function onCloseResponseFeedbackModal() {
    resetBookmarkAnswerStatus();
    resetUpdateAnswerStatus();
    refetchAnswerById();
    setIsResponseFeedbackModalOpen(false);
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isBookmarkAnswerSuccess || isUpdateAnswerSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onCloseResponseFeedbackModal,
      onClose: onCloseResponseFeedbackModal,
    };
  }

  function onFormSubmit() {
    const {annotation} = getFormValues();

    updateAnswer({answerId, annotation, score: answerRating});
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingForm numberOfInputs={7} />
          </div>
        )}

        {isFetchAnswerByIdError && !isLoading && (
          <NoResultsWarning
            description="Parece que essa resposta não está disponível"
            buttonText="Voltar às respostas"
            buttonIcon="arrow-left"
            onButtonClick={() => history.push('/respostas')}
          />
        )}

        {!!answerData && !isFetchAnswerByIdError && !isLoading && (
          <>
            <PageHeader
              title="Resposta"
              onGoBackButtonClick={history.goBack}
              rightElement={
                <div className={styles.rightButtonsContainer}>
                  <NewButton
                    icon={['fas', 'save']}
                    text="Salvar"
                    kind="primary"
                    type="submit"
                    userRole="teacher"
                    disabled={isLoading || !isEmpty(formErrors)}
                    onClick={onFormSubmit}
                  />
                  <NewButton
                    icon={[answerData.bookmark ? 'fal' : 'fas', 'bookmark']}
                    text={answerData.bookmark ? 'Desfavoritar' : 'Favoritar'}
                    kind="primary"
                    type="button"
                    userRole="teacher"
                    disabled={isLoading}
                    onClick={() => bookmarkAnswer(answerId)}
                  />
                </div>
              }
            />

            <fieldset>
              <div className={styles.promptSelectWrapper}>
                <div className={styles.promptHeader}>
                  <label htmlFor="promptId">Prompt</label>
                  <button
                    type="button"
                    className={styles.promptVisualizeButton}
                    onClick={() => setIsOpenPromptPreview(true)}
                  >
                    <Icon icon={['fas', 'eye']} color="#5d3d85" />
                  </button>
                </div>
                <div>
                  <Controller
                    control={formControl}
                    name="promptId"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, ref}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 300,
                            }),
                          }}
                          ref={ref}
                          value={{
                            label: answerData.prompt.title,
                            value: answerData.prompt.id,
                          }}
                          name={name}
                          inputId="promptId"
                          isDisabled
                        />
                      </>
                    )}
                  />

                  <Controller
                    control={formControl}
                    name="promptVersion"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, ref}}) => (
                      <>
                        <Select
                          styles={{
                            ...reactSelectStyles,
                            container: (provided) => ({
                              ...provided,
                              minWidth: 120,
                            }),
                          }}
                          ref={ref}
                          value={{
                            label: `Versão ${answerData.prompt_version.version}`,
                            value: answerData.prompt_version.id,
                          }}
                          name={name}
                          inputId="promptVersion"
                          isDisabled
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <Controller
                control={formControl}
                name="composition_string"
                rules={{
                  required: true,
                }}
                render={({field: {name, ref, value}}) => (
                  <TextArea
                    id="composition_string"
                    labelText="Texto"
                    name={name}
                    ref={ref}
                    value={value}
                    resizable
                    disabled
                  />
                )}
              />

              <Controller
                control={formControl}
                name="content"
                rules={{
                  required: true,
                }}
                render={({field: {name, ref, value}}) => (
                  <TextArea
                    id="content"
                    labelText="Resposta"
                    name={name}
                    ref={ref}
                    value={value}
                    resizable
                    disabled
                  />
                )}
              />

              <Controller
                control={formControl}
                name="annotation"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <TextArea
                    id="annotation"
                    labelText="Anotações"
                    placeholder="Ex: Uma anotação qualquer..."
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={getFormFieldError(
                      formErrors.annotation?.type,
                    )}
                    resizable
                  />
                )}
              />

              <div>
                <label>Nota</label>
                <StarRating
                  amountOfStars={5}
                  activeStars={answerRating}
                  onClick={(rating) => setAnswerRating(rating)}
                  smallerRatingLabel="1"
                  biggerRatingLabel="5"
                  size="2x"
                />
              </div>
            </fieldset>
          </>
        )}
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
      {isOpenPromptPreview && !!answerData && (
        <PromptPreviewModal
          isOpen
          onClose={() => setIsOpenPromptPreview(false)}
          promptId={answerData.prompt.id}
          promptVersionNumber={answerData.prompt_version.version}
          answer={answerData.content}
        />
      )}
    </PageWrapper>
  );
}

export default AnswerVisualization;
