import LoadingRow from 'components/LoadingComponents/LoadingRow';
import styles from './LoadingResponse.module.scss';

function LoadingResponse(): JSX.Element {
  return (
    <div className={styles.container}>
      <LoadingRow />
      <LoadingRow width={600} height={200} />
      <LoadingRow />
      <LoadingRow width={400} height={100} />
    </div>
  );
}

export default LoadingResponse;
