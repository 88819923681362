import {Checkbox, Icon, InputText, NewButton, Button} from 'letrus-ui';
import {Controller, useForm} from 'react-hook-form';
import NoDataIllustration from 'images/no-data.svg';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import DeletionModal from 'components/DeletionModal';
import Select from 'react-select';
import {
  useCreateTextMarkerVersion,
  useDeleteTextMarkerVersion,
  useFetchTextMarkerVersionByIdLazy,
  useValidateTextMarker,
  useUpdateTextMarkerProductionVersion,
} from 'store/reducers/textMarkers';
import FeedbackModal from 'components/FeedbackModal';
import {APIComponents} from 'utils/types/NLPToolsAPI';
import isValidJSON from 'utils/isValidJSON';
import TextArea from 'components/TextArea';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import SelectOption from 'utils/types/SelectOption';
import {useFetchTextMarkerCategories} from 'store/reducers/textMarkerCategories';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './TextMarkerEdition.module.scss';

interface FormValues {
  category: SelectOption;
  subcategory: string;
  pattern: string;
  label: string;
  pattern_spacy: string;
  antipatterns: string;
  suggestion: string;
  sentence: string;
  identificationStart: string;
  identificationEnd: string;
  comment: string;
  isCaseSensitive: boolean;
}

const TextMarkerEdition: React.FC = () => {
  const [
    isCreateTextMarkerVersionModalOpen,
    setIsCreateTextMarkerVersionModalOpen,
  ] = useState<boolean>(false);
  const [
    isUpdateTextMarkerProductionVersionOpen,
    setIsUpdateTextMarkerProductionVersionModalOpen,
  ] = useState<boolean>(false);
  const {id: ruleId, version: ruleVersion} =
    useParams<{id: string; version: string}>();
  const [versionNumber, setVersionNumber] = useState<number>(
    parseInt(ruleVersion, 10),
  );
  const [productionVersion, setProductionVersion] = useState<
    number | undefined
  >(1);
  const [
    isDeleteTextMarkerVersionModalOpen,
    setIsDeleteTextMarkerVersionModalOpen,
  ] = useState<boolean>(false);
  const [textMarkerToDeleteId, setTextMarkerToDeleteId] = useState<number>(0);
  const [textMarkerVersionToDelete, setTextMarkerVersionToDelete] =
    useState<number>(0);

  const [deleteTextMarkerVersion] = useDeleteTextMarkerVersion();

  const {
    isFetching: isFetchingTextMarkerCategories,
    isLoading: isLoadingTextMarkerCategories,
    data: textMarkerCategoriesData,
  } = useFetchTextMarkerCategories({
    limit: 1000,
  });
  const [
    createTextMarkerVersion,
    {
      isError: isCreateTextMarkerVersionError,
      isSuccess: isCreateTextMarkerVersionSuccess,
      isLoading: isCreatingTextMarkerVersion,
      data: createTextMarkerVersionData,
      error: createTextMarkerVersionError,
    },
  ] = useCreateTextMarkerVersion();
  const [
    fetchTextMarkerVersionByIdLazy,
    {
      isError: isFetchTextMarkerVersionByIdError,
      isLoading: isLoadingTextMarkerVersionById,
      data: fetchTextMarkerVersionByIdData,
      isFetching: isFetcingTextMarkerVersionById,
    },
  ] = useFetchTextMarkerVersionByIdLazy();
  const [
    validateTextMarker,
    {
      isError: isValidateTextMarkerError,
      isLoading: isValidatingTextMarker,
      isSuccess: isValidateTextMarkerSuccess,
      data: validateTextMarkerData,
    },
  ] = useValidateTextMarker();
  const [
    updateTextMarkerProductionVersion,
    {
      isError: isUpdateTextMarkerProductionVersionError,
      isLoading: isUpdatingTextMarkerProductionVersion,
      isSuccess: isUpdateTextMarkerProductionVersionSuccess,
      error: updateTextMarkerProductionVersionError,
    },
  ] = useUpdateTextMarkerProductionVersion();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    trigger,
    watch,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      category: {label: '', value: ''},
      subcategory: '',
      pattern_spacy: '',
      antipatterns: '',
      label: '',
      suggestion: '',
      pattern: '',
      sentence: '',
      identificationStart: '',
      identificationEnd: '',
      comment: '',
    },
    mode: 'onBlur',
  });

  const history = useHistory();

  useEffect(() => {
    fetchTextMarkerVersionByIdLazy(
      {
        rule_id: parseInt(ruleId, 10),
        version: versionNumber,
      },
      true,
    );
  }, [versionNumber, ruleId]);

  useEffect(() => {
    if (fetchTextMarkerVersionByIdData) {
      setProductionVersion(fetchTextMarkerVersionByIdData.prod_version);

      const {
        category,
        label,
        sub_category,
        comment,
        current_version: {
          on_match_start,
          on_match_end,
          pattern,
          case_sensitive,
          pattern_spacy,
          suggestion,
          antipatterns,
        },
      } = fetchTextMarkerVersionByIdData;

      reset({
        category: {label: category.name, value: String(category.id)},
        subcategory: sub_category,
        label,
        suggestion,
        pattern_spacy: JSON.stringify(pattern_spacy),
        isCaseSensitive: case_sensitive,
        pattern,
        antipatterns: JSON.stringify(antipatterns),
        identificationStart: String(on_match_start),
        identificationEnd: String(on_match_end),
        comment,
      });
    }
  }, [fetchTextMarkerVersionByIdData]);

  useEffect(() => {
    if (isCreateTextMarkerVersionError) {
      setIsCreateTextMarkerVersionModalOpen(true);
    }
  }, [isCreateTextMarkerVersionError]);

  useEffect(() => {
    if (isCreateTextMarkerVersionSuccess) {
      setIsCreateTextMarkerVersionModalOpen(true);
    }
  }, [isCreateTextMarkerVersionSuccess]);

  useEffect(() => {
    if (isUpdateTextMarkerProductionVersionError) {
      setIsUpdateTextMarkerProductionVersionModalOpen(true);
    }
  }, [isUpdateTextMarkerProductionVersionError]);

  useEffect(() => {
    if (isUpdateTextMarkerProductionVersionSuccess) {
      setIsUpdateTextMarkerProductionVersionModalOpen(true);
    }
  }, [isUpdateTextMarkerProductionVersionSuccess]);

  const createTextMarkerVersionErrorData = (
    createTextMarkerVersionError as ResponseError
  )?.data as SimpleErrorPayload;
  const updateTextMarkerProductionVersionErrorData = (
    updateTextMarkerProductionVersionError as ResponseError
  )?.data as SimpleErrorPayload;

  const isLoading =
    isLoadingTextMarkerVersionById ||
    isFetcingTextMarkerVersionById ||
    isFetchingTextMarkerCategories ||
    isUpdatingTextMarkerProductionVersion ||
    isLoadingTextMarkerCategories;

  const {pattern_spacy, sentence} = watch();

  const isValidateButtonDisabled =
    !pattern_spacy || !sentence || Boolean(errors.pattern_spacy);

  function onClickDeleteTextMarkerVersion(
    textMarkerId: number,
    textMarkerVersionNumber: number,
  ) {
    setTextMarkerToDeleteId(textMarkerId);
    setTextMarkerVersionToDelete(textMarkerVersionNumber);
    setIsDeleteTextMarkerVersionModalOpen(true);
  }

  function onClickDeleteTextMarkerVersionModal() {
    deleteTextMarkerVersion({
      textMarkerId: textMarkerToDeleteId,
      textMarkerVersionNumber: textMarkerVersionToDelete,
    });
    setIsDeleteTextMarkerVersionModalOpen(false);
    history.push(AuthRoutes.textMarkerList);
  }

  function triggerTextMarkerValidation() {
    const {
      sentence,
      pattern,
      identificationStart,
      identificationEnd,
      isCaseSensitive,
      suggestion,
      pattern_spacy,
      antipatterns,
    } = getValues();

    trigger(['pattern_spacy'], {shouldFocus: true});
    validateTextMarker({
      pattern_spacy: JSON.parse(pattern_spacy),
      antipatterns: JSON.parse(antipatterns),
      case_sensitive: isCaseSensitive,
      suggestion,
      text: sentence,
      pattern,
      on_match_start: parseInt(identificationStart, 10),
      on_match_end: parseInt(identificationEnd, 10),
    });
  }

  function resetData(ruleId: number, ruleVersion: number) {
    history.push(`/marcadores-textuais/${ruleId}/${ruleVersion}`);

    setIsCreateTextMarkerVersionModalOpen(false);
    setVersionNumber(ruleVersion);
  }

  function onSubmit() {
    const {
      category,
      subcategory,
      comment,
      pattern,
      label,
      identificationStart,
      isCaseSensitive,
      pattern_spacy,
      antipatterns,
      suggestion,
      identificationEnd,
    } = getValues();

    const textMarkerVersionData: APIComponents['schemas']['TextMarkerUpdate'] =
      {
        id: fetchTextMarkerVersionByIdData?.id as number,
        category_id: parseInt(category.value, 10),
        label,
        sub_category: subcategory,
        comment,
        version: {
          pattern_spacy: JSON.parse(pattern_spacy),
          case_sensitive: isCaseSensitive,
          suggestion,
          pattern,
          on_match_start: parseInt(identificationStart, 10),
          on_match_end: parseInt(identificationEnd, 10),
          antipatterns: JSON.parse(antipatterns),
        },
      };

    createTextMarkerVersion(textMarkerVersionData);
  }

  return (
    <PageWrapper>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {isFetchTextMarkerVersionByIdError && !isLoadingTextMarkerVersionById && (
          <div className={styles.noResults}>
            <img src={NoDataIllustration} alt="Ilustração de sem resultados" />
            <h2>Parece que essa versão do marcador não está disponível</h2>

            <div className={styles.resetDataButtonWrapper} title="Recarregar">
              <NewButton
                icon={['fas', 'undo']}
                onClick={() => history.push(AuthRoutes.textMarkerList)}
                text="Voltar aos marcadores"
              />
            </div>
          </div>
        )}

        {isLoading && <LoadingForm numberOfInputs={10} />}

        {fetchTextMarkerVersionByIdData && !isFetchTextMarkerVersionByIdError && (
          <>
            <header>
              <div className={styles.buttonContainer}>
                <button type="button" onClick={history.goBack}>
                  <Icon icon="chevron-left" color="#414449" />
                </button>

                <h1>Editar marcador textual</h1>
              </div>
              <div className={styles.RightButtonsContainer}>
                <Button
                  text="Remover versão"
                  kind="negativeSecondary"
                  userRole="teacher"
                  type="button"
                  onClick={() =>
                    onClickDeleteTextMarkerVersion(
                      parseInt(ruleId, 10),
                      parseInt(ruleVersion, 10),
                    )
                  }
                />
                <NewButton
                  text="Criar nova versão"
                  kind="primary"
                  userRole="teacher"
                  type="submit"
                  isLoading={isCreatingTextMarkerVersion}
                  disabled={isCreatingTextMarkerVersion}
                />
              </div>
            </header>

            <div className={styles.fieldset}>
              <div
                className={styles.textMarkerVersionSelect}
                data-testid="textMarkerVersionSelect"
              >
                <label htmlFor="textMarkerVersion">Versão a visualizar</label>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: 318,
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onChange={(event) => {
                    resetData(
                      parseInt(ruleId, 10),
                      parseInt(String(event?.value), 10),
                    );
                  }}
                  options={fetchTextMarkerVersionByIdData.versions
                    .map(({version}) => ({
                      label: `Versão ${version}`,
                      value: version,
                    }))
                    .slice()
                    .reverse()}
                  value={{
                    label: `Versão ${ruleVersion}`,
                    value: parseInt(ruleVersion, 10),
                  }}
                  placeholder="Selecione a versão do marcador"
                  name="textMarkerVersion"
                  inputId="textMarkerVersion"
                />
                <label htmlFor="productionVersion">Versão de produção</label>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: 318,
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onChange={(event) => {
                    setProductionVersion(event?.value);
                    updateTextMarkerProductionVersion({
                      id: parseInt(ruleId, 10),
                      version: event?.value as number,
                    });
                  }}
                  options={[
                    {label: 'Nenhuma', value: undefined},
                    ...fetchTextMarkerVersionByIdData.versions
                      .map(({version}) => ({
                        label: `Versão ${version}`,
                        value: version,
                      }))
                      .slice()
                      .reverse(),
                  ]}
                  value={{
                    label: productionVersion
                      ? `Versão ${productionVersion}`
                      : 'Nenhuma',
                    value: productionVersion || undefined,
                  }}
                  placeholder="Selecione a versão de produção"
                  name="productionVersion"
                  inputId="productionVersion"
                />
              </div>

              <div className={styles.selectWrapper}>
                <label htmlFor="category">Categoria</label>
                <Controller
                  control={control}
                  name="category"
                  rules={{
                    required: true,
                  }}
                  render={({field: {name, onBlur, onChange, ref, value}}) => (
                    <Select
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          '&:hover': {borderColor: '#5d3d85'},
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: '#95989A',
                          paddingRight: 12,
                        }),
                        indicatorSeparator: () => ({}),
                      }}
                      onChange={onChange}
                      options={textMarkerCategoriesData?.results?.map(
                        ({id, name}) => ({
                          label: name,
                          value: String(id),
                        }),
                      )}
                      ref={ref}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Selecione a categoria"
                      name={name}
                      inputId="category"
                    />
                  )}
                />
              </div>

              <Controller
                control={control}
                name="subcategory"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="subcategory"
                    labelText="Subcategoria"
                    placeholder="Ex: Subcategoria X"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.subcategory?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="label"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="label"
                    labelText="Etiqueta"
                    placeholder="Ex: Etiqueta X"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.label?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="comment"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <TextArea
                    id="comment"
                    labelText="Comentário"
                    placeholder="Ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.comment?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : undefined
                    }
                    resizable
                  />
                )}
              />

              <Controller
                control={control}
                name="pattern"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="pattern"
                    labelText="Padrão"
                    placeholder={`Ex: [{"LOWER": "mais"},{"LOWER": {"REGEX": "alt[ao]"}}]`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="pattern_spacy"
                rules={{
                  required: true,
                  validate: isValidJSON,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <TextArea
                    id="pattern_spacy"
                    labelText="Padrão SpaCy"
                    placeholder={`Ex: [{"LOWER": "mais"},{"TEXT":{"REGEX": ”ˆ(menor|menores)$”}}]`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    errorMessage={
                      errors?.pattern_spacy?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : errors?.pattern_spacy?.type === 'validate'
                        ? 'Padrão SpaCy inválido, verifique o formato do JSON'
                        : undefined
                    }
                    resizable
                  />
                )}
              />
              <Controller
                control={control}
                name="antipatterns"
                rules={{
                  required: true,
                  validate: isValidJSON,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <TextArea
                    id="antipatterns"
                    labelText="Antipadrões"
                    placeholder={`Ex: [[{"LOWER": {"REGEX": "alt[ao]"}},{"LOWER": "astral"}]]
                  `}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={
                      errors?.antipatterns?.type === 'required'
                        ? 'Este campo não pode ficar em branco'
                        : errors?.antipatterns?.type === 'validate'
                        ? 'Antipadrões inválidos, verifique o formato do JSON'
                        : undefined
                    }
                    resizable
                  />
                )}
              />
              <Controller
                control={control}
                name="identificationStart"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="identificationStart"
                    labelText="Início da identificação"
                    placeholder="Ex: 1"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="identificationEnd"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="identificationEnd"
                    labelText="Fim da identificação"
                    placeholder="Ex: 5"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="suggestion"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="suggestion"
                    labelText="Sugestão"
                    placeholder="Ex: Procure termos mais específicos"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="isCaseSensitive"
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <Checkbox
                    id="isCaseSensitive"
                    labelText="É case sensitive"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={String(value)}
                    checked={value}
                  />
                )}
              />

              <div className={styles.sentenceValidationWrapper}>
                <div>
                  <Controller
                    control={control}
                    name="sentence"
                    render={({field: {name, onBlur, onChange, ref, value}}) => (
                      <InputText
                        id="sentence"
                        labelText="Sentença"
                        placeholder="Ex: beba agua"
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value ?? ''}
                      />
                    )}
                  />
                </div>

                <NewButton
                  text="Validar"
                  icon={['fas', 'search']}
                  isLoading={isValidatingTextMarker}
                  disabled={isValidatingTextMarker || isValidateButtonDisabled}
                  onClick={triggerTextMarkerValidation}
                />
              </div>

              {validateTextMarkerData && isValidateTextMarkerSuccess && (
                <div className={styles.resultsWrapper}>
                  <span className={styles.result}>
                    {validateTextMarkerData.suggestion ? (
                      <>
                        <Icon
                          icon={['fas', 'check']}
                          color="#00c341"
                          size="lg"
                        />
                        Identificado
                      </>
                    ) : (
                      <>
                        <Icon
                          icon={['fas', 'times']}
                          color="#f24e54"
                          size="lg"
                        />
                        Não identificado
                      </>
                    )}
                  </span>
                </div>
              )}

              {isValidatingTextMarker && (
                <div className={styles.loadingRowWrapper}>
                  <LoadingRow height={25} />
                </div>
              )}

              {isValidateTextMarkerError ? (
                <label className={styles.errorLabel} htmlFor="sentence">
                  Ocorreu um erro ao validar o marcador.
                  <br /> Por favor, verifique os campos e tente novamente
                </label>
              ) : null}
            </div>
          </>
        )}
      </form>

      <FeedbackModal
        isOpen={isCreateTextMarkerVersionModalOpen}
        title="Criar nova versão"
        subtitle={isCreateTextMarkerVersionError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isCreateTextMarkerVersionError
            ? createTextMarkerVersionErrorData.detail
            : 'O marcador foi atualizado.'
        }
        onClose={
          isCreateTextMarkerVersionError
            ? () => setIsCreateTextMarkerVersionModalOpen(false)
            : () =>
                resetData(
                  parseInt(ruleId, 10),
                  createTextMarkerVersionData?.current_version.version || 1,
                )
        }
        onButtonClick={
          isCreateTextMarkerVersionError
            ? () => setIsCreateTextMarkerVersionModalOpen(false)
            : () =>
                resetData(
                  parseInt(ruleId, 10),
                  createTextMarkerVersionData?.current_version.version || 1,
                )
        }
        buttonText={
          isCreateTextMarkerVersionError ? 'Tentar novamente' : 'Fechar'
        }
        iconName={isCreateTextMarkerVersionError ? 'undo' : 'arrow-left'}
      />
      <DeletionModal
        isOpen={isDeleteTextMarkerVersionModalOpen}
        message="Deseja mesmo excluir essa versão?"
        onClickDelete={onClickDeleteTextMarkerVersionModal}
        onClose={() => setIsDeleteTextMarkerVersionModalOpen(false)}
        subtitle="Tem certeza?"
        title="Excluir versão"
      />
      <FeedbackModal
        isOpen={isUpdateTextMarkerProductionVersionOpen}
        title="Atualizar versão de produção"
        subtitle={
          isUpdateTextMarkerProductionVersionError ? 'Erro!' : 'Sucesso!'
        }
        feedbackMessage={
          isUpdateTextMarkerProductionVersionError
            ? updateTextMarkerProductionVersionErrorData.detail
            : 'A versão de produção foi atualizada.'
        }
        onClose={() => setIsUpdateTextMarkerProductionVersionModalOpen(false)}
        onButtonClick={() =>
          setIsUpdateTextMarkerProductionVersionModalOpen(false)
        }
        buttonText={
          isUpdateTextMarkerProductionVersionError
            ? 'Tentar novamente'
            : 'Fechar'
        }
        iconName={
          isUpdateTextMarkerProductionVersionError ? 'undo' : 'arrow-left'
        }
      />
    </PageWrapper>
  );
};

export default TextMarkerEdition;
