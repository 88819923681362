import {NewButton} from 'letrus-ui';
import NoDataIllustration from 'images/no-data.svg';
import styles from './NoResults.module.scss';

export interface NoResultsProps {
  resetData(): void;
}

function NoResults({resetData}: NoResultsProps): JSX.Element {
  return (
    <div className={styles.noResults}>
      <img src={NoDataIllustration} alt="Ilustração de sem resultados" />
      <h2>Sem resultados</h2>

      <div className={styles.resetDataButtonWrapper} title="Recarregar">
        <NewButton icon={['fas', 'undo']} onClick={resetData} />
      </div>
    </div>
  );
}

export default NoResults;
