import {Icon, InputText, NewButton} from 'letrus-ui';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {AuthRoutes} from 'routes';
import type {GroupBase, OptionsOrGroups} from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';
import Select from 'react-select';
import {useFetchPromptsLazy} from 'store/reducers/prompts';
import {useCreatePromptUnified} from 'store/reducers/promptsUnified';
import {useFetchCorpusCompetences} from 'store/reducers/corpus';
import FeedbackModal, {
  FeedbackModalProps,
  FieldError,
} from 'components/FeedbackModal';
import UnifiedPromptBlocksInfoModal from 'components/UnifiedPromptBlocksInfoModal';
import PageHeader from 'components/PageHeader';
import SelectOption from 'utils/types/SelectOption';
import {ResponseError, FormErrorPayload} from 'utils/types/ResponseError';
import LoadingForm from 'components/LoadingComponents/LoadingForm';
import {reactSelectStyles} from 'utils/styles/reactSelect';
import {isEmpty} from 'lodash';
import PromptUnifiedPreview from 'components/PromptUnifiedPreview';
import {usePromptsUnified, PromptCompetences} from 'features/usePromptsUnified';
import PageWrapper from '../../components/PageWrapper';
import styles from './PromptUnifiedCreation.module.scss';

interface PromptListItem {
  id: number;
  title: string;
  description: string;
}

interface FormValues {
  name: string;
  prompts: SelectOption[];
  prompt_competences: PromptCompetences[];
}

interface LoadOptionsParams {
  page: number;
}

function PromptUnifiedCreation(): JSX.Element {
  const [isResponseFeedbackModalOpen, setIsResponseFeedbackModalOpen] =
    useState(false);
  const [
    isUnifiedPromptBlocksInfoModalOpen,
    setIsUnifiedPromptBlocksInfoModalOpen,
  ] = useState(false);
  const [currentPaginationPrompts, setCurrentPaginationPrompts] = useState<
    PromptListItem[]
  >([]);

  const [allPrompts, setAllPrompts] = useState<PromptListItem[]>([]);

  const [promptsTotal, setPromptsTotal] = useState(0);

  const {
    responseFeedbacks,
    getFormFieldError,
    validatePrompts,
    serializeFormValues,
  } = usePromptsUnified();

  const loadOptionsAdditionalParams: LoadOptionsParams = {
    page: 1,
  };

  const [
    fetchPromptsLazy,
    {data: promptListResponse, isLoading: isLoadingPromptList},
  ] = useFetchPromptsLazy();
  const {data: corpusCompetences, isLoading: isLoadingCorpusCompetences} =
    useFetchCorpusCompetences({
      limit: 100,
    });
  const [
    createPromptUnified,
    {
      isError: isCreatePromptUnifiedError,
      isLoading: isCreatingUnifiedPrompt,
      isSuccess: isCreatePromptUnifiedSuccess,
      error: createPromptUnifiedError,
      status: createPromptUnifiedStatus,
    },
  ] = useCreatePromptUnified();

  const isLoading = isLoadingPromptList || isLoadingCorpusCompetences;

  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    getValues: getFormValues,
    watch: formWatch,
    formState: {errors: formErrors},
    setError: setFormError,
    clearErrors: clearFormError,
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {prompt_competences: []},
  });
  const {fields, append, remove} = useFieldArray({
    name: 'prompt_competences',
    control: formControl,
  });
  const [promptCompetences, prompts] = formWatch([
    'prompt_competences',
    'prompts',
  ]);
  const history = useHistory();

  useEffect(() => {
    fetchPromptsLazy(
      {
        limit: 10,
      },
      true,
    );
  }, []);

  useEffect(() => {
    if (promptListResponse?.results?.length) {
      setCurrentPaginationPrompts(
        promptListResponse.results.map((prompt) => ({
          title: prompt.title,
          description: prompt.description,
          id: prompt.id,
        })),
      );
      setAllPrompts([
        ...allPrompts,
        ...promptListResponse.results.map((prompt) => ({
          description: prompt.description,
          title: prompt.title,
          id: prompt.id,
        })),
      ]);
      setPromptsTotal(promptListResponse.total);
    }
  }, [promptListResponse]);

  useEffect(() => {
    if (
      formErrors?.prompts &&
      !validatePrompts(prompts, promptCompetences, corpusCompetences)
    ) {
      clearFormError('prompts');
    }
  }, [promptCompetences]);

  useEffect(() => {
    const newPrompts = prompts
      ? prompts.filter((prompt) => {
          return (
            !fields.length ||
            !fields.find((field) => field.prompt_id === Number(prompt.value))
          );
        })
      : [];

    if (newPrompts.length) {
      append(
        newPrompts.map((prompt) => ({
          prompt_id: Number(prompt.value),
          prompt_name: prompt.label,
          competences: [],
        })),
      );
    }

    const promptsToRemove = fields
      .map((field, index) => ({...field, index}))
      .filter((field) => {
        return !prompts?.find(
          (prompt) => field.prompt_id === Number(prompt.value),
        );
      });

    if (promptsToRemove.length) {
      const indexesToRemove = promptsToRemove.map((prompt) => prompt.index);
      remove(indexesToRemove);
    }
  }, [prompts]);

  function loadOptions(
    _search: string,
    prevOptions: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>,
    params: LoadOptionsParams | undefined,
  ) {
    const currentPage = params?.page || 1;
    const hasMorePrompts = currentPage * 10 < promptsTotal;

    if (isLoadingPromptList) {
      return {options: [], hasMore: true};
    }
    const prevOptionsWithoutSelectedPrompts = prevOptions.filter(
      (option) =>
        !prompts?.find(
          (prompt) =>
            Number(prompt.value) === Number((option as SelectOption).value),
        ),
    );
    const promptListWithoutSelectedPrompts = currentPaginationPrompts.filter(
      (promptListItem) =>
        !prompts?.find((prompt) => Number(prompt.value) === promptListItem.id),
    );
    const promptsToAdd = promptListWithoutSelectedPrompts.filter(
      (prompt) =>
        !prevOptionsWithoutSelectedPrompts.find(
          (option) => prompt.id === Number((option as SelectOption).value),
        ),
    );

    if (promptsToAdd.length) {
      return {
        options: currentPaginationPrompts
          .filter(
            (currentPaginationPrompt) =>
              !prompts?.find(
                (prompt) => Number(prompt.value) === currentPaginationPrompt.id,
              ),
          )
          .map((prompt) => ({label: prompt.title, value: String(prompt.id)})),
        hasMore: hasMorePrompts,
        additional: {
          page: currentPage + 1,
        },
      };
    }

    if (!isLoadingPromptList) {
      fetchPromptsLazy(
        {
          limit: 10,
          offset: (currentPage - 1) * 10,
        },
        false,
      );
    }

    return {options: [], hasMore: true};
  }

  useEffect(() => {
    const shouldOpenResponseFeedbackModal =
      isCreatePromptUnifiedSuccess || isCreatePromptUnifiedError;

    if (shouldOpenResponseFeedbackModal) {
      setIsResponseFeedbackModalOpen(true);
    }
  }, [createPromptUnifiedStatus]);

  const createPromptErrorData = (createPromptUnifiedError as ResponseError)
    ?.data as FormErrorPayload;

  function getResponseType(): keyof typeof responseFeedbacks {
    if (isCreatePromptUnifiedError || isCreatePromptUnifiedSuccess) {
      return 'createPromptUnified';
    }

    return 'default';
  }

  function getResponseMessage(): string | FieldError[] {
    if (isCreatePromptUnifiedError) {
      const errorMessages = Object.entries(createPromptErrorData ?? {})?.map(
        ([field, errors]) => ({
          fieldName: field,
          errors,
        }),
      );

      return errorMessages;
    }
    if (isCreatePromptUnifiedSuccess) {
      return responseFeedbacks.createPromptUnified.success.message;
    }

    return '';
  }

  function onResposeFeedbackClose() {
    setIsResponseFeedbackModalOpen(false);
    if (isCreatePromptUnifiedSuccess) {
      history.push(AuthRoutes.promptUnifiedList);
    }
  }

  function getResponseFeedbackModalProps(): FeedbackModalProps {
    const isSuccessFeedback = isCreatePromptUnifiedSuccess;
    const responseType = getResponseType();
    const responseMessage = getResponseMessage();

    return {
      isOpen: true,
      title: responseFeedbacks[responseType]?.title ?? '',
      subtitle: isSuccessFeedback ? 'Sucesso!' : 'Erro',
      feedbackMessage: responseMessage,
      buttonText: 'Fechar',
      onButtonClick: onResposeFeedbackClose,
      onClose: onResposeFeedbackClose,
    };
  }

  function onFormSubmit() {
    const errorPrompts = validatePrompts(
      prompts,
      promptCompetences,
      corpusCompetences,
    );

    if (errorPrompts) {
      setFormError('prompts', {
        type: 'error',
        message: errorPrompts,
      });
      return;
    }

    const {name, prompt_competences} = getFormValues();

    const serializedFormValues = serializeFormValues(
      name,
      prompt_competences,
      corpusCompetences,
    );

    createPromptUnified(serializedFormValues);
  }

  return (
    <PageWrapper>
      <form
        className={styles.container}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <PageHeader
          title="Criar prompt unificado"
          onGoBackButtonClick={history.goBack}
          rightElement={
            <div className={styles.rightContent}>
              <button
                type="button"
                className={styles.helpIconButton}
                onClick={() => setIsUnifiedPromptBlocksInfoModalOpen(true)}
              >
                <Icon icon={['far', 'question-circle']} size="2x" />
              </button>
              <NewButton
                text="Criar prompt unificado"
                kind="primary"
                userRole="teacher"
                type="submit"
                isLoading={isCreatingUnifiedPrompt}
                disabled={isCreatingUnifiedPrompt || !isEmpty(formErrors)}
              />
            </div>
          }
        />

        {isLoading && <LoadingForm numberOfInputs={6} />}

        {!isLoading && (
          <fieldset>
            {prompts?.length ? (
              <div className={styles.previewWrapper}>
                <PromptUnifiedPreview
                  prompts={prompts.map((prompt) => {
                    const promptInList = allPrompts.find(
                      (promptListItem) =>
                        promptListItem.id === Number(prompt.value),
                    );
                    return {
                      id: promptInList?.id || Number(prompt.value),
                      title: promptInList?.title || prompt.label,
                      description: promptInList?.description || prompt.label,
                    };
                  })}
                />
              </div>
            ) : (
              <div />
            )}

            <div className={styles.fieldsWrapper}>
              <Controller
                control={formControl}
                name="name"
                rules={{
                  required: true,
                }}
                render={({field: {name, onBlur, onChange, ref, value}}) => (
                  <InputText
                    id="title"
                    labelText="Nome do prompt"
                    placeholder="Ex: Prompt para validar competência 1"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    value={value ?? ''}
                    errorMessage={getFormFieldError(formErrors?.name?.type)}
                  />
                )}
              />

              <label htmlFor="prompts">Inserir prompts</label>
              <Controller
                control={formControl}
                name="prompts"
                rules={{
                  required: true,
                }}
                render={({field: {name, onChange, value}}) => (
                  <>
                    <AsyncPaginate
                      loadOptions={loadOptions}
                      additional={loadOptionsAdditionalParams}
                      styles={{
                        ...reactSelectStyles,
                        container: (provided) => ({
                          ...provided,
                          maxWidth: '300px',
                        }),
                      }}
                      isMulti
                      onChange={onChange}
                      value={value}
                      name={name}
                      inputId="prompts"
                      placeholder="Selecione os prompts"
                    />
                    {formErrors?.prompts && (
                      <label
                        htmlFor="prompts"
                        className={styles.error}
                        id="error"
                      >
                        {(formErrors?.prompts as any)?.message
                          ? (formErrors?.prompts as any)?.message
                          : getFormFieldError(
                              (formErrors?.prompts as any)?.type,
                            )}
                      </label>
                    )}
                  </>
                )}
              />

              {!!fields?.length &&
                fields.map((field, index) => (
                  <div key={field.id}>
                    <label htmlFor={`prompt_competences.${index}.competences`}>
                      {field.prompt_name}
                    </label>
                    <Controller
                      control={formControl}
                      name={`prompt_competences.${index}.competences` as const}
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: {name, onBlur, onChange, ref, value},
                      }) => (
                        <>
                          <Select
                            styles={{
                              ...reactSelectStyles,
                              container: (provided) => ({
                                ...provided,
                                minWidth: 300,
                              }),
                            }}
                            onChange={onChange}
                            options={corpusCompetences?.results
                              ?.concat([{id: 99999, name: 'general_comment'}])
                              .filter(
                                (competence) =>
                                  !promptCompetences.reduce(
                                    (accumulator, prompt_competence) => {
                                      if (
                                        prompt_competence.competences?.find(
                                          (promptCompetence) =>
                                            competence.id ===
                                            Number(promptCompetence.value),
                                        )
                                      ) {
                                        return true;
                                      }
                                      return accumulator;
                                    },
                                    false,
                                  ),
                              )
                              .map(({id, name}) => ({
                                label: name,
                                value: String(id),
                              }))}
                            isMulti
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            placeholder="Selecione as competências"
                            name={name}
                            inputId={name}
                          />
                          {formErrors?.prompt_competences && (
                            <label
                              htmlFor={`prompt_competences.${index}.competences`}
                              className={styles.error}
                              id="error"
                            >
                              {getFormFieldError(
                                (
                                  formErrors?.prompt_competences &&
                                  (formErrors?.prompt_competences[index]
                                    ?.competences as any)
                                )?.type,
                              )}
                            </label>
                          )}
                        </>
                      )}
                    />
                  </div>
                ))}
            </div>
          </fieldset>
        )}
      </form>

      {isResponseFeedbackModalOpen && (
        <FeedbackModal {...getResponseFeedbackModalProps()} />
      )}
      {isUnifiedPromptBlocksInfoModalOpen && (
        <UnifiedPromptBlocksInfoModal
          onClose={() => setIsUnifiedPromptBlocksInfoModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
}

export default PromptUnifiedCreation;
