/* eslint-disable no-restricted-globals */
import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/services/axiosBaseQuery';
import {API_URL} from 'utils/apiUrl';
import {APIComponents} from 'utils/types/NLPToolsAPI';

export interface ValidateGrammarRulePayload {
  suggestion?: string;
}

export const plagiarismTextsReducer = createApi({
  reducerPath: 'plagiarismTexts',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL as string,
  }),
  endpoints(builder) {
    return {
      fetchPlagiarismTexts: builder.query<
        APIComponents['schemas']['Paginated_PlagiarismTextGet_'],
        {search?: string; limit?: number; offset?: number}
      >({
        query: ({search, limit, offset}) => ({
          url: '/plagiarism_texts',
          method: 'GET',
          params: {
            search: search || undefined,
            limit,
            offset,
          },
        }),
      }),
      fetchPlagiarismTextById: builder.query<
        APIComponents['schemas']['PlagiarismTextGetDetail'],
        number
      >({
        query: (rule_id) => ({
          url: `/plagiarism_texts/${rule_id}`,
          method: 'GET',
        }),
      }),
      createPlagiarismText: builder.mutation<
        APIComponents['schemas']['PlagiarismTextCreate'],
        APIComponents['schemas']['PlagiarismTextCreate']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/plagiarism_texts`,
          data,
        }),
      }),
      updatePlagiarismText: builder.mutation<
        APIComponents['schemas']['PlagiarismTextCreate'],
        APIComponents['schemas']['PlagiarismTextCreate'] & {id: number}
      >({
        query: (data) => ({
          method: 'PUT',
          url: `/plagiarism_texts/${data.id}`,
          data,
        }),
      }),
      deletePlagiarismText: builder.mutation<void, number>({
        query: (plagiarismTextId) => ({
          method: 'DELETE',
          url: `/plagiarism_texts/${plagiarismTextId}`,
        }),
      }),
      testPlagiarismText: builder.mutation<
        APIComponents['schemas']['PlagiarismTextTestResult'],
        APIComponents['schemas']['PlagiarismTextTest']
      >({
        query: (data) => ({
          method: 'POST',
          url: `/plagiarism_texts/test`,
          data,
        }),
      }),
    };
  },
});

export const {
  fetchPlagiarismTexts: {
    useQuery: useFetchPlagiarismTexts,
    useLazyQuery: useFetchPlagiarismTextsLazy,
  },
  fetchPlagiarismTextById: {useLazyQuery: useFetchPlagiarismTextByIdLazy},
  createPlagiarismText: {useMutation: useCreatePlagiarismText},
  deletePlagiarismText: {useMutation: useDeletePlagiarismText},
  updatePlagiarismText: {useMutation: useUpdatePlagiarismText},
  testPlagiarismText: {useMutation: useTestPlagiarismText},
} = plagiarismTextsReducer.endpoints;
