import {Icon, NewButton, Pagination} from 'letrus-ui';
import Select from 'react-select';
import {useHistory, useParams, Link} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import NoDataIllustration from 'images/no-data.svg';
import Tooltip from 'react-tooltip';
import {
  useAnnotateEvaluationLazy,
  useFetchEntityEvaluationVersionByIdLazy,
  useFetchEntityEvaluationVersionSamplesByIdLazy,
  useReexecuteEntityEvaluation,
} from 'store/reducers/entityEvalutions';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import {AnnotationEnum} from 'utils/types/NLPToolsAPI';
import FeedbackModal from 'components/FeedbackModal';
import SelectOption from 'utils/types/SelectOption';
import {Bar} from 'react-chartjs-2';
import {AuthRoutes} from 'routes';
import {ResponseError, SimpleErrorPayload} from 'utils/types/ResponseError';
import PageWrapper from '../../components/PageWrapper';
import styles from './EntityEvaluationDetails.module.scss';

enum EvaluationColorEnum {
  'correct' = '#00c341',
  'incorrect' = '#eb5757',
  'unset' = '#414449',
}

interface RouteParams {
  id: string;
  version: string;
}

const EntityEvaluationDetails: React.FC = () => {
  const [isAnnotationFeedbackModalOpen, setIsAnnotationFeedbackModalOpen] =
    useState<boolean>(false);
  const [
    isReexecuteEntityEvaluationFeedbackModalOpen,
    setIsReexecuteEntityEvaluationFeedbackModalOpen,
  ] = useState<boolean>(false);
  const [entities, setEntities] = useState<SelectOption[]>([
    {label: 'Todas as entidades', value: 'all'},
  ]);
  const [entitiesState, setEntitiesState] = useState<SelectOption>({
    label: 'Todas',
    value: '',
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [entityEvaluationsMap, setEntityEvaluationsMap] = useState<
    Record<number, AnnotationEnum>
  >({});
  const {id: entityEvaluationId, version: entityEvaluationVersion} =
    useParams<RouteParams>();
  const [versionNumber, setVersionNumber] = useState<number>(
    parseInt(entityEvaluationVersion, 10),
  );

  const [
    fetchEntityEvaluationVersionByIdLazy,
    {
      isError: isFetchEntityEvaluationVersionByIdError,
      isLoading: isLoadingEntityEvaluationVersionById,
      data: fetchEntityEvaluationVersionByIdData,
      isFetching: isFetcingEntityEvaluationVersionById,
    },
  ] = useFetchEntityEvaluationVersionByIdLazy();
  const [
    fetchEntityEvaluationVersionSamplesByIdLazy,
    {
      isError: isFetchEntityEvaluationVersionSamplesByIdError,
      isLoading: isLoadingEntityEvaluationVersionSamplesById,
      data: fetchEntityEvaluationVersionSamplesByIdData,
      isFetching: isFetcingEntityEvaluationVersionSamplesById,
    },
  ] = useFetchEntityEvaluationVersionSamplesByIdLazy();
  const [
    annotateEvaluationLazy,
    {
      isError: isAnnotateEvaluationError,
      isLoading: isAnnotatingEvaluation,
      isSuccess: isAnnotateEvaluationSuccess,
      error: annotateEvaluationError,
    },
  ] = useAnnotateEvaluationLazy();
  const [
    reexecuteEntityEvaluation,
    {
      isError: isReexecuteEntityEvalutionError,
      isLoading: isLoadingReexecution,
      isSuccess: isReexecuteEntityEvalutionSuccess,
      error: reexecuteEntityEvaluationError,
    },
  ] = useReexecuteEntityEvaluation();

  const history = useHistory();

  const annotateEvaluationErrorData = (annotateEvaluationError as ResponseError)
    ?.data as SimpleErrorPayload;
  const reexecuteEntityEvaluationErrorData = (
    reexecuteEntityEvaluationError as ResponseError
  )?.data as SimpleErrorPayload;

  useEffect(() => {
    fetchEntityEvaluationVersionByIdLazy({
      test_id: parseInt(entityEvaluationId, 10),
      version: versionNumber,
    });
  }, []);

  useEffect(() => {
    fetchEntityEvaluationVersionSamplesByIdLazy(
      {
        test_id: parseInt(entityEvaluationId, 10),
        version: versionNumber,
        limit: 10,
        offset: (currentPage - 1) * 10,
        entity_ids: entities.find((entity) => entity?.value === 'all')
          ? fetchEntityEvaluationVersionByIdData?.entity_ids
          : entities.map((entity) => entity.value),
        annotation: entitiesState.value,
      },
      true,
    );
  }, [currentPage]);

  useEffect(() => {
    if (isAnnotateEvaluationError) {
      setIsAnnotationFeedbackModalOpen(true);
    }
  }, [isAnnotateEvaluationError]);

  useEffect(() => {
    if (isAnnotateEvaluationSuccess) {
      setIsAnnotationFeedbackModalOpen(true);
      fetchEntityEvaluationVersionSamplesByIdLazy({
        test_id: parseInt(entityEvaluationId, 10),
        version: versionNumber,
        limit: 10,
        offset: (currentPage - 1) * 10,
        entity_ids: entities.find((entity) => entity?.value === 'all')
          ? fetchEntityEvaluationVersionByIdData?.entity_ids
          : entities.map((entity) => entity.value),
        annotation: entitiesState.value,
      });
    }
  }, [isAnnotateEvaluationSuccess]);

  useEffect(() => {
    if (isReexecuteEntityEvalutionError) {
      setIsReexecuteEntityEvaluationFeedbackModalOpen(true);
    }
  }, [isReexecuteEntityEvalutionError]);

  useEffect(() => {
    if (isReexecuteEntityEvalutionSuccess) {
      setIsReexecuteEntityEvaluationFeedbackModalOpen(true);
    }
  }, [isReexecuteEntityEvalutionSuccess]);

  const isGrammarRule =
    fetchEntityEvaluationVersionByIdData?.entity_type === 'grammarrule';

  const totalSamplesNumber =
    fetchEntityEvaluationVersionSamplesByIdData?.total || 0;

  const entitiesPrecision = useMemo(() => {
    return fetchEntityEvaluationVersionByIdData?.entity_ids.map((entityId) => {
      const truePositiveSamples =
        fetchEntityEvaluationVersionSamplesByIdData?.results
          .filter((sample) => sample.entity_id === entityId)
          .filter((entityIdSample) => entityIdSample.evaluation === 'TP');
      const entityPrecision =
        ((truePositiveSamples?.length || 0) / totalSamplesNumber) * 100;

      return {
        id: entityId,
        precision: entityPrecision,
      };
    });
  }, [
    fetchEntityEvaluationVersionByIdData,
    fetchEntityEvaluationVersionSamplesByIdData,
  ]);

  const totalNumberOfPages = useMemo(
    () =>
      fetchEntityEvaluationVersionSamplesByIdData?.total
        ? Math.ceil(fetchEntityEvaluationVersionSamplesByIdData.total / 10)
        : 0,
    [fetchEntityEvaluationVersionSamplesByIdData],
  );

  const isLoading =
    isLoadingEntityEvaluationVersionById ||
    isFetcingEntityEvaluationVersionById ||
    isLoadingEntityEvaluationVersionSamplesById ||
    isFetcingEntityEvaluationVersionSamplesById;

  const grammarRuleStateOptions = useMemo(
    () => [
      {
        label: 'Verdadeiras',
        value: 'correct',
      },
      {
        label: 'Falsas',
        value: 'incorrect',
      },
      {
        label: 'Não anotadas',
        value: 'unset',
      },
    ],
    [],
  );

  function onClickFilter() {
    fetchEntityEvaluationVersionSamplesByIdLazy(
      {
        test_id: parseInt(entityEvaluationId, 10),
        version: versionNumber,
        limit: 10,
        offset: (currentPage - 1) * 10,
        annotation: entitiesState.value,
        entity_ids: entities.find((entity) => entity?.value === 'all')
          ? fetchEntityEvaluationVersionByIdData?.entity_ids
          : entities.map((entity) => entity.value),
      },
      true,
    );
  }

  function onChangeEntityAnnotation(
    {target: {value}}: ChangeEvent<HTMLSelectElement>,
    testId: number,
  ) {
    setEntityEvaluationsMap({
      ...entityEvaluationsMap,
      [testId]: value as AnnotationEnum,
    });
  }

  function onChangePage(page: number) {
    setCurrentPage(page);
    setEntityEvaluationsMap({});
  }

  function resetData(
    entityEvaluationId: number,
    entityEvaluationVersion: number,
  ) {
    history.push(
      `/avaliacoes-entidade/${entityEvaluationId}/${entityEvaluationVersion}`,
    );

    setIsAnnotationFeedbackModalOpen(false);
    setVersionNumber(entityEvaluationVersion);
  }

  function submitEvaluations() {
    annotateEvaluationLazy({
      test_id: parseInt(entityEvaluationId, 10),
      version: parseInt(entityEvaluationVersion, 10),
      data: Object.entries(entityEvaluationsMap).map(
        ([entityId, evaluation]) => ({
          id: parseInt(entityId, 10),
          annotation: evaluation,
        }),
      ),
    });
  }

  return (
    <PageWrapper>
      <div className={styles.container}>
        {isFetchEntityEvaluationVersionByIdError &&
        !isLoadingEntityEvaluationVersionById ? (
          <div className={`${styles.noResults} ${styles.samples}`}>
            <img src={NoDataIllustration} alt="Ilustração de sem resultados" />
            <h2>Parece que essa versão da avaliação não está disponível</h2>

            <div className={styles.resetDataButtonWrapper} title="Recarregar">
              <NewButton
                icon={['fas', 'undo']}
                onClick={() => history.push(AuthRoutes.entityEvalutionList)}
                text="Voltar às avaliações"
              />
            </div>
          </div>
        ) : null}

        {isLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingRow height={300} width="100%" />
            <LoadingTable />
          </div>
        ) : null}

        {fetchEntityEvaluationVersionByIdData &&
        fetchEntityEvaluationVersionSamplesByIdData &&
        !isFetchEntityEvaluationVersionSamplesByIdError &&
        !isFetchEntityEvaluationVersionByIdError &&
        !isLoading ? (
          <>
            <header>
              <div className={styles.leftButtons}>
                <button type="button" onClick={history.goBack}>
                  <Icon icon="chevron-left" color="#414449" />
                </button>

                <h1>Detalhes da avaliação</h1>
              </div>

              <div className={styles.rightButtons}>
                <span data-tip="" data-for="reExecuteTest">
                  <NewButton
                    text="Reexecutar avaliação"
                    kind="primary"
                    userRole="teacher"
                    type="button"
                    icon={['fas', 'undo-alt']}
                    isLoading={isLoadingReexecution || isLoading}
                    onClick={() =>
                      reexecuteEntityEvaluation({
                        entityEvalutionId: Number(entityEvaluationId),
                      })
                    }
                    disabled={isLoadingReexecution}
                  />
                </span>

                <NewButton
                  text="Anotar resultados"
                  kind="primary"
                  userRole="teacher"
                  type="button"
                  icon={['fas', 'marker']}
                  onClick={submitEvaluations}
                  isLoading={isAnnotatingEvaluation}
                  disabled={!Object.keys(entityEvaluationsMap).length}
                />

                <Tooltip
                  className={styles.reExecuteTestTooltip}
                  id="reExecuteTest"
                  type="dark"
                  effect="float"
                  place="top"
                  multiline
                >
                  <div className={styles.wrapper}>
                    <p>
                      Reexecutar a avaliação significa calcular novamente a
                      porcentagem de precisão das entidades que foram
                      atualizadas (se houverem)
                    </p>
                  </div>
                </Tooltip>
              </div>
            </header>

            <div className={styles.fieldset}>
              <div
                className={styles.testVersionSelectWrapper}
                data-testid="entityEvaluationVersionSelectWrapper"
              >
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: 318,
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onChange={(event) => {
                    resetData(
                      parseInt(entityEvaluationId, 10),
                      parseInt(String(event?.value), 10),
                    );
                  }}
                  options={fetchEntityEvaluationVersionByIdData?.versions
                    ?.map(({version}) => ({
                      label: `Versão ${version}`,
                      value: version,
                    }))
                    .slice()
                    .reverse()}
                  defaultValue={{
                    label: `Versão ${fetchEntityEvaluationVersionByIdData?.current_version.version}`,
                    value:
                      fetchEntityEvaluationVersionByIdData?.current_version
                        .version,
                  }}
                  inputId="entityEvaluationVersion"
                  placeholder="Selecione a versão da regra"
                  name="entityEvaluationVersion"
                />
              </div>

              <div className={styles.precisionPercentageWrapper}>
                <h2>Porcentagem de precisão das regras</h2>

                <Bar
                  data={{
                    labels: entitiesPrecision?.map(
                      (rule) =>
                        `${isGrammarRule ? 'Regra' : 'Marcador'} ${rule.id}`,
                    ),
                    datasets: [
                      {
                        label: ' % de precisão',
                        data: entitiesPrecision?.map((rule) => rule.precision),
                        backgroundColor: '#ffc105',
                        barThickness: 50,
                        borderRadius: 2,
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                  width={700}
                  height={160}
                  color="#414449"
                />
              </div>

              <div className={styles.filterWrapper}>
                <div className={styles.selectWrapper}>
                  <label htmlFor="entities">
                    {isGrammarRule ? 'Regras ' : 'Marcadores '}
                    (múltipla escolha)
                  </label>
                  <Select
                    inputId="entities"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: 318,
                        borderColor: '#95989A',
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    isMulti
                    onChange={(event) => setEntities(event.map((item) => item))}
                    options={[
                      {
                        label: `Todas as ${
                          isGrammarRule ? 'regras' : 'marcadores'
                        }`,
                        value: 'all',
                      },
                      ...fetchEntityEvaluationVersionByIdData?.entity_ids?.map(
                        (entityId) => ({
                          label: `${
                            isGrammarRule ? 'Regra' : 'Marcador'
                          } ${entityId}`,
                          value: String(entityId),
                        }),
                      ),
                    ]}
                    value={entities}
                    placeholder={`Selecione as ${
                      isGrammarRule ? 'regras' : 'marcadores'
                    }`}
                    name="entitiesSelect"
                  />

                  <label htmlFor="state">
                    Estado {isGrammarRule ? 'das regras' : 'dos marcadores'}
                  </label>
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: 318,
                        borderColor: '#95989A',
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    onChange={(event) =>
                      setEntitiesState({
                        label: event?.label as string,
                        value: event?.value as string,
                      })
                    }
                    options={[
                      {label: 'Todas', value: ''},
                      ...grammarRuleStateOptions,
                    ]}
                    value={entitiesState}
                    placeholder={`Selecione o estado ${
                      isGrammarRule ? 'das regras' : 'dos marcadores'
                    }`}
                    inputId="state"
                    name="entitiesState"
                  />
                </div>

                <NewButton
                  text="Filtrar"
                  kind="primary"
                  userRole="teacher"
                  type="button"
                  isLoading={isFetcingEntityEvaluationVersionById}
                  disabled={isFetcingEntityEvaluationVersionById}
                  onClick={onClickFilter}
                />
              </div>

              {fetchEntityEvaluationVersionSamplesByIdData?.results?.length &&
              !isFetcingEntityEvaluationVersionSamplesById ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th title="Anotação">Anotação</th>
                        <th title="Resultado">Resultado</th>
                        <th title="Left context">Left context</th>
                        <th title="KWIC">KWIC</th>
                        <th title="Right context">Right context</th>
                        <th title={isGrammarRule ? 'Regra' : 'Marcador'}>
                          {isGrammarRule ? 'Regra' : 'Marcador'}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {fetchEntityEvaluationVersionSamplesByIdData?.results?.map(
                        (item) => (
                          <tr key={item.id}>
                            <td>
                              <select
                                id={`annotation-${item.id}`}
                                data-testid={`annotation-${item.id}`}
                                style={{
                                  borderColor: entityEvaluationsMap[item.id]
                                    ? EvaluationColorEnum[
                                        entityEvaluationsMap[item.id]
                                      ]
                                    : EvaluationColorEnum[item.annotation],
                                  color: entityEvaluationsMap[item.id]
                                    ? EvaluationColorEnum[
                                        entityEvaluationsMap[item.id]
                                      ]
                                    : EvaluationColorEnum[item.annotation],
                                }}
                                onChange={(event) =>
                                  onChangeEntityAnnotation(event, item.id)
                                }
                              >
                                <option
                                  value="correct"
                                  style={{color: '#00c341'}}
                                  selected={item.annotation === 'correct'}
                                >
                                  V
                                </option>
                                <option
                                  value="incorrect"
                                  style={{color: '#eb5757'}}
                                  selected={item.annotation === 'incorrect'}
                                >
                                  F
                                </option>
                                <option
                                  value="unset"
                                  style={{color: '#414449'}}
                                  selected={item.annotation === 'unset'}
                                >
                                  N
                                </option>
                              </select>
                            </td>
                            <td title={item.evaluation}>{item.evaluation}</td>
                            <td
                              title={item.text.slice(0, item.kwic_start)}
                              className={styles.leftContext}
                            >
                              {item.text.slice(0, item.kwic_start)}
                            </td>
                            <td
                              title={item.text.slice(
                                item.kwic_start,
                                item.kwic_end,
                              )}
                            >
                              {item.text.slice(item.kwic_start, item.kwic_end)}
                            </td>
                            <td
                              title={item.text.slice(item.kwic_end)}
                              className={styles.rightContext}
                            >
                              {item.text.slice(item.kwic_end)}
                            </td>
                            <td title={String(item.entity_id)}>
                              <div className={styles.entityRedirectWrapper}>
                                <span>{item.entity_id}</span>
                                <Link
                                  to={`/${
                                    isGrammarRule
                                      ? 'regras-gramaticais'
                                      : 'marcadores-textuais'
                                  }/${item.entity_id}/${item.entity_version}`}
                                  title={
                                    isGrammarRule
                                      ? 'Ir aos detalhes da regra'
                                      : 'Ir aos detalhes do marcador'
                                  }
                                >
                                  <Icon
                                    icon={['fas', 'external-link']}
                                    size="1x"
                                    color="#666"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>

                  <div className={styles.paginationWrapper}>
                    <Pagination
                      currentPage={currentPage}
                      hasNext={currentPage < totalNumberOfPages}
                      hasPrevious={currentPage > 1}
                      totalPages={totalNumberOfPages}
                      onChange={onChangePage}
                    />
                  </div>
                </>
              ) : null}

              {!fetchEntityEvaluationVersionSamplesByIdData?.results?.length ||
              isFetchEntityEvaluationVersionSamplesByIdError ? (
                <>
                  <div className={styles.noResults}>
                    <img
                      src={NoDataIllustration}
                      alt="Ilustração de sem resultados"
                    />
                    <h2>Sem resultados</h2>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>

      <FeedbackModal
        isOpen={isAnnotationFeedbackModalOpen}
        title="Anotar resultados"
        subtitle={isAnnotateEvaluationError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isAnnotateEvaluationError
            ? annotateEvaluationErrorData.detail
            : 'As anotações foram feitas.'
        }
        onClose={() => setIsAnnotationFeedbackModalOpen(false)}
        onButtonClick={() => setIsAnnotationFeedbackModalOpen(false)}
        buttonText={isAnnotateEvaluationError ? 'Tentar novamente' : 'Voltar'}
        iconName={isAnnotateEvaluationError ? 'undo' : 'arrow-left'}
      />

      <FeedbackModal
        isOpen={isReexecuteEntityEvaluationFeedbackModalOpen}
        title="Reexecutar avaliação"
        subtitle={isReexecuteEntityEvalutionError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isReexecuteEntityEvalutionError
            ? reexecuteEntityEvaluationErrorData.detail
            : 'A avaliação foi colocada na fila de reexecução.'
        }
        onClose={() => setIsReexecuteEntityEvaluationFeedbackModalOpen(false)}
        onButtonClick={() =>
          setIsReexecuteEntityEvaluationFeedbackModalOpen(false)
        }
        buttonText={
          isReexecuteEntityEvalutionError ? 'Tentar novamente' : 'Voltar'
        }
        iconName={isReexecuteEntityEvalutionError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default EntityEvaluationDetails;
