/* eslint-disable @typescript-eslint/no-unused-vars */
import {Icon, NewButton, Pagination, Search, Modal} from 'letrus-ui';
import {Link, useHistory} from 'react-router-dom';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import NoDataIllustration from 'images/no-data.svg';
import dayjs from 'dayjs';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import DeletionModal from 'components/DeletionModal';
import {
  useDeleteEntityEvalution,
  useFetchEntityEvalutionsLazy,
} from 'store/reducers/entityEvalutions';
import FeedbackModal from 'components/FeedbackModal';
import Tooltip from 'react-tooltip';
import Select from 'react-select';
import {APIComponents, NLPEntityEnum} from 'utils/types/NLPToolsAPI';
import SelectOption from 'utils/types/SelectOption';
import entitiesPerPageOptions from 'utils/entitiesPerPageOptions';
import {AuthRoutes} from 'routes';
import PageWrapper from '../../components/PageWrapper';
import styles from './EntityEvalutionList.module.scss';

const EntityEvalutionList: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [
    isDeleteEntityEvalutionModalOpen,
    setIsDeleteEntityEvalutionModalOpen,
  ] = useState<boolean>(false);
  const [isRerunEntityEvalutionModalOpen, setIsRerunEntityEvalutionModalOpen] =
    useState<boolean>(false);
  const [numberOfTextMarkersPerPage, setNumberOfTextMarkersPerPage] =
    useState<SelectOption | null>({label: '10', value: '10'});
  const [entityEvalutionToDeleteId, setEntityEvalutionToDeleteId] =
    useState<number>(0);
  const [entityEvalutionToRerunId, setEntityEvalutionToRerunId] =
    useState<number>(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const [entityEvalutionType, setPrecisionTestType] = useState<SelectOption>({
    label: 'Todos',
    value: '',
  });

  const [
    fetchEntityEvalutionsLazy,
    {
      isFetching: isFetchingEntityEvaluations,
      isLoading: isLoadingEntityEvaluations,
      isUninitialized: isFetchEntityEvaluationsUninitialized,
      data,
    },
  ] = useFetchEntityEvalutionsLazy();
  const [
    deleteEntityEvalution,
    {
      isLoading: isDeletingEntityEvalution,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
    },
  ] = useDeleteEntityEvalution();

  const history = useHistory();

  useEffect(() => {
    fetchEntityEvalutionsLazy(
      {
        search: searchText,
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }, [currentPage, numberOfTextMarkersPerPage?.value]);

  useEffect(() => {
    if (isDeleteError) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setIsFeedbackModalOpen(true);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteSuccess && !isDeletingEntityEvalution) {
      fetchEntityEvalutionsLazy({}, true);
    }
  }, [isDeleteSuccess, isDeletingEntityEvalution]);

  const totalNumberOfPages = useMemo(
    () =>
      data?.total
        ? Math.ceil(data.total / Number(numberOfTextMarkersPerPage?.value))
        : 0,
    [data],
  );
  const isLoading = isLoadingEntityEvaluations || isFetchingEntityEvaluations;
  const hasFirstEntityEvaluationsFetchHappened =
    !isFetchEntityEvaluationsUninitialized;

  const entityEvalutionTypeOptions = [
    {
      label: 'Regras gramaticais',
      value: 'grammarrule',
    },
    {
      label: 'Marcadores',
      value: 'textmarker',
    },
  ];

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value === '') {
      fetchEntityEvalutionsLazy(
        {limit: numberOfTextMarkersPerPage?.value, offset: 0},
        true,
      );

      setCurrentPage(1);
    }

    const inputValue = event.target.value.toLowerCase();

    setSearchText(inputValue);
  }

  function onClickDeleteEntityEvalution(entityEvalutionId: number) {
    setEntityEvalutionToDeleteId(entityEvalutionId);
    setIsDeleteEntityEvalutionModalOpen(true);
  }

  function onDeleteEntityEvalution() {
    deleteEntityEvalution({entityEvalutionId: entityEvalutionToDeleteId});
    setIsDeleteEntityEvalutionModalOpen(false);
  }

  function onClickRerunEntityEvalution(entityEvalutionId: number) {
    setEntityEvalutionToRerunId(entityEvalutionId);
    setIsRerunEntityEvalutionModalOpen(true);
  }

  function rerunEntityEvalution() {}

  function onChangeEntityEvalutionType(event) {
    setCurrentPage(1);
    setPrecisionTestType(event);

    fetchEntityEvalutionsLazy(
      {
        search: searchText,
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
        entity_type: event.value as NLPEntityEnum,
      },
      true,
    );
  }

  function onSubmitSearch() {
    setCurrentPage(1);

    fetchEntityEvalutionsLazy(
      {
        search: searchText,
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
        entity_type: entityEvalutionType.value as NLPEntityEnum,
      },
      true,
    );
  }

  function resetData() {
    setCurrentPage(1);
    setSearchText('');
    setPrecisionTestType({label: 'Todos', value: ''});

    fetchEntityEvalutionsLazy(
      {
        limit: numberOfTextMarkersPerPage?.value,
        offset: (currentPage - 1) * Number(numberOfTextMarkersPerPage?.value),
      },
      true,
    );
  }

  return (
    <PageWrapper>
      <div className={styles.container}>
        <header>
          <h1>Avaliações de entidade</h1>

          <NewButton
            text="Criar avaliação"
            kind="primary"
            userRole="teacher"
            type="button"
            onClick={() => history.push(AuthRoutes.entityEvalutionCreation)}
          />
        </header>

        {(isLoading || isDeletingEntityEvalution) && (
          <div className={styles.loadingWrapper}>
            <LoadingTable />
          </div>
        )}

        {!isLoading &&
          hasFirstEntityEvaluationsFetchHappened &&
          !data?.results?.length && (
            <div className={styles.noResults}>
              <img
                src={NoDataIllustration}
                alt="Ilustração de sem resultados"
              />
              <h2>Sem resultados</h2>

              <div className={styles.resetDataButtonWrapper} title="Recarregar">
                <NewButton icon={['fas', 'undo']} onClick={resetData} />
              </div>
            </div>
          )}

        {!isLoading && data?.results?.length && !isDeletingEntityEvalution && (
          <div className={styles.tableWrapper}>
            <div className={styles.subHeader}>
              <div className={styles.searchContainer}>
                <Search
                  onClick={onSubmitSearch}
                  id="name"
                  search={{
                    name: 'search',
                    value: searchText,
                    onChange: onChangeSearchText as (
                      eventOrValue:
                        | string
                        | React.SyntheticEvent<Element, Event>,
                    ) => void,
                    // TODO: Type onChange correctly on UI
                  }}
                  searchProps={{
                    placeholder: 'Pesquisar avaliação',
                    searchTag: 'input',
                    required: false,
                  }}
                />
              </div>

              <div
                data-testid="select-wrapper"
                className={styles.selectWrapper}
              >
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: 280,
                      '&:hover': {borderColor: '#5d3d85'},
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#95989A',
                      paddingRight: 12,
                    }),
                    indicatorSeparator: () => ({}),
                  }}
                  onChange={onChangeEntityEvalutionType}
                  options={[
                    {label: 'Todos', value: ''},
                    ...entityEvalutionTypeOptions,
                  ]}
                  value={entityEvalutionType}
                  placeholder="Filtrar por tipo"
                  name="entityEvalutionType"
                />

                <div
                  data-tip=""
                  data-for="numberOfEntitiesPerPageSelect"
                  data-testid="numberOfEntitiesPerPageSelectWrapper"
                  className={styles.numberOfEntitiesPerPageSelectWrapper}
                >
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: 100,
                        '&:hover': {borderColor: '#5d3d85'},
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#95989A',
                        paddingRight: 12,
                      }),
                      indicatorSeparator: () => ({}),
                    }}
                    onChange={(event) => setNumberOfTextMarkersPerPage(event)}
                    options={entitiesPerPageOptions}
                    value={numberOfTextMarkersPerPage}
                    inputId="entityEvaluationVersion"
                    placeholder="Selecione a versão da regra"
                    name="entityEvaluationVersion"
                  />
                </div>

                <Tooltip
                  className={styles.numberOfEntitiesPerPageSelectTooltip}
                  id="numberOfEntitiesPerPageSelect"
                  type="dark"
                  effect="solid"
                  place="top"
                >
                  <div className={styles.wrapper}>
                    <p>Número de categorias por página</p>
                  </div>
                </Tooltip>
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th title="Nome">Nome</th>
                  <th title="Categoria">Precisão das entidades</th>
                  <th title="Subcategoria">Anotados</th>
                  <th title="Comentário">Processados</th>
                  <th title="Data de criação">Data de criação</th>
                  <th title="Ações">Ações</th>
                </tr>
              </thead>

              <tbody>
                {data?.results?.map((item) => (
                  <tr key={item.id}>
                    <td title={item.title}>{item.title}</td>
                    <td
                      data-tip={<></>}
                      data-for="rulesPrecision"
                      className={styles.rulesPrecision}
                    >
                      <Icon icon={['fas', 'search']} />
                      Ver detalhes
                      <Tooltip
                        className={styles.rulesPrecisionTooltipWrapper}
                        id="rulesPrecision"
                        type="dark"
                        effect="float"
                        place="top"
                        multiline
                      >
                        <ul>
                          <li>Regra 1 - 50%</li>
                          <li>Regra 2 - 50%</li>
                          <li>Regra 3 - 50%</li>
                          <li>Regra 4 - 50%</li>
                          <li>Regra 5 - 50%</li>
                        </ul>
                      </Tooltip>
                    </td>
                    <td title={String(item.annotated)}>{item.annotated}</td>
                    <td title={String(item.processed)}>{item.processed}</td>
                    <td title={item.created}>
                      {dayjs(item.created).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      <div className={styles.buttonWrapper}>
                        <Link
                          to={`/avaliacoes-entidade/${item.id}/${
                            item.latest_version || 1
                          }`}
                          title="Ir aos detalhes da avaliação"
                        >
                          <Icon
                            icon={['fas', 'external-link']}
                            color="#666"
                            size="lg"
                          />
                        </Link>

                        <button
                          type="button"
                          className={styles.deleteButton}
                          title="Excluir avaliação"
                          onClick={() => onClickDeleteEntityEvalution(item.id)}
                        >
                          <Icon
                            icon={['fas', 'trash-alt']}
                            color="#666"
                            size="lg"
                          />
                        </button>

                        {item.outdated ? (
                          <button
                            data-tip={<></>}
                            data-for="newRuleVersionWarning"
                            className={styles.info}
                            onClick={() => onClickRerunEntityEvalution(item.id)}
                            type="button"
                            data-testid="rerunEntityEvaluationButton"
                          >
                            <Icon
                              icon={['far', 'repeat-alt']}
                              color="#ffc105"
                              size="lg"
                            />

                            <Tooltip
                              className={
                                styles.newRuleVersionWarningTooltipWrapper
                              }
                              id="newRuleVersionWarning"
                              type="dark"
                              effect="float"
                              place="top"
                              multiline
                            >
                              <p>
                                Essa avaliação possui uma ou mais entidades
                                atualizadas
                              </p>
                            </Tooltip>
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className={styles.paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                hasNext={currentPage < totalNumberOfPages}
                hasPrevious={currentPage > 1}
                totalPages={totalNumberOfPages}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      <DeletionModal
        isOpen={isDeleteEntityEvalutionModalOpen}
        message="Deseja mesmo excluir essa avaliação?"
        onClickDelete={onDeleteEntityEvalution}
        onClose={() => setIsDeleteEntityEvalutionModalOpen(false)}
        subtitle="Tem certeza?"
        title="Excluir avaliação"
      />

      <Modal
        isOpen={isRerunEntityEvalutionModalOpen}
        clickOutsideToClose
        onClose={() => setIsRerunEntityEvalutionModalOpen(false)}
        buttonClose
        minHeight={400}
        minWidth={400}
      >
        <div className={styles.rerunEntityEvalutionModalWrapper}>
          <Icon icon={['far', 'repeat-alt']} size="4x" color="#414449" />

          <h2>Reexecução da avaliação</h2>

          <p>Deseja reexecutar essa avaliação?</p>

          <div className={styles.buttonWrapper}>
            <NewButton
              text="Cancelar"
              type="button"
              onClick={() => setIsRerunEntityEvalutionModalOpen(false)}
            />
            <NewButton
              text="Reexecutar"
              kind="primary"
              type="button"
              userRole="teacher"
              onClick={rerunEntityEvalution}
            />
          </div>
        </div>
      </Modal>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        title="Excluir avaliação de entidade"
        subtitle={isDeleteError ? 'Erro!' : 'Sucesso!'}
        feedbackMessage={
          isDeleteError
            ? 'Ocorreu um erro ao excluir a avaliação, tente novamente.'
            : 'A avaliação foi excluída.'
        }
        onClose={() => setIsFeedbackModalOpen(false)}
        onButtonClick={() => setIsFeedbackModalOpen(false)}
        buttonText={isDeleteError ? 'Tentar novamente' : 'Voltar'}
        iconName={isDeleteError ? 'undo' : 'arrow-left'}
      />
    </PageWrapper>
  );
};

export default EntityEvalutionList;
